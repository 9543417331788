import React from 'react';
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/semantic-ui/tabulator_semantic-ui.min.css';

interface ProjectLinesTableProps {
  data: any;
  onCellEdited: any;
  onDataChanged: any;
  resort: any;
  rightClickMenu: any;
  parseClipboard: any;
}

export default class ProjectLinesTable extends React.Component<ProjectLinesTableProps> {
  ref?: React.ElementRef<any>;

  constructor(props: ProjectLinesTableProps) {
    super(props);
  }

  shouldComponentUpdate(nextProps: any) {
    const currentData = this.props.data;
    const nextData = nextProps.data;
    return currentData.rows.length !== nextData.rows.length;
  }

  render(): React.ReactNode {
    const { data, onCellEdited, onDataChanged, resort, rightClickMenu, parseClipboard } = this.props;
    return (
      <ReactTabulator
        ref={(ref: any) => (this.ref = ref)}
        data={data.rows}
        columns={data.columns}
        clipboard={true}
        clipboardPasteAction='insert'
        cellEdited={(cell: any): any => onCellEdited(cell)}
        dataChanged={(data: any): any => onDataChanged(data)}
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        clipboardPasteParser={(clipboard): Array<RowData> => parseClipboard(clipboard)}
        rowMoved={(): void => resort()}
        options={{
          height: '426px',
          rowContextMenu: rightClickMenu,
          movableRows: true,
        }}
      />
    );
  }
}
