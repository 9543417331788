import React from 'react';
import { FieldTemplateProps } from '@rjsf/core';
import { Form } from 'semantic-ui-react';

export const FieldTemplate: React.FC<FieldTemplateProps> = (props: FieldTemplateProps) => {
  // Return with the label, if it's in a situation where it would be missing
  if (
    props.schema.format
    || props.schema.enum
  ) {
    return (
      <Form.Field required={props.required}>
        <label>{props.schema.title}</label>
        {props.children}
      </Form.Field>
    );
  }

  // Return the field as-is in other situations
  return props.children;
};
