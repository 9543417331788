import React from 'react';
import { FirebaseAuthService } from '../services/AuthService';
import { inject, observer } from 'mobx-react';
import { Loader, Message } from 'semantic-ui-react';
import InvoiceRow from '../pages/subscription/components/InvoiceRow';
import { Subscription } from '../services/PaymentService';


interface TrialAccountMessageState {
  loading: boolean;
}

interface TrialAccountMessageProps {
  auth?: FirebaseAuthService;
  subscriptions: Array<Subscription> | undefined;
  paymentMethodLast4: string | undefined;
}

@inject('auth')
@observer
export default class TrialAccountMessage extends React.Component<TrialAccountMessageProps, TrialAccountMessageState> {

  state: TrialAccountMessageState = {
    loading: true
  };

  async componentDidMount(): Promise<void> {
    this.setState({
      loading: false
    });
  }

  getDaysLeftInTrialSubscription(): number {
    const ONE_DAY = 1000 * 60 * 60 * 24;
    const todayDate = new Date();
    const currentSubscription = this.props.subscriptions ? this.props.subscriptions[0] : undefined;
    const expirationDate = new Date(currentSubscription?.trial_end as number * 1000);
    const start = Date.UTC(expirationDate.getFullYear(), expirationDate.getMonth(), expirationDate.getDate());
    const end = Date.UTC(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());
    return (start - end) / ONE_DAY;
  }

  render(): React.ReactNode {
    const { loading } = this.state;

    if (loading) {
      return <Loader/>;
    }

    const currentSubscription = this.props.subscriptions?.[0];
    const trialEnd = currentSubscription?.trial_end || 0;
    const currentTimeStamp = parseInt(String(+new Date() / 1000));

    // Display Trial message when there are subscriptions and trial period is not ended
    if (currentSubscription && currentTimeStamp < trialEnd) {
      return (
        <Message warning>
          <Message.Header> { this.getDaysLeftInTrialSubscription() } Days Remaining in Trial</Message.Header>
          <p>
            On { InvoiceRow.secondsToISOString(currentSubscription?.trial_end as number) } your credit card ending in
            ****{ this.props.paymentMethodLast4 || '****' } will be charged
            ${ (currentSubscription.plan.amount as number / 100).toFixed(0) } for
            your { currentSubscription.plan.nickname.toLowerCase().includes('monthly') ? 'monthly' : 'annual' } plan,
            and every { currentSubscription.plan.interval } thereafter.
          </p>
        </Message>
      );
    }

    // Otherwise display nothing
    return null;
  }
}
