import React from 'react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { SubscriptionCommonProps } from './components/AddPaymentMethod';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import Page from '../../components/Page';
import Checkout from './components/Checkout';
import ThankYouModal from './components/ThankYouModal';
import TagManagerService from '../../services/TagManagerService';
import { PaymentMethod, Plan, StripeCustomer, Subscription } from '../../services/PaymentService';

export default class ChangeSubscriptionPageContainer extends React.Component<SubscriptionCommonProps> {
  render(): React.ReactNode {
    return (
      <ElementsConsumer>
        {({ stripe, elements }): React.ReactNode => (
          <ChangeSubscriptionPage
            {...this.props}
            stripe={stripe as Stripe}
            elements={elements as StripeElements}
            location={this.props.location}
          />
        )}
      </ElementsConsumer>
    );
  }
}

interface ChangeSubscriptionPageState {
  initialized: boolean;
  isLoading: boolean;
  openedThankYouModal: boolean;
  paymentMethods: Array<PaymentMethod> | undefined;
  showAddCardModal: boolean;
  stripeCustomer: StripeCustomer | undefined;
  subscriptions: Array<Subscription> | undefined;
  trialDays: number | undefined;
}

@inject('stripe')
@inject('auth')
@inject('alert')
@inject('payment')
@observer
class ChangeSubscriptionPage extends React.Component<SubscriptionCommonProps, ChangeSubscriptionPageState> {

  state: ChangeSubscriptionPageState = {
    initialized: false,
    isLoading: false,
    openedThankYouModal: false,
    paymentMethods: [],
    showAddCardModal: false,
    stripeCustomer: undefined,
    subscriptions: undefined,
    trialDays: undefined,
  }

  async componentDidMount(): Promise<void> {
    const [ paymentMethods, subscriptions, trialDays ] = await Promise.all([
      await this.props.payment?.getPaymentMethods(this.props.auth?.user?.stripeCustomerId as string),
      await this.props.payment?.getSubscriptions(),
      await this.props.payment?.getTrialPeriodDays(),
    ]);
    let stripeCustomer;
    if (this.props.auth?.user?.stripeCustomerId) {
      stripeCustomer = await this.props.payment?.getStripeCustomer(this.props.auth?.user?.stripeCustomerId);
    }
    this.setState({
      initialized: true,
      paymentMethods,
      subscriptions,
      stripeCustomer,
      trialDays,
    });
  }

  async onChangePaymentMethods(defaultPaymentMethodId?: string): Promise<void> {
    const stripeCustomer = this.state.stripeCustomer;
    const paymentMethods = await this.props.payment?.getPaymentMethods(this.props.auth?.user?.stripeCustomerId as string);

    if (stripeCustomer && defaultPaymentMethodId) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      stripeCustomer.invoice_settings.default_payment_method = defaultPaymentMethodId;
      this.setState({
        stripeCustomer
      });
    }
    this.setState({
      initialized: true,
      paymentMethods,
      showAddCardModal: false
    });
  }

  async changeSubscriptionAction(plan: Plan): Promise<void> {
    this.setState({ isLoading: true });
    const subscription = await this.props.payment?.changeSubscription(
      this.state.subscriptions![0].id,
      this.state.stripeCustomer as StripeCustomer,
      plan as Plan,
      this.state.trialDays as number
    );
    if (subscription) {
      const updatedSubscriptions = [ subscription ];
      this.setState({
        isLoading: false,
        openedThankYouModal: true,
        subscriptions: updatedSubscriptions,
      });
      TagManagerService.logPurchaseEvent(subscription.plan.amount);
    }
  }

  closeThankYouModal(): void {
    this.setState({ openedThankYouModal: false });
  }

  render(): React.ReactNode {
    const { initialized, paymentMethods, isLoading, showAddCardModal, subscriptions, stripeCustomer, openedThankYouModal, trialDays } = this.state;
    const { elements, stripe, auth, payment } = this.props;
    const { plan } = this.props.location;
    const defaultPaymentMethodId = stripeCustomer?.invoice_settings.default_payment_method;
    const currentPlan = subscriptions?.[0]?.plan;

    if (typeof plan === 'undefined') return <Redirect to='/subscription/select'/>;

    if (!initialized) return <Loader active/>;

    const billInfo = !trialDays || trialDays === 0
      ? 'immediately'
      : `on ${
        new Date(
          new Date().getTime()
          + new Date().getTimezoneOffset()*1000*60
          + trialDays*1000*60*60*24
        ).toLocaleDateString()
      }`;

    return (
      <Page pageTitle='Subscription Change' breadcrumb={[
        { key: 'home', href: '/', content: 'Home', link: true },
        { key: 'subscription_manage', href: '/subscription/manage', content: 'Manage Subscription', link: true },
        { key: 'subscription_select', href: '/subscription/select', content: 'Subscription Selection', link: true },
        { key: 'subscription_change', content: 'Subscription Change', active: true },
      ]}>
        <Checkout
          defaultPaymentMethodId={defaultPaymentMethodId as string}
          auth={auth}
          payment={payment}
          paymentMethods={paymentMethods}
          subscriptions={subscriptions}
          creditCardDetails={paymentMethods}
          isLoading={isLoading}
          plan={plan}
          showAddCardModal={showAddCardModal}
          onTriggerFinalActionClick={(): Promise<void> => this.changeSubscriptionAction(plan)}
          onCloseAddCardModal={(): void => this.setState({ showAddCardModal: false })}
          onChangePaymentMethods={(defaultPaymentMethodId: string | undefined): Promise<void> => this.onChangePaymentMethods(defaultPaymentMethodId)}
          onOpenAddCardModal={(): void => this.setState({ showAddCardModal: true })}
          stripe={stripe}
          stripeCustomer={stripeCustomer}
          elements={elements}
          trialDays={trialDays as number}
        />
        <ThankYouModal
          header='Thank You'
          openedThankYouModal={openedThankYouModal}
          onClose={(): void => this.closeThankYouModal()}
          navigateToAfterClose='/'
        >
          {
            trialDays && trialDays > 0
              ? (
                <React.Fragment>
                  <div>
                    Your subscription has been changed to the {currentPlan?.nickname.toLowerCase()} plan.
                    You will receive a {trialDays}-day trial.  If you cancel the trial before it expires, your
                    credit card will not be charged.
                  </div>
                  <div>
                    Your credit card will be charged ${currentPlan!.amount / 100} in advance
                    starting { billInfo }, for one { currentPlan!.interval } of service.
                    Your credit card will be charged in advance each { currentPlan!.interval } thereafter.
                  </div>
                </React.Fragment>

              ) : (
                <React.Fragment>
                  <div>
                    Your subscription has been changed to the {currentPlan?.nickname.toLowerCase()} plan.
                    Your credit card has been charged ${currentPlan!.amount / 100} in advance for
                    one { currentPlan!.interval } of service. You will automatically be billed in advance
                    each { currentPlan!.interval } hereafter.
                  </div>
                </React.Fragment>
              )
          }
        </ThankYouModal>
      </Page>
    );
  }
}
