import { JSONSchema7 } from 'json-schema';
import { FormValidation } from '@rjsf/core';
import { ProjectJSON } from './ProjectJSON';

export const ProjectJSONSchema: JSONSchema7 = {
  type: 'object',
  required: [ 'name', 'originalContractAmount', 'generalContractorId', 'addressLine1', 'city', 'state', 'zip', 'defaultRetainage' ],
  properties: {
    generalContractorId: {
      type: 'string',
      title: 'General Contractor',
    },
  },
  dependencies: {
    generalContractorId: {
      properties: {
        name: {
          type: 'string',
          title: 'Project Name',
        },
        originalContractAmount: {
          type: 'number',
          title: 'Original Contract Amount',
        },
        defaultRetainage: {
          type: 'number',
          title: 'Default Retainage (% can be changed by line item later)',
        },
        projectId: {
          type: 'string',
          title: 'Project #',
        },
        subcontractNumber: {
          type: 'string',
          title: 'Subcontractor Number',
        },
        addressLine1: {
          type: 'string',
          title: 'Address Line 1',
        },
        addressLine2: {
          type: 'string',
          title: 'Address Line 2',
        },
        city: {
          type: 'string',
          title: 'City',
        },
        state: {
          type: 'string',
          title: 'State',
          enum: [ 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY' ]
        },
        zip: {
          type: 'string',
          title: 'Zip',
        },
      }
    }
  },
};

export const validateProject = (formData: ProjectJSON, errors: FormValidation): FormValidation => {
  // Do some cool custom validation in here
  // If there is an error you can use errors.addError()
  return errors;
};
