import React, { Fragment } from 'react';
import Page from '../../components/Page';
import { DataService } from '../../services/DataService';
import { Link, RouteChildrenProps } from 'react-router-dom';
import Page404 from '../Page404';
import { Button, Form, Grid, Header, Label, Loader, Table } from 'semantic-ui-react';
import ActiveApplication from '../../schemas/applications/ActiveApplication';
import NewApplication from './components/NewApplication';
import SubscriptionRequired from '../../components/SubscriptionRequired';
import { observer, inject } from 'mobx-react';
import { FirebaseAuthService } from '../../services/AuthService';
import PDFList from './components/PDFList';
import { ApplicationLineJSON } from '../../schemas/ApplicationLineJSONSchema';
import { ApplicationLinesType } from '../../common/Constants';
import PaymentService, { Subscription } from '../../services/PaymentService';

const ApplicationLinesTable = (linesType: ApplicationLinesType, applicationLines: Array<ApplicationLineJSON>): React.ReactNode => {
  const fontColor = linesType === ApplicationLinesType.ProjectLineItems ? 'black' : 'red';
  return(
    <div style={{ marginTop: '1em' }}>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={4} style={{ fontSize: '1.2em', color: fontColor, position: 'sticky', top: 48 }}>{linesType}</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell style={{ color: fontColor, position: 'sticky', top: 98 }}>#</Table.HeaderCell>
            <Table.HeaderCell style={{ position: 'sticky', top: 98 }}>Description</Table.HeaderCell>
            <Table.HeaderCell style={{ position: 'sticky', top: 98 }}>Scheduled Value</Table.HeaderCell>
            <Table.HeaderCell style={{ position: 'sticky', top: 98 }}>Percent Complete</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            applicationLines.map(applicationLine => {
              return (
                <Table.Row key={applicationLine.id}>
                  <Table.Cell style={{ color: fontColor }}>{applicationLine.sequenceNumber}</Table.Cell>
                  <Table.Cell>{applicationLine.lineDescription}</Table.Cell>
                  <Table.Cell>{
                    applicationLine.scheduledValue
                      ? applicationLine.scheduledValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                      : ''
                  }</Table.Cell>
                  <Table.Cell>{
                    applicationLine.percentComplete
                      ? Number(applicationLine.percentComplete).toLocaleString('en-US', { style: 'percent' })
                      : ''
                  }</Table.Cell>
                </Table.Row>
              );
            })
          }
        </Table.Body>
      </Table>
    </div>
  );
};

interface ShowApplicationPageProps extends RouteChildrenProps<{ id: string }> {
  auth?: FirebaseAuthService;
  payment?: PaymentService;
}

interface ShowApplicationsPageState {
  loading: boolean;
  error: boolean;
  application?: ActiveApplication;
  subscriptions: Array<Subscription> | undefined;
}

@inject('auth')
@inject('payment')
@observer
export default class ShowApplicationPage extends React.Component<ShowApplicationPageProps, ShowApplicationsPageState> {
  state: ShowApplicationsPageState = {
    loading: true,
    error: false,
    subscriptions: undefined
  }
  dataService = new DataService();

  async componentDidMount(): Promise<void> {
    const application: ActiveApplication = new ActiveApplication(this.props.match?.params.id);

    // Load the application
    await application.load();
    const error = application.error;
    const loading = false;

    const subscriptions = await this.props.payment?.getSubscriptions();

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.setState({ loading, error, application, subscriptions });
  }

  render(): React.ReactNode {
    // Show the loader
    if (this.state.loading) {
      return <Loader active>Loading...</Loader>;
    }

    // Show the error page
    if (this.state.error) {
      return <Page404 {...this.props} />;
    }

    // Show an error if the application has no project ID
    if (!this.state?.application?.project) {
      return (
        <Page pageTitle='Invalid Request'>
          Application {this.state?.application?.id} is orphaned. Please update the database to assign a valid Project
          ID.
        </Page>
      );
    }

    let lines: React.ReactNode;
    if (this.state.application?.originalApplicationLines && this.state.application.originalApplicationLines.length > 0) {
      lines = ApplicationLinesTable(ApplicationLinesType.ProjectLineItems, this.state.application.originalApplicationLines);
    } else {
      lines = (
        <div style={{ marginTop: '1em' }}>
          <Header>{ApplicationLinesType.ProjectLineItems}</Header>
          None
        </div>
      );
    }

    let changeOrders: React.ReactNode;
    if (this.state.application?.changeOrderLines && this.state.application.changeOrderLines.length > 0) {
      changeOrders = ApplicationLinesTable(ApplicationLinesType.ChangeOrders, this.state.application.changeOrderLines);
    } else {
      changeOrders = (
        <div style={{ marginTop: '1em' }}>
          <Header>{ApplicationLinesType.ChangeOrders}</Header>
          None
        </div>
      );
    }

    const statusColor = !this.state.application.status ? 'grey' : {
      Open: 'green',
      Submitted: 'blue',
      Completed: 'grey',
      Rejected: 'red',
      Final: 'grey'
    }[this.state.application.status] as 'blue' | 'green' | 'red' | 'grey';

    return (
      <Page
        pageTitle='Application Summary'
        breadcrumb={[
          { key: 'home', href: '/', content: 'Your Projects', link: true },
          { key: 'project', href: `/projects/${this.state.application.projectId}`, content: 'Project Summary', link: true },
          { key: 'application', content: (this.state.application.status === 'Final' ? 'Final ' : '') + `Application #${this.state.application.sequenceNumber}`, active: true },
        ]}
      >
        <Grid stackable>

          <Grid.Column width={8}>
            <Form style={{ position: 'relative' }}>
              <Form.Group>
              </Form.Group>
              <Form.Field>
                <label>Application Status</label>
                <Label color={statusColor}>{this.state.application.status ? this.state.application.status : 'None'}</Label>
              </Form.Field>
              <Form.Field>
                <label>Project Name</label>
                <span>{this.state.application.project?.name}</span>
              </Form.Field>
              <Form.Field>
                <label>Original Contract Amount</label>
                <span>{
                      this.state.application.project?.originalContractAmount
                        ? this.state.application.project.originalContractAmount.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD'
                        })
                        : ''
                }</span>
              </Form.Field>
              <Form.Field>
                <label>Application Date</label>
                <span>{new Date((this.state.application?.applicationDate as string).replace(/-/g, '/')).toLocaleDateString()}</span>
              </Form.Field>
              <Form.Field>
                <label>Period To</label>
                <span>{new Date((this.state.application?.periodTo as string).replace(/-/g, '/')).toLocaleDateString()}</span>
              </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column width={8}>
            <PDFList application={this.state.application}/>
          </Grid.Column>

          <Grid.Column width={16}>
            <Form style={{ position: 'relative' }}>
              <Form.Field>
                {lines}
                {changeOrders}
              </Form.Field>

              {
                  // Only show the edit button if the application status is open
                  this.state.application?.status === 'Open'
                    ?
                    <Form.Group>
                      {
                        // Show the submit for approval button
                        this.state.application?.allowSubmit
                          ?
                          // TODO: Implement application submission workflow
                          <Fragment>
                            <SubscriptionRequired
                              applicationId={this.state.application.id as string}
                              notSubscribedComponent={<Button as={Link} to='#'>Create Application #{Number(this.state.application.sequenceNumber)+1}</Button>}
                              subscribedComponent={<NewApplication application={this.state.application} onSave={(): void => this.setState({ loading: true })} {...this.props} />}
                              subscriptions={this.state.subscriptions}
                            />
                          </Fragment> : ''
                      }
                      <Button primary as={Link} to={`/applications/${this.state.application?.id}/edit`}>
                        Edit
                      </Button>
                    </Form.Group> : ''
              }
            </Form>
          </Grid.Column>
        </Grid>

      </Page>
    );
  }
}
