import React from 'react';
import { Breadcrumb, Container, Header, Loader } from 'semantic-ui-react';
import AppMenu from './menu/AppMenu';
import { SemanticShorthandCollection } from 'semantic-ui-react/dist/commonjs/generic';
import { BreadcrumbSectionProps } from 'semantic-ui-react/dist/commonjs/collections/Breadcrumb/BreadcrumbSection';
import { ContextMenuList } from './menu/ContextMenu';
import { createMedia } from '@artsy/fresnel';
import { Footer } from './Footer';
import { Link } from 'react-router-dom';
import { widthFix } from '../common/tabulator-utils/AjaxUtils';

interface PageProps {
  pageTitle?: string;
  suppressHeader?: boolean;
  breadcrumb?: SemanticShorthandCollection<BreadcrumbSectionProps>;
  loading?: boolean;
  contextMenu?: Array<ContextMenuList>;
}

export interface BreadcrumbRootObject {
  key: string;
  path: string;
  title: string;
}


export interface BreadcrumbObject {
  key: string;
  href?: string;
  content?: string;
  link: boolean;
}


export const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    computer: 770,
  },
});

export default class Page extends React.Component<PageProps> {

  async componentDidMount(): Promise<void> {
    document.title = this.props.pageTitle ? this.props.pageTitle : '';
  }


  render(): React.ReactNode {
    // Build the header either w/ the breadcrumb or title
    let breadcrumbs: SemanticShorthandCollection<BreadcrumbSectionProps>;
    if (this.props.breadcrumb) {
      breadcrumbs = this.props.breadcrumb;
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      breadcrumbs.forEach((breadcrumb: BreadcrumbSectionProps) => {
        if(breadcrumb.href) {
          breadcrumb.as = () => <Link to={breadcrumb.href as string}>{breadcrumb.content}</Link>;
        }
      });
    }
    const header = (breadcrumbs!) ?
      <Breadcrumb size='huge' icon='right chevron' sections={breadcrumbs!}/>
      // This "|| <div ..." is needed to manipulate a height of the page for the footer
      // TODO https://gitlab.com/blackbittechnologies/aia-manager/-/issues/667
      : this.props.pageTitle || <div style={{ padding: '1px' }}/>;
    const container = (<div>
      <Container className='content-container'>
        {
          (!this.props.suppressHeader)
            ? <Header as="h2">{header}</Header>
            : ''
        }
        <Loader active={this.props.loading}>Loading...</Loader>
        {(this.props.loading) ? '' : this.props.children}
      </Container>
      <Footer/>
    </div>);

    widthFix();
    return (
      <AppMenu contextMenu={this.props.contextMenu} content={container}/>
    );
  }
}
