import React from 'react';
import { AuthServiceInterface } from '../services/AuthService';
import { Message } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import Page from '../components/Page';

interface CheckEmailForPasswordProps {
  auth?: AuthServiceInterface;
}

@inject('auth')
@observer
export default class CheckEmailForPasswordPage extends React.Component<CheckEmailForPasswordProps> {

  render(): React.ReactNode {
    return (
      <Page
        pageTitle='Check Your Email'
      >
        <Message warning>
          <Message.Header>Please check your email address to continue</Message.Header>
          <p>
            We have sent an email to {this.props.auth?.user?.email} with a link to log into the app.
          </p>
          <p>
            Please check your email and click on the link to continue using the system.
            The email will be from noreply@payearned.com.  If you do not see the email, please be sure to check your
            spam folder.
          </p>
        </Message>
      </Page>
    );
  }
}
