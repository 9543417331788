import React from 'react';
import FadeIn from 'react-fade-in';
import { Image } from 'semantic-ui-react';
import {
  StepperAction,
  StepperContent,
  StepperContext
} from 'react-material-stepper';
export const GUIDE_STEP_1 = 'guide-step-one';


interface Step1Props {
  onClose: () => void;
}

const GuideStep1: React.FunctionComponent<Step1Props> = ({ onClose }: Step1Props) => {
  const { resolve } = React.useContext(StepperContext);

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    resolve('');
  };

  return (
    <StepperContent
      onSubmit={onSubmit}
      actions={
        <React.Fragment>
          <StepperAction onClick={ (): void => onClose() }>Close</StepperAction>
          <StepperAction align='right' type="button" disabled/>
          <StepperAction align='right' type="submit">Next</StepperAction>
        </React.Fragment>
      }
    >
      <FadeIn>
        <Image src='/gif/1-Create-Project-with-GC.gif' size='big' centered rounded/>
      </FadeIn>
    </StepperContent>
  );
};

export default GuideStep1;
