import React from 'react';
import Page from '../components/Page';
import { List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default class TestPage extends React.Component {
  render(): React.ReactNode {


    return (
      <Page pageTitle={`Welcome to ${process.env.REACT_APP_APP_NAME}!`}>
        <List divided relaxed>
          <List.Item>
            <List.Icon name="paperclip"/>
            <List.Content>
              <List.Header as={Link} to="/applications">Applications</List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="building"/>
            <List.Content>
              <List.Header as={Link} to="/projects">Projects</List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="address book"/>
            <List.Content>
              <List.Header as={Link} to="/general-contractors">General Contractors</List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="user"/>
            <List.Content>
              <List.Header as={Link} to="/profile">Profile</List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="credit card outline"/>
            <List.Content>
              <List.Header as={Link} to="/subscription">Subscribe to Plan</List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="calculator"/>
            <List.Content>
              <List.Header as={Link} to="/calculation-test">Calculation Form Demo</List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="pencil alternate"/>
            <List.Content>
              <List.Header as={Link} to="/invoices">Billing History</List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="numbered list"/>
            <List.Content>
              <List.Header as={Link} to="/bank-accounts">Bank Accounts</List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="file archive"/>
            <List.Content>
              <List.Header as={Link} to="/receipts">Receipt History</List.Header>
            </List.Content>
          </List.Item>
        </List>
      </Page>
    );
  }
}
