import React from 'react';
import { inject, observer } from 'mobx-react';
import Page from '../../components/Page';
import { Table, TableHeader } from 'semantic-ui-react';
import { SubscriptionCommonProps } from './components/AddPaymentMethod';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import InvoiceRow from './components/InvoiceRow';
import { Invoice } from '../../services/PaymentService';


export  default class InvoicesPageContainer extends React.Component{
  render(): React.ReactNode{
    return (
      <ElementsConsumer>
        {( { stripe, elements } ): React.ReactNode => (
          <InvoicesPage stripe={stripe as Stripe} elements={elements as StripeElements}/>
        )}
      </ElementsConsumer>
    );
  }
}

interface InvoicesPageState {
  invoices?: Array<Invoice> | undefined;
}

@inject('stripe')
@inject('auth')
@inject('payment')
@observer
class InvoicesPage extends React.Component<SubscriptionCommonProps, InvoicesPageState>{

  async componentDidMount(): Promise<void> {
    let invoices;
    if (this.props.auth?.user?.stripeCustomerId) {
      invoices = await this.props.payment?.getAllInvoices();
    }
    this.setState({
      invoices
    });
  }

  render(): React.ReactNode {

    return (
      <Page pageTitle='Billing History'  breadcrumb={[
        { key: 'home', href: '/', content: 'Your Projects', link: true },
        { key: 'invoices', content: 'Billing History', active: true },
      ]}>
        <br/>
        <Table celled sortable fixed size='small'>
          <TableHeader>
            <Table.HeaderCell>
                Created
            </Table.HeaderCell>
            <Table.HeaderCell>
                Amount
            </Table.HeaderCell>
            <Table.HeaderCell>
                Invoice Preview
            </Table.HeaderCell>
            <Table.HeaderCell>
                Download invoice PDF
            </Table.HeaderCell>
          </TableHeader>
          <InvoiceRow
            stripe={this.props.stripe}
            invoices={this.state.invoices}
            elements={this.props.elements}
            payment={this.props.payment}
          />
        </Table>
      </Page>
    );
  }

}
