import React from 'react';
import Page from '../../components/Page';
import { DataService } from '../../services/DataService';
import { Loader, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ApplicationJSON } from '../../schemas/applications/ApplicationJSON';

interface ListApplicationsState {
  loading: boolean;
  applications: Array<ApplicationJSON>;
}

export default class ListApplications extends React.Component<{}, ListApplicationsState> {
  state: ListApplicationsState = {
    loading: true,
    applications: [],
  }
  dataService = new DataService();

  async componentDidMount(): Promise<void> {
    const applications = await this.dataService.list('applications') as unknown as Array<ApplicationJSON>;
    this.setState({ loading: false, applications });
  }

  render(): React.ReactNode {
    // Show the loader
    if (this.state.loading) { return <Loader active />; }

    let applicationList;
    if (this.state.applications && this.state.applications.length > 0) {
      applicationList = (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Application Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              this.state.applications.map(application => {
                return (
                  <Table.Row key={application.id}>
                    <Table.Cell>
                      <Link to={`${window.location.pathname}/${application.id}`}>
                        {new Date(application.applicationDate as Date|string).toLocaleDateString()}
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                );
              })
            }
          </Table.Body>
        </Table>
      );
    }

    return (
      <Page breadcrumb={[
        { key: 'home', href: '/', content: 'Your Projects', link: true },
        { key: 'applications', content: 'Applications', active: true },
      ]}>
        { applicationList }
      </Page>
    );
  }
}
