import React, { CSSProperties } from 'react';
import { UserJSON } from '../../../schemas/UserJSONSchema';
import { Card, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { FirebaseAuthService } from '../../../services/AuthService';
import ActiveApplication from '../../../schemas/applications/ActiveApplication';
import CookieService from '../../../services/CookieService';
import {
  APPLICATION_FOR_PAYMENT_PDF_PATH,
  CHANGE_ORDERS_PDF_PATH,
  CONTINUATION_SHEET_PDF_PATH
} from '../../../common/Constants';

interface PDFListProps {
  auth?: FirebaseAuthService;
  cookie?: CookieService;
  application: ActiveApplication;
  style?: CSSProperties;
  title?: string;
}

@inject('auth')
@inject('cookie')
@observer
export default class PDFList extends React.Component<PDFListProps> {
  render(): React.ReactNode {
    if (this.props.application?.project?.generalContractor) {
      // TODO: Replace with subcontractor relationship in project, once it gets added
      const subcontractor = (this.props.auth ? this.props.auth.user : {}) as UserJSON;

      const style = this.props.style ? this.props.style : {};
      style.fontSize = '1.2rem';
      style.marginBottom = '1em';

      return (
        <Card raised style={ style }>
          <Card.Content>
            <Card.Header
              style={ { fontSize: '1.4rem' } }>{ this.props.title ? this.props.title : `Application #${ this.props.application.sequenceNumber } Documents` }</Card.Header>
            <Card.Description>
              <List>
                <List.Item>
                  <List.Icon name='file'/>
                  <List.Content>
                    <Link target={ '_blank' }
                      to={ {
                        pathname: APPLICATION_FOR_PAYMENT_PDF_PATH + `${ this.props.application.id }` ,
                      } }>
                      { this.props.application.status === 'Final' ? 'Final ' : '' }
                      Application for Payment
                    </Link>
                  </List.Content>
                </List.Item>
                {
                  this.props.application.status !== 'Final'
                    ? (
                      <React.Fragment>
                        <List.Item>
                          <List.Icon name='file'/>
                          <List.Content>
                            <Link target={ '_blank' }
                              to={ { pathname: CONTINUATION_SHEET_PDF_PATH + `${ this.props.application.id }` }  }>
                              Continuation Sheet
                            </Link>
                          </List.Content>
                        </List.Item>
                        {
                          this.props.application?.changeOrders.length > 0 ?
                            <List.Item>
                              <List.Icon name='file'/>
                              <List.Content>
                                <Link target={ '_blank' }
                                  to={ { pathname: CHANGE_ORDERS_PDF_PATH + `${ this.props.application.id }` } }>Change
                                  Orders</Link>
                              </List.Content>
                            </List.Item> : ''
                        }
                      </React.Fragment>
                    ) : (
                      ''
                    )
                }
              </List>
            </Card.Description>
          </Card.Content>
        </Card>
      );
    }
    return '';
  }
}
