import React from 'react';
import { ProjectLineJSON } from '../../schemas/ProjectLineSchema';
import { DataService } from '../../services/DataService';
import { Button, Loader, Popup, Table, Modal, Icon, Header, Form } from 'semantic-ui-react';
import Page from '../../components/Page';
import { Link, RouteChildrenProps } from 'react-router-dom';
import Page404 from '../Page404';
import { STEP3 } from '../../components/change-orders/new-stepper/steps';
import { StepId } from 'react-material-stepper/src/typings';
import ActiveProject from '../../schemas/projects/ActiveProject';
import NewChangeOrderStepper from '../../components/change-orders/new-stepper/NewChangeOrderStepper';
import EditChangeOrderStepper from '../../components/change-orders/new-stepper/EditChangeOrderStepper';
import { STEP4 } from '../../components/change-orders/new-stepper/steps/step4';

interface ListChangeOrdersState {
  loading: boolean;
  error: boolean;
  changeOrders: Array<ProjectLineJSON> | undefined;
  showNewCOModal: boolean;
  showEditCOModal: boolean;
  changeOrderToEdit: ProjectLineJSON | undefined;
  isCancelInitialized: boolean;
}

export default class NewListChangeOrders extends React.Component<RouteChildrenProps<{ id: string }>, ListChangeOrdersState> {

  state: ListChangeOrdersState = {
    loading: true,
    error: false,
    changeOrders: [],
    showNewCOModal: false,
    showEditCOModal: false,
    changeOrderToEdit: undefined,
    isCancelInitialized: false
  }

  dataService = new DataService();
  activeProject = new ActiveProject(this.props.match?.params.id);

  async componentDidMount(): Promise<void> {
    await this.activeProject.load();
    await this.activeProject.calculate();
    this.setState({  loading: false, changeOrders: this.activeProject.changeOrders });
  }

  private async deleteChangeOrder(projectLineId: string): Promise<void> {
    this.setState({ loading: true });
    await this.dataService.del('project-lines', projectLineId);
    await this.activeProject.load();
    await this.activeProject.calculate();
    this.setState({  loading: false, changeOrders: this.activeProject.changeOrders });
  }

  private async closeModal(stepId: StepId): Promise<void> {
    if (stepId === STEP3 || stepId === STEP4) {
      await this.activeProject.load();
      await this.activeProject.calculate();
      this.setState({ showNewCOModal: false, showEditCOModal: false, changeOrders: this.activeProject.changeOrders });
    }
  }

  render(): React.ReactNode {
    console.log(this.state.isCancelInitialized);
    // Show the loader
    if (this.state.loading) {
      return <Loader active/>;
    }

    // Show the error page
    if (this.state.error) {
      return <Page404 {...this.props} />;
    }

    let changeOrdersTable;
    if (this.props.match?.params.id && this.state.changeOrders && this.state.changeOrders.length > 0) {
      changeOrdersTable = (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Actions</Table.HeaderCell>
              <Table.HeaderCell>App #</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Scheduled Value</Table.HeaderCell>
              <Table.HeaderCell>Retainage</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              this.state.changeOrders.map(changeOrder => {
                return (
                  <Table.Row key={changeOrder.id}>
                    <Table.Cell>
                      {
                        changeOrder.id == null ? '' :
                          <div>
                            <span>
                              <Button
                                icon='edit'
                                primary
                                onClick={(): void => this.setState({ showEditCOModal: true, changeOrderToEdit: changeOrder })}
                              />
                            </span>
                            <Popup
                              content={changeOrder.billedToDate !== 0 ? 'Change order has been billed, cannot delete' : 'Delete change order'}
                              trigger={
                                <span>
                                  <Modal
                                    size='small'
                                    trigger={
                                      <Button
                                        color='red'
                                        icon='delete'
                                        disabled={changeOrder.billedToDate !== 0}
                                      />
                                    }
                                    header='Delete change order'
                                    content={`Are you sure you want to delete ${changeOrder.description}`}
                                    actions={[
                                      'Cancel',
                                      {
                                        key: 'done',
                                        content: 'Delete',
                                        negative: true,
                                        onClick: (): Promise<void> => this.deleteChangeOrder(changeOrder.id as string)
                                      }
                                    ]}
                                  />
                                </span>
                              }
                            />
                          </div>
                      }
                    </Table.Cell>
                    <Table.Cell>{changeOrder.firstApplicationSequenceNumber}</Table.Cell>
                    <Table.Cell>{changeOrder.description}</Table.Cell>
                    <Table.Cell>{
                      changeOrder.scheduledValue
                        ? changeOrder.scheduledValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                        : ''
                    }</Table.Cell>
                    <Table.Cell>{
                      changeOrder.retainage ? Number(changeOrder.retainage).toLocaleString('en-US', { style: 'percent' }) : '0%'
                    }</Table.Cell>
                  </Table.Row>
                );
              })
            }
          </Table.Body>
        </Table>
      );
    } else {
      changeOrdersTable = (
        <Header as='h4'>There are no change orders for this project</Header>
      );
    }

    return (
      <Page
        pageTitle='Change Orders'
        breadcrumb={[
          { key: 'home', href: '/', content: 'Your Projects', link: true },
          { key: 'project', href: `/projects/${this.props.match?.params.id}`, content: 'Project Summary', link: true },
          { key: 'change-orders', content: 'Change Orders', active: true },
        ]}
      >
        {changeOrdersTable}
        <Form.Group>
          <Button as={Link} to={`/projects/${this.props.match?.params.id}`}>
            Cancel
          </Button>
          <Button primary onClick={(): void => this.setState({ showNewCOModal: true ,isCancelInitialized: false })}>
            <Icon name="plus"/>
            New Change Order
          </Button>
        </Form.Group>

        {
          this.state.isCancelInitialized ? null : <div> <Modal
            open={this.state.showNewCOModal}
            onOpen={(): void => this.setState({ showNewCOModal: true })}
            onClose={(): void => this.setState({ showNewCOModal: false })}
            size='small'
          >
            <NewChangeOrderStepper onResolveCancel={(): void => this.setState({ isCancelInitialized: true })} project={this.activeProject} closeModalCallback={(stepId: StepId): Promise<void> => this.closeModal(stepId)}/>
          </Modal>
          <Modal
            open={this.state.showEditCOModal}
            onOpen={(): void => this.setState({ showEditCOModal: true })}
            onClose={(): void => this.setState({ showEditCOModal: false })}
            size='small'
          >
            <EditChangeOrderStepper project={this.activeProject} closeModalCallback={(stepId: StepId): Promise<void> => this.closeModal(stepId)} changeOrderToEdit={this.state.changeOrderToEdit}/>
          </Modal></div>
        }
      </Page>
    );
  }
}
