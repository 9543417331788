import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { FirebaseAuthService } from '../services/AuthService';
import { Loader } from 'semantic-ui-react';
import LoginPage from '../pages/LoginPage';
import EmailVerificationRequiredPage from '../pages/EmailVerificationRequiredPage';
import { BreadcrumbRootObject } from './Page';

export interface ProtectedRouteProps extends RouteProps {
  auth?: FirebaseAuthService;
  loaderContent?: string;
  breadcrumbs?: Array<BreadcrumbRootObject>;
}

@inject('auth')
@observer
export default class ProtectedRoute extends Route<ProtectedRouteProps> {
  render(): React.ReactNode {
    const { auth, loaderContent, path } = this.props;

    // If auth isn't initialized, show a spinner
    if (!auth?.initialized) {
      return <Loader active={true}>
        {typeof loaderContent !== 'undefined' ? <div>{loaderContent}</div> : <div>Loading...</div>}
      </Loader>;
    }

    if (auth?.user?.id) {
      // If the user doesn't have a verified email, render the email verification required page
      if (!auth.user.emailVerified) {
        return <EmailVerificationRequiredPage/>;
      }
      return <Route {...this.props as ProtectedRouteProps}/>;
    }
    // Otherwise, return the LoginPage component
    // return <LoginPage/>;
    return <Redirect to='/login' />;
  }
}
