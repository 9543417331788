import React, { ChangeEvent } from 'react';
import { Button, Checkbox, Form,  Message, Modal, Segment } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Redirect, Link } from 'react-router-dom';
import { TermsOfService } from '../../pages/terms/components/TermsOfService';
import EmailVerificationRequiredPage from '../../pages/EmailVerificationRequiredPage';
import { CheckboxProps } from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import { EMAIL_IS_ALREADY_IN_USE_ERROR } from '../../common/Constants';
import TagManagerService from '../../services/TagManagerService';
import { AuthProps } from '../../pages/SignupPage';


export type role = 'SUB_CONTRACTOR' | 'GENERAL_CONTRACTOR' | 'unassigned' | 'ADMINISTRATOR';

interface SignupState {
  role: role;
  email: string;
  password: string;
  confirmPassword: string;
  passwordStrength: number;
  agreeToTermsOfService: boolean;
  openedTermsOfService: boolean;
  errorMessage: string | undefined;
  loading: boolean;
  touched: Array<string>;
  focused: string;
}

const email = 'email';
const password = 'password';
const confirmPassword = 'confirmPassword';

@inject('auth')
@inject('cookie')
@observer
export default class EmailPasswordSignup extends React.Component<AuthProps> {

  state: SignupState = {
    role: 'SUB_CONTRACTOR',
    email: '',
    password: '',
    confirmPassword: '',
    passwordStrength: 0,
    agreeToTermsOfService: false,
    openedTermsOfService: false,
    errorMessage: undefined,
    loading: false,
    touched: [],
    focused: ''
  }

  private async signUp(): Promise<void> {
    this.setState({ loading: true });
    if (this.props.auth) {
      try {
        await this.props?.auth.signup(this.state.email, this.state.password, this.state.role, this.props.cookie?.appCookies?.referral as string);
        this.setState({ errorMessage: undefined });
        TagManagerService.logSignupEvent(this.state.email);
      } catch (e) {
        this.setState({ errorMessage: e.message, loading: false });
      }
    }
  }

  displayErrorMessage(): React.ReactNode {

    if(typeof this.state.errorMessage !== 'undefined' && typeof this.state.errorMessage.startsWith(EMAIL_IS_ALREADY_IN_USE_ERROR)){
      return (
        <Message error>
          {this.state.errorMessage}
          <br/>
          <Link to={{ pathname: '/login', state: { email:this.state.email } }}>Log in with this email.</Link>
        </Message>
      ) ;
    }

    if (this.invalidEmail()) {
      return <Message error content='Invalid email address'/>;
    }

    if (this.invalidStrength()) {
      return <Message error content='Weak password. Add another uncommon word or two'/>;
    }

    if (this.notMatchingPasswords() && this.state.confirmPassword.length > 0) {
      return (
        <Message error content="Passwords don't match" />
      );
    }

    if (this.state.errorMessage) {
      return (
        <Message negative content={this.state.errorMessage}/>
      );
    }
  }

  invalidEmail(): boolean {
    return this.state.focused !== email
      && this.state.touched.includes(email)
      && this.state.email.match(/^[a-z0-9][a-z0-9+.]*@[a-z0-9-]+(\.[a-z0-9-])+/) === null;
  }

  invalidStrength(): boolean {
    return this.state.password.length > 0 && this.state.touched.includes(password) && this.state.passwordStrength < 2;
  }

  notMatchingPasswords(): boolean {
    return this.state.password !== this.state.confirmPassword;
  }

  onChangeEmail(e: React.ChangeEvent<HTMLInputElement>): void {
    let touched = this.state.touched;
    touched.push(email);
    touched = touched.filter((v, i, a) => a.indexOf(v) === i);
    this.setState({ email: e.target.value.trim(), touched: touched });
  }

  onChangePassword(e: React.ChangeEvent<HTMLInputElement>): void {
    let touched = this.state.touched;
    touched.push(password);
    touched = touched.filter((v, i, a) => a.indexOf(v) === i);
    this.setState({ password: e.target.value.trim(), touched: touched });
  }

  onChangeScore(score: number): void {
    this.setState({ passwordStrength: score });
  }

  onFocus(fieldName: string): void {
    this.setState({ focused: fieldName });
  }

  openModal(isOpen: boolean): void {
    return this.setState({ openedTermsOfService: isOpen });
  }

  render(): React.ReactNode {

    if (this.props.auth?.user?.id) {
      setTimeout(() => {
        this.setState({ loading: false });
      });
      if (!this.props.auth.user.emailVerified) {
        return <EmailVerificationRequiredPage firstLogin={true} />;
      }
      return <Redirect to="/"/>;
    }

    return (
      <span>
        <div style={{ width: '420px', height: '60px', display: 'block', marginBottom: '10px' }}>
          {this.displayErrorMessage()}
        </div>
        <Form size='large'>
          <Segment stacked>
            <Form.Input
              required
              type="email"
              error={this.invalidEmail()}
              placeholder="E-mail address"
              value={this.state.email}
              onFocus={(): void => this.onFocus(email)}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => this.onChangeEmail(e)}
            />
            <Form.Input
              required
              type="password"
              error={this.invalidStrength()}
              placeholder="Create a Password"
              value={this.state.password}
              onFocus={(): void => this.onFocus(password)}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => this.onChangePassword(e)}
            />
            <PasswordStrengthBar password={this.state.password} onChangeScore={(score): void => this.onChangeScore(score)}/>
            <Form.Input
              required
              type="password"
              error={this.notMatchingPasswords()}
              placeholder="Confirm Password"
              value={this.state.confirmPassword}
              onFocus={(): void => this.onFocus(confirmPassword)}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => this.setState({ confirmPassword: e.target.value.trim() })}
            />
            <p style={{ textAlign: 'left' }}>
              <Checkbox
                label='I agree to the'
                checked={this.state.agreeToTermsOfService}
                onChange={(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps): void => this.setState({ agreeToTermsOfService: data.checked })}
              />
              <a onClick={(): void => this.openModal(true)}> Terms of Service</a>
            </p>
            <Button
              disabled={!this.state.email || !this.state.password || !this.state.confirmPassword || this.invalidEmail() || this.state.loading
            || this.invalidStrength() || this.notMatchingPasswords() || !this.state.agreeToTermsOfService}
              color='blue'
              fluid
              size='large'
              loading={this.state.loading}
              onClick={(): Promise<void> => this.signUp()}
            >
              {
                this.state.loading
                  ? <span>&nbsp;</span>
                  : 'Signup'
              }
            </Button>
          </Segment>
          <Modal
            open={this.state.openedTermsOfService}
            onClose={(): void => this.openModal(false)}
            onOpen={(): void => this.openModal(true)}
          >
            <Modal.Header>Terms of Service</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <TermsOfService/>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button primary onClick={(): void => this.openModal(false)}>
              Close
              </Button>
            </Modal.Actions>
          </Modal>
        </Form>
      </span>
    );
  }
}
