import React, { ChangeEvent } from 'react';
import { Button, Form, Header, Icon, Loader, Message, Segment } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { AuthServiceInterface } from '../services/AuthService';
import CenteredGrid from '../components/CenteredGrid';

interface AuthProps {
  auth?: AuthServiceInterface;
}

interface ForgotPasswordState {
  waitingConfirmation: boolean;
  confirmationMessage: string;
  email: string;
  password: string;
  errorMessage: string;
}

@inject('auth')
@observer
export default class ForgotPasswordPage extends React.Component<AuthProps, ForgotPasswordState> {
  state: ForgotPasswordState = {
    waitingConfirmation: false,
    confirmationMessage: '',
    email: '',
    password: '',
    errorMessage: '',
  }

  private async sendPasswordResetEmail(): Promise<void> {
    if (this.props.auth && this.state.email) {
      try {
        this.setState({ waitingConfirmation: true });
        await this.props.auth.sendPasswordResetEmail(this.state.email);
        this.setState({ confirmationMessage: 'Check your email', waitingConfirmation: false });
      } catch (e) {
        this.setState({ errorMessage: e.message, waitingConfirmation: false });
      }
    }
  }

  displayResultMessage(): React.ReactNode {
    if (this.state?.confirmationMessage) {
      return (
        <CenteredGrid>
          <Icon size='huge' name='envelope'/>
          <Message compact centered size='large' success>
            <Message.Header>
              {this.state.confirmationMessage}
            </Message.Header>
            <p>
              We just sent you an email with a link to reset your password
            </p>
          </Message>
        </CenteredGrid>
      );
    } else if (this.state?.errorMessage) {
      return (
        <CenteredGrid>
          <Icon size='huge' name='exclamation triangle'/>
          <Message compact centered size='large' negative>
            <Message.Header>
              Error sending email
            </Message.Header>
            <p>
              {this.state.errorMessage}
            </p>
          </Message>
        </CenteredGrid>
      );
    }
  }

  invalidEmail(): boolean {
    return this.state.email.match(/^[a-z0-9][a-z0-9+.]*@[a-z0-9-]+(\.[a-z0-9-])+/) === null;
  }

  render(): React.ReactNode {
    if (!this.props.auth?.initialized || this.state.waitingConfirmation) {
      return <Loader active={true}>Loading...</Loader>;
    }
    if (this.state.confirmationMessage || this.state.errorMessage) {
      return this.displayResultMessage();
    }
    return (
      <CenteredGrid>
        <Icon size='huge' name='lock'/>
        <Header as='h2' color='blue' textAlign='center'>
          Forgot Password?
        </Header>
        <Header as='h3' color='blue' textAlign='center'>
          You can reset your password here
        </Header>
        <Form size='large'>
          <Segment stacked>
            <Form.Input
              required
              type="email"
              placeholder="E-mail address"
              value={this.state.email}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => this.setState({ email: e.target.value.trim() })}
            />
            <Button
              color='blue'
              fluid
              size='large'
              disabled={this.invalidEmail()}
              onClick={(): Promise<void> => this.sendPasswordResetEmail()}
            >
              Reset Password
            </Button>
          </Segment>
        </Form>
      </CenteredGrid>
    );
  }
}
