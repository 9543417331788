import ActiveApplication from '../schemas/applications/ActiveApplication';

const filterApplicationChangeOrders = (application: ActiveApplication): void => {
  if (application?.project?.projectLines) {
    application.applicationLines = application.applicationLines.filter(
      // Bring it in if we have an original project line
      line => line.projectLine && line.projectLine.lineType === 'original' || (
        // Or we have a change order with a firstApplicationSequence number that
        // matches the current or a prior application
        line.projectLine
        && line.projectLine.lineType === 'changeOrder'
        && application.sequenceNumber
        && line.projectLine.firstApplicationSequenceNumber && (
          line.projectLine.firstApplicationSequenceNumber <= application.sequenceNumber
        )
      )
    );
    // Update the change order lines using the filtered application lines
    application.changeOrderLines = application.applicationLines.filter(line => line?.projectLine?.lineType === 'changeOrder');
  }
};

export default filterApplicationChangeOrders;
