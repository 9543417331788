import React from 'react';
import { SubscriptionCommonProps } from './AddPaymentMethod';
import {
  Button,
  FormInput,
  Image,
  InputOnChangeData,
  Label,
  ListContent,
  ListHeader,
  ListItem,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Radio
} from 'semantic-ui-react';
import { BankAccountObj, StripeCustomer } from '../../../services/PaymentService';


interface BankAccountProps extends SubscriptionCommonProps {
  account: BankAccountObj;
  checked: boolean;
  label?: string;
  onChange: (accountId: string) => void;
  stripeCustomer: StripeCustomer | undefined;
  value: string;
}

interface BankAccountState {
  verifyTriggered: boolean;
  depositOne: number | null;
  depositTwo: number | null;
  depositOneError: string;
  depositTwoError: string;
}


export default class BankAccount extends React.Component<BankAccountProps> {

  state: BankAccountState = {
    verifyTriggered: false,
    depositOne: null,
    depositTwo: null,
    depositOneError: '',
    depositTwoError: ''
  }

  private async triggerOnChange(accountId: string): Promise<void> {
    this.props.onChange(accountId);
  }

  private async triggerVerifyButton(): Promise<void> {
    this.setState({ verifyTriggered: true });
  }

  private async triggerBankAccountVerify(accountId: string): Promise<void> {
    const { depositOne, depositTwo } = this.state;
    const depositQueue: Array<number> = [ depositOne as number, depositTwo as number ];
    if (depositQueue.length === 2) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      this.props.payment?.verifyBankAccount(
        accountId as string,
        depositQueue).then(async function (bankAccount) {
          if (bankAccount) {
            self.setState({ verifyTriggered: false });
          }
        });
    }
  }

  async handleOnChange(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): Promise<void> {
    this.setState({ [e.target.name]: data.value });
  }

  private getBankAccount(bankAccount: BankAccountObj): BankAccountObj|null{
    const expectedBankAccount =  this.props.stripeCustomer?.sources.data.find(account => account.id === bankAccount.id);
    return expectedBankAccount ? expectedBankAccount : null;
  }

  render(): React.ReactNode {
    const { account, checked, label, value } = this.props;
    const { verifyTriggered, depositOne, depositTwo, depositOneError, depositTwoError } = this.state;
    return (
      <ListItem>
        <Radio
          name='radioGroup'
          checked={ checked }
          value={ value }
          label={ label }
          onChange={ (): Promise<void> => this.triggerOnChange(account.id) }
          style={ { marginTop: '10px' } }
        />
        {
          account !== null ? <Image spaced="right" size="mini" src={ null }/> : null
        }
        <ListContent style={ { marginLeft: 10, color: 'grey' } }>
          <ListHeader> { account.bank_name } **** { account.last4 } </ListHeader>
          { account.currency.toUpperCase() }
        </ListContent>
        {
          this.getBankAccount(account)?.status === 'new' ? <Label tag style={ { left: '20px', marginBottom: '0px', bottom: '5px' } }>Pending
            Verification</Label> : <Label tag color='yellow' style={ { left: '20px', marginBottom: '0px', bottom: '5px' } }>Verified</Label>
        }
        {
          this.props.stripeCustomer?.default_source === account.id &&  this.getBankAccount(account)?.status === 'verified' ?
            <Label tag style={ { left: '20px', marginBottom: '0px', bottom: '5px' } } color="blue">Default</Label> : null
        }
        {
          this.getBankAccount(account)?.status === 'new' ? <Button.Group size='mini' style={ { marginLeft: '110px' } }>
            <Button primary onClick={ (): Promise<void> => this.triggerVerifyButton() }>Verify</Button>
            <Button.Or/>
            <Button>Delete</Button>
          </Button.Group> : <Button style={{ marginLeft: '133px' }}>Delete</Button>
        }
        <Modal
          centered
          size='small'
          open={ verifyTriggered }
        >
          <ModalHeader>
            Verify Bank Account
          </ModalHeader>
          <ModalContent>
            <Label>First deposit</Label>
            <FormInput
              label='$'
              width='5'
              type='number'
              value={ depositOne }
              name='depositOne'
              placeholder='32'
              style={ { marginTop: '10px' } }
              error={ depositOneError ? depositOneError : null }
              onChange={ (e, d): Promise<void> => this.handleOnChange(e, d) }
            />

            <Label style={ { marginTop: '10px' } }>Second deposit</Label>
            <FormInput
              label='$'
              width='5'
              type='number'
              value={ depositTwo }
              name='depositTwo'
              placeholder='45'
              style={ { marginTop: '10px' } }
              error={ depositTwoError ? depositTwoError : null }
              onChange={ (e, d): Promise<void> => this.handleOnChange(e, d) }
            />
          </ModalContent>
          <ModalActions>
            <Button primary onClick={ (): Promise<void> => this.triggerBankAccountVerify(account.id) }>Verify</Button>
            <Button onClick={ (): void => this.setState({ verifyTriggered: false }) }>Cancel</Button>
          </ModalActions>
        </Modal>
      </ListItem>
    );
  }
}
