import React from 'react';
import { Form, Message, MessageContent, MessageHeader } from 'semantic-ui-react';

interface FirstApplicationProps {
  sequenceNumber?: number;
  firstApplication: boolean;
  onChange: (sequenceNumber: number) => void;
}

interface FirstApplicationState {
  sequenceNumber: number;
}

export default class FirstApplication extends React.Component<FirstApplicationProps, FirstApplicationState> {
  state: FirstApplicationState = {
    sequenceNumber: this.props.sequenceNumber !== undefined ? this.props.sequenceNumber : 1
  };

  onChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const sequenceNumber = Number(e.target.value);
    this.setState({ sequenceNumber });
    this.props.onChange(sequenceNumber);
  }

  render(): React.ReactNode {
    if (!this.props.firstApplication) { return ''; }

    return (
      <div style={{ marginBottom: '15px' }}>
        <Message warning>
          <MessageHeader>First Application / Transferring to PAYearned</MessageHeader>
          <MessageContent>
            <p>
              This is the first PAYearned application for this project. If you are transferring a project that has been
              previously billed using something other than PAYearned, you must change the starting application
              number (#) in PAYearned to match the last application number (#) billed.  This will ensure application #
              continuity.
            </p>
            <p>
              When changing the application # to facilitate a project transfer, confirm the total amount
              billed for each line matches the last application that was billed to the general contractor.
            </p>
            <Form>
              <Form.Field width="2">
                <label>Starting Application #</label>
                <Form.Input
                  type="number"
                  min="1"
                  onChange={e => this.onChange(e)}
                  value={this.state.sequenceNumber}
                />
              </Form.Field>
            </Form>
          </MessageContent>
        </Message>
      </div>
    );
  }
}

