import * as React from 'react';
import Stepper, { Step } from 'react-material-stepper';
import 'react-material-stepper/dist/react-stepper.css';
import {
  GuideStep1,
  GuideStep2,
  GuideStep3,
  GUIDE_STEP_1,
  GUIDE_STEP_2,
  GUIDE_STEP_3
} from './steps';
import '../../../styles/stepper.css';


interface GuideStepperProps {
  onClose: () => void;
}

const GuideStepper: React.FunctionComponent<GuideStepperProps> = (props: GuideStepperProps) => {

  return <Stepper>
    <Step
      stepId={GUIDE_STEP_1}
      title=''
    >
      <GuideStep1 onClose={(): void => props.onClose()}/>
    </Step>
    <Step
      stepId={GUIDE_STEP_2}
      title=''
    >
      <GuideStep2 onClose={(): void => props.onClose()}/>
    </Step>
    <Step
      stepId={GUIDE_STEP_3}
      title=''
    >
      <GuideStep3 onClose={(): void => props.onClose()}/>
    </Step>
  </Stepper>;
};

export default GuideStepper;
