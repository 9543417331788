import { Auth } from './AuthService';
import { ProjectJSON } from '../schemas/projects/ProjectJSON';

export type AllowableValueType = number | string | Date | undefined | boolean | null;

export interface DataJSON {
  id?: string;
  [name: string]: AllowableValueType;
}

export type SchemaEndpoint =
  'none'
  | 'projects'
  | 'project-lines'
  | 'general-contractors'
  | 'users'
  | 'sub-contractors'
  | 'applications'
  | 'application-lines';

type SaveMethodType=
  | 'PUT'
  | 'PATCH'

export interface DataServiceInterface {
  list: (collection: SchemaEndpoint) => Promise<Array<DataJSON>>;
  get: (collection: SchemaEndpoint, id: string) => Promise<DataJSON | null>;
  create: (collection: SchemaEndpoint, json: DataJSON) => Promise<DataJSON>;
  save: (collection: SchemaEndpoint, id: string, json: DataJSON) => Promise<void>;
  del: (collection: SchemaEndpoint, id: string) => Promise<void>;
  search: (collection: SchemaEndpoint, query: { [field: string]: string }) => Promise<Array<DataJSON> | null>;
}

export const BASE_URL = process.env.REACT_APP_API_SERVICE_URL;

export class DataService implements DataServiceInterface {
  async list(collection: SchemaEndpoint, archived?: boolean): Promise<Array<DataJSON>> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    const fetchUrl = archived ? `${ BASE_URL }/${ collection }?archived=true` : `${ BASE_URL }/${ collection }`;
    const response = await fetch(
      fetchUrl,
      {
        headers: { 'Authorization': `Bearer ${ Auth.user.accessToken }` }
      }
    );
    if (response.ok) {
      const results = await response.json();
      return results.data;
    }
    return [];
  }

  async listProjects(archived?: boolean, closed?: boolean, unbilledRetainage?: boolean): Promise<Array<DataJSON>> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    const collection = 'projects';
    let fetchUrl = `${ BASE_URL }/${ collection }`;

    if (archived) {
      fetchUrl += '?archived=true';
    } else {
      fetchUrl += '?archived=false';
    }

    if (closed) {
      fetchUrl += '&closed=true';
    } else {
      fetchUrl += '&closed=false';
    }

    if (unbilledRetainage) {
      fetchUrl += '&unbilledRetainage=true';
    }

    const response = await fetch(
      fetchUrl,
      {
        headers: { 'Authorization': `Bearer ${ Auth.user.accessToken }` }
      }
    );
    if (response.ok) {
      const results = await response.json();
      return results.data;
    }
    return [];
  }

  async projectNameExists(name: string, currentProjectID?: string): Promise<boolean> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    const collection = 'projects';
    const fetchUrl = `${ BASE_URL }/${ collection }?name=${ name }&archived=false&closed=false`;

    const response = await fetch(
      fetchUrl,
      {
        headers: { 'Authorization': `Bearer ${ Auth.user.accessToken }` }
      }
    );
    if (response.ok) {
      const results = await response.json();
      if (results.total === 0) return false;
      if (results.data.filter((project: ProjectJSON) => project.id !== currentProjectID).length > 0) return true;
    }
    return false;
  }

  async get(collection: SchemaEndpoint, id: string): Promise<DataJSON | null> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    const response = await fetch(
      `${ BASE_URL }/${ collection }/${ id }`,
      {
        headers: { 'Authorization': `Bearer ${ Auth.user.accessToken }` }
      }
    );
    if (response.ok) {
      return await response.json();
    }
    return null;
  }

  async create(collection: SchemaEndpoint, json: DataJSON): Promise<DataJSON> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    const response = await fetch(
      `${ BASE_URL }/${ collection }`,
      {
        method: 'POST',
        body: JSON.stringify(json),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${ Auth.user.accessToken }`,
        }
      }
    );
    if (response.ok) {
      return await response.json();
    }
    throw new Error(`DataService error posting to ${ BASE_URL }/${ collection }`);
  }

  async save(collection: SchemaEndpoint, id: string, json: DataJSON): Promise<void> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    const response = await fetch(
      `${BASE_URL}/${collection}/${id}`,
      {
        method: 'PUT',
        body: JSON.stringify(json),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${ Auth.user.accessToken }`,
        }
      }
    );
    if (response.ok) {
      return await response.json();
    }
    throw new Error(`DataService error posting to ${ BASE_URL }/${ collection }`);
  }

  async patch(collection: SchemaEndpoint, ids: URLSearchParams, json: DataJSON): Promise<void> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }

    const response = await fetch(
      `${BASE_URL}/${collection}?${ids}`,
      {
        method: 'PATCH',
        body: JSON.stringify(json),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${ Auth.user.accessToken }`,
        }
      }
    );
    if (response.ok) {
      return await response.json();
    }
    throw new Error(`DataService error posting to ${ BASE_URL }/${ collection }`);
  }

  async del(collection: SchemaEndpoint, id: string): Promise<void> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    const response = await fetch(
      `${ BASE_URL }/${ collection }/${ id }`,
      {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${ Auth.user.accessToken }` }
      }
    );
    if (response.ok) {
      return;
    }
    throw new Error(`DataService error deleting ${ BASE_URL }/${ collection }`);
  }

  async search(collection: SchemaEndpoint, query: { [field: string]: string }): Promise<Array<DataJSON> | null> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    const params = new URLSearchParams(query).toString();
    const response = await fetch(
      `${ BASE_URL }/${ collection }?${ params }`,
      {
        headers: { 'Authorization': `Bearer ${ Auth.user.accessToken }` }
      }
    );
    if (response.ok) {
      const results = await response.json();
      return results.data;
    }
    return [];
  }
}
