import React from 'react';
import { Container, Modal } from 'semantic-ui-react';
import GuideStepper from './stepper/GuideStepper';
import CookieService from '../../services/CookieService';
import { inject, observer } from 'mobx-react';

interface GuideModalProps {
  header?: string;
  openedGuideModal: boolean;
  onClose: () => void;
  cookie?: CookieService;
}


@inject('cookie')
@observer
export default class GuideModal extends React.Component<GuideModalProps> {

  componentDidMount(): void {
    if (this.props.openedGuideModal) {
      this.props.cookie?.setSeenGuideModal(true);
    }
  }

  render(): React.ReactNode {
    return (
      <Container>
        <Modal
          className='guide-modal'
          open={this.props.openedGuideModal}
          onClose={(): void => this.props.onClose()}
          size='large'
        >
          <GuideStepper onClose={(): void => this.props.onClose()}/>
        </Modal>
      </Container>
    );
  }
}
