import React from 'react';
import Page from '../components/Page';
import { RouteChildrenProps } from 'react-router-dom';

export default class Page404 extends React.Component<RouteChildrenProps> {
  render(): React.ReactNode {
    return (
      <Page pageTitle="404 Not Found">
        <p style={{ margin: '5em' }}>
          The requested url <b>{this.props.location.pathname}</b> was not found.
        </p>
      </Page>
    );
  }
}
