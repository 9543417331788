import React from 'react';

export default class BillingInfo extends React.Component {
  render(): React.ReactNode {
    return (
      <React.Fragment>
        <p>
          <span style={{ color: 'red' }}>*</span>
          If you are not fully satisfied during your first 30 days, sign into
          your account profile, select subscriptions, and click the cancel button.  It&apos;s that simple, no phone call,
          no email, just click cancel.  Your credit card will not be charged.
        </p>
        <p>
          After the first 30 days, your credit card will be charged in advance for service, automatically each billing
          cycle.  If you wish to discontinue service, you can disable automatic billing. When you disable automatic
          billing, your subscription will terminate at the end of the billing cycle.
        </p>
        <p>
          Please be aware that after your subscription is terminated, you will no longer have access to PDF
          documents.  You will need to re-subscribe in order to access them.
        </p>
      </React.Fragment>
    );
  }
}
