import React, { ChangeEvent } from 'react';
import { Link, Redirect, RouteProps } from 'react-router-dom';
import { AuthServiceInterface } from '../services/AuthService';
import { Button, Form, Header, Image, Loader, Message, Segment } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import CenteredGrid from '../components/CenteredGrid';

interface ResetPasswordProps extends RouteProps {
  mode: string;
  actionCode: string;
  auth?: AuthServiceInterface;
}

interface ResetPasswordPageState {
  initialErrorMessage: string;
  confirmationMessage: string;
  confirmationErrorMessage: string;
  redirect: boolean;
  validResetPasswordLink: boolean;
  waitingConfirmation: boolean;
  password: string;
  confirmPassword: string;
}

@inject('auth')
@observer
export default class ResetPasswordPage extends React.Component<ResetPasswordProps, ResetPasswordPageState> {

  state: ResetPasswordPageState = {
    initialErrorMessage: '',
    confirmationMessage: '',
    confirmationErrorMessage: '',
    redirect: false,
    validResetPasswordLink: false,
    waitingConfirmation: false,
    password: '',
    confirmPassword: ''
  }

  async verifyPasswordResetCode(): Promise<void> {
    // Verify action code.
    if (this.props.auth) {
      try {
        // Get the one-time code from the query parameter.
        await this.props.auth.verifyPasswordResetCode(this.props.actionCode);
        await this.setState({ validResetPasswordLink: true });
      } catch (e) {
        this.setState({ validResetPasswordLink: false, initialErrorMessage: e.message });
      }
    }
  }

  async resetPassword(): Promise<void> {
    if (this.props.auth) {
      try {
        await this.setState({ waitingConfirmation: true });
        await this.props.auth.confirmPasswordReset(this.props.actionCode, this.state.password as string);
        // Email address has been verified.
        await this.setState({ confirmationMessage: 'Your password has been reset successfully!' });
        await setTimeout(() => this.setState({ redirect: true }), 5000);
      } catch (e) {
        this.setState({ confirmationErrorMessage: e.message });
      }
      await this.setState({ waitingConfirmation: false });
    }
  }

  displayConfirmationMessage(): React.ReactNode {
    if (this.state.confirmationMessage) {
      return (
        <CenteredGrid>
          <Segment stacked>
            <Message size='large' success>
              {this.state.confirmationMessage}
            </Message>
            <Button primary as={Link} to='/'>
              Go to {process.env.REACT_APP_APP_NAME}
            </Button>
          </Segment>
        </CenteredGrid>
      );
    } else if (this.state.confirmationErrorMessage) {
      return (
        <CenteredGrid>
          <Segment stacked>
            <Message size='large' negative>
              {this.state.confirmationErrorMessage}
            </Message>
            <Button primary as={Link} to='/'>
              Go to {process.env.REACT_APP_APP_NAME}
            </Button>
          </Segment>
        </CenteredGrid>
      );
    }
  }

  render(): React.ReactNode {
    // If Auth service or Messages are not loaded, then show Loader
    if (!this.props.auth?.initialized || this.state.waitingConfirmation) {
      return <Loader active={true}>Loading...</Loader>;
    }

    // If there is an error after url verification, show error message
    if (this.state.initialErrorMessage) {
      return (
        <CenteredGrid>
          <Segment stacked>
            <Message size='large' negative>
              {this.state.initialErrorMessage}
            </Message>
            <Button primary as={Link} to='/'>
              Go to {process.env.REACT_APP_APP_NAME}
            </Button>
          </Segment>
        </CenteredGrid>
      );
    }

    // Redirect user after password reset to Home page
    if (this.state.redirect) {
      return <Redirect to='/'/>;
    }

    // If there is confirmation or error message after password reset, then show them
    if (this.state.confirmationMessage || this.state.confirmationErrorMessage) {
      return this.displayConfirmationMessage();
    }

    // If nothing above is true, then show Password reset form
    return (
      <CenteredGrid>
        <Image centered style={{ height: '100px' }} src='/img/PAYearned-logo.png'/>
        <Header as='h2' color='blue' textAlign='center'>{process.env.REACT_APP_APP_NAME} password reset</Header>

        <Form size='large'>
          <Segment stacked>
            <Form.Input
              required
              type="password"
              placeholder="Password"
              value={this.state.password}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => this.setState({ password: e.target.value.trim() })}
            />
            <Form.Input
              required
              type="password"
              placeholder="Confirm Password"
              value={this.state.confirmPassword}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => this.setState({ confirmPassword: e.target.value.trim() })}
            />
            <Button
              color='blue'
              fluid
              size='large'
              disabled={(!this.state.password.trim() || !this.state.confirmPassword.trim()) || (this.state.password !== this.state.confirmPassword)}
              onClick={(): Promise<void> => this.resetPassword()}>
              Reset Password
            </Button>
          </Segment>
        </Form>
      </CenteredGrid>
    );
  }
}
