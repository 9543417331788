import TagManager, { DataLayerArgs } from 'react-gtm-module';

export default class TagManagerService {

  static logLoginEvent(userEmail: string): void {
    const dataLayerArgs: DataLayerArgs = {
      dataLayer: {
        event: 'login',
        userEmail: userEmail
      }
    };
    TagManager.dataLayer(dataLayerArgs);
  }

  static logSignupEvent(userEmail: string): void {
    const dataLayerArgs: DataLayerArgs = {
      dataLayer: {
        event: 'sign_up',
        userEmail: userEmail
      }
    };
    TagManager.dataLayer(dataLayerArgs);
  }

  static logPurchaseEvent(value: number): void {
    const valueWithoutExtraZeros = value / 100;
    const dataLayerArgs: DataLayerArgs = {
      dataLayer: {
        event: 'purchase',
        currency: 'USD',
        value: valueWithoutExtraZeros
      }
    };
    TagManager.dataLayer(dataLayerArgs);
  }

  static logRefundEvent(value: number): void {
    const valueWithoutExtraZeros = value / 100;
    const dataLayerArgs: DataLayerArgs = {
      dataLayer: {
        event: 'refund',
        currency: 'USD',
        value: valueWithoutExtraZeros
      }
    };
    TagManager.dataLayer(dataLayerArgs);
  }

}
