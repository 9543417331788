import React from 'react';
import Page from '../components/Page';
import { DataService } from '../services/DataService';
import { Button, Grid, Header, Icon, Loader, Message } from 'semantic-ui-react';
import { Link, RouteChildrenProps } from 'react-router-dom';
import { ProjectJSON } from '../schemas/projects/ProjectJSON';
import FreeAccountMessage from '../components/FreeAccountMessage';
import { ReactTabulator } from 'react-tabulator';
import { MAX_PHONE_WIDTH_MEDIA_QUERY, ProjectColumnName } from '../common/Constants';
import TrialAccountMessage from '../components/TrialAccountMessage';
import { inject, observer } from 'mobx-react';
import { FirebaseAuthService } from '../services/AuthService';
import GuideModal from '../components/guide-modal/GuideModal';
import CookieService from '../services/CookieService';
import UnbilledRetainageButton from './projects/components/UnbilledRetainageButton';
import PaymentService, { Subscription } from '../services/PaymentService';
import LearnMore from '../components/LearnMore';
import '../styles/main.css';

interface UserHomePageProps extends RouteChildrenProps {
  auth?: FirebaseAuthService;
  payment?: PaymentService;
  cookie?: CookieService;
}

interface UserHomeState {
  loading: boolean;
  projects: Array<ProjectJSON>;
  filtered: boolean;
  isGuideOpen: boolean;
  subscriptions: Array<Subscription> | undefined;
  paymentMethodLast4: string | undefined;
}

@inject('auth')
@inject('payment')
@inject('cookie')
@observer
export default class OldBeforePaginationFeatureUserHomePage extends React.Component<UserHomePageProps, UserHomeState> {
  ref?: React.ElementRef<any>;
  dataService = new DataService();
  state: UserHomeState = {
    loading: true,
    projects: [],
    filtered: false,
    isGuideOpen: false,
    subscriptions: undefined,
    paymentMethodLast4: undefined
  }

  columns = [
    {
      title: ProjectColumnName.CreateDate, field: 'createdAt',
      formatter: 'link',
      formatterParams: this.getDateFormatterParams(),
      width: 125,
    },
    {
      title: ProjectColumnName.PercentComplete, field: 'percentComplete',
      formatter: 'link',
      formatterParams: this.getPercentFormatterParams(),
      width: 125,
    },
    {
      title: ProjectColumnName.ProjectName, field: 'name',
      formatter: 'link',
      formatterParams: this.getFormatterParams(),
      headerFilter: 'input',
      headerFilterPlaceholder: 'Search project name',
    },
    {
      title: ProjectColumnName.Location, field: 'addressLine1',
      formatter: 'link',
      formatterParams: this.getFormatterParams(),
      headerFilter: 'input',
      headerFilterPlaceholder: 'Search location',
    },
    {
      title: ProjectColumnName.GeneralContractorName, field: 'generalContractor.name',
      formatter: 'link',
      formatterParams: this.getFormatterParams(),
      headerFilter: 'input',
      headerFilterPlaceholder: 'Search general contractor',
    },
  ]

  steps = [
    {
      selector: '.free-account-warning',
      content: 'Currently you are using free account, which allows you to begin using the system. We also have 30-day risk-free evaluation period for paid account',
    },
    {
      selector: '.new-project-btn',
      content: 'First you will need to create a new project',
    },
    {
      selector: '.closed-project-btn',
      content: 'When project is finished, it will be located in Closed Projects Page',
    },
    {
      selector: '.archived-project-btn',
      content: 'Any closed project can be archived and then deleted from Archived Projects Page',
    },
    {
      selector: '.contact-us-menu-btn',
      content: 'If you have any questions or feedback, please let us know here. Thank you for choosing PAYEarned',
    },
  ];

  async componentDidMount(): Promise<void> {
    try {
      let projects = await this.dataService?.listProjects(false, false) as Array<ProjectJSON>;
      const subscriptions = await this.props.payment?.getSubscriptions();
      if (!projects) projects = [];
      projects = projects.map(p => {
        p.addressLine1 = p.addressLine1?.concat(', ' + p.city || '', ' ' + p.zip || '');
        return p;
      });

      // Get last4 of default payment Method, which is needed for TrialAccountMessage component
      const subscriptionWithDefaultPaymentMethod = subscriptions?.find(s => s?.default_payment_method?.length > 0);
      let paymentMethodLast4 = undefined;
      if (subscriptionWithDefaultPaymentMethod) {
        const paymentMethod = await this.props.payment?.getPaymentMethod(subscriptionWithDefaultPaymentMethod.default_payment_method);
        paymentMethodLast4 = paymentMethod?.card.last4;
      }

      this.setState({ loading: false, projects, subscriptions, paymentMethodLast4 });
    } catch (e) {
      this.setState({ loading: false });
    }

  }

  getDateFormatterParams(): any {
    return {
      label: (cell: any) => {
        const value: Date = new Date(cell._cell.row.data.createdAt);
        return value.toLocaleDateString();
      },
      url: (cell: any) => {
        const value: number | string | null | undefined = cell._cell.row.data.id;
        return '/projects/' + value;
      }
    };
  }

  getPercentFormatterParams(): any {
    return {
      label: (cell: any) => {
        const value: number = (cell._cell.row.data.percentComplete) ? Number(cell._cell.row.data.percentComplete) : 0;
        return value.toLocaleString('en-US', { style: 'percent' });
      },
      url: (cell: any) => {
        const value: number | string | null | undefined = cell._cell.row.data.id;
        return '/projects/' + value;
      }
    };
  }

  getFormatterParams(): any {
    return {
      url: (cell: any) => {
        const value: number | string | null | undefined = cell._cell.row.data.id;
        return '/projects/' + value;
      }
    };
  }

  rowClick = (e: any, row: any): void => {
    const value: number | string | null | undefined = row._row.data.id;
    this.props.history.push('/projects/' + value);
  };

  clearFilters(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.ref.table.clearHeaderFilter();
    this.setState({ filtered: false });
  }

  closeGuideModal(): void {
    this.setState({ isGuideOpen: false });
  }

  openGuideModal(): void {
    this.setState({ isGuideOpen: true });
  }

  render(): React.ReactNode {
    const isMobileView: boolean = window.matchMedia(MAX_PHONE_WIDTH_MEDIA_QUERY).matches;
    const hiddenOrVisibleOnMobile: string = isMobileView ? 'none' : 'block';
    const hiddenOrVisibleMobileWarning: string = isMobileView ? 'block' : 'none';
    const { loading } = this.state;

    if (loading) {
      return <Loader active>Loading...</Loader>;
    }

    return (
      <Page pageTitle="Your Projects">
        <Button
          className='info-btn'
          circular
          icon='info'
          onClick={(): void => this.openGuideModal()}
        />
        {(this.state.subscriptions?.length || this.state.projects.length > 0) && (
          <FreeAccountMessage subscriptions={this.state.subscriptions} history={this.props.history}/>
        )}
        <TrialAccountMessage subscriptions={this.state.subscriptions} paymentMethodLast4={this.state.paymentMethodLast4}/>
        <Message warning style={{ display: hiddenOrVisibleMobileWarning }}>
          <Message.Header>Mobile version has limited functionality!</Message.Header>
          <p>For full featured experience, please visit the website from a laptop or a tablet.</p>
        </Message>
        <div style={{ display: hiddenOrVisibleOnMobile, marginTop: '2em' }}>
          <Grid>
            {/* <Grid.Column className='new-project'>
              <Button primary as={Link} to={'/projects/new'} className='new-project-btn'>
                <Icon name="plus"/>
                New Project
              </Button>
              {!(this.state.projects.length > 0) && (
                <Header as='h2' style={{ fontSize: '1.5em' }}>
                  {this.state.subscriptions?.length ? 'You don\'t have any open projects, check Closed/Archived' : 'Create one using your Free Limited Account'}
                </Header>
              )}
            </Grid.Column> */}
            {this.state.subscriptions?.length ? (
              <Grid.Column className='new-project subscribed'>
                <Button primary as={Link} to={'/projects/new'} className='new-project-btn'>
                  <Icon name="plus"/>
                  New Project
                </Button>
                {!(this.state.projects.length > 0) && (
                  <Header as='h2' style={{ fontSize: '1.5em' }}>
                    You don't have any open projects, check Closed/Archived
                  </Header>
                )}
              </Grid.Column>
            ) : (
              <>
                {this.state.projects.length > 0 ? (
                  <Grid.Column className='new-project'>
                    <Button primary as={Link} to={'/projects/new'} className='new-project-btn'>
                      <Icon name="plus"/>
                      New Project
                    </Button>
                  </Grid.Column>
                ): (
                  <Grid.Column className='new-project'>
                    <Grid.Column className='new-project item first'>
                      <Header as='h2' style={{ fontSize: '1.5em' }}>
                        Begin using your Free <u>Limited</u> Account
                      </Header>
                      <Button primary as={Link} to={'/projects/new'} className='new-project-btn'>
                        Create a Project
                      </Button>
                    </Grid.Column>
                    <Grid.Column className='new-project item second'>
                      <Header as='h2'>
                        OR
                      </Header>
                    </Grid.Column>
                    <Grid.Column className='new-project item third'>
                      <Header as='h2' style={{ fontSize: '1.5em' }}>
                        Upgrade now to an <u>Unlimited</u> Subscription Risk Free
                      </Header>
                      <LearnMore title='Subscribe Now' history={this.props.history} />
                    </Grid.Column>
                    <Grid.Column className='new-project item fourth'>
                      <Header>
                        Cancel any time during the first 30 days and your credit card will never be charged
                      </Header>
                    </Grid.Column>
                  </Grid.Column>
                )}
              </>
            )}
            <Grid.Column textAlign='right' width={6}>
              <UnbilledRetainageButton/>
              <Button as={Link} to={'/projects/closed'} className='closed-project-btn'>
                <Icon name="close"/>
                Closed Projects
              </Button>
              <Button as={Link} to={'/projects/archived'} className='archived-project-btn'>
                <Icon name="archive"/>
                Archived Projects
              </Button>
            </Grid.Column>
          </Grid>
        </div>
        {
          this.state.filtered
            ?
            <Message warning>
              <Message.Header>Project List Filtered</Message.Header>
              <p>
                You are currently filtering the project list. Click the button below to go back to the full
                project list.
              </p>
              <Button onClick={(): void => this.clearFilters()}>
                <Icon color="red" name="delete"/>
                Clear Filters
              </Button>
            </Message>
            : ''
        }
        {
          this.state.projects.length > 0 ? (
            <ReactTabulator
              ref={(ref: any) => (this.ref = ref)}
              // @ts-ignore
              columns={this.columns}
              data={this.state.projects}
              rowClick={this.rowClick}
              dataFiltered={(): void => {
                let filtered = false;
                document.querySelectorAll('div.tabulator .tabulator-header-filter input').forEach((input) => {
                  if (!filtered) {
                    filtered = (input as HTMLInputElement).value !== '';
                  }
                });
                if (filtered !== this.state.filtered) {
                  this.setState({ filtered });
                }
              }}
            />
          ) : (
            <p>
              <Header as='h2' className='watch-video' style={{ fontSize: '1.5em' }}>Watch our <a href="https://payearned.com/help/" target="_blank" rel="noopener noreferrer">How
                to Tutorials to learn more.</a></Header>
            </p>
          )
        }
        <div>
          <GuideModal openedGuideModal={this.state.isGuideOpen} onClose={(): void => this.closeGuideModal()}/>
        </div>
      </Page>
    );
  }
}
