import * as React from 'react';
import Stepper, { Step } from 'react-material-stepper';
import 'react-material-stepper/dist/react-stepper.css';
import { Step1, STEP1, Step2, STEP2, Step3, STEP3 } from './steps';
import './style.css';
import { StepId } from 'react-material-stepper/src/typings';
import { ProjectJSON } from '../../../schemas/projects/ProjectJSON';
import { GeneralStepperProps } from './steps/step1';


interface NewChangeOrderStepperProps extends GeneralStepperProps{
  project: ProjectJSON;
  closeModalCallback: (stepId: StepId) => void;
}


const NewChangeOrderStepper: React.FunctionComponent<NewChangeOrderStepperProps> = (newChangeOrderStepperProps: NewChangeOrderStepperProps) =>
{

  function triggerCancelButton(): void {
    newChangeOrderStepperProps.onResolveCancel();
  }

  return <Stepper className='custom-theme' onResolve={(stepId: StepId): void => newChangeOrderStepperProps.closeModalCallback(stepId)}>
    <Step stepId={STEP1} title='New Change Order'>
      <Step1 onResolveCancel={triggerCancelButton}/>
    </Step>
    <Step stepId={STEP2} title='Select Project Line'>
      <Step2 onResolveCancel={triggerCancelButton} project={newChangeOrderStepperProps.project}/>
    </Step>
    <Step stepId={STEP3} title='Create Change Order'>
      <Step3 onResolveCancel={triggerCancelButton} project={newChangeOrderStepperProps.project}/>
    </Step>
  </Stepper>;
};





export default NewChangeOrderStepper;
