import React from 'react';
import { inject, observer } from 'mobx-react';
import BankAccount from './components/BankAccount';
import Page from '../../components/Page';
import { Button, Grid, GridColumn, GridRow, List } from 'semantic-ui-react';
import AddBankAccount from './components/AddBankAccount';
import { SubscriptionCommonProps } from './components/AddPaymentMethod';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { BankAccountObj, StripeCustomer } from '../../services/PaymentService';


export default class BankAccountsPageContainer extends React.Component {
  render(): React.ReactNode {
    return (
      <ElementsConsumer>
        {({ stripe, elements }): React.ReactNode => (
          <BankAccountsPage {...this.props} stripe={stripe as Stripe} elements={elements as StripeElements}
          />

        )}
      </ElementsConsumer>
    );
  }
}

interface BankAccountsState {
  triggerAddBankAccount: boolean;
  stripeCustomer: StripeCustomer | undefined;
}

@inject('stripe')
@inject('auth')
@inject('alert')
@inject('payment')
@observer
class BankAccountsPage extends React.Component<SubscriptionCommonProps, BankAccountsState> {

  state: BankAccountsState = {
    triggerAddBankAccount: false,
    stripeCustomer: undefined
  }

  async componentDidMount(): Promise<void> {
    let stripeCustomer;
    if (this.props.auth?.user?.stripeCustomerId) {
      stripeCustomer = await this.props.payment?.getStripeCustomer(this.props.auth?.user?.stripeCustomerId);
    }
    this.setState({
      stripeCustomer
    });
  }

  async triggerBankAccount(bankAccount: BankAccountObj): Promise<void> {
    //TODO: has to be implemented
  }

  private onAccountAddition(): void {
    this.setState({ triggerAddBankAccount: false });
  }

  render(): React.ReactNode {
    const { elements, stripe, auth, payment } = this.props;
    const { triggerAddBankAccount } = this.state;


    if (this.props.payment?.bankAccounts.length === 0 || triggerAddBankAccount) {
      return (
        <Page pageTitle='Bank Accounts' breadcrumb={[
          { key: 'home', href: '/', content: 'Your Projects', link: true },
        ]}>
          <Grid textAlign='left' style={{ height: '50' }}>
            <GridColumn style={{ maxWidth: 400 }}>
              <AddBankAccount
                stripe={stripe}
                payment={payment}
                elements={elements}
                onAccountAddition={(): void => this.onAccountAddition()}
              />
            </GridColumn>
          </Grid>
        </Page>
      );
    }

    return (
      <Page pageTitle='Bank Accounts' breadcrumb={[
        { key: 'home', href: '/', content: 'Your Projects', link: true },
      ]}>
        <Grid divided='vertically' textAlign='left' style={{ height: '50' }}>
          <GridRow columns={1}>
            <GridColumn style={{ maxWidth: 750 }}>
              <List celled>
                {
                  this.props.payment?.bankAccounts.map(bankAccount =>
                    <BankAccount
                      key={bankAccount.id}
                      auth={auth}
                      account={bankAccount}
                      stripe={stripe}
                      stripeCustomer={this.state.stripeCustomer}
                      payment={payment}
                      elements={elements}
                      checked={bankAccount.id === this.state.stripeCustomer?.default_source}
                      onChange={(): Promise<void> => this.triggerBankAccount(bankAccount)}
                      value={bankAccount.id}
                    />
                  )
                }
              </List>
            </GridColumn>
            <Button primary
              style={{ marginLeft: '10px' }}
              onClick={(): void => this.setState({ triggerAddBankAccount: true })}
            >
              Add Bank Account
            </Button>
          </GridRow>
        </Grid>
      </Page>
    );
  }
}

