import React, { ReactElement } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import { FirebaseAuthService } from '../../services/AuthService';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { SemanticICONS, SemanticShorthandItem } from 'semantic-ui-react/dist/commonjs/generic';
import { IconProps } from 'semantic-ui-react/dist/commonjs/elements/Icon';

interface ContextMenuProps {
  auth?: FirebaseAuthService;
  menus?: Array<ContextMenuList>;
}

export interface ContextMenuList {
  title: string;
  items: Array<ContextMenuItem>;
}

interface ContextMenuItem {
  icon: SemanticShorthandItem<IconProps> | SemanticICONS;
  text?: string;
  href?: string;
  onClick?: () => void | Promise<void>;
  component?: ReactElement;
}

@inject('auth')
@observer
export default class ContextMenu extends React.Component<ContextMenuProps> {
  state = { open: false, componentClick: false };

  constructor(props: ContextMenuProps) {
    super(props);
    this.closeMenu = this.closeMenu.bind(this);
    this.escHandler = this.escHandler.bind(this);
  }

  componentDidMount(): void {
    window.addEventListener('click', this.closeMenu);
    window.addEventListener('keydown', this.escHandler);
  }

  componentWillUnmount(): void {
    window.removeEventListener('click', this.closeMenu);
    window.removeEventListener('keydown', this.escHandler);
  }

  escHandler(evt: KeyboardEvent): void {
    if (evt.key === 'Esc' || evt.key === 'Escape') {
      this.setState({ open: false });
    }
  }

  closeMenu(): void {
    this.setState({ open: false });
  }

  render(): React.ReactNode {
    if (this.props.auth?.initialized) {
      if (this.props.auth?.user?.id && this.props.menus && this.props.menus.length > 0) {
        return (
          this.props.menus.map((dropdown, i) => {
            return (
              <Dropdown
                item key={i}
                text={dropdown.title}
                open={this.state.open}
                onClick={(): void => {
                  if (this.state.componentClick) {
                    this.setState({ open: false, componentClick: false });
                  } else {
                    this.setState({ open: !this.state.open });
                  }
                }}
              >
                <Dropdown.Menu>
                  {
                    dropdown.items.map((menuItem, i) => {
                      // If we have an href
                      if (menuItem.href) {
                        return (
                          <Dropdown.Item
                            key={i}
                            icon={menuItem.icon}
                            text={menuItem.text}
                            as={Link}
                            to={menuItem.href}
                          />
                        );
                      }

                      // If we have a component
                      if (menuItem.component) {
                        return (
                          <Dropdown.Item
                            key={i}
                            onClick={(): void => this.setState({ componentClick: true })}
                          >
                            <Icon name={menuItem.icon as SemanticICONS}/>
                            {menuItem.component}
                          </Dropdown.Item>
                        );
                      }

                      // Return with onClick
                      return (
                        <Dropdown.Item
                          key={i}
                          icon={menuItem.icon}
                          text={menuItem.text}
                          onClick={(): void => {
                            if (menuItem.onClick) {
                              menuItem.onClick();
                            }
                          }}
                        />
                      );
                    })
                  }
                </Dropdown.Menu>
              </Dropdown>
            );
          })
        );
      }
      return '';
    }
  }
}
