import React from 'react';
import { Button, Card, CardContent, CardGroup, CardHeader, Grid, Popup } from 'semantic-ui-react';
import PaymentService, { PaymentMethod, Plan, Subscription } from '../../../services/PaymentService';
import { AuthServiceInterface } from '../../../services/AuthService';

const buttonStyle = {
  marginTop: '18px',
  borderBottomWidth: '5px',
  paddingBottom: '11px',
  marginBottom: '5px'
};

const popUpStyle = {
  borderRadius: 0,
  opacity: 0.7,
  padding: '2em',
};

interface PaymentPlanSummaryProps {
  auth?: AuthServiceInterface;
  isLoading?: boolean;
  onSubscribe: (plan: Plan) => Promise<void>;
  payment?: PaymentService;
  paymentMethods: Array<PaymentMethod> | undefined;
  plan: Plan;
  subscriptions: Array<Subscription> | undefined;
  trialDays: number;
}

export default class PaymentPlanSummary extends React.Component<PaymentPlanSummaryProps> {

  async componentDidMount(): Promise<void> {
    const paymentMethods = await this.props.payment?.getPaymentMethods(this.props.auth?.user?.stripeCustomerId as string);
    this.setState({
      paymentMethods,
    });
  }

  private async triggerSubscribe(plan: Plan): Promise<void> {
    await this.props.onSubscribe(plan);
  }

  render(): React.ReactNode {
    const { plan, isLoading, subscriptions, paymentMethods, trialDays } = this.props;
    const billInfo = trialDays === 0
      ? 'immediately'
      : `on ${
        new Date(
          new Date().getTime()
          + new Date().getTimezoneOffset()*1000*60
          + trialDays*1000*60*60*24
        ).toLocaleDateString()
      }`;

    return (
      <CardGroup>
        <Card style={ { marginLeft: '55px', width: '400px' } }>
          <CardContent>
            <CardHeader>Summary</CardHeader>
          </CardContent>
          <CardContent extra>
            <Card.Description>
              <Grid style={{ padding: '14px 0px 14px 0px' }}>
                {
                  trialDays > 0
                    ? <Grid.Row>
                      <Grid.Column>
                        You will receive your first { trialDays } days for $0.  You can cancel service at any time
                        during the first { trialDays } days, and your credit card will not be charged.  After the
                        first {
                          this.props.trialDays
                        } days, your credit card will automatically be charged {
                          this.props.plan.interval === 'year' ? 'annually' : 'monthly'
                        } <b>in advance.</b>
                      </Grid.Column>
                    </Grid.Row>
                    : <Grid.Row>
                      <Grid.Column>
                          You have already used your 30-day trial. Your credit card will now be charged ${
                          plan.amount / 100
                        } in advance, for one { plan.interval } of service.
                        Your credit card will be charged in advance each { plan.interval } thereafter.
                      </Grid.Column>
                    </Grid.Row>
                }
              </Grid>
            </Card.Description>
            <p/>
            <div style={ { fontSize: '12px' } }>By completing your purchase you agree to our terms of service.</div>
            {
              paymentMethods?.length === 0 ? <Popup
                content='Please provide your credit card information'
                position='bottom center'
                style={popUpStyle}
                trigger={
                  <span>
                    <Button
                      onClick={ (): Promise<void> => this.triggerSubscribe(plan) }
                      disabled
                      style={ buttonStyle }
                      fluid color='green'>
                      {
                        subscriptions?.length === 0 ? 'Activate Subscription' : 'Change Subscription'
                      }
                    </Button>
                  </span>
                }
              /> : <Button
                loading={isLoading}
                onClick={ (): Promise<void> => this.triggerSubscribe(plan) }
                style={ buttonStyle }
                fluid color='green'>
                {
                  subscriptions?.length === 0 ? 'Activate Subscription' : 'Change Subscription'
                }
              </Button>
            }
          </CardContent>
        </Card>
      </CardGroup>
    );
  }
}
