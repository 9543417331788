import { ApplicationJSON } from './ApplicationJSON';
import { ApplicationLineJSON } from '../ApplicationLineJSONSchema';
import { DataJSON, DataService } from '../../services/DataService';
import moment from 'moment';
import { UpdatedField } from '../../components/CalculationSchemaForm';
import { ProjectLineJSON } from '../ProjectLineSchema';
import { ProjectJSON } from '../projects/ProjectJSON';

export default class ActiveApplication implements ApplicationJSON {
  id?: string;
  sequenceNumber?: number;
  status?: 'Open' | 'Rejected' | 'Completed' | 'Final' = 'Open';
  projectId?: string;
  project?: ProjectJSON;
  applicationDate?: Date | string;
  periodTo?: Date | string;
  applicationLines: Array<ApplicationLineJSON> = [];
  originalApplicationLines: Array<ApplicationLineJSON> = [];
  changeOrderLines: Array<ApplicationLineJSON> = [];
  dataService = new DataService();
  error = true;
  loading = false;
  saving = false;
  private _onLoad: Array<(value?: void | PromiseLike<void> | undefined) => void> = [];
  private _onSave: Array<(value: ApplicationJSON) => void> = [];

  constructor();
  constructor(id?: string);
  constructor(json?: ApplicationJSON);
  constructor(id?: string | ApplicationJSON) {
    // If nothing was passed in, do almost nothing
    if (!id) {
      this.error = false;
    } else if (typeof id === 'string') {
      // Load the application
      this.id = id;
      this.load();
    } else {
      // Create it from JSON
      this.error = false;
      this.fromJSON(id);
    }
  }

  get allowSubmit(): boolean {
    return Boolean(this.applicationLines?.find(applicationLine =>
      (applicationLine.incrementalBillAmount && Math.abs(applicationLine.incrementalBillAmount) > 0)
      || applicationLine.incrementalStoredAmount && Math.abs(applicationLine.incrementalStoredAmount) > 0)
    );
  }

  get originalContractAmount(): number {
    return this.project?.originalContractAmount as number;
  }

  get totalScheduledValue(): number {
    try {
      return this.applicationLines
        .filter(al => al.projectLine?.lineType === 'original')
        .map(al => al.scheduledValue as number).reduce((total, current) => total + current);
    } catch(err) {
      return 0;
    }
  }

  get totalBilledToDate(): number {
    try {
      return this.applicationLines
        .filter(al => al.projectLine?.lineType === 'original')
        .map(al => al.billedToDate as number).reduce((total, current) => total + current);
    } catch(err) {
      return 0;
    }
  }

  get totalIncrementalBillAmount(): number {
    try {
      return this.applicationLines
        .filter(al => al.projectLine?.lineType === 'original')
        .map(al => al.incrementalBillAmount || 0 as number)
        .reduce((total, current) => total + current);
    } catch (err) {
      return 0;
    }
  }

  get totalIncrementalStoredAmount(): number {
    try {
      return this.applicationLines
        .filter(al => al.projectLine?.lineType === 'original')
        .map(al => al.incrementalStoredAmount || 0 as number)
        .reduce((total, current) => total + current);
    } catch (err) {
      return 0;
    }
  }

  get totalMaterialPresentlyStored(): number {
    try {
      return this.applicationLines
        .filter(al => al.projectLine?.lineType === 'original')
        .map(al =>
          (al.storedToDate ? al.storedToDate : 0)
          + (al.incrementalStoredAmount ? al.incrementalStoredAmount : 0)
        ).reduce((total, current) => total + current);
    } catch (err) {
      return 0;
    }
  }

  get totalCompletedInStoreAsPercentage(): number {
    return (this.totalCompletedAndStoredToDate / this.totalScheduledValue);
  }

  get changeOrders(): ApplicationLineJSON[] {
    return this.applicationLines
      .filter(applicationLine => applicationLine.projectLine?.lineType === 'changeOrder');
  }

  get totalScheduledValueChangeOrders(): number {
    if (this.changeOrders.length > 0) {
      return this.changeOrders
        .map(al => al.scheduledValue as number)
        .reduce((total, current) => total + current);
    }
    return 0;
  }

  get totalBilledToDateChangeOrders(): number {
    if (this.changeOrders.length > 0) {
      return this.changeOrders
        .map(al => al.billedToDate as number)
        .reduce((total, current) => total + current);
    }
    return 0;
  }

  get totalIncrementalBillAmountChangeOrders(): number {
    if (this.changeOrders.length > 0) {
      return this.changeOrders
        .map(al => al.incrementalBillAmount || 0 as number)
        .reduce((total, current) => total + current);
    }
    return 0;
  }

  get totalIncrementalStoredAmountChangeOrders(): number {
    if (this.changeOrders.length > 0) {
      return this.changeOrders
        .map(al => al.incrementalStoredAmount || 0 as number)
        .reduce((total, current) => total + current);
    }
    return 0;
  }

  get totalMaterialPresentlyStoredChangeOrders(): number {
    if (this.changeOrders.length > 0) {
      return this.changeOrders
        .map(al =>
          (al.storedToDate ? al.storedToDate : 0)
          + (al.incrementalStoredAmount ? al.incrementalStoredAmount : 0)
        ).reduce((total, current) => total + current);
    }
    return 0;
  }

  get totalCompletedAndStoredToDateChangeOrders(): number {
    return this.totalBilledToDateChangeOrders
      + this.totalIncrementalBillAmountChangeOrders
      + this.totalMaterialPresentlyStoredChangeOrders;
  }

  get totalCompletedInStoreAsPercentageChangeOrders(): number {
    return (this.totalCompletedAndStoredToDateChangeOrders / this.totalScheduledValueChangeOrders);
  }

  get balanceToFinishChangeOrders(): number {
    return this.totalScheduledValueChangeOrders - this.totalCompletedAndStoredToDateChangeOrders;
  }

  get totalRetainedChangeOrders(): number {
    const changeOrders = this.applicationLines
      .filter(line => line.projectLine!.lineType === 'changeOrder');
    if(changeOrders.length === 0) {
      return 0;
    }
    return changeOrders
      .map(al => al.retained || 0 as number)
      .reduce((total, current) => total + current);
  }

  get netApprovedChangeOrders(): number {
    let netApprovedChangeOrders = 0;
    this.applicationLines
      .filter(applicationLine => applicationLine.projectLine?.lineType === 'changeOrder')
      .forEach(applicationLine => {
        netApprovedChangeOrders += applicationLine.scheduledValue as number;
      });
    return netApprovedChangeOrders;
  }

  get totalAdjustedContractAmount(): number {
    return this.originalContractAmount + this.netApprovedChangeOrders;
  }

  get totalCompletedAndStoredToDate(): number {
    return this.totalBilledToDate
      + this.totalIncrementalBillAmount
      + this.totalMaterialPresentlyStored;
  }

  get totalCompletedAndStored(): number {
    try {
      return this.applicationLines
        .filter(line => line.projectLine!.lineType === 'original')
        .map(al => (al.incrementalBilledToDate || 0 as number) + (al.incrementalStoredToDate || 0 as number))
        .reduce((total, current) => total + current);
    } catch (err) {
      return 0;
    }
  }

  get totalRetained(): number {
    try {
      return this.applicationLines
        .filter(line => line.projectLine!.lineType === 'original')
        .map(al => al.retained || 0 as number)
        .reduce((total, current) => total + current);
    } catch (err) {
      return 0;
    }
  }

  get totalEarnedLessRetainage(): number {
    return this.totalCompletedAndStored - this.totalRetained;
  }

  get totalEarnedLessRetainageChangeOrders(): number {
    return this.totalCompletedAndStoredToDateChangeOrders - this.totalRetainedChangeOrders;
  }

  get previousApplicationForPayment(): number {
    let previousApplicationForPayment = 0;
    this.applicationLines.forEach(applicationLine => {
      previousApplicationForPayment += (
        (applicationLine.billedToDate as number)
        + (applicationLine.storedToDate as number)
      ) * (1 - (applicationLine.retainage as number));
    });
    return previousApplicationForPayment;
  }

  get previousChangeOrders(): number {
    if (this.changeOrders.length > 0) {
      return this.changeOrders
        .map(changeOrder => (changeOrder.billedToDate as number) * (1 - (changeOrder.retainage as number)))
        .reduce((total, current) => total+current);
    }
    return 0;
  }

  get currentPaymentDue(): number {
    const currentPaymentDue = Math.round((this.grandTotalEarnedLessRetainage - this.previousApplicationForPayment) * 100) / 100;
    // eslint-disable-next-line no-compare-neg-zero
    if(currentPaymentDue === -0) return 0;
    return currentPaymentDue;
  }

  get balanceToFinish(): number {
    if (this.status === 'Final') return 0;
    return this.originalContractAmount - this.totalCompletedAndStoredToDate;
  }

  get grandTotalScheduledValue(): number {
    return this.totalScheduledValue + this.totalScheduledValueChangeOrders;
  }

  get grandTotalBilledToDate(): number {
    return this.totalBilledToDate + this.totalBilledToDateChangeOrders;
  }

  get grandTotalIncrementalBillAmount(): number {
    return this.totalIncrementalBillAmount + this.totalIncrementalBillAmountChangeOrders;
  }

  get grandTotalMaterialPresentlyStored(): number {
    return this.totalMaterialPresentlyStored + this.totalMaterialPresentlyStoredChangeOrders;
  }

  get grandTotalCompletedAndStoredToDate(): number {
    return this.totalCompletedAndStoredToDate + this.totalCompletedAndStoredToDateChangeOrders;
  }

  get grandTotalEarnedLessRetainage(): number {
    return this.grandTotalCompletedAndStoredToDate - this.grandTotalRetained;
  }

  get grandTotalCompletedInStoreAsPercentage(): number {
    return (this.grandTotalCompletedAndStoredToDate / this.grandTotalScheduledValue);
  }

  get grandTotalBalanceToFinish(): number {
    if (this.status === 'Final') return 0;
    return this.balanceToFinish + this.balanceToFinishChangeOrders;
  }

  get grandTotalBalanceToFinishIncludingRetainage(): number {
    if (this.status === 'Final') return 0;
    return this.totalAdjustedContractAmount - this.grandTotalEarnedLessRetainage;
  }

  get grandTotalRetained(): number {
    return this.totalRetained + this.totalRetainedChangeOrders;
  }

  get grandTotalMaterialThisPeriod(): number {
    try {
      return this.applicationLines
        .map(line => line?.incrementalStoredAmount && line.incrementalStoredAmount > 0 ? line.incrementalStoredAmount : 0)
        .reduce((current, total) => current + total);
    } catch (err) {
      return 0;
    }
  }

  get grandTotalMaterialReversalThisPeriod(): number {
    try {
      return this.applicationLines
        .map(line => line?.incrementalStoredAmount && line.incrementalStoredAmount < 0 ? line.incrementalStoredAmount : 0)
        .reduce((current, total) => current + total);
    } catch (err) {
      return 0;
    }
  }

  get grandTotalRetainageThisPeriod(): number {
    try {
      return this.applicationLines
        .map(line => {
          let billedAmountRetainage =
            line?.incrementalBillAmount !== undefined ? line.incrementalBillAmount : 0;
          billedAmountRetainage = billedAmountRetainage * Number(line?.retainage);
          let materialRetainage =
            line?.incrementalStoredAmount !== undefined ? line.incrementalStoredAmount : 0;
          materialRetainage = materialRetainage * Number(line?.retainage);
          return billedAmountRetainage + materialRetainage;
        })
        .reduce((current, total) => current + total);
    } catch (err) {
      return 0;
    }
  }

  get changeOrdersMaterialThisPeriod(): number {
    const changeOrders = this.applicationLines
      .filter(line => line.projectLine!.lineType === 'changeOrder');
    if(changeOrders.length === 0) {
      return 0;
    }
    return changeOrders
      .map(line => line?.incrementalStoredAmount && line.incrementalStoredAmount > 0 ? line.incrementalStoredAmount : 0)
      .reduce((current, total) => current + total);
  }

  get changeOrdersMaterialReversalThisPeriod(): number {
    const changeOrders = this.applicationLines
      .filter(line => line.projectLine!.lineType === 'changeOrder');
    if(changeOrders.length === 0) {
      return 0;
    }
    return changeOrders
      .map(line => line?.incrementalStoredAmount && line.incrementalStoredAmount < 0 ? line.incrementalStoredAmount : 0)
      .reduce((current, total) => current + total);
  }

  get changeOrdersRetainageThisPeriod(): number {
    const changeOrders = this.applicationLines
      .filter(line => line.projectLine!.lineType === 'changeOrder');
    if(changeOrders.length === 0) {
      return 0;
    }
    return changeOrders
      .map(line => {
        let billedAmountRetainage =
          line?.incrementalBillAmount !== undefined ? line.incrementalBillAmount : 0;
        billedAmountRetainage = billedAmountRetainage * Number(line?.retainage);
        let materialRetainage =
          line?.incrementalStoredAmount !== undefined ? line.incrementalStoredAmount : 0;
        materialRetainage = materialRetainage * Number(line?.retainage);
        return billedAmountRetainage + materialRetainage;
      })
      .reduce((current, total) => current + total);
  }

  async load(): Promise<void> {
    if (this.id && !this.loading) {
      this.loading = true;
      const application = await this.dataService.get('applications', this.id) as ApplicationJSON;

      // Load the project
      if (application && application.projectId) {
        // Set the application attributes
        this.id = application.id;
        this.sequenceNumber = application.sequenceNumber;
        this.status = application.status;
        this.projectId = application.projectId;
        if (application.applicationDate) this.applicationDate = moment(application.applicationDate).format('YYYY-MM-DD');
        if (application.periodTo) this.periodTo = moment(application.periodTo).format('YYYY-MM-DD');
        this.applicationLines = application.applicationLines ? application.applicationLines : [];

        // Fetch the project
        this.project = await this.dataService.get('projects', `${application.projectId}?applicationSequenceNumber=${application.sequenceNumber}`) as ProjectJSON;

        // If we have a project with project lines, then we have no errors
        this.error = !(this.project && this.project.projectLines);

        // Build the application lines
        this.buildApplicationLines();
      }
      this.loading = false;

      // Copy the onLoad array and reset it
      const onLoad = this._onLoad.slice();
      this._onLoad = [];

      // Make the callbacks from the copy
      onLoad.forEach(callback => callback());
    } else if (this.loading) {
      // If we're already loading, return a promise, and add queue up the callback
      return new Promise(callback => {
        this._onLoad.push(callback);
      });
    }
  }

  buildApplicationLines(): void {
    if (this.project && this.project.projectLines) {
      // Sort the project lines
      this.project.projectLines = this.project.projectLines
        .sort((a, b) =>
          a.lineType === 'original' && b.lineType === 'changeOrder'
            ? -1
            : a.lineType === 'changeOrder' && b.lineType === 'original'
              ? 1
              : 0
        )
        .sort((a, b) => Number(a.sequenceNumber) - Number(b.sequenceNumber));

      this.project.projectLines
        .forEach((projectLine: ProjectLineJSON, i: number) => {
          // Set the billedToDate
          projectLine.billedToDate = projectLine.billedToDate ? projectLine.billedToDate : 0;
          projectLine.storedToDate = projectLine.storedToDate ? projectLine.storedToDate : 0;

          // See if we have an existing application line
          let existingApplicationLine = this.applicationLines
          ?.find(applicationLine => applicationLine.projectLineId === projectLine.id);

          // If we don't have an existing application line, fabricate one
          if (!existingApplicationLine) {
            existingApplicationLine = {
              applicationId: this.id,
              projectLineId: projectLine.id,
              projectLine: projectLine,
              lineDescription: projectLine.description,
              scheduledValue: projectLine.scheduledValue,
              retainage: projectLine.retainage,
            };
          // Add the fabricated application line
          this.applicationLines?.push(existingApplicationLine);
          } else {
          // Otherwise, update the application line with the values from the projectLine
            existingApplicationLine.lineDescription = projectLine.description;
            existingApplicationLine.scheduledValue = projectLine.scheduledValue;
            existingApplicationLine.retainage = projectLine.retainage;
          }
          existingApplicationLine.projectLine = projectLine;
          existingApplicationLine.sequenceNumber = i + 1;
          existingApplicationLine.billedToDate = projectLine.billedToDate;
          existingApplicationLine.storedToDate = projectLine.storedToDate;

          // Perform calculations
          const billedToDate = projectLine.billedToDate as number;
          const storedToDate = projectLine.storedToDate as number;
          const scheduledValue = projectLine.scheduledValue as number;
          const incrementalBillAmount = typeof existingApplicationLine.incrementalBillAmount === 'number' ? existingApplicationLine.incrementalBillAmount : 0;
          const incrementalStoredAmount = typeof existingApplicationLine.incrementalStoredAmount === 'number' ? existingApplicationLine.incrementalStoredAmount : 0;
          const retainage = typeof existingApplicationLine.retainage === 'number' ? existingApplicationLine.retainage : 0;

          // Set the incremental billed to date
          existingApplicationLine.incrementalBilledToDate = billedToDate + incrementalBillAmount;
          existingApplicationLine.incrementalStoredToDate = storedToDate + incrementalStoredAmount;

          // If we're less than $1 short of the total scheduled value, add the difference to the incremental bill amount
          existingApplicationLine.balanceToFinish = Math.round((scheduledValue - billedToDate - storedToDate - incrementalBillAmount - incrementalStoredAmount) * 100) / 100;
          if (
            existingApplicationLine.incrementalBillAmount
            && existingApplicationLine.incrementalBilledToDate
            && Math.abs(existingApplicationLine.balanceToFinish) < 1
          ) {
            existingApplicationLine.incrementalBillAmount += existingApplicationLine.balanceToFinish;
            existingApplicationLine.incrementalBilledToDate += existingApplicationLine.balanceToFinish;
            existingApplicationLine.balanceToFinish = 0;
          }

          // Calculate percent complete based on the new billed to date amount
          existingApplicationLine.percentComplete = Math.round((
            existingApplicationLine.incrementalBilledToDate / scheduledValue) * 10000) / 10000;

          // Calculate the retained amount based on the new billed to date value and the retainage
          // Round down to the nearest cent
          existingApplicationLine.retained = Math.round((existingApplicationLine.incrementalBilledToDate +
            existingApplicationLine.incrementalStoredToDate) * retainage * 100) / 100;
        });

      // Adjust the first original line to put residual pennies in the retained amount
      if(this.status === 'Final') {
        const firstLine = this.applicationLines.find(line => line.projectLine?.lineType === 'original');
        if(firstLine?.retained) firstLine.retained += Math.round(this.currentPaymentDue * 100) / 100;
      }

      // Sort the application lines
      this.applicationLines = this.applicationLines
        .sort((a, b) =>
          a.projectLine?.lineType === 'original' && b.projectLine?.lineType === 'changeOrder'
            ? -1
            : a.projectLine?.lineType === 'changeOrder' && b.projectLine?.lineType === 'original'
              ? 1
              : 0
        )
        .sort((a, b) => Number(a.sequenceNumber) - Number(b.sequenceNumber));

      // Assign original lines and change orders
      this.originalApplicationLines = this.applicationLines.filter(line => line?.projectLine?.lineType === 'original');
      this.changeOrderLines = this.applicationLines.filter(line => line?.projectLine?.lineType === 'changeOrder');

      // Add originalSequenceNumber for change orders
      this.changeOrderLines.forEach(changeOrder => {
        if (changeOrder?.projectLine?.parentId) {
          const originalProjectLine = this.originalApplicationLines.find(
            existingChangeOrder => existingChangeOrder?.projectLineId === changeOrder?.projectLine?.parentId
          );
          if (originalProjectLine) {
            changeOrder.originalSequenceNumber = originalProjectLine.sequenceNumber;
          }
        } else {
          changeOrder.originalSequenceNumber = changeOrder.sequenceNumber;
        }
      });

      // Re-sort change orders by originalSequenceNumber
      const changeOrders = this.changeOrderLines.sort((a, b) => {
        return Number(a.originalSequenceNumber) - Number(b.originalSequenceNumber);
      });
    }
  }

  async save(data: ApplicationJSON = this.toJSON()): Promise<ApplicationJSON> {
    // Make sure we're not already saving
    if (!this.saving) {
      // Set the saving state
      this.saving = true;

      // Create a new object w/ the form data
      const application: ApplicationJSON = JSON.parse(JSON.stringify(data));

      // Change the dates to the proper format
      application.applicationDate = moment(application.applicationDate).toDate();
      application.periodTo = moment(application.periodTo).toDate();

      // Set up an array of Promise to make updates concurrently
      const promises: Array<Promise<void | ApplicationJSON>> = [];

      // Set the sequenceNumber
      application.sequenceNumber = application.sequenceNumber ? Number(application.sequenceNumber) : Number(application.project?.applications?.length) + 1;

      // Filter on application lines with incremental amounts
      const applicationLines = application.applicationLines?.filter(applicationLine => {
        const hasBillAmount =
          Math.abs(Number(applicationLine.incrementalBillAmount)) > 0
          || Math.abs(Number(applicationLine.incrementalStoredAmount)) > 0;
        // If we have an application line ID and no bill amount, queue up the record for deletion
        if (applicationLine.id && !hasBillAmount) promises.push(this.dataService.del('application-lines', applicationLine.id));
        return hasBillAmount;
      });

      // Delete unneeded fields
      delete application.project;
      delete application.originalApplicationLines;
      delete application.applicationLines;
      delete application.changeOrderLines;

      // Create a new application
      if (!application.id) {
        const newApplication = await this.dataService.create('applications', application as DataJSON) as ApplicationJSON;
        application.id = newApplication.id;
        this.id = newApplication.id;
      } else {
        // Save an existing application
        promises.push(this.dataService.save('applications', application.id, application as unknown as DataJSON));
      }

      // If there are application lines, save them as well
      if (applicationLines && applicationLines.length > 0) {
        applicationLines.forEach((applicationLine: ApplicationLineJSON) => {
          // Store the application ID
          applicationLine.applicationId = application.id;

          // Delete unneeded fields
          delete applicationLine.projectLine;

          // Save an existing line
          if (applicationLine.id) {
            promises.push(this.dataService.save('application-lines', applicationLine.id, applicationLine as unknown as DataJSON) as unknown as Promise<ApplicationLineJSON>);
          } else {
            // Create a new line
            promises.push(this.dataService.create('application-lines', applicationLine as unknown as DataJSON) as unknown as Promise<ApplicationLineJSON>);
          }
        });
      }

      // Save application/application lines
      await Promise.all(promises);

      this.saving = false;

      // Copy the onSave array and reset it
      const onSave = this._onSave.slice();
      this._onSave = [];

      // Make the callbacks from the copy
      onSave.forEach(callback => callback(application));

      // Return the application
      return application;
    } else {
      // If we're already saving, return a promise and queue up the callback
      return new Promise((callback: (value: ApplicationJSON) => void) => {
        this._onSave.push(callback);
      });
    }
  }

  async complete(): Promise<ApplicationJSON> {
    this.status = 'Completed';
    return this.save();
  }

  calculate(updatedField?: UpdatedField | null): ApplicationJSON {
    if (updatedField && updatedField.path.match(/incrementalBillAmount|percentComplete|incrementalStoredAmount/)) {
      let rowNumber: RegExpMatchArray | string | number | null = updatedField.path.match(/\d+/);
      if (rowNumber !== null) {
        rowNumber = Number(rowNumber[0]);
        const fieldMatch = updatedField.path.match(/incrementalBillAmount|percentComplete|incrementalStoredAmount/);
        if (this.applicationLines && this.applicationLines[rowNumber] && fieldMatch) {
          const row = this.applicationLines[rowNumber];
          const scheduledValue = Number(row.scheduledValue);
          const billedToDate = Number(row.billedToDate);
          const storedToDate = isNaN(Number(row.storedToDate)) ? 0 : Number(row.storedToDate);
          let incrementalBillAmount = isNaN(Number(row.incrementalBillAmount)) ? 0 : Number(row.incrementalBillAmount);
          let incrementalStoredAmount = isNaN(Number(row.incrementalStoredAmount)) ? 0 : Number(row.incrementalStoredAmount);
          let percentComplete = Number(row.percentComplete) > 1 ? 1 : Number(row.percentComplete);
          const maxIncrementalBillAllowed = Math.round(100 * (scheduledValue - billedToDate)) / 100;
          const minIncrementalBillDiscountAllowed = maxIncrementalBillAllowed < 0 ? maxIncrementalBillAllowed : 0;
          const maxIncrementalStoredAllowed = Math.round(100 * (scheduledValue - billedToDate - storedToDate)) / 100;
          const minIncrementalStoredReversalAllowed = Math.round(-100 * storedToDate) / 100;

          switch (fieldMatch[0]) {
          case 'percentComplete':
            // Reduce the percent complete if it is over 100%
            percentComplete = percentComplete > 1 ? 1 : percentComplete;

            // Calculate the incrementalBillAmount, if the percent complete is greater than the current billed amount
            if(percentComplete > billedToDate / scheduledValue) {
              incrementalBillAmount = Math.round(100*(scheduledValue*percentComplete - billedToDate)) / 100;
            } else {
              // Otherwise, set the incremental bill amount to 0
              incrementalBillAmount = 0;
            }
            break;

          case 'incrementalBillAmount':
            incrementalBillAmount = Math.round(100*incrementalBillAmount) / 100;

            // Adjustment for discount
            if (scheduledValue < 0) {
              // If it exceeds min, reduce to match
              if (incrementalBillAmount < minIncrementalBillDiscountAllowed) {
                incrementalBillAmount = minIncrementalBillDiscountAllowed;
              } else if (incrementalBillAmount > 0) {
                // Set to 0 if it's a positive value
                incrementalBillAmount = 0;
              }
            } else if(incrementalBillAmount > maxIncrementalBillAllowed) {
              incrementalBillAmount = maxIncrementalBillAllowed;
            }

            // Update the percent complete
            percentComplete = (billedToDate + incrementalBillAmount) / scheduledValue;
            break;

          case 'incrementalStoredAmount':
            incrementalStoredAmount = Math.round(100*incrementalStoredAmount) / 100;

            // Reduce the incremental stored if it exceeds the max
            if (incrementalStoredAmount > scheduledValue - billedToDate - incrementalBillAmount) {
              incrementalStoredAmount = maxIncrementalStoredAllowed;
            }

            // Reduce the reversal, if it's less than the min allowed
            if (incrementalStoredAmount < minIncrementalStoredReversalAllowed) {
              incrementalStoredAmount = minIncrementalStoredReversalAllowed;
            }
            break;

          default:
            break;
          }

          // If we're 100% billed, reverse out any remaining materials stored
          if (
            Math.round(10000*percentComplete)/10000 === 1
            && storedToDate > 0
          ) {
            incrementalStoredAmount = minIncrementalStoredReversalAllowed;
          }

          // Update all the values
          row.percentComplete = Math.round(percentComplete * 1000) / 1000;
          row.incrementalBillAmount =
            (typeof row.incrementalBillAmount === 'undefined' || row.incrementalBillAmount === null) && incrementalBillAmount === 0
              ? undefined
              : incrementalBillAmount;
          row.incrementalStoredAmount =
            incrementalStoredAmount === 0
              ? undefined
              : incrementalStoredAmount;

          // Update the original and change order arrays
          this.originalApplicationLines = this.applicationLines.filter(line => line?.projectLine?.lineType === 'original');
          this.changeOrderLines = this.applicationLines.filter(line => line?.projectLine?.lineType === 'changeOrder')
            .sort((a, b) => {
              return Number(a.originalSequenceNumber) - Number(b.originalSequenceNumber);
            });
        }
      }
      console.log('Application updated field', updatedField);
    }
    return this.toJSON();
  }

  toString(): string {
    return JSON.stringify(this.toJSON());
  }

  fromJSON(json: ApplicationJSON): void {
    Object.keys(json).forEach(key => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this[key] = json[key];
    });
  }

  toJSON(): ApplicationJSON {
    const { id, sequenceNumber, status, projectId, project, applicationDate, periodTo, applicationLines, originalApplicationLines, changeOrderLines } = this;
    return JSON.parse(JSON.stringify({
      id,
      sequenceNumber,
      status,
      projectId,
      project,
      applicationDate,
      periodTo,
      applicationLines,
      originalApplicationLines,
      changeOrderLines,
    }));
  }
}
