import React from 'react';
import { WidgetProps } from '@rjsf/core';
import { Form, InputOnChangeData } from 'semantic-ui-react';
import Currency from './Currency';

interface CurrencyWidgetState {
  displayValue?: string;
}
export default class CurrencyWidget extends React.Component<WidgetProps, CurrencyWidgetState> {
  state: CurrencyWidgetState = {};

  onChange(e: React.ChangeEvent<EventTarget>, data: InputOnChangeData): void {
    // Set the value
    const value = new Currency(data.value);

    // Call onChange if the value actually changed
    if (new Currency(this.props.value).value !== value.value) {
      this.props.onChange(value.value);
      this.setState({ displayValue: value.displayValue.match(/\.$/) ? value.displayValue : undefined });
    } else {
      this.setState({ displayValue: value.displayValue });
    }
  }

  render(): React.ReactNode {
    const value = new Currency(this.props.value);
    const displayValue = this.state.displayValue ? this.state.displayValue : value.displayValue;

    const width = !value.value
      ? 110
      : 50 + value.value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).length * 7;

    // If it's a read-only field, output the value pre-fixed with a $ sign
    if (this.props.schema.readOnly) {
      return (
        <Form.Field style={{ width }}>
          <label>{this.props.schema.title ? this.props.schema.title : this.props.id.replace(/^root_/, '')}</label>
          <span>{
            value.value
              ? value.value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
              : '$0.00'
          }</span>
        </Form.Field>
      );
    }

    return (
      <Form.Input
        style={{ width }}
        type="string"
        label={this.props.schema.title}
        icon="dollar"
        iconPosition="left"
        value={displayValue}
        error={false}
        onChange={(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void =>
          this.onChange(e, data)
        }
        onBlur={(): void => this.props.onBlur(this.props.id, displayValue)}
        onFocus={(): void => this.props.onFocus(this.props.id, displayValue)}
        required={this.props.required}
        placeholder={this.props.uiSchema['ui:options']?.placeholder}
      />
    );
  }
}
