import React from 'react';
import { AuthServiceInterface } from '../services/AuthService';
import { Button, Message } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import Page from '../components/Page';

interface EmailVerificationRequiredPageProps {
  auth?: AuthServiceInterface;
  firstLogin?: boolean;
}

interface EmailVerificationRequiredPageState {
  disableButton: boolean;
}

@inject('auth')
@observer
export default class EmailVerificationRequiredPage extends React.Component<
  EmailVerificationRequiredPageProps
  , EmailVerificationRequiredPageState
> {

  state: EmailVerificationRequiredPageState = {
    disableButton: false,
  };

  async sendVerificationEmail(): Promise<void> {
    this.setState({ disableButton: true });
    if (this.props.auth) {
      await this.props.auth.sendVerificationEmail();
    }
  }

  render(): React.ReactNode {
    return (
      <Page
        pageTitle='Email Verification Required'
      >
        <Message warning>
          <Message.Header>Please verify your email address to continue</Message.Header>
          <p>
            We have sent an email to {this.props.auth?.user?.email} to verify your email address.
          </p>
          <p>
            Please check your email and click on the link to verify your email to continue using the system.
            The email will be from noreply@payearned.com.  If you do not see the email, please be sure to check your
            spam folder.
          </p>
        </Message>
        {
          this.props.firstLogin
            ? ''
            :
            <React.Fragment>
              <p>
                If you would like us to send the confirmation email again, please click the button below.
              </p>
              <Button primary disabled={this.state.disableButton} onClick={(): Promise<void> => this.sendVerificationEmail()}>
                {
                  this.state.disableButton ? 'Email Sent' : 'Re-send Verification Email'
                }
              </Button>
            </React.Fragment>
        }
      </Page>
    );
  }
}
