import {
  Document,
  Page as PDFPage,
  Text,
  View,
  StyleSheet,
  Font
} from '@react-pdf/renderer';
import React from 'react';
import { ApplicationJSON } from '../schemas/applications/ApplicationJSON';
import ActiveApplication from '../schemas/applications/ActiveApplication';
import { PDFHeader } from './components/PDFHeader';
import { ProjectJSON } from '../schemas/projects/ProjectJSON';
import MaterialTransactionSummary from './components/MaterialTransactionSummary';
import { PDFFooter } from './components/PDFFooter';
import filterApplicationChangeOrders from './FilterApplicationChangeOrders';
import { BrandFooter } from './components/BrandFooter';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/fonts/roboto/Roboto-Regular.ttf', fontStyle: 'normal' },
    { src: '/fonts/roboto/Roboto-Italic.ttf', fontStyle: 'italic' },
    { src: '/fonts/roboto/Roboto-Thin.ttf', fontStyle: 'normal', fontWeight: 'thin' },
    { src: '/fonts/roboto/Roboto-ThinItalic.ttf', fontStyle: 'italic', fontWeight: 'thin' },
    { src: '/fonts/roboto/Roboto-Light.ttf', fontStyle: 'normal', fontWeight: 'light' },
    { src: '/fonts/roboto/Roboto-LightItalic.ttf', fontStyle: 'italic', fontWeight: 'light' },
    { src: '/fonts/roboto/Roboto-Light.ttf', fontStyle: 'normal', fontWeight: 'light' },
    { src: '/fonts/roboto/Roboto-LightItalic.ttf', fontStyle: 'italic', fontWeight: 'light' },
    { src: '/fonts/roboto/Roboto-Medium.ttf', fontStyle: 'normal', fontWeight: 'medium' },
    { src: '/fonts/roboto/Roboto-MediumItalic.ttf', fontStyle: 'italic', fontWeight: 'medium' },
    { src: '/fonts/roboto/Roboto-Bold.ttf', fontStyle: 'normal', fontWeight: 'bold' },
    { src: '/fonts/roboto/Roboto-BoldItalic.ttf', fontStyle: 'italic', fontWeight: 'bold' },
    { src: '/fonts/roboto/Roboto-Black.ttf', fontStyle: 'normal', fontWeight: 'heavy' },
    { src: '/fonts/roboto/Roboto-BlackItalic.ttf', fontStyle: 'italic', fontWeight: 'heavy' },
  ]
});
Font.registerHyphenationCallback(word => [ word ]);

const styles = StyleSheet.create({
  page: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    fontFamily: 'Roboto',
    paddingBottom: 58
  },
  main: {
    margin: 31,
    marginTop: 15,
    marginBottom: 15
  },
  table: {
    display: 'table',
    width: 'auto',
    marginTop: 15,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    alignSelf: 'auto',
    borderLeftWidth: 1,
  },
  tableRowFooterTotal: {
    margin: 'auto',
    flexDirection: 'row',
    alignSelf: 'auto',
    backgroundColor: '#ededed',
    borderLeftWidth: 1
  },
  tableRowFooterChangeOrders: {
    marginTop: 15,
    flexDirection: 'row',
    alignSelf: 'auto',
    backgroundColor: '#ededed',
    borderTopWidth: 1,
    borderLeftWidth: 1
  },
  tableRowFooterGrandTotals: {
    marginTop: 15,
    flexDirection: 'row',
    alignSelf: 'auto',
    backgroundColor: '#ededed',
    borderTopWidth: 1,
    borderLeftWidth: 1
  },
  tableNarrowCol: {
    width: '5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: '10%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableMediumCol: {
    width: '15%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableWideCol: {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableExtraWideCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  mergedCell: {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  mergedInnerTopCell: {
    width: '100%',
    borderBottomWidth: 1
  },
  mergedInnerColumnsContainer: {
    width: '100%',
    margin: 'auto',
    flexDirection: 'row',
    alignSelf: 'auto',
  },
  mergedInnerLeftColumn: {
    width: '50%',
    borderRightWidth: 1
  },
  mergedInnerRightColumn: {
    width: '50%'
  },
  tableCell: {
    textAlign: 'center',
    marginTop: 5,
    fontSize: 10,
  },
  annotatedCell: {
    textAlign: 'center',
    marginTop: 5,
    fontSize: 10,
    flexDirection: 'row',
  },
  materialMessage: {
    top: 2,
    margin: '0 10 5 10',
    textAlign: 'left',
    flexDirection: 'row',
    fontStyle: 'italic',
    fontSize: 8,
  },
  materialMessageAsterisk: {
    top: '-3px',
    left: '-5px',
    width: 0,
    textAlign: 'left',
    color: 'red',
    fontSize: 10,
  },
  materialAsterisk: {
    top: '-3px',
    left: '3px',
    width: 0,
    textAlign: 'left',
    color: 'red',
  },
  tableCellNoMargin: {
    textAlign: 'center',
    fontSize: 10
  },
  tableCellBold: {
    textAlign: 'center',
    marginTop: 5,
    fontSize: 10,
    fontWeight: 'bold'
  }
});

export function ContinuationSheetPDF(applicationJSON: ApplicationJSON, projectJSON: ProjectJSON, logo?: { url: string; width: number; height: number } | undefined): React.ReactElement {
  const application = new ActiveApplication(applicationJSON);

  // Filter change orders
  filterApplicationChangeOrders(application);

  const enUs = 'en-US';
  const currencyStyle = { style: 'currency', currency: 'USD' };
  const percentStyle = { style: 'percent' };

  return (
    <Document>
      <PDFPage
        size='LETTER'
        orientation='landscape'
        style={ styles.page }
        wrap
      >
        {/* Header */ }
        <View style={ styles.main } fixed>
          { PDFHeader(applicationJSON, projectJSON, 'continuation', undefined, logo) }
        </View>

        <View style={ styles.main }>
          <View style={ styles.table }>
            {/* Top Table Header */ }
            <View fixed style={ styles.tableRow }>
              <View style={ styles.tableNarrowCol }>
                <Text style={ styles.tableCell }>A</Text>
              </View>
              <View style={ styles.tableWideCol }>
                <Text style={ styles.tableCell }>B</Text>
              </View>
              <View style={ styles.tableCol }>
                <Text style={ styles.tableCell }>C</Text>
              </View>
              <View style={ styles.tableCol }>
                <Text style={ styles.tableCell }>D</Text>
              </View>
              <View style={ styles.tableCol }>
                <Text style={ styles.tableCell }>E</Text>
              </View>
              <View style={ styles.tableCol }>
                <Text style={ styles.tableCell }>F</Text>
              </View>
              <View style={ styles.tableMediumCol }>
                <Text style={ styles.tableCell }>G</Text>
              </View>
              <View style={ styles.tableCol }>
                <Text style={ styles.tableCell }>H</Text>
              </View>
              <View style={ styles.tableCol }>
                <Text style={ styles.tableCell }>I</Text>
              </View>
            </View>
            {/* Named Table Header */ }
            <View fixed style={ styles.tableRow }>
              <View style={ styles.tableNarrowCol }>
                <Text style={ styles.tableCell }>ITEM #</Text>
              </View>
              <View style={ styles.tableWideCol }>
                <Text style={ styles.tableCell }>DESCRIPTION OF WORK</Text>
              </View>
              <View style={ styles.tableCol }>
                <Text style={ styles.tableCell }>SCHEDULED VALUE</Text>
              </View>

              {/*Create a merged cell by specifying a width of 20%*/ }
              <View style={ styles.mergedCell }>

                {/*Content for the top of the merged cell, with a border on the bottom*/ }
                <View style={ styles.mergedInnerTopCell }>
                  <Text style={ styles.tableCell }>WORK COMPLETED</Text>
                </View>

                {/*Split the bottom of the cell into two columns*/ }
                {/*Essentially this creates one row with two columns, within the merged cell*/ }
                <View style={ styles.mergedInnerColumnsContainer }>
                  <View style={ styles.mergedInnerLeftColumn }>
                    <Text style={ styles.tableCell }>FROM PREVIOUS APPLICATIONS</Text>
                    <Text style={ styles.tableCellNoMargin }>Column (D & E)</Text>
                  </View>
                  <View style={ styles.mergedInnerRightColumn }>
                    <Text style={ styles.tableCell }>THIS PERIOD</Text>
                  </View>
                </View>
              </View>

              <View style={ styles.tableCol }>
                <Text style={ styles.tableCell }>MATERIALS PRESENTLY STORED</Text>
                <Text style={ styles.tableCellNoMargin }>(not in D or E)</Text>
              </View>
              <View style={ styles.tableCol }>
                <Text style={ styles.tableCell }>TOTAL COMPLETED AND STORED TO DATE</Text>
                <Text style={ styles.tableCellNoMargin }>(D + E + F)</Text>
              </View>
              <View style={ styles.tableNarrowCol }>
                <Text style={ styles.tableCell }>%</Text>
                <Text style={ styles.tableCellNoMargin }>(G / C)</Text>
              </View>
              <View style={ styles.tableCol }>
                <Text style={ styles.tableCell }>BALANCE TO FINISH EXCLUDING RETAINAGE</Text>
              </View>
              <View style={ styles.tableCol }>
                <Text style={ styles.tableCell }>RETAINAGE</Text>
                <Text style={ styles.tableCellNoMargin }>(G x Retainage)</Text>
              </View>
            </View>
            {/* Table Data Row */ }
            {
              application && application.applicationLines ? application?.applicationLines
                  .filter(applicationLine => applicationLine.projectLine?.lineType === 'original')
                  .map(applicationLine => {
                    const scheduledValue = applicationLine?.scheduledValue?.toLocaleString(enUs, currencyStyle);
                    const billedToDate = applicationLine?.billedToDate?.toLocaleString(enUs, currencyStyle);
                    const incrementalBillAmount = Number(applicationLine?.incrementalBillAmount || 0).toLocaleString(enUs, currencyStyle);
                    const materialsPresentlyStored = applicationLine?.incrementalStoredToDate?.toLocaleString(enUs, currencyStyle);
                    const newMaterialStored = applicationLine?.incrementalStoredAmount !== undefined && applicationLine?.incrementalStoredAmount > 0
                      ? applicationLine.incrementalStoredAmount.toLocaleString(enUs, currencyStyle)
                      : undefined;
                    const materialReversal = applicationLine?.incrementalStoredAmount !== undefined && applicationLine?.incrementalStoredAmount < 0
                      ? (-applicationLine.incrementalStoredAmount).toLocaleString(enUs, currencyStyle)
                      : undefined;
                    const incrementalBilledToDate =
                      (
                        Number(applicationLine?.billedToDate)
                        + Number(applicationLine?.incrementalBillAmount ? applicationLine?.incrementalBillAmount : 0)
                        + Number(applicationLine?.incrementalStoredToDate)
                      ).toLocaleString(enUs, currencyStyle);
                    const percentComplete = (
                      (
                        Number(applicationLine?.billedToDate)
                        + Number(applicationLine?.incrementalBillAmount ? applicationLine?.incrementalBillAmount : 0)
                        + Number(applicationLine?.incrementalStoredToDate)
                      ) / Number(applicationLine?.scheduledValue)
                    ).toLocaleString(enUs, { style: 'percent' });
                    const balanceToFinish = (applicationLine.balanceToFinish
                      ? applicationLine.balanceToFinish
                      : 0
                    ).toLocaleString(enUs, currencyStyle);
                    const retained = applicationLine?.retained?.toLocaleString(enUs, currencyStyle);
                    return (
                      <View style={styles.tableRow} wrap={false} key={applicationLine.id}>
                        <View style={styles.tableNarrowCol}>
                          <Text style={styles.tableCell}>{applicationLine.sequenceNumber}</Text>
                        </View>
                        <View style={styles.tableWideCol}>
                          <View style={styles.tableCell}>
                            <Text>{applicationLine.lineDescription}</Text>
                            {
                              newMaterialStored
                                ?
                                <View style={styles.materialMessage}>
                                  <Text style={styles.materialMessageAsterisk}>*</Text>
                                  <Text>{newMaterialStored} new materials stored</Text>
                                </View>
                                : materialReversal
                                  ?
                                  <View style={styles.materialMessage}>
                                    <Text style={styles.materialMessageAsterisk}>*</Text>
                                    <Text>{materialReversal} materials used, previously billed</Text>
                                  </View>
                                  : materialReversal
                                    ?
                                    <View style={ styles.materialMessage }>
                                      <Text style={ styles.materialMessageAsterisk }>*</Text>
                                      <Text>{ materialReversal } material used, previously billed</Text>
                                    </View>
                                    : <View/>
                            }
                          </View>
                        </View>
                        <View style={ styles.tableCol }>
                          <Text style={ styles.tableCell }>{ scheduledValue }</Text>
                        </View>
                        <View style={ styles.tableCol }>
                          <Text style={ styles.tableCell }>{ billedToDate }</Text>
                        </View>
                        <View style={ styles.tableCol }>
                          <View style={ styles.annotatedCell }>
                            {
                              materialReversal
                                ? <Text style={ styles.materialAsterisk }>*</Text>
                                : <View/>
                            }
                            <Text>{ incrementalBillAmount }</Text>
                          </View>
                        </View>
                        <View style={ styles.tableCol }>
                          <View style={ styles.annotatedCell }>
                            {
                              newMaterialStored || materialReversal
                                ? <Text style={ styles.materialAsterisk }>*</Text>
                                : <View/>
                            }
                            <Text>{ materialsPresentlyStored }</Text>
                          </View>
                        </View>
                        <View style={ styles.tableCol }>
                          <Text style={ styles.tableCell }>{ incrementalBilledToDate }</Text>
                        </View>
                        <View style={ styles.tableNarrowCol }>
                          <Text style={ styles.tableCell }>{ percentComplete }</Text>
                        </View>
                        <View style={ styles.tableCol }>
                          <Text style={ styles.tableCell }>{ balanceToFinish }</Text>
                        </View>
                        <View style={ styles.tableCol }>
                          <Text style={ styles.tableCell }>{ retained }</Text>
                        </View>
                      </View>
                    );
                  }) :
                <View style={ styles.tableRow }>
                  <View style={ styles.tableNarrowCol }/>
                  <View style={ styles.tableWideCol }/>
                  <View style={ styles.tableCol }/>
                  <View style={ styles.tableCol }/>
                  <View style={ styles.tableCol }/>
                  <View style={ styles.tableCol }/>
                  <View style={ styles.tableCol }/>
                  <View style={ styles.tableNarrowCol }/>
                  <View style={ styles.tableCol }/>
                  <View style={ styles.tableCol }/>
                </View>
            }
            {/* Table Footer Totals Row */ }
            <View style={ styles.tableRowFooterTotal }>
              <View style={ styles.tableExtraWideCol }>
                <Text style={ styles.tableCellBold }>TOTALS</Text>
              </View>
              <View style={ styles.tableCol }>
                <Text
                  style={ styles.tableCell }>{ application.totalScheduledValue.toLocaleString(enUs, currencyStyle) }</Text>
              </View>
              <View style={ styles.tableCol }>
                <Text
                  style={ styles.tableCell }>{ application.totalBilledToDate.toLocaleString(enUs, currencyStyle) }</Text>
              </View>
              <View style={ styles.tableCol }>
                <Text
                  style={ styles.tableCell }>{ application.totalIncrementalBillAmount.toLocaleString(enUs, currencyStyle) }</Text>
              </View>
              <View style={ styles.tableCol }>
                <Text
                  style={ styles.tableCell }>{ application.totalMaterialPresentlyStored.toLocaleString(enUs, currencyStyle) }</Text>
              </View>
              <View style={ styles.tableCol }>
                <Text
                  style={ styles.tableCell }>{ application.totalCompletedAndStoredToDate.toLocaleString(enUs, currencyStyle) }</Text>
              </View>
              <View style={ styles.tableNarrowCol }>
                <Text
                  style={ styles.tableCell }>{ application.totalCompletedInStoreAsPercentage.toLocaleString(enUs, percentStyle) }</Text>
              </View>
              <View style={ styles.tableCol }>
                <Text
                  style={ styles.tableCell }>{ application.balanceToFinish.toLocaleString(enUs, currencyStyle) }</Text>
              </View>
              <View style={ styles.tableCol }>
                <Text
                  style={ styles.tableCell }>{ application.totalRetained.toLocaleString(enUs, currencyStyle) }</Text>
              </View>
            </View>
          </View>

          <View wrap={false}>
            {
              // Display footers for change orders, if we have change orders
              application.changeOrders.length > 0 ? (
                <View>
                  <View style={ styles.tableRowFooterChangeOrders }>
                    <View style={ styles.tableExtraWideCol }>
                      <Text style={ styles.tableCellBold }>TOTAL CHANGE ORDERS</Text>
                    </View>
                    <View style={ styles.tableCol }>
                      <Text
                        style={ styles.tableCell }>{ application.totalScheduledValueChangeOrders.toLocaleString(enUs, currencyStyle) }</Text>
                    </View>
                    <View style={ styles.tableCol }>
                      <Text
                        style={ styles.tableCell }>{ application.totalBilledToDateChangeOrders.toLocaleString(enUs, currencyStyle) }</Text>
                    </View>
                    <View style={ styles.tableCol }>
                      <Text
                        style={ styles.tableCell }>{ application.totalIncrementalBillAmountChangeOrders.toLocaleString(enUs, currencyStyle) }</Text>
                    </View>
                    <View style={ styles.tableCol }>
                      <Text
                        style={ styles.tableCell }>{ application.totalMaterialPresentlyStoredChangeOrders.toLocaleString(enUs, currencyStyle) }</Text>
                    </View>
                    <View style={ styles.tableCol }>
                      <Text
                        style={ styles.tableCell }>{ application.totalCompletedAndStoredToDateChangeOrders.toLocaleString(enUs, currencyStyle) }</Text>
                    </View>
                    <View style={ styles.tableNarrowCol }>
                      <Text
                        style={ styles.tableCell }>{ application.totalCompletedInStoreAsPercentageChangeOrders.toLocaleString(enUs, percentStyle) }</Text>
                    </View>
                    <View style={ styles.tableCol }>
                      <Text
                        style={ styles.tableCell }>{ application.balanceToFinishChangeOrders.toLocaleString(enUs, currencyStyle) }</Text>
                    </View>
                    <View style={ styles.tableCol }>
                      <Text
                        style={ styles.tableCell }>{ application.totalRetainedChangeOrders.toLocaleString(enUs, currencyStyle) }</Text>
                    </View>
                  </View>

                  {/* Table Footer Grand Totals */ }
                  <View style={ styles.tableRowFooterGrandTotals }>
                    <View style={ styles.tableExtraWideCol }>
                      <Text style={ styles.tableCellBold }>GRAND TOTALS</Text>
                    </View>
                    <View style={ styles.tableCol }>
                      <Text
                        style={ styles.tableCell }>{ application.grandTotalScheduledValue.toLocaleString(enUs, currencyStyle) }</Text>
                    </View>
                    <View style={ styles.tableCol }>
                      <Text
                        style={ styles.tableCell }>{ application.grandTotalBilledToDate.toLocaleString(enUs, currencyStyle) }</Text>
                    </View>
                    <View style={ styles.tableCol }>
                      <Text
                        style={ styles.tableCell }>{ application.grandTotalIncrementalBillAmount.toLocaleString(enUs, currencyStyle) }</Text>
                    </View>
                    <View style={ styles.tableCol }>
                      <Text
                        style={ styles.tableCell }>{ application.grandTotalMaterialPresentlyStored.toLocaleString(enUs, currencyStyle) }</Text>
                    </View>
                    <View style={ styles.tableCol }>
                      <Text
                        style={ styles.tableCell }>{ application.grandTotalCompletedAndStoredToDate.toLocaleString(enUs, currencyStyle) }</Text>
                    </View>
                    <View style={ styles.tableNarrowCol }>
                      <Text
                        style={ styles.tableCell }>{ application.grandTotalCompletedInStoreAsPercentage.toLocaleString(enUs, percentStyle) }</Text>
                    </View>
                    <View style={ styles.tableCol }>
                      <Text
                        style={ styles.tableCell }>{ application.grandTotalBalanceToFinish.toLocaleString(enUs, currencyStyle) }</Text>
                    </View>
                    <View style={ styles.tableCol }>
                      <Text
                        style={ styles.tableCell }>{ application.grandTotalRetained.toLocaleString(enUs, currencyStyle) }</Text>
                    </View>
                  </View>
                </View>
              ) : <View/>
            }
            <MaterialTransactionSummary application={ application }/>
          </View>
          {BrandFooter({ bottom: -27 })}
          {PDFFooter()}
        </View>
      </PDFPage>
    </Document>
  );
}
