import React from 'react';
import { Button, Container, Header, Image, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

interface SubscriptionRequiredModalProps {
  modalDisplayed: boolean;
  onCloseClick?: () => void;
  isCalledFromRoute?: boolean;
}

export default class SubscriptionRequiredScreen extends React.Component<SubscriptionRequiredModalProps> {
  clickOnClose(): void {
    if(typeof this.props.onCloseClick !== 'undefined')
      this.props.onCloseClick();

    if(typeof this.props.isCalledFromRoute === 'undefined'){
      window.location.href = '/';
    }
  }

  render(): React.ReactNode {
    const { modalDisplayed } = this.props;
    const display = typeof modalDisplayed === 'undefined' ? true : modalDisplayed;
    return (
      <Container>
        <Header
          image
          style={ {
            marginTop: '3em',
            marginBottom: '1em'
          } }
        >
          <Image style={ { width: '100%', height: '120px' } }
            src='/img/PAYearned-logo-long.png'/>
        </Header>
        <Modal open={ display }>
          <Modal.Header>Subscription Required</Modal.Header>
          <Modal.Content>
            <p style={ { fontSize: '1.5em' } }>
              <p>
                <b>Subscription required for full access to the { process.env.REACT_APP_APP_NAME } solution</b>
              </p>
              <p>
                {/* eslint-disable-next-line react/no-unescaped-entities */ }
                <b>No risk 30-day trial. Cancel within 30 days if you're not fully satisfied, and your credit card
                  will never be charged!</b>
              </p>
              <p>
                <i><b style={ { color: 'red' } }>With a paid subscription to { process.env.REACT_APP_APP_NAME }, you
                  will have access to all PDF documents for you to submit to general contractors:</b></i>
              </p>
            </p>
            <p>
              <ul>
                <li>Applications & Certificates for payment</li>
                <li>Continuation Sheets</li>
                <li>Change Order Sheets</li>
              </ul>
            </p>
            <p>
              { process.env.REACT_APP_APP_NAME } will automatically generate the forms for you! All you need to do is
              print, sign, and deliver them to your general contractors. No more having to fill these forms out every
              time you want to get paid. Let PAYearned do it for you!
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={ (): void => this.clickOnClose() }>Close</Button>
            <Button color='green' as={ Link }
              to={ { pathname: '/subscription/select', query: { callbackUrl: window.location.pathname } } }>Subscribe
              Now</Button>
          </Modal.Actions>
        </Modal>;
      </Container>
    );

  }
}
