import { JSONSchema7 } from 'json-schema';
import CurrencyWidget from './widgets/currency/CurrencyWidget';
import { UiSchema } from '@rjsf/core';
import PercentWidget from './widgets/PercentWidget';
import { TableCellFieldTemplate } from './templates/TableCellFieldTemplate';

export const ApplicationLineChangeOrderJSONSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    originalSequenceNumber: {
      type: 'number',
      title: '#',
      readOnly: true,
    },
    lineDescription: {
      type: 'string',
      title: 'Description',
      readOnly: true,
    },
    scheduledValue: {
      type: 'number',
      title: 'Scheduled Value',
      readOnly: true,
    },
    billedToDate: {
      type: 'number',
      title: 'Billed to Date',
      readOnly: true,
    },
    storedToDate: {
      type: 'number',
      title: 'Stored to Date',
      readOnly: true,
    },
    incrementalBillAmount: {
      type: 'number',
      title: 'Work Completed This Period',
    },
    incrementalStoredAmount: {
      type: 'number',
      title: 'Materials Stored/(Used) This Period'
    },
    percentComplete: {
      type: 'number',
      title: 'Total Percent of Completion'
    },
  }
};

export const ApplicationLineChangeOrderUISchema: UiSchema = {
  originalSequenceNumber: {
    'ui:FieldTemplate': TableCellFieldTemplate,
  },
  lineDescription: {
    'ui:FieldTemplate': TableCellFieldTemplate,
  },
  scheduledValue: {
    'ui:FieldTemplate': TableCellFieldTemplate,
    'ui:widget': CurrencyWidget,
  },
  retainage: {
    'ui:FieldTemplate': TableCellFieldTemplate,
    'ui:widget': PercentWidget,
  },
  billedToDate: {
    'ui:FieldTemplate': TableCellFieldTemplate,
    'ui:widget': CurrencyWidget,
  },
  storedToDate: {
    'ui:FieldTemplate': TableCellFieldTemplate,
    'ui:widget': CurrencyWidget,
  },
  percentComplete: {
    'ui:FieldTemplate': TableCellFieldTemplate,
    'ui:widget': PercentWidget,
  },
  incrementalBillAmount: {
    'ui:FieldTemplate': TableCellFieldTemplate,
    'ui:widget': CurrencyWidget,
  },
  incrementalStoredAmount: {
    'ui:FieldTemplate': TableCellFieldTemplate,
    'ui:widget': CurrencyWidget,
  },
};
