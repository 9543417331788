import React from 'react';
import Page from '../components/Page';

export default class PrivacyPage extends React.Component {
  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

  render(): React.ReactNode {
    return(
      <Page pageTitle='Privacy Policy'>
        <div>
          <p style={{ textAlign: 'center' }}>
            <strong>Version: 1.0</strong>
          </p>
          <p style={{ textAlign: 'center' }}>
            <strong>Last revised on: May 31, 2021</strong>
          </p>
          <p>
            <strong>Company: Results Zone, LLC DBA PAYearned </strong>
            and its affiliated entities<strong> </strong>(“<strong>Company</strong>”, “<strong>us</strong>”, “<strong>our</strong>”, and “<strong>we</strong>”)
          </p>
          <p>
            <strong>Company Contact Information: </strong>
            <a href="mailto:info@payearned.com">info@payearned.com</a>
          </p>
          <p>
            <strong>Company Website: </strong>
            The website located at
            <a href="https://payearned.com">https://PAYearned.com</a> (together with any websites on related domains or
            subdomains, the “<strong>Site</strong>”).
          </p>
          <p>
            <strong>Company Apps: </strong>
            The mobile or online application(s) or platforms entitled “PAYearned.com” (collectively, the “<strong>App</strong>”).
          </p>
          <p>
            <strong>
              BY USING THE SITE, THE APP, OR ANY OF COMPANY’S PRODUCTS OR SERVICES, YOU ARE EXPRESSLY AGREEING AND CONSENTING TO THE TERMS OF THIS PRIVACY POLICY! PLEASE READ IT CAREFULLY!
            </strong>
          </p>
          <p>
            This Privacy Policy (this “Policy”) is a legal agreement between you and your business, if applicable, on the one hand (together, “you” or “your”), and Company (as defined above) on the other hand. This Policy describes how we may collect, store, use and share certain information about you and your usage of the Site, the App and related products and services (this information is referred to as your “User Information” throughout this Policy).
          </p>
          <p>
            <strong>1. User Information Is Collected.</strong><strong> </strong>
            Company collects your information during your access or use of (i) any websites or web application provided, published, developed or made available by the Company, including the Site; (ii) any mobile or online applications provided, published, licensed, developed or made available by the Company, including the App; and (iii) any feature, content, software, hardware, services or other products available on or through the Site or the App or otherwise provided by the Company (together with the Site and the App, the “Services”). You access and use of the Services is conditioned on your providing us with any requested User Information, and your refusal to provide requested User Information may prevent Company from providing certain Services.
          </p>
          <p>
            <strong>2. What Information Is Collected.</strong>
          </p>
          <p>
            <strong>2.1. Personally, Identifiable Information.</strong><strong> </strong>
            Company may collect any  personally identifiable information that you provide in connection with any Services, including, without limitation: your name, email address, mailing address, phone number, photograph, birthdate, passport, driver’s license, government-issued identification numbers, and other similar information provided with respect to your business or its employees, officers, representatives or affiliates, and certain historical, contact, and demographic information about you.
          </p>
          <p>
            <strong>2.2. Device Information.</strong><strong> </strong>
            Company may collect information about your device when you access any Services, including hardware model, operating system and version, unique device identifier, mobile network information, and information about the device’s interaction with the Services, as well as GPS information and information about the location of your device when using any Services.
          </p>
          <p>
            <strong>2.3. Payment Information.</strong><strong> </strong>
            Company may collect information provided in connection with you making, accepting, requesting or recording payments, credits or money transfers through any Services, including: payment card numbers, bank accounts information, when and where the transactions occur, the names of the transacting parties, a description of the transactions, the payment or transfer amounts, billing and shipping information, and the devices and payment methods used to complete the transactions.
          </p>
          <p>
            <strong>2.4. Tax Information.</strong><strong> </strong>
            Company may collect information regarding your tax information, including states of residence, withholding allowances and tax filing status.
          </p>
          <p>
            <strong>2.5. Purchase Information.</strong><strong> </strong>
            Company may collect information and data about your purchase information and preferences, including products and services purchased, amounts paid, frequency of purchases, location of purchases, and similar information.
          </p>
          <p>
            <strong>2.6. Usage Information.</strong><strong> </strong>
            Company may collect information and data about how you use the Services, including access time, Services accessed, browser type and language, Internet Protocol (“IP”) address, other applications on your device, webpages and applications viewed and used, time spent on webpages and applications, links clicked, and conversion information (e.g., transactions entered into).
          </p>
          <p>
            <strong>2.7. Aggregated Data.</strong><strong> </strong>
            Company may collect aggregated data about your or your use of Services, or any other aggregated form of the other types of User Information set forth above. Aggregated data that we collect cannot be specifically associated with you individually, unlike most of the other forms of User Information.
          </p>
          <p>
            <strong>3. How User Information is Collected.</strong>
          </p>
          <p>
            <strong>3.1. Information Given  by You.</strong><strong> </strong>
            You provide certain User Information when you register for your account with the Services and as you use the Services. We consider all such information voluntarily provided.
          </p>
          <p>
            <strong>3.2. Automatic Collection.</strong><strong> </strong>
            User Information can be recorded and collected by software or processes that run automatically on your computer or device or on Company’s servers as you use the Services.
          </p>
          <p>
            <strong>3.3. Cookies.</strong><strong> </strong>
            User Information may be collected by sending cookies to your device. Cookies are small data files that are stored on your hard drive or in your device memory when you use the Services. Among other things, cookies support the integrity of the Company registration process, retain your preferences and account settings, and help evaluate and compile aggregated statistics about your activity. Company may use both session cookies and persistent cookies. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be used by the browser on subsequent uses of the Services. Company may link the information stored in cookies to your User Information submitted while using the Services. You can typically remove and reject cookies from our Site with your browser settings. Many browsers are set to accept cookies until you change your settings. If you remove or reject our cookies, it could affect how the Services are provided.
          </p>
          <p>
            <strong>3.4. Web Beacons.</strong><strong> </strong>
            User Information may also be collected by using web beacons. Web beacons are electronic images that may be used in connection with the Services. Company may use web beacons to deliver cookies, track the number of visits to the Services, understand Service usage and effectiveness, and determine whether an email has been opened and acted upon.
          </p>
          <p>
            <strong>3.5. Collection from Third Parties.</strong><strong> </strong>
            User Information is also collected from third parties that already have access to certain of your User Information, including third-party verification services, credit bureaus, mailing list providers, and publicly available sources. User Information may also include your data and content contained within a third-party service links or integrates with the Services. In some circumstances, where lawful, this information may include your government-issued identification number.  These third parties may use cookies, web beacons, and other technologies to collect User Information. This Privacy Policy does not apply to, and Company is not responsible for, third-party cookies, web beacons, or other tracking technologies, which are covered by such third parties’ privacy policies. Company continually evaluates analytics services to use in addition its own, and Company may update this policy in the future to reflect Company’s ongoing use of said services.
          </p>
          <p>
            <strong>4. How Collected User Information Is Used.</strong>
          </p>
          <p>
            <strong>4.1. As Permitted by Law.</strong><strong> </strong>
            Company may use, publish, store, duplicate, manipulate and share User Information in any way not otherwise prohibited by applicable privacy laws.
          </p>
          <p>
            <strong>4.2. Provide Services.</strong><strong> </strong>
            User Information is stored and used so that you can verify your identify and save your preferences, profile and information in connection with creating, using and accessing your account with the Services, and to deliver, operate, provide, maintain, enhance, personalize, tailor and facilitate your use of the Services.
          </p>
          <p>
            <strong>4.3. Improve Services.</strong><strong> </strong>
            User Information is also used to develop new products and Services, provide optimization, statistical analysis, and improvements for existing Services, and to improve web design and functionality.
          </p>
          <p>
            <strong>4.4. Communication Purposes.</strong><strong> </strong>
            User Information is used to deliver technical notices, security alerts, support messages, administrative notices and alerts, and communications relevant to your use of the Service, as well as to inform you about software compatibility issues, send news or information, conduct surveys and collect feedback. User Information is also used to communicate with you about products, services, contests, promotions, discounts, incentives, gift cards, loyalty programs, and rewards, based on your communication preferences and applicable privacy laws.
          </p>
          <p>
            <strong>4.5. Facilitate Payments.</strong><strong> </strong>
            Using and storing your User Information is necessary for us to be able to process or record payment transactions or money transfers.
          </p>
          <p>
            <strong>4.6. Track and Display Consumer Behavior.</strong><strong> </strong>
            User Information is stored so that the Services can track historical transactions, payments, invoices, receipts and past order information. User Information is also used to display current, ongoing, pending or requested transactions or orders.
          </p>
          <p>
            <strong>4.7. For Automated Decisions.</strong><strong> </strong>
            User Information may be used by the Services to automatically trigger certain actions by the Services, including targeted advertisements or content, directing you to specific products or offerings, suggesting various actions or offerings based on behavior and preferences, etc.
          </p>
          <p>
            <strong>4.8. Legal Compliance.</strong><strong> </strong>
            User Information is stored so that we can comply with applicable laws and regulations, including the privacy laws and anti-money laundering laws.
          </p>
          <p>
            <strong>4.9. Aggregated Data Collection.</strong><strong> </strong>
            User Information is collected and aggregated to monitor aggregate usage and web traffic routing of all users of the Services, as well as to generate aggregated statistics about purchasing behavior of different demographics and populations.
          </p>
          <p>
            <strong>4.10. Dispute Resolution; Contract Enforcement.</strong><strong> </strong>
            User Information may be used to resolve disputes; collect fees; provide assistance with problems with Services; protect Company rights or property or the security or integrity of the Services; enforce the Terms of Use and other terms and conditions that govern use of the Services; and investigate, detect and prevent fraud, security breaches, and other potentially prohibited or illegal activities.
          </p>
          <p>
            <strong>5. How Collected User Information Is Shared.</strong>
            User Information is collected and aggregated to monitor aggregate usage and web traffic routing of all users of the Services, as well as to generate aggregated statistics about purchasing behavior of different demographics and populations.
          </p>
          <p>
            <strong>5.1. As Permitted by Law.</strong><strong> </strong>
            Company may share User Information in any way not otherwise prohibited by applicable privacy laws.
          </p>
          <p>
            <strong>5.2. With Consent.</strong><strong> </strong>
            User Information may be shared with other parties with your consent, such as for marketing uses that you authorize or consent to.
          </p>
          <p>
            <strong>5.3. Facilitate Third Party Services.</strong><strong> </strong>
            Company, like many businesses, sometimes hire other companies or individuals to perform certain business-related functions and will need to share certain User Information for such parties to perform such functions. These third-party functions may include: anonymous site metrics, analytics services, payment processing, point of sale services, task management services, delivery of loyalty programs, management of gift cards, creation and delivery of promotional materials, product support and maintenance, order fulfillment and shipment/delivery of products and Services to users, mailing services, maintaining databases and other features or services included in or necessary for the Services.  When Company employs another party to perform a function of this nature, we only provide them with the information that they need to perform their specific function.
          </p>
          <p>
            <strong>5.4. Delivery of Targeted Services and Ads.</strong><strong> </strong>
            User Information may also be shared or licensed to third-parties without your consent to deliver targeted and non-targeted third-party content and advertisements in connection with the Services as well as un-related services, websites and applications.
          </p>
          <p>
            <strong>5.5. For Legal and Safety Purposes.</strong><strong> </strong>
            User Information may be shared for legal, protection, and safety purposes, such as to comply with laws, respond to lawful requests and legal processes, protect the rights and property of Company against competitors or claimants, and enforce our agreements, policies, and terms of use.
          </p>
          <p>
            <strong>5.6. Sale of User Information.</strong><strong> </strong>
            User Information may be sold, transferred or assigned to third-parties without your consent for their own uses, subject to applicable privacy laws.
          </p>
          <p>
            <strong>5.7. Business Transfers.</strong><strong> </strong>
            As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, User Information may be part of the transferred assets.
          </p>
          <p>
            <strong>5.8. Affiliated Entities, Agents, Consultants and Related Third Parties.</strong><strong> </strong>
            We may also share your User Information with our affiliated entities for purposes consistent with this Policy.
          </p>
          <p>
            <strong>6. How Collected User Information Is Protected.</strong>
          </p>
          <p>
            <strong>6.1. Protective Measures.</strong><strong> </strong>
            Company takes commercially reasonable measures, including administrative, technical, and physical safeguards, to (i) protect User Information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction, (ii) ensure the security, confidentiality, and integrity of the User Information, (iii) protect against any anticipated threats or hazards to the security or integrity of the User Information, (iv) protect against unauthorized access to, or unauthorized use or disclosure of, the User Information, and (v) take such security measures required by any applicable privacy laws.
          </p>
          <p>
            <strong>6.2. Third Party Partners and Employees.</strong><strong> </strong>
            Company may, by itself or using third-party service providers, hold, process and store User Information, including in the United States, Japan, the European Union and other countries. Company restricts access to User Information to those employees, contractors, and agents who need to know that information for purposes of performing their obligations to Company or to you, and who are subject to contractual confidentiality obligations, and who may be disciplined or terminated if they fail to meet these obligations. Company third-party service providers store and transmit User Information in compliance with appropriate confidentiality and security measures.
          </p>
          <p>
            <strong>6.3. Security Breach.</strong><strong> </strong>
            Company cannot guarantee that unauthorized third parties will never be able to defeat Company security measures or use User Information for improper purposes. In the event that any User Information in Company’s possession or under Company’s control is compromised as a result of a security breach, Company shall give prompt notice to you, with full particulars, and shall immediately commence a thorough investigation of any such incident.
          </p>
          <p>
            <strong>6.4. Company Not Liable for Breach.</strong><strong> </strong>
            Company will not be liable for any damages, claims, liability, or causes of action that arise as a result of any such security breach.
          </p>
          <p>
            <strong>7. Exclusions.</strong>
            This Policy shall not apply to (i) information posted by you to any public areas of the Services, such as bulletin boards, chat room, community pages or comment areas, (ii) any ideas for new products or modifications to existing products, and (iii) other unsolicited submissions (collectively, “Unsolicited Information”). All Unsolicited Information shall be deemed to be non-confidential and voluntarily given, and Company shall be free to reproduce, use, disclose, and distribute such Unsolicited Information to others without limitation or attribution.
          </p>
          <p>
            <strong>8. Choices; International Privacy Laws; Retention.</strong>
          </p>
          <p>
            <strong>8.1. Right to be  Informed, Access.</strong><strong> </strong>
            We strive to be completely transparent in how we are using your personally identifiable information (your “Personal Data”). You have the right to know exactly what Personal Data is held about you and how it is processed.
          </p>
          <p>
            <strong>8.2. Right of Rectification.</strong><strong> </strong>
            You are entitled to correct your Personal Data if it is inaccurate or incomplete.
          </p>
          <p>
            <strong>8.3. Right of Erasure or to Decline to Share.</strong><strong> </strong>
            You have the right to have your Personal Data deleted or removed for any or no reason, and you have the right to decline to share certain Personal Data with us. However, if you choose to exercise either of these rights, your ability to use and access our websites, apps, products and services may be impacted because various features require your Personal Data to function correctly.
          </p>
          <p>
            <strong>8.4. Right to Data Portability.</strong><strong> </strong>
            You have the right to retain and reuse any Personal Data for your own purpose.
          </p>
          <p>
            <strong>8.5. Right to  Object, Restrict Processing.</strong><strong> </strong>
            In certain circumstances, you are entitled to object to the use of your Personal Data. This includes when your Personal Data is used for the purpose of direct marketing, scientific and historical research or the performance of a task in the public interest, when such use has a consequence with a legal bearing on yourself, or when such use is for the purpose of automatic decision making that has a material impact outside your use of our websites, apps, products or services. You may not  object if our use of your Personal Data is otherwise permitted by applicable law.
          </p>
          <p>
            <strong>8.6. International Privacy Laws.</strong><strong> </strong>
            If you are using any of the websites, apps, products or services from outside the United States, please be aware that you are sending information, including personally identifiable information and data, to the United States where our servers are located. That information may then be transferred within the United States or back out of the United States to other countries outside of your country of residence, depending on the type of information and how it is stored or used by us. These countries (including the United States) may not necessarily have data protection laws as comprehensive or protective as those in your country of residence; however, our collection, storage and use of your Personal Data will at all times continue to be governed by this Privacy Policy.
            For personally identifiable information received from the European Union, we also certify that we adhere 			to the EU-US Privacy Shield principles of Notice, Choice, Onward Transfers, Security, Data Integrity & 			Purpose Limitation, Access and Recourse, and Enforcement.
          </p>
          <p>
            <strong>8.7. Data Retention.</strong><strong> </strong>
            We only retain your Personal Data for as long as your account with us is active, and then for an additional limited period of time for as long as we need it to fulfill the purposes for which we initially collected such information, unless otherwise required by law or to protect our rights. For example, we may be required to retain copies of certain information to comply with legal obligations, to resolve disputes or enforce our agreements.
          </p>
          <p>
            <strong>9. Changes to Policy.</strong>
          </p>
          <p>
            <strong>9.1. Notification of Changes.</strong><strong> </strong>
            This Policy is subject to occasional revision, and if we make any substantial changes, we may notify you through the Services, by sending you an e-mail to the last e-mail address you provided to us (if any), and/or by prominently posting notice of the changes on the Site or through your account.  You are responsible for providing us with your most current e-mail address.  In the event that the last e-mail address that you have provided us is not valid, or for any reason is not capable of delivering to you the notice described above, our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice.
          </p>
          <p>
            <strong>9.2. Effectiveness of Changes.</strong><strong> </strong>
            Any changes to this Policy will be effective upon the earlier of fifteen (15) calendar days following our dispatch of an e-mail notice to you (if applicable) or fifteen (15) calendar days following our posting of notice of the changes on our Site.  These changes will be effective immediately for new users of the Services.
          </p>
          <p>
            <strong>9.3. Use and Acceptance of Policy.</strong><strong> </strong>
            Continued use of the Services following notice of changes to this Policy shall indicate your acknowledgement and acceptance of such changes and agreement to be bound by the updated Policy.
          </p>
          <p>
            <strong>9.4. Last Revision Date.</strong><strong> </strong>
            This Policy was last updated on the date indicated above. Please review this policy periodically, and especially before you voluntarily provide any User Information.
          </p>
          <p>
            <strong>10. Contact Information.</strong><strong> </strong>
            If you have any questions that aren’t addressed by this Privacy Policy, please let us know! Our contact information can be found at the top of this Privacy Policy. Use it to contact us for anything related to our use of your information, including opting-out of sharing your information with others, updating your information, finding out what information we have about you, or for anything that you feel violates any of your above listed rights.
          </p>
        </div>
      </Page>
    );
  }
}
