import React from 'react';
import { observer, inject } from 'mobx-react';
import { Container } from 'semantic-ui-react';
import Alert from './Alert';
import { AlertService } from '../services/AlertService';

interface AlertContainerProps {
  alert?: AlertService;
}

const styles = {
  alertContainer: {
    position: 'absolute',
    top: 47,
    right: 20,
    width: 'auto',
    textAlign: 'center',
    zIndex: 9999
  }
};

@inject('alert')
@observer
export default class AlertContainer extends React.Component<AlertContainerProps> {
  render(): React.ReactNode {
    return (
      <Container style={styles.alertContainer}>
        {this.props.alert?.alerts.map((alert, index) => (
          <Alert
            key={index + Math.random()}
            message={alert.message}
            title={alert.title}
            alertType={alert.type}
            onDismiss={(): void => this.props.alert?.dismissAlert(alert.id)}
          />
        ))}
      </Container>
    );
  }
}
