import React from 'react';
import { Plan } from '../../../services/PaymentService';
import {DISABLE_AUTOMATIC_BILLING_MESSAGE} from "./DisableAutomaticBillingMessage";

interface SubscriptionConfirmationWithoutTrialProps {
  plan?: Plan;
  trialDays: number;
}
export default class SubscriptionConfirmationWithoutTrial extends React.Component<SubscriptionConfirmationWithoutTrialProps> {
  render(): React.ReactNode {
    if (!this.props.plan) return '';
    const amount = (this.props.plan.amount / 100).toLocaleString(
      'en-US', { style: 'currency', currency: 'USD' }
    );
    return <React.Fragment>
      <p>
        Congratulations, your subscription has been activated.  Your credit card has been charged {amount} in advance
        for one {this.props.plan.interval} of service, and will be automatically be charged every {
          this.props.plan.interval
        } hereafter.
      </p>
      { DISABLE_AUTOMATIC_BILLING_MESSAGE }
    </React.Fragment>;
  }
}
