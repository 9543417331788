import React from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import { FirebaseAuthService } from '../services/AuthService';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Subscription } from '../services/PaymentService';

interface ProfileMenuProps {
  auth?: FirebaseAuthService;
  subscriptions: Array<Subscription> | undefined;
}

@inject('auth')
@observer
export default class ProfileMenu extends React.Component<ProfileMenuProps> {

  async logout(): Promise<void> {
    await this.props.auth?.logout();
  }

  render(): React.ReactNode {
    const { subscriptions } = this.props;
    return (
      <Menu.Menu position='right' style={ { zIndex: 101 } }>
        <Dropdown item text='Account Menu'>
          <Dropdown.Menu>
            <Dropdown.Item
              icon='user'
              text='Profile'
              href='/profile'
            />
            <Dropdown.Item
              icon='lock'
              text='Password'
              href='/change-password'
            />
            <Dropdown.Item
              icon='payment'
              text={ subscriptions && subscriptions.length > 0 ? 'Manage Subscription' : 'Subscribe'}
              href={ subscriptions && subscriptions.length > 0 ? '/subscription/manage': '/subscription/select' }
            />
            <Dropdown.Item
              icon='file alternate'
              text='Receipts'
              href='/receipts'
            />
            <Dropdown.Item
              icon='question circle'
              text='How To Video Tutorials'
              href='https://payearned.com/help'
              target="_blank"
            />
            <Dropdown.Item
              icon='sign-out'
              text='Logout'
              as={ Link }
              to='/'
              onClick={ (): Promise<void> | undefined => this.logout() }
            />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    );
  }
}
