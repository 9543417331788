import React from 'react';
import Page from '../../components/Page';
import { ProjectJSONSchema, validateProject } from '../../schemas/projects/ProjectJSONSchema';
import { DataService, DataJSON } from '../../services/DataService';
import { RouteChildrenProps } from 'react-router-dom';
import Page404 from '../Page404';
import { Icon, Loader } from 'semantic-ui-react';
import { FormValidation, IChangeEvent, UiSchema } from '@rjsf/core';
import { JSONSchema7 } from 'json-schema';
import { FieldTemplate } from '../../schemas/templates/FieldTemplate';
import { ProjectLineJSON } from '../../schemas/ProjectLineSchema';
import CalculationSchemaForm, { UpdatedField } from '../../components/CalculationSchemaForm';
import { ArrayFieldTableTemplate } from '../../schemas/templates/ArrayFieldTableTemplate';
import { TableCellFieldTemplate } from '../../schemas/templates/TableCellFieldTemplate';
import CurrencyWidget from '../../schemas/widgets/currency/CurrencyWidget';
import PercentWidget from '../../schemas/widgets/PercentWidget';
import { SemanticShorthandCollection } from 'semantic-ui-react/dist/commonjs/generic';
import { BreadcrumbSectionProps } from 'semantic-ui-react/dist/commonjs/collections/Breadcrumb/BreadcrumbSection';
import dot from 'dot-object';
import { ProjectJSON } from '../../schemas/projects/ProjectJSON';
import { ProjectUISchema } from '../../schemas/projects/ProjectUISchema';
import { GeneralContractorJSON } from '../../schemas/GeneralContractorJSONSchema';
import { MAX_PHONE_WIDTH_MEDIA_QUERY } from '../../common/Constants';
import NewFeatureDropdownSelector from '../../schemas/widgets/NewFeatureDropdownSelector';
import ConfirmDuplicateName from './components/ConfirmDuplicateName';
import { inject, observer } from 'mobx-react';
import { FirebaseAuthService } from '../../services/AuthService';
import { role } from '../../components/auth/EmailPasswordSignup';
import { AlertService } from '../../services/AlertService';

interface EditProjectLocation extends Location {
  state: {
    project?: ProjectJSON;
  };
}

interface EditProjectProps extends RouteChildrenProps<{ id: string }> {
  location: EditProjectLocation;
  auth: FirebaseAuthService;
  alert: AlertService;
}

interface EditProjectState {
  loading: boolean;
  error: boolean;
  project?: ProjectJSON;
  schema: JSONSchema7;
  generalContractors: Array<GeneralContractorJSON>;
  showNameWarning: boolean;
  isEditAllowed: boolean;
}

@inject('auth')
@inject('alert')
@observer
export default class EditProject extends React.Component<EditProjectProps, EditProjectState> {
  uiSchema: UiSchema = ProjectUISchema;

  state: EditProjectState = {
    loading: true,
    error: false,
    schema: ProjectJSONSchema,
    generalContractors: [],
    showNameWarning: false,
    isEditAllowed: true
  }
  deletedProjectIds: Array<string> = [];
  dataService = new DataService();
  descriptionFieldObserver?: MutationObserver;

  async componentDidMount(): Promise<void> {
    let loading = true;
    let error = true;
    let project: ProjectJSON = {};
    const userRole: role = this.props.auth.user?.role as role;

    const generalContractors = await this.dataService.list('general-contractors') as unknown as Array<GeneralContractorJSON>;
    this.setState({ generalContractors });

    // Proceed if we have an id and we weren't passed in a project
    if (this.props.match?.params.id && !this.props.location.state?.project) {
      // Load the record
      project = await this.dataService.get('projects', this.props.match.params.id) as unknown as ProjectJSON;
      error = !project;
    } else if (this.props.location.state?.project) {
      // If a project was passed in, load it
      project = this.props.location.state.project;
      error = false;
    } else {
      // If we don't have an ID, it's because we have a new record
      error = false;
    }

    this.uiSchema.generalContractorId['ui:widget'] = NewFeatureDropdownSelector;
    this.uiSchema.generalContractorId['ui:options'] = {
      alert: this.props.alert,
      selectedRecordId: project.generalContractorId
    };

    loading = false;

    // Sort the project lines
    if (project && project.projectLines) {
      project.projectLines = project.projectLines
        .sort((a, b) => Number(a.sequenceNumber) - Number(b.sequenceNumber));
    }

    // Add change orders
    if (
      project
      && project.projectLines
      && project.projectLines.find(projectLine => projectLine.lineType === 'changeOrder')
    ) {
      project.changeOrders = project.projectLines.filter(projectLine => projectLine.lineType === 'changeOrder');
      project.projectLines = project.projectLines.filter(projectLine => projectLine.lineType === 'original');
    }

    // If we have a project with applications, check to see if we need to update the form to disallow certain things
    const schema: JSONSchema7 = this.state.schema;

    if (typeof project === 'undefined' && generalContractors.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      project.generalContractor = generalContractors[generalContractors.length - 1];
    }

    //The project which has application can be edited only if you are Administrator

    if (project && project.applications && project.applications.length > 0) {
      const hasCompletedApplications = Boolean(project.applications.find(application => application.status?.toLowerCase() === 'completed'));

      // If user is not admin --> he is not able to see the project which has some completed applications
      if (hasCompletedApplications && userRole !== 'ADMINISTRATOR') {
        this.setState({ isEditAllowed: false });
      }

      // If the project has completed applications, disable updates to the project/project lines
      if (hasCompletedApplications &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        typeof schema.properties!.originalContractAmount!.readOnly !== 'undefined'
      ) {
        // Make the original contract amount read-only
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        schema.properties!.originalContractAmount!.readOnly = true;

        // Make the project lines read-only
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        schema.properties!.projectLines!.items.properties.description.readOnly = true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        schema.properties!.projectLines!.items.properties.scheduledValue.readOnly = true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        schema.properties!.projectLines!.items.properties.retainage.readOnly = true;

        // Disable addition/deletion of project lines
        this.uiSchema.projectLines['ui:options'] = {
          disableDelete: true,
          disableAdd: true,
        };

        // Enable change orders
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        schema.properties!.changeOrders = {
          type: 'array',
          title: 'Change Orders',
          items: {
            type: 'object',
            required: [ 'description', 'scheduledValue', 'retainage' ],
            properties: {
              description: {
                type: 'string',
                title: 'Line Description',
              },
              scheduledValue: {
                type: 'number',
                title: 'Scheduled Value',
              },
              retainage: {
                type: 'number',
                title: 'Retainage',
              },
            },
          },
        };
        this.uiSchema.changeOrders = {
          'ui:ArrayFieldTemplate': ArrayFieldTableTemplate,
          items: {
            description: {
              'ui:FieldTemplate': TableCellFieldTemplate,
            },
            scheduledValue: {
              'ui:FieldTemplate': TableCellFieldTemplate,
              'ui:widget': CurrencyWidget,
            },
            retainage: {
              'ui:FieldTemplate': TableCellFieldTemplate,
              'ui:widget': PercentWidget,
            },
          },
        };
      }
    }

    // Workaround for showing Required placeholder for description inputs
    this.descriptionFieldObserver = new MutationObserver(() => {
      const descriptionInputs = document.querySelectorAll('input[id*=\'_description\']');
      if (descriptionInputs) {
        descriptionInputs.forEach(line => {
          (line as HTMLInputElement).placeholder = 'Required';
        });
      }
    });
    this.descriptionFieldObserver.observe(document, { childList: true, subtree: true });

    // Set the data for the GC lookup
    this.uiSchema.generalContractorId.options.parent = project;
    this.setState({ loading, error, project, schema });
  }

  componentWillUnmount(): void {
    this.descriptionFieldObserver?.disconnect();
  }

  deleteCheck(originalLines: Array<ProjectLineJSON>, formDataLines?: Array<ProjectLineJSON>): void {
    // Check to see if any project IDs are missing
    originalLines
      // Only get project lines with IDs
      ?.filter((projectLine: ProjectLineJSON) => projectLine.id)
      // Iterate through the project lines with IDs
      .forEach((existingProjectLine: ProjectLineJSON) => {
        // If we don't find the project ID, add it to the deleted project IDs array
        if (!formDataLines?.find((currentProjectLine => currentProjectLine.id === existingProjectLine.id))) {
          this.deletedProjectIds.push(existingProjectLine!.id as string);
          this.deletedProjectIds = this.deletedProjectIds.filter((v, i, a) => a.indexOf(v) === i);
        }
      });
  }

  async save(e: IChangeEvent<ProjectJSON>): Promise<void> {
    // Exit if we're already saving
    if (this.state.loading) {
      return;
    }

    // Show the loader
    this.setState({ loading: true });

    // Create a new object w/ the form data
    const project: ProjectJSON = JSON.parse(JSON.stringify(e.formData));

    // Check to see if a project exists with the same name
    const projectExists = await this.dataService.projectNameExists(String(project.name), project.id);
    if (projectExists) {
      this.setState({ loading: false, showNameWarning: true, project });
      return;
    }

    // Perform the save
    this._save(project);
  }

  async _save(project: ProjectJSON) {
    // Create a new project
    const isNew = !project.id;
    if (!project.id) {
      const newProject = await this.dataService.create('projects', project as unknown as DataJSON) as unknown as ProjectJSON;
      project.id = newProject.id;
    } else {
      // Save an existing project
      // If there are no deleted IDs, just save the project
      if (this.deletedProjectIds.length === 0) {
        await this.dataService.save('projects', project.id, project as unknown as DataJSON);
      } else {
        // If we have deleted project IDs, use a promise array to perform actions concurrently
        const promises: Array<Promise<ProjectJSON | void>> = [];

        // Queue up the save
        promises.push(this.dataService.save('projects', project.id, project as unknown as DataJSON));

        // Queue up the deletions
        this.deletedProjectIds.forEach(projectLineId => {
          promises.push(this.dataService.del('project-lines', projectLineId));
        });

        // Wait for everything to come back
        await Promise.all(promises);
      }
    }

    // Move to the add project lines step, if it's a new project
    if (isNew) {
      this.props.history.push(`/projects/${project.id}/edit-project-lines`);
    } else {
      // Otherwise, go back to display mode for the project
      this.props.history.push(`/projects/${project.id}`);
    }
  }

  checkDuplicateName(allowDuplicate: boolean): void {
    if (allowDuplicate) {
      // Show the loader
      this.setState({ loading: true, showNameWarning: false });
      this._save(this.state.project as ProjectJSON);
    } else {
      this.setState({ showNameWarning: false });
    }
  }

  calculate(project: ProjectJSON, updatedField: UpdatedField | null | undefined): ProjectJSON {
    // See if we added a new project line or change order
    if (
      updatedField?.path.match(/(^changeOrders\.\d+$)|(^projectLines\.\d+$)/)
    ) {
      const newLine = dot.pick(updatedField.path, project) as ProjectLineJSON;
      // Add the sequence number
      newLine.sequenceNumber =
        // Calculate the sequence number from the project lines
        (project.projectLines?.length ? project.projectLines.length : 1)
        // Add the change orders too
        + (project.changeOrders ? project.changeOrders.length : 0);

      // Add retainage if we have a default retainage and the retainage is not yet set
      if (project.defaultRetainage && project.defaultRetainage > 0 && !newLine.retainage) {
        newLine.retainage = project.defaultRetainage;
      }
    }

    // If the updated field was a retainage value and it's greater than 1, reset it to 1
    if (updatedField?.path?.match(/retainage/) && Number(updatedField?.value) > 1) {
      const line = dot.pick(updatedField?.path.replace(/\.retainage$/, ''), project) as ProjectLineJSON;
      line.retainage = 1;
    }
    this.uiSchema.generalContractorId.options.parent = project;
    return project;
  }

  render(): React.ReactNode {
    const { project } = this.state;
    // Show the loader
    if (this.state.loading) {
      return <Loader active/>;
    }

    if (typeof project === 'undefined' || this.state.error || !this.state.isEditAllowed) {
      return <Page404 {...this.props} />;
    }

    const breadcrumb: SemanticShorthandCollection<BreadcrumbSectionProps> = [
      { key: 'home', href: '/', content: 'Your Projects', link: true },
    ];
    if (this.props.match?.params.id) {
      breadcrumb.push({
        key: 'project',
        href: `/projects/${this.state?.project?.id}`,
        content: 'Project Summary',
        link: true
      });
      breadcrumb.push({ key: 'edit', content: 'Edit Project Info', active: true });
    } else {
      breadcrumb.push({ key: 'new', content: 'Create Project', active: true });
    }
    return (
      <Page pageTitle={this.props.match?.params.id ? 'Edit Project Info' : 'Create Project'} breadcrumb={breadcrumb}>
        {
          this.state.showNameWarning
            ? <ConfirmDuplicateName
              onClose={(allowDuplicateName): void => this.checkDuplicateName(allowDuplicateName)}
              name={String(this.state?.project?.name)}
            />
            : ''
        }
        <CalculationSchemaForm
          schema={this.state.schema}
          uiSchema={this.uiSchema}
          FieldTemplate={FieldTemplate}
          formData={this.state.project}
          onSubmit={(e: IChangeEvent<ProjectJSON>): Promise<void> => this.save(e)}
          onCalculate={(project: ProjectJSON, updatedField?: UpdatedField | null): ProjectJSON => this.calculate(project, updatedField)}
          validate={(formData: ProjectJSON, errors: FormValidation): FormValidation => validateProject(formData, errors)}
          saveButtonLabel={this.state?.project?.id
            ? <React.Fragment><Icon name='save'/>Save Project Info</React.Fragment>
            : <React.Fragment><Icon name='list'/>Add Project Lines</React.Fragment>}
        />
      </Page>
    );
  }
}
