import Page from '../components/Page';
import React from 'react';
import { AlertService } from '../services/AlertService';
import { BASE_URL, DataService } from '../services/DataService';
import { inject, observer } from 'mobx-react';
import { ProjectColumnName } from '../common/Constants';
import { ProjectJSON } from '../schemas/projects/ProjectJSON';
import { ReactTabulator } from 'react-tabulator';
import { RouteChildrenProps } from 'react-router-dom';
import { Method, PaginationType, TabulatorOptions } from '../common/tabulator-utils/SchemaDefinition';
import { Auth } from '../services/AuthService';
import { ajaxURLGenerate, mapAjaxResponse } from '../common/tabulator-utils/AjaxUtils';
import { Header } from 'semantic-ui-react';
import ClearProjectFilters from '../components/ClearProjectFilters';

interface ClosedProjectsPageProps extends RouteChildrenProps<{ id: string }> {
  alert?: AlertService;
}

interface ClosedProjectsPageState {
  loading: boolean;
  projects: Array<ProjectJSON>;
  filtered: boolean;
  alert?: AlertService;
}

@inject('alert')
@observer
export default class ClosedProjectsPage extends
  React.Component<ClosedProjectsPageProps, ClosedProjectsPageState> {
  ref?: React.ElementRef<any>;
  dataService = new DataService();

  state: ClosedProjectsPageState = {
    loading: true,
    projects: [],
    filtered: false,
  }

  columns = [
    {
      title: ProjectColumnName.CreateDate, field: 'createdAt',
      formatter: 'link',
      formatterParams: this.getDateFormatterParams(),
      width: 125,
    },
    {
      title: ProjectColumnName.ProjectName, field: 'name',
      formatter: 'link',
      formatterParams: this.getFormatterParams(),
      headerFilter: 'input',
      headerFilterPlaceholder: 'Search project name',
    },
    {
      title: ProjectColumnName.Location, field: 'addressLine1',
      formatter: 'link',
      formatterParams: this.getLocationFormatter(),
      headerFilter: 'input',
      headerFilterPlaceholder: 'Search location',
    },
    {
      title: ProjectColumnName.GeneralContractorName, field: 'generalContractor.name',
      formatter: 'link',
      formatterParams: this.getFormatterParams(),
      headerFilter: 'input',
      headerFilterPlaceholder: 'Search general contractor',
    },
  ]

  async componentDidMount(): Promise<void> {
    const projects = await this.dataService?.listProjects(false, true) as Array<ProjectJSON>;
    this.setState({ projects });
  }

  getDateFormatterParams(): any {
    return {
      label: (cell: any) => {
        const value: Date = new Date(cell._cell.row.data.createdAt);
        return value.toLocaleDateString();
      },
      url: (cell: any) => {
        const value: number | string | null | undefined = cell._cell.row.data.id;
        return '/projects/' + value;
      }
    };
  }

  getFormatterParams(): any {
    return {
      url: (cell: any) => {
        const value: number | string | null | undefined = cell._cell.row.data.id;
        return '/projects/' + value;
      }
    };
  }

  getLocationFormatter(): any {
    return {
      url: (cell: any) => {
        const value: number | string | null | undefined = cell._cell.row.data.id;
        return '/projects/' + value;
      },
      label: (cell: any) => {
        const p = cell._cell.row.data;
        return p.addressLine1?.concat(', ' + p.city || '', ' ' + p.zip || '');
      }
    };
  }

  rowClick = (e: any, row: any): void => {
    const value: number | string | null | undefined = row._row.data.id;
    this.props.history.push('/projects/' + value);
  };

  clearFilters(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.ref.table.clearHeaderFilter();
    this.setState({ filtered: false });
  }

  render(): React.ReactNode {
    const tabulatorOptions: TabulatorOptions = {
      pagination: PaginationType.REMOTE,
      paginationSize:10,
      ajaxConfig: {
        method: Method.GET,
        headers: {
          'Authorization': `Bearer ${ Auth.user?.accessToken }`,
        }
      },
      ajaxURL: `${ BASE_URL }/projects`,
      ajaxURLGenerator: ajaxURLGenerate,
      ajaxResponse: mapAjaxResponse,
      ajaxLoaderLoading: '<span>Loading.</span>',
      ajaxFiltering: true,
      ajaxParams: {
        closed: true,
        archived: false
      },
      layout:'fitColumns',
      responsiveLayout: true
    };

    return(
      <Page pageTitle='Closed' breadcrumb={[
        { key: 'home', href: '/', content: 'Your Projects', link: true },
        { key: 'closed', content: 'Closed', active: true }
      ]}>
        <ClearProjectFilters filtered={this.state.filtered} clearFilters={() => this.clearFilters()} />
        {
          this.state.projects.length > 0 ? (
            <ReactTabulator
              ref={(ref: any) => (this.ref = ref)}
              // @ts-ignore
              columns={this.columns}
              options={tabulatorOptions}
              data={this.state.projects}
              rowClick={this.rowClick}
              dataFiltered={(): void => {
                let filtered = false;
                document.querySelectorAll('div.tabulator .tabulator-header-filter input').forEach((input) => {
                  if (!filtered) { filtered = (input as HTMLInputElement).value !== ''; }
                });
                if (filtered !== this.state.filtered) {
                  this.setState({ filtered });
                }
              }}
            />
          ) : (
            <Header as='h3'>There are no Closed Projects.</Header>
          )
        }
      </Page>
    );
  }
}
