import React from 'react';
import CookieService from '../services/CookieService';
import { RouteComponentProps } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';



interface ReferralComponentProps extends RouteComponentProps{
  rId: string;
  cookie?: CookieService;
}

@inject('cookie')
@observer
export default class ReferralComponent extends React.Component<ReferralComponentProps>{

  async componentDidMount(): Promise<void> {
    if(this.props.match.params){
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const { rId } = this.props.match.params;
      this.props.cookie?.setReferralId(rId);
    }
  }

  render(): React.ReactNode {
    return (
      <Redirect to="/"/>
    );
  }
}
