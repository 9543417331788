import React from 'react';
import Page, { Media, MediaContextProvider } from '../../components/Page';
import { offeredOptions } from './PlanDescriptionData';
import { Button, Container, Header, Icon, Table } from 'semantic-ui-react';
import {
  DesktopPriceTableHeaderRow,
  DesktopPriceAmountRow,
  DesktopOptionDescriptionRow,
  MobilePriceCards
} from './PricesComponents';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { FirebaseAuthService } from '../../services/AuthService';
import PAYEarned from '../../components/PAYEarned';
import BillingInfo from '../../components/BillingInfo';


interface DisplayPricesPageProps {
  auth?: FirebaseAuthService;
}

export default class DisplayPricesPage extends React.Component{

  render(): JSX.Element {
    return (
      <MediaContextProvider>
        <DesktopDisplayPricesPage/>
        <MobileDisplayPricesPage/>
      </MediaContextProvider>
    );
  }
}

@inject('auth')
@observer
class DesktopDisplayPricesPage extends React.Component<DisplayPricesPageProps> {

  render(): React.ReactNode {

    const routeForGetStarted = (this.props.auth?.initialized && this.props.auth?.user?.id) ? '/subscription/select' : '/signup';

    return (
      <Media greaterThan='mobile'>
        <Page>
          <Header as="h2"><PAYEarned /> Pricing</Header>
          <Container textAlign='center'>
            <Button primary size='huge' as={ Link } to={ routeForGetStarted }
              style={ { margin: '2 em auto 0em auto' } }>
              Get Started
              <Icon name='arrow right'/>
            </Button>
          </Container>

          <Table celled structured style={ { marginTop: 30 } }>
            <Table.Header celled structured>
              <DesktopPriceTableHeaderRow/>
            </Table.Header>
            <Table.Body>
              <DesktopPriceAmountRow/>
              {
                offeredOptions.map(option => <DesktopOptionDescriptionRow
                  key={ option.id }
                  option={ option }
                />)
              }
            </Table.Body>
          </Table>
          <p><b>No Risk</b></p>
          <BillingInfo />
        </Page>
      </Media>
    );
  }
}

@inject('auth')
@observer
class MobileDisplayPricesPage extends React.Component<DisplayPricesPageProps> {

  render(): React.ReactNode {

    const routeForGetStarted = (this.props.auth?.initialized && this.props.auth?.user?.id) ? '/subscription/select' : '/signup';

    return (
      <Media at={ 'mobile' }>
        <Page>
          <Header as="h2"><PAYEarned /> Pricing</Header>
          <Container textAlign='center'>
            <Button primary size='huge' as={ Link } to={ routeForGetStarted }
              style={ { margin: '2 em auto 0em auto' } }>
              Get Started
              <Icon name='arrow right'/>
            </Button>
          </Container>
          <MobilePriceCards/>
          <p><span style={ { color: 'red' } }>*</span><b>No Risk</b></p>
          <BillingInfo />
        </Page>
      </Media>
    );
  }
}
