import { JSONSchema7 } from 'json-schema';
import { FormValidation } from '@rjsf/core';
import { ApplicationLineJSONSchema } from '../ApplicationLineJSONSchema';
import { ApplicationJSON } from './ApplicationJSON';
import { ApplicationLineChangeOrderJSONSchema } from '../ApplicationLineChangeOrderJSONSchema';
import { ApplicationLinesType } from '../../common/Constants';

export const ApplicationJSONSchema: JSONSchema7 = {
  type: 'object',
  title: 'Application',
  required: [ 'applicationDate', 'periodTo' ],
  properties: {
    applicationDate: {
      type: 'string',
      title: 'Application Date',
      format: 'date',
    },
    periodTo: {
      type: 'string',
      title: 'Period To',
      format: 'date',
    }
  },
  dependencies: {
    periodTo: {
      properties: {
        originalApplicationLines: {
          type: 'array',
          title: ApplicationLinesType.ProjectLineItems,
          items: ApplicationLineJSONSchema
        },
        changeOrderLines: {
          type: 'array',
          title: ApplicationLinesType.ChangeOrders,
          items: ApplicationLineChangeOrderJSONSchema
        },
      },
    }
  }
};

export const validateApplication = (formData: ApplicationJSON, errors: FormValidation): FormValidation => {
  // Do some cool custom validation in here
  // If there is an error you can use errors.addError()
  //test
  // Make sure none of the lines exceed the allowed amount
  formData.applicationLines?.forEach(line => {
    const scheduledValue = Number(line.scheduledValue);
    const billedToDate = Number(line.billedToDate);
    const storedToDate = Number(line.storedToDate);
    const incrementalBillAmount = isNaN(Number(line.incrementalBillAmount)) ? 0 : Number(line.incrementalBillAmount);
    const incrementalStoredAmount = isNaN(Number(line.incrementalStoredAmount)) ? 0 : Number(line.incrementalStoredAmount);
    if(
      (scheduledValue > 0 && incrementalBillAmount + incrementalStoredAmount + billedToDate + storedToDate > scheduledValue)
      || (scheduledValue < 0 && incrementalBillAmount + incrementalStoredAmount + billedToDate + storedToDate < scheduledValue)
    ) {
      if (storedToDate > 0) {
        errors.addError(`Total billed amount for line #${line.sequenceNumber} exceeds the allowed amount.  Either reduce the Work Completed This Period, or enter a negative amount for Materials Used, Previously Stored.`);
      } else {
        errors.addError(`Total billed amount for line #${line.sequenceNumber} exceeds the allowed amount.`);
      }
    }
    if(incrementalStoredAmount < 0 && -incrementalStoredAmount > incrementalBillAmount) {
      errors.addError(`A negative amount has been entered in line #${line.sequenceNumber} for Materials Used, Previously Stored.  The Work Completed This Period should be updated to be equal to or greater than this amount.`);
    }
    setTimeout(() => document.querySelectorAll('li.content').forEach(node => node.innerHTML = node.innerHTML.replace(/root: /, '')), 0);
  });
  return errors;
};
