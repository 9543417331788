import React from 'react';
import { Link, Redirect, RouteProps } from 'react-router-dom';
import { AuthServiceInterface } from '../services/AuthService';
import { Button, Header, Image, Loader, Message, Segment } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import CenteredGrid from '../components/CenteredGrid';

interface EmailVerificationPageProps extends RouteProps {
  mode: string;
  actionCode: string;
  auth?: AuthServiceInterface;
}

interface EmailVerificationPageState {
  confirmationMessage: string;
  errorMessage: string;
  redirect: boolean;
  verifyingEmail: boolean;
}

@inject('auth')
@observer
export default class EmailVerificationPage extends React.Component<EmailVerificationPageProps, EmailVerificationPageState> {

  state: EmailVerificationPageState = {
    confirmationMessage: '',
    errorMessage: '',
    redirect: false,
    verifyingEmail: false
  };

  async componentDidMount(): Promise<void> {
    await this.verifyEmail();
    setTimeout(() => this.setState({ redirect: true }), 5000);
  }

  async verifyEmail(): Promise<void> {
    // Try to apply the email verification code.
    if (this.props.auth) {
      try {
        this.setState({ verifyingEmail: true });
        await this.props.auth.applyActionCode(this.props.actionCode);
        // Email address has been verified.
        this.setState({ confirmationMessage: 'Your email has been verified.', verifyingEmail: false });
      } catch (e) {
        this.setState({ errorMessage: e.message, verifyingEmail: false });
      }
    }
  }

  displayMessage(): React.ReactNode {
    if (this.state.confirmationMessage) {
      return (
        <Message large success>
          {this.state.confirmationMessage}
        </Message>
      );
    } else if (this.state.errorMessage) {
      return (
        <Message large negative>
          {this.state.errorMessage}
        </Message>
      );
    }
  }

  render(): React.ReactNode {
    // Redirect user after verification to Home page
    if (this.state.redirect) {
      return <Redirect to='/'/>;
    }

    // If Auth service or Messages are not loaded, then show Loader
    if ((!this.props.auth?.initialized || !this.state.confirmationMessage) && !this.state.errorMessage) {
      return <Loader active={true}>Loading...</Loader>;
    }

    return (
      <CenteredGrid>
        <Image centered style={{ height: '100px' }} src='/img/PAYearned-logo.png'/>
        <Header as='h2' color='blue' textAlign='center'>{process.env.REACT_APP_APP_NAME} Email Verification</Header>
        <Segment stacked>
          {this.displayMessage()}
          <Button primary as={Link} to='/' loading={this.state.verifyingEmail}>
            Go to {process.env.REACT_APP_APP_NAME}
          </Button>
        </Segment>
      </CenteredGrid>
    );
  }
}
