import React, { Component, ErrorInfo, ReactNode } from 'react';
import LogService from '../services/LogService';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    this.setState({ hasError: true });
    LogService.error(error, info);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong</h1>
          <h1>Please call (954) 399-2774 or email <a href="mailto:tech.support@payearned.com">tech.support@payearned.com</a> for support</h1>
        </div>
      );
    }
    return this.props.children;
  }
}
