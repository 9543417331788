import React from 'react';
import JSONSchemaForm from '@rjsf/semantic-ui';
import { FormProps, IChangeEvent, ErrorSchema, AjvError, ISubmitEvent, UiSchema } from '@rjsf/core';
import { DiffPatcher } from 'jsondiffpatch';
import dot from 'dot-object';
import { Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { JSONSchema7, JSONSchema7Definition } from 'json-schema';
import './CalculationSchemaForm.css';
import { FieldTemplate } from '../schemas/templates/FieldTemplate';
import { findDOMNode } from 'react-dom';

export interface UpdatedField {
  path: string;
  value: boolean | number | string | null | undefined;
}

interface CalculationSchemaFormProps<T> extends FormProps<T> {
  onCalculate?: (formData: T, updatedField?: UpdatedField | null) => T;
  disableButtons?: boolean;
  saveButtonLabel?: string | React.ReactElement;
  projectUrl?: string;
  onCancel?: () => void;
  isButtonLoading?: boolean;
}
interface CalculationSchemaFormState<T> {
  originalFormData: T | undefined;
  formData: T;
  isNew: boolean;
  touched: Array<string>;
  hasErrors: boolean;
  currentField?: string | null;
  errorSchema?: ErrorSchema;
}

export default class CalculationSchemaForm<T> extends React.Component<CalculationSchemaFormProps<T>, CalculationSchemaFormState<T>> {
  state: CalculationSchemaFormState<T>;
  calculating = false;
  initialized = false;
  form?: JSONSchemaForm<T>;

  constructor(props: CalculationSchemaFormProps<T>) {
    super(props);
    const formData = this.props.formData ? this.removeNulls(this.props.formData) as T : {} as T;
    this.state = {
      originalFormData: formData,
      formData,
      isNew: Object.keys(formData).length === 0,
      touched: [],
      hasErrors: false,
    };
  }

  componentDidMount(): void {
    // Add an event listener to disable submit on enter keypress
    // See https://github.com/rjsf-team/react-jsonschema-form/issues/1597#issuecomment-596331277 for info
    // eslint-disable-next-line react/no-find-dom-node
    const root = findDOMNode(this);
    root && root.addEventListener('keydown', this.onKeyDown);
    this.initialized = true;
  }

  componentWillUnmount(): void {
    // Remove the keypress listener
    // See https://github.com/rjsf-team/react-jsonschema-form/issues/1597#issuecomment-596331277 for info
    // eslint-disable-next-line react/no-find-dom-node
    const root = findDOMNode(this);
    root && root.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (event: any): void => {
    // Prevent Enter press from activating submit action
    // See https://github.com/rjsf-team/react-jsonschema-form/issues/1597#issuecomment-596331277 for info
    if (event.keyCode === 13) {
      const target: any = event.target;
      event.preventDefault();
      target.click();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeNulls(originalObject: any): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = JSON.parse(JSON.stringify(originalObject));
    // Obtained from https://stackoverflow.com/a/38364486
    Object.keys(obj).forEach(k =>
      (obj[k] && typeof obj[k] === 'object') && this.removeNulls(obj[k]) ||
      (obj[k] === null && obj[k] !== undefined) && delete obj[k]
    );
    return obj;
  }

  onChange(e: IChangeEvent<T>, es?: ErrorSchema): void {
    // Exit if we're calculating
    if (this.calculating) {
      return;
    }

    // Set the calculating flag to true to avoid an infinite loop
    this.calculating = true;

    // Pass through the new form data to re-render with the updated value
    this.setState({ formData: e.formData });

    // Get the updated field
    const updatedField = this.getUpdatedField(this.state.formData, e.formData);

    // If we have an updated field and we're initialized, update the current field and touched
    if (updatedField && this.initialized) {
      // Add the updated field to the touched array
      const currentField = updatedField.path;
      let touched = this.state.touched;
      touched.push(currentField);
      touched = touched.filter((v, i, a) => a.indexOf(v) === i);

      // // If the field value is the same as the original value, remove it from touched
      // const originalValue = dot.pick(currentField, this.state.originalFormData);
      // const currentValue = dot.pick(currentField, e.formData);
      // if (originalValue === currentValue) {
      //   touched.splice(touched.indexOf(currentField), 1);
      // }
      this.setState({ currentField, touched });
    }

    // Use setTimeout to call onCalculate, to let all the events fire to get everything synched up
    setTimeout(() => {
      this.onCalculate(e.formData, updatedField);

      // Call the onChange if it was passed in and we're initialized
      if (this.props.onChange && this.initialized) {
        this.props.onChange(e, es);
      }

      // If we're not initialized yet, update the original form data
      if (!this.initialized) {
        this.setState({ originalFormData: this.state.formData });
        console.log('Initial state:', this.state);
      } else {
        console.log('Updated state:', this.state);
      }

      this.initialized = true;
    });
  }

  getUpdatedField(oldFormData: T, newFormData: T): UpdatedField | null {
    // Use jsondiffpatch to get the updated field
    const delta = new DiffPatcher().diff(oldFormData, newFormData);
    if (delta) {
      // Use dot-object to get the dot-notation path for the updated value
      const deltaKeys = Object.keys(dot.dot(delta));

      // If the delta is the removal of an item, shift off the first deltaKey
      if (deltaKeys[0].match(/._t$/)) {
        deltaKeys.shift();
      }

      // Build the path by transforming the deltaKey
      const path = deltaKeys[0]
        // Fix double-brackets
        .replace(/\[(\d+)\]\[0\]$/, '.$1')
        // Remove the array with the change details
        .replace(/\[0\]$/, '')
        // Replace the removal of an array item to get rid of the underscore
        .replace(/_(\d+)$/, '$1')
        // Remove brackets
        .replace(/\[|\]/g, '');

      // Use dot-object to get the updated value from the form
      const value = dot.pick(path, newFormData);

      // Return the path & value
      return { path, value };
    }
    return null;
  }

  onCalculate(formData: T, updatedField: UpdatedField | null): void {
    // Create a copy of formData
    let updatedFormData = JSON.parse(JSON.stringify(formData)) as T;

    // Perform the calculation if it was passed in
    if (this.props.onCalculate) {
      // Get the updated form data
      updatedFormData = this.props.onCalculate(updatedFormData, updatedField);
    }

    // Update the form
    this.setState({ formData: updatedFormData });

    this.calculating = false;
  }

  transformErrors(errors: Array<AjvError>): Array<AjvError> {
    let touchedErrors: Array<AjvError> = [];

    // Set this.hasErrors we can't use state, because setting state in this method results
    // in an infinite loop, so that's why we set it in the component
    // Let's assume we have all false positives until we encounter a true error
    let hasErrors = false;
    errors.forEach(error => {
      const path = error.property
        // Remove leading dot
        .replace(/^\./, '')
        // Remove square brackets
        .replace(/\[(\d+)\]/g, '.$1');

      const errorFieldSchema = this.getSchema(path) as JSONSchema7;

      // Get the current value
      const currentValue = dot.pick(path, this.state.formData);

      const required = Boolean(
        errorFieldSchema.required
        || (this.props.schema.required && this.props.schema.required.find(field => field === path))
      );

      // If we don't have the following condition, then we do have errors
      // We're making sure we don't have a non-required field that is set to null or undefined
      if (errorFieldSchema && required && (currentValue === null || typeof currentValue === 'undefined')) {
        hasErrors = true;
      } else {
        return;
      }

      // Add the error if it's been touched and it is not the current field
      if (this.state.touched.find(touched => touched === path) && path !== this.state.currentField) {
        const uiSchema = this.getSchema(path, this.props.uiSchema) as UiSchema | null;

        // If the widget is a currency, multiply the number by 100
        if (uiSchema && uiSchema.name === 'PercentWidget') {
          const errorNumberMatch = error.message.match(/\d+$/);
          if (errorNumberMatch && errorNumberMatch.length > 0) {
            const newNumber = String(Number(errorNumberMatch[0]) * 100) + '%';
            error.message = error.message.replace(/\d+$/, newNumber);
          }
        }
        error.stack = `${errorFieldSchema.title} ${error.message}`;
        touchedErrors.push(error);
      }
    });

    // Call transformErrors if it was passed in
    if (this.props.transformErrors) {
      touchedErrors = this.props.transformErrors(touchedErrors);
    }

    // Update the error state if it has changed
    if (hasErrors !== this.state.hasErrors) {
      setTimeout(() => this.setState({ hasErrors }));
      console.log('Error state updated.');
    }
    console.log('ERRORS:', errors);
    return touchedErrors;
  }

  getSchema(
    path: string,
    inputSearchSchema: { [key: string]: JSONSchema7Definition } | undefined = this.props.schema.properties
  ): JSONSchema7 | UiSchema | null {
    // Remove indices from the path
    path = path.replace(/\.\d+/g, '');

    // Get the schema paths
    let searchSchema: any = inputSearchSchema;
    if (this.props.schema.dependencies) {
      for (const prop in this.props.schema.dependencies) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        searchSchema = Object.assign({}, searchSchema, this.props.schema.dependencies[prop].properties);
      }
    }
    const schemas = Object.keys(dot.dot(searchSchema));

    // Find the matching schema
    let schemaPath = schemas.find((schema: string) => {
      schema = schema.replace(/\.items|\.properties|\.type$|\.ui:.*/g, '');
      return schema === path;
    });

    if (!schemaPath) {
      return null;
    }

    schemaPath = schemaPath.replace(/.type$/, '');
    const schema = dot.pick(schemaPath, searchSchema);
    if (!schema.title) {
      schema.title = schemaPath.replace(/.*\.([^.]+)$/, '$1');
    }
    return schema;
  }

  onBlur(field: string, value: boolean | number | string | null): void {
    // This workaround is needed to handle dropdown clear
    const clearIcon = document.querySelector('#gc_select > i');
    if (clearIcon) {
      return;
    }
    this.setState({ currentField: null });
    // Call onBlur if defined
    if (this.props.onBlur) {
      this.props.onBlur(field, value);
    }
  }

  onFocus(field: string, value: boolean | number | string | null): void {
    // Get the field path
    const currentField = field.replace(/^root_/, '').replace(/_/g, '.');

    // Update touched to add the field
    let touched = this.state.touched;
    touched.push(currentField);
    touched = touched.filter((v, i, a) => a.indexOf(v) === i);

    // Update state
    this.setState({ currentField, touched });
    // Call onFocus if defined
    if (this.props.onFocus) {
      this.props.onFocus(field, value);
    }
  }
  onCancelClick(): void {
    if(typeof this.props.onCancel !== 'undefined'){
      this.props.onCancel();
    }else {
      window.history.back();
    }
  }

  onSubmit(e: ISubmitEvent<T>): void {
    // Do not submit if we have errors
    if (this.state.hasErrors) {
      return;
    }

    // Call the submit method
    if (this.props.onSubmit) {
      this.props.onSubmit(e);
    }
  }

  render(): React.ReactNode {
    // Display the form
    return (
      <React.Fragment>
        <JSONSchemaForm
          {...this.props}
          FieldTemplate={FieldTemplate}
          liveValidate
          schema={this.props.schema}
          formData={this.state.formData}
          onChange={(e: IChangeEvent<T>, es?: ErrorSchema): void => this.onChange(e, es)}
          onSubmit={(e: ISubmitEvent<T>): void => this.onSubmit(e)}
          onBlur={(field: string, value: boolean | number | string | null): void => this.onBlur(field, value)}
          onFocus={(field: string, value: boolean | number | string | null): void => this.onFocus(field, value)}
          transformErrors={(errors: Array<AjvError>): Array<AjvError> => this.transformErrors(errors)}
          ref={(form: JSONSchemaForm<T>): JSONSchemaForm<T> => this.form = form}
        >
          {this.props.children}
          {
            !this.props.disableButtons
              ? (
                <React.Fragment>
                  <Button
                    as={Link}
                    onClick={(): void => this.onCancelClick()}
                  >
                    <Icon name='cancel'/>
                    Cancel
                  </Button>
                  <Button
                    loading={this.props.isButtonLoading}
                    primary
                    disabled={this.state.touched.length === 0 || this.state.hasErrors}
                  >
                    {
                      this.props.saveButtonLabel
                        // Show the save button label if one was passed in
                        ? this.props.saveButtonLabel
                        // Otherwise, show create/save item type
                        : this.state.isNew
                          ? <React.Fragment><Icon name='save'/>Create {this.props.schema.$comment}</React.Fragment>
                          : <React.Fragment><Icon name='save'/>Save {this.props.schema.$comment}</React.Fragment>
                    }
                  </Button>
                </React.Fragment>
              ) : (
                ''
              )
          }
        </JSONSchemaForm>
        <div style={{ height: '75px' }}/>
      </React.Fragment>
    );
  }
}
