import * as React from 'react';
import {
  StepperAction,
  StepperContent,
  StepperContext
} from 'react-material-stepper';
import { STEP3 } from './step3';

export const STEP1 = 'step-one';

interface ChangeOrderType {
  isForExistedProjectLine: boolean;
}

export interface GeneralStepperProps  {
  onResolveCancel: () => void;
}

export const cancelButtonStyle = {
  background: ' #e0e1e2 none',
  color: 'rgba(0,0,0,.6)',
  boxShadow: '0 0 0 1px transparent inset, 0 0 0 0 rgba(34,36,38,.15) inset',
  transition: 'opacity .1s ease,background-color .1s ease,color .1s ease,box-shadow .1s ease,background .1s ease'
};

const Step1: React.FunctionComponent<GeneralStepperProps> = ({ onResolveCancel }: GeneralStepperProps) => {
  const { resolve, goAt } = React.useContext(StepperContext);

  const createNewChangeOrder = (changeOrderType: ChangeOrderType): void => {
    resolve(changeOrderType);
    goAt(STEP3);
  };

  function handleCancelClick(): void {
    onResolveCancel();
  }

  return (
    <StepperContent
      actions={
        <React.Fragment>
          <StepperAction
            style={ cancelButtonStyle }
            onClick={ handleCancelClick }
            align='left'
            type='button'>
            Cancel
          </StepperAction>
          <StepperAction
            onClick={ (): void => createNewChangeOrder({ isForExistedProjectLine: false }) }
            align='right'
            type='submit'
            value={ 'edit' }>
            No
          </StepperAction>
          <StepperAction
            onClick={ (): void => resolve({ isForExistedProjectLine: true }) }
            align='right'
            type='submit'
            value={ 'new' }>
            Yes
          </StepperAction>
        </React.Fragment>
      }
    >
      Is this change order for an existing project line?
    </StepperContent>
  );
};

export default Step1;
