import React from 'react';
import { Button, Container, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PAYEarned from './PAYEarned';
import BillingInfo from './BillingInfo';

interface LearnMoreState {
  show: boolean;
}

interface LearnMoreProps {
  history?: any;
  title?: string;
}
export default class LearnMore extends React.Component<LearnMoreProps, LearnMoreState> {
  state = { show: false };

  onSubscribe = (): void => {
    this.props.history.push('/subscription/select');
  }

  render(): React.ReactNode {
    return (
      <React.Fragment>
        <Button color="green" onClick={this.onSubscribe}>
          {this.props.title || 'Subscribe'}
        </Button>
        {/* <Button color="green" onClick={(): void => this.setState({ show: true })}>
          Subscribe
        </Button> */}
        {/* <Modal open={this.state.show}>
          <Modal.Header><PAYEarned/> Subscription Information</Modal.Header>
          <Modal.Content style={{ fontSize: '1.25em' }}>
            <p>
              <b><span style={{ color: 'red' }}>*</span>First 30 Days $0</b>
            </p>
            <p>
              With your paid subscription, you receive access to:
            </p>
            <ul>
              <li>Unlimited Applications & Certificates for Payment</li>
              <li>Unlimited Continuation Sheets</li>
              <li>Unlimited Change Order Sheets</li>
            </ul>
            <p>
              <b>No Risk</b>
            </p>
            <BillingInfo />
            <p>
              <Container textAlign='center'>
                <Button primary size='huge' as={Link} to="/subscription/select">
                  Subscribe
                </Button>
              </Container>
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={ (): void => this.setState({ show: false })}>Close</Button>
          </Modal.Actions>
        </Modal> */}
      </React.Fragment>
    );
  }
}
