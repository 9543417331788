import React from 'react';
import { Button, Menu } from 'semantic-ui-react';
import { FirebaseAuthService } from '../../services/AuthService';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import ProfileMenu from '../ProfileMenu';
import { Subscription } from '../../services/PaymentService';

interface LoginLogoutButtonProps {
  auth?: FirebaseAuthService;
  subscriptions: Array<Subscription> | undefined;
}

@inject('auth')
@observer
export default class LoginLogoutButton extends React.Component<LoginLogoutButtonProps> {

  render(): React.ReactNode {

    if (this.props.auth?.initialized) {
      if (this.props.auth?.user?.id) {
        return <ProfileMenu subscriptions={ this.props.subscriptions }/>;
      }

      return (
        <Menu.Menu position='right'>
          <Menu.Item className='borderless'>
            <Button as={Link} to="/login">
              Log in
            </Button>
            <Button as={Link} to="/signup" primary style={{ marginLeft: '0.5em' }}>
              Sign Up
            </Button>
          </Menu.Item>
          <Menu.Item />
        </Menu.Menu>
      );
    }
    return '';
  }
}
