import * as React from 'react';
import { Loader } from 'semantic-ui-react';

interface CustomLoaderProps {
  loadingMessage?: string;
}

export class CustomLoader extends React.Component<CustomLoaderProps> {
  render(): React.ReactNode {
    const message = this.props.loadingMessage || 'Loading...';
    return (
      <Loader active={ true }>{ message}</Loader>
    );
  }
}
