import * as React from 'react';
import { useState } from 'react';
import { StepperAction, StepperContent, StepperContext } from 'react-material-stepper';
import { cancelButtonStyle, GeneralStepperProps, STEP1 } from './step1';
import { ChangeOrderType, ProjectLineJSON } from '../../../../schemas/ProjectLineSchema';
import { DataJSON, DataService } from '../../../../services/DataService';
import { STEP2 } from './step2';
import { Dimmer, Form, Loader, } from 'semantic-ui-react';
import CalculationSchemaForm from '../../../CalculationSchemaForm';
import { IChangeEvent, UiSchema } from '@rjsf/core';
import CurrencyWidget from '../../../../schemas/widgets/currency/CurrencyWidget';
import PercentWidget from '../../../../schemas/widgets/PercentWidget';
import { ProjectJSON } from '../../../../schemas/projects/ProjectJSON';

export const STEP3 = 'step-three';

interface Step3Props extends GeneralStepperProps{
  project: ProjectJSON;
}

// This step is used for Create Change Order modal.
const Step3: React.FunctionComponent<Step3Props> = (step3Props) => {
  const { resolve, reject, getData, goAt } = React.useContext(StepperContext);
  const dataService = new DataService();
  const back = (): void => goAt(STEP1);
  const selectedProjectLine = getData(STEP2);
  const firstApplicationSequenceNumber = step3Props.project.applications && step3Props.project.applications?.length > 0
    ? Number(step3Props.project.applications[0].sequenceNumber)
    : 1;
  const currentApplicationSequenceNumber = step3Props.project.applications && step3Props.project.applications?.length > 0
    ? Number(step3Props.project.applications[step3Props.project.applications.length-1].sequenceNumber)
    : 1;
  const defaultRetainage: number = step3Props.project?.defaultRetainage
    ? step3Props.project.defaultRetainage
    : 0;
  const [ applicationSequenceNumber, setApplicationSequenceNumber ] = useState(currentApplicationSequenceNumber);

  const emptyProjectLine: ProjectLineJSON = {
    id: undefined,
    sequenceNumber: step3Props.project.projectLines?.length ? step3Props.project.projectLines.length + 1 : 1,
    projectId: step3Props.project.id,
    lineType: 'changeOrder',
    description: selectedProjectLine?.description,
    scheduledValue: undefined,
    retainage: defaultRetainage,
    billedToDate: undefined,
    parentId: selectedProjectLine ? selectedProjectLine.id : undefined,
    firstApplicationSequenceNumber: applicationSequenceNumber,
  };

  const [ projectLine, setProjectLine ] = useState<ProjectLineJSON>(emptyProjectLine);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isError, setIsError ] = useState(false);
  const [ isFormValid, setIsFormValid ] = useState(false);


  function determineChangeOrderType(scheduledValue: number): ChangeOrderType {
    let changeOrderType: ChangeOrderType = 'A';
    if (!selectedProjectLine) {
      changeOrderType = 'A';
    } else if (scheduledValue > 0) {
      changeOrderType = 'R+';
    } else if (scheduledValue < 0) {
      changeOrderType = 'R-';
    }
    return changeOrderType;
  }

  function fixApplicationSequenceNumber(newValue?: number): number|undefined {
    if (newValue === undefined) return undefined;
    if (newValue < firstApplicationSequenceNumber) return firstApplicationSequenceNumber;
    if (newValue > currentApplicationSequenceNumber) return currentApplicationSequenceNumber;
    return newValue;
  }

  function onChange(e: IChangeEvent<ProjectLineJSON>): void {
    let valid = true;
    projectLine.description = e.formData.description;
    projectLine.scheduledValue = e.formData.scheduledValue;
    projectLine.retainage = e.formData.retainage;
    projectLine.changeOrderType = determineChangeOrderType(Number(projectLine.scheduledValue));
    projectLine.firstApplicationSequenceNumber = fixApplicationSequenceNumber(e.formData.firstApplicationSequenceNumber);
    if (projectLine.firstApplicationSequenceNumber === undefined) valid = false;
    if (
      !projectLine?.description
      || projectLine?.description?.length === 0
      || projectLine.retainage === undefined
      || !projectLine.scheduledValue
    ) {
      valid = false;
    }
    setIsFormValid(valid);
    setProjectLine(projectLine);
  }

  const onCalculate = (formData: ProjectLineJSON): ProjectLineJSON => {
    formData.firstApplicationSequenceNumber = fixApplicationSequenceNumber(formData.firstApplicationSequenceNumber);
    return formData;
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    if (isFormValid) {
      try {
        setIsError(true);
        setIsLoading(true);
        await dataService.create('project-lines', projectLine as unknown as DataJSON);
      } catch (e) {
        console.log(e);
        setIsError(true);
      }
      resolve(true);
    } else {
      reject('Please fill out all fields');
    }
  };

  const uiSchema: UiSchema = {
    scheduledValue: {
      'ui:widget': CurrencyWidget
    },
    retainage: {
      'ui:widget': PercentWidget,
    }
  };

  if (isError) {
    //TODO Display result.error.message in your UI.
  }

  if (isLoading) {
    return <Dimmer active inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>;
  }

  function handleCancelClick(): void {
    step3Props.onResolveCancel();
  }

  return (
    <Form>
      <StepperContent
        onSubmit={onSubmit}
        actions={
          <React.Fragment>
            <StepperAction
              style={ cancelButtonStyle }
              onClick={ handleCancelClick }
              align='left'
              type='button'>
              Cancel
            </StepperAction>
            <StepperAction align="right" type="button" onClick={back}>
              Back
            </StepperAction>
            <StepperAction align='right' type="submit" disabled={!isFormValid}>
              Save
            </StepperAction>
          </React.Fragment>
        }
      >
        <CalculationSchemaForm
          schema={{
            type: 'object',
            required: [ 'firstApplicationSequenceNumber', 'description', 'scheduledValue', 'retainage' ],
            properties: {
              firstApplicationSequenceNumber: {
                type: 'number',
                title: 'App #',
              },
              description: {
                type: 'string',
                title: 'Description',
                readOnly: !!projectLine.parentId,
              },
              scheduledValue: {
                type: 'number',
                title: 'Scheduled Value',
              },
              retainage: {
                type: 'number',
                title: 'Retainage',
                maximum: 1,
              },
            }
          }}
          uiSchema={uiSchema}
          formData={{
            description: projectLine?.description ? projectLine.description : '',
            scheduledValue: 0,
            retainage: step3Props.project?.defaultRetainage ? step3Props.project.defaultRetainage : 0,
            firstApplicationSequenceNumber: Number(emptyProjectLine.firstApplicationSequenceNumber),
          }}
          disableButtons={true}
          onChange={onChange}
          onCalculate={onCalculate}
        > </CalculationSchemaForm>
      </StepperContent>
    </Form>
  );
};

export default Step3;
