import * as React from 'react';
import { useState } from 'react';
import { StepperAction, StepperContent, StepperContext } from 'react-material-stepper';
import { ChangeOrderType, ProjectLineJSON } from '../../../../schemas/ProjectLineSchema';
import { DataJSON, DataService } from '../../../../services/DataService';
import { Dimmer, Form, Loader, } from 'semantic-ui-react';
import CalculationSchemaForm from '../../../CalculationSchemaForm';
import { IChangeEvent, UiSchema } from '@rjsf/core';
import CurrencyWidget from '../../../../schemas/widgets/currency/CurrencyWidget';
import PercentWidget from '../../../../schemas/widgets/PercentWidget';
import { ProjectJSON } from '../../../../schemas/projects/ProjectJSON';
import { cancelButtonStyle } from './step1';

export const STEP4 = 'step-four';

type Step4Props = {
  project: ProjectJSON;
  changeOrder: ProjectLineJSON | undefined;
}

// This Step is used for Edit Change Order modal
const Step4: React.FunctionComponent<Step4Props> = (step4Props) => {
  const { resolve, reject } = React.useContext(StepperContext);
  const dataService = new DataService();
  const firstApplicationSequenceNumber = step4Props.project.applications && step4Props.project.applications?.length > 0
    ? Number(step4Props.project.applications[0].sequenceNumber)
    : 1;
  const currentApplicationSequenceNumber = step4Props.project.applications && step4Props.project.applications?.length > 0
    ? Number(step4Props.project.applications[step4Props.project.applications.length-1].sequenceNumber)
    : 1;
  const changeOrderToEdit: ProjectLineJSON = {
    id: step4Props.changeOrder?.id,
    sequenceNumber: step4Props.changeOrder?.sequenceNumber
      ? step4Props.changeOrder?.sequenceNumber
      : step4Props.project.projectLines?.length ? step4Props.project.projectLines.length + 1 : 1,
    projectId: step4Props.project.id,
    lineType: 'changeOrder',
    description: step4Props.changeOrder?.description,
    scheduledValue: step4Props.changeOrder?.scheduledValue,
    retainage: step4Props.changeOrder?.retainage,
    billedToDate: step4Props.changeOrder?.billedToDate,
    firstApplicationSequenceNumber:
      step4Props.changeOrder?.firstApplicationSequenceNumber
        ? step4Props.changeOrder.firstApplicationSequenceNumber
        : currentApplicationSequenceNumber,
  };
  const [ projectLine, setProjectLine ] = useState<ProjectLineJSON>(changeOrderToEdit);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isError, setIsError ] = useState(false);
  const [ isFormValid, setIsFormValid ] = useState(true);

  function determineChangeOrderType(scheduledValue: number): ChangeOrderType {
    let changeOrderType: ChangeOrderType = 'R+';
    if (scheduledValue > 0) {
      changeOrderType = 'R+';
    } else if (scheduledValue < 0) {
      changeOrderType = 'R-';
    }
    return changeOrderType;
  }

  function fixApplicationSequenceNumber(newValue?: number): number|undefined {
    if (newValue === undefined) return undefined;
    if (newValue < firstApplicationSequenceNumber) return firstApplicationSequenceNumber;
    if (newValue > currentApplicationSequenceNumber) return currentApplicationSequenceNumber;
    return newValue;
  }

  function onChange(e: IChangeEvent<ProjectLineJSON>): void {
    let valid = true;
    projectLine.description = e.formData.description;
    projectLine.scheduledValue = e.formData.scheduledValue;
    projectLine.retainage = e.formData.retainage;
    projectLine.changeOrderType = determineChangeOrderType(Number(projectLine.scheduledValue));
    projectLine.firstApplicationSequenceNumber = fixApplicationSequenceNumber(e.formData.firstApplicationSequenceNumber);
    if (projectLine.firstApplicationSequenceNumber === undefined) valid = false;
    if (
      !projectLine?.description
      || projectLine?.description?.length === 0
      || projectLine.retainage === undefined
      || !projectLine.scheduledValue
    ) {
      valid = false;
    }
    setIsFormValid(valid);
    setProjectLine(projectLine);
  }

  const onCalculate = (formData: ProjectLineJSON): ProjectLineJSON => {
    formData.firstApplicationSequenceNumber = fixApplicationSequenceNumber(formData.firstApplicationSequenceNumber);
    return formData;
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    if (isFormValid && projectLine.id) {
      try {
        setIsError(false);
        setIsLoading(true);
        await dataService.save('project-lines', projectLine.id, projectLine as unknown as DataJSON);
      } catch (e) {
        console.log(e);
        setIsError(true);
      }
      resolve(true);
    } else {
      reject('Please fill out all fields');
    }
  };

  const uiSchema: UiSchema = {
    scheduledValue: {
      'ui:widget': CurrencyWidget
    },
    retainage: {
      'ui:widget': PercentWidget,
    }
  };

  if (isError) {
    //TODO Display result.error.message in your UI.
  }

  if (isLoading) {
    return <Dimmer active inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>;
  }

  return (
    <Form>
      <StepperContent
        onSubmit={onSubmit}
        actions={
          <React.Fragment>
            <StepperAction style={cancelButtonStyle} align="right" type="button" onClick={(): void => resolve(STEP4)}>
              Cancel
            </StepperAction>
            <StepperAction align='right' type="submit" disabled={!isFormValid || typeof projectLine.retainage === 'undefined' }>
              Save
            </StepperAction>
          </React.Fragment>
        }
      >
        <CalculationSchemaForm
          schema={{
            type: 'object',
            required: [ 'firstApplicationSequenceNumber', 'description', 'scheduledValue', 'retainage' ],
            properties: {
              firstApplicationSequenceNumber: {
                type: 'number',
                title: 'App #',
              },
              description: {
                type: 'string',
                title: 'Description',
                readOnly: !!projectLine.parentId || (changeOrderToEdit?.billedToDate !== undefined && changeOrderToEdit.billedToDate !== 0),
              },
              scheduledValue: {
                type: 'number',
                title: 'Scheduled Value',
                readOnly: changeOrderToEdit?.billedToDate !== undefined && changeOrderToEdit.billedToDate !== 0,
              },
              retainage: {
                type: 'number',
                title: 'Retainage',
                maximum: 1,
                readOnly: changeOrderToEdit?.billedToDate !== undefined && changeOrderToEdit.billedToDate !== 0,
              },
            }
          }}
          uiSchema={uiSchema}
          formData={{
            description: projectLine?.description ? projectLine.description : '',
            scheduledValue: projectLine?.scheduledValue ? projectLine.scheduledValue : 0,
            retainage: projectLine?.retainage ? projectLine.retainage : 0,
            firstApplicationSequenceNumber: Number(changeOrderToEdit.firstApplicationSequenceNumber),
          }}
          disableButtons={true}
          onChange={onChange}
          onCalculate={onCalculate}
        > </CalculationSchemaForm>
      </StepperContent>
    </Form>
  );
};

export default Step4;
