import React from 'react';
import Page from '../../components/Page';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { DataService } from '../../services/DataService';
import { Link, RouteChildrenProps } from 'react-router-dom';
import Page404 from '../Page404';
import { Button, Form, Loader } from 'semantic-ui-react';
import { GeneralContractorJSON } from '../../schemas/GeneralContractorJSONSchema';

interface ShowGeneralContractorsState {
  loading: boolean;
  error: boolean;
  generalContractor?: GeneralContractorJSON;
}

export default class ShowGeneralContractor extends React.Component<RouteChildrenProps<{id: string}>, ShowGeneralContractorsState> {
  state: ShowGeneralContractorsState = {
    loading: true,
    error: false,
  }
  dataService = new DataService();

  async componentDidMount(): Promise<void> {
    let loading = true;
    let error = true;
    let generalContractor: GeneralContractorJSON;

    // Proceed if we have an id
    if (this.props.match?.params.id) {
      generalContractor = await this.dataService.get('general-contractors', this.props.match.params.id) as unknown as GeneralContractorJSON;
      error = !generalContractor;
    }
    loading = false;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.setState({ loading, error, generalContractor });
  }

  render(): React.ReactNode {
    const callBackUrl = this.props.location.hash.substring(1);

    // Show the loader
    if (this.state.loading) { return <Loader active />; }

    // Show the error page
    if (this.state.error) { return <Page404 {...this.props} />; }

    return (
      <Page breadcrumb={[
        { key: 'general-contractors', href: '/general-contractors', content: 'General Contractors', link: true },
        { key: 'general-contractor', content: this.state.generalContractor?.name, active: true },
      ]}>
        <Form>
          <Form.Field>
            <label>Company Name</label>
            <span>{this.state.generalContractor?.name}</span>
          </Form.Field>
          <Form.Field>
            <label>License Number</label>
            <span>{this.state.generalContractor?.licenseNumber}</span>
          </Form.Field>
          <Form.Field>
            <label>Phone number</label>
            <span>{this.state.generalContractor?.phoneNumber}</span>
          </Form.Field>
          <Form.Field>
            <label>Address Line 1</label>
            <span>{this.state.generalContractor?.addressLine1}</span>
          </Form.Field>
          <Form.Field>
            <label>Address Line 2</label>
            <span>{this.state.generalContractor?.addressLine2}</span>
          </Form.Field>
          <Form.Field>
            <label>City</label>
            <span>{this.state.generalContractor?.city}</span>
          </Form.Field>
          <Form.Field>
            <label>State</label>
            <span>{this.state.generalContractor?.state}</span>
          </Form.Field>
          <Form.Field>
            <label>Zip</label>
            <span>{this.state.generalContractor?.zip}</span>
          </Form.Field>
          <Form.Field>
            <label>Contact Name</label>
            <span>{this.state.generalContractor?.contactName}</span>
          </Form.Field>
          <Form.Field>
            <label>Contact Email Address</label>
            <span>{this.state.generalContractor?.email}</span>
          </Form.Field>
          <Form.Group>
            <Button
              primary
              as={Link}
              to={{
                pathname: `/general-contractors/${this.state.generalContractor?.id}/edit`,
                hash: callBackUrl
              }}>
              Edit
            </Button>
          </Form.Group>
        </Form>
      </Page>
    );
  }
}
