import React, { ChangeEvent } from 'react';
import { WidgetProps } from '@rjsf/core';
import { Button, Form, Image as SemanticImage } from 'semantic-ui-react';

interface ImageUploadWidgetProps extends WidgetProps {
  uiSchema: {
    options: {
      maxRatio?: number;
      ratioWarningMessage?: string;
    };
  };
}

interface ImageUploadWidgetState {
  imageData?: string;
}

export default class ImageUploadWidget extends React.Component<ImageUploadWidgetProps, ImageUploadWidgetState> {
  state: ImageUploadWidgetState = {
    imageData: this.props.value
  };

  async onChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
    if (e.target.files) {
      const blobURL = URL.createObjectURL(e.target.files[0]);
      this.setState({ imageData: blobURL });
      // If there is no max ratio, we're done
      if (!this.props.uiSchema.options.maxRatio) {
        this.props.onChange(blobURL);
        return;
      }

      // Otherwise, we need to calculate the ratio to see if it exceeds the max allowed
      const image = new Image();
      return new Promise<void>(resolve => {
        image.onload = (): void => {
          if (image.width / image.height > this.props.uiSchema.options.maxRatio!) {
            alert(
              this.props.uiSchema.options.ratioWarningMessage
              ?? 'Warning: This image is too wide'
            );
          }
          this.props.onChange(blobURL);
          resolve();
        };
        image.src = blobURL;
      });
    }
  }

  render(): React.ReactNode {
    const image = (
      this.state.imageData
        ?
        <SemanticImage
          size="small"
          src={this.state.imageData}
        />
        : ''
    );
    // If it's a read-only field, output the value post-fixed with a % sign
    if (this.props.schema.readOnly) {
      return (
        <Form.Field>
          <label>{this.props.schema.title ? this.props.schema.title : this.props.id.replace(/^root_/, '')}</label>
          <div>{image}</div>
        </Form.Field>
      );
    }

    return (
      <Form.Field>
        <label>{this.props.schema.title ? this.props.schema.title : this.props.id.replace(/^root_/, '')}</label>
        <div>{image}</div>
        <br/>
        <span>
          <Button
            as="label"
            htmlFor="imageUpload"
            content="Select JPG/PNG"
            labelPosition="left"
            icon="image"
          />
          <input
            id="imageUpload"
            type="file"
            style={{ display: 'none' }}
            accept=".png, .jpg, .jpeg"
            onChange={(e): Promise<void> => this.onChange(e)}
          />
        </span>
      </Form.Field>
    );
  }
}
