import React from 'react';
import { Icon, Menu, TableBody, TableCell, TableRow } from 'semantic-ui-react';
import InvoiceRow from './InvoiceRow';
import { Receipt } from '../../../services/PaymentService';

interface ReceiptRowProps {
  receipts: Array<Receipt> | undefined;
}

export default class ReceiptRow extends React.Component<ReceiptRowProps>{
  render(): React.ReactNode {
    return (
      <TableBody>
        {
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.props?.receipts?.map(({ id, created, amount,receipt_url }) =>(
            <TableRow key={id}>
              <TableCell>
                <div style={{ textAlign: 'center' }}>
                  { InvoiceRow.secondsToISOString(created as number) }
                </div>
              </TableCell>
              <TableCell>
                <div style={{ textAlign: 'center' }}>
                  { (Number(amount) / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                </div>
              </TableCell>
              <TableCell>
                {/* eslint-disable-next-line @typescript-eslint/camelcase */ }
                <Menu.Item href={ receipt_url } target='_blank'
                  style={{ justifyContent: 'center',
                    alignItems: 'center', height: '100%', display: 'flex' }}>
                  <Icon name='file pdf outline' size='large'/>
                </Menu.Item>
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>

    );
  }
}
