import * as React from 'react';
import { Container, Menu, Icon, Sidebar, Segment } from 'semantic-ui-react';
import LoginLogoutButton from './LoginLogoutButton';
import ContextMenu, { ContextMenuList } from './ContextMenu';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { FirebaseAuthService } from '../../services/AuthService';
import { MediaContextProvider, Media } from '../Page';
import HomeButton from './HomeButton';
import LogoButton from './LogoButton';
import TagManager, { DataLayerArgs } from 'react-gtm-module';
import PaymentService, { Plan, Subscription } from '../../services/PaymentService';

interface AppMenuProps {
  contextMenu?: Array<ContextMenuList>;
  content?: React.ReactNode;
  auth?: FirebaseAuthService;
  payment?: PaymentService;
}

interface AppMenuState {
  isLoading: boolean;
  subscriptions: Array<Subscription> | undefined;
}

export default class AppMenu extends React.Component<AppMenuProps> {
  render(): JSX.Element {
    return (
      <MediaContextProvider>
        <DesktopAppMenu>{this.props.content}</DesktopAppMenu>
        <MobileAppMenu>{this.props.content}</MobileAppMenu>
      </MediaContextProvider>
    );
  }
}

@inject('auth')
@inject('payment')
@observer
class DesktopAppMenu extends React.Component<AppMenuProps, AppMenuState> {

  state: AppMenuState = {
    isLoading: false,
    subscriptions: undefined
  }

  async componentDidMount(): Promise<void> {
    this.setState({ isLoading: true });
    try {
      const subscriptions = await this.props.payment?.getSubscriptions();
      this.setState({ subscriptions, isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  }

  render(): JSX.Element {

    const { subscriptions, isLoading } = this.state;
    const { auth, contextMenu, children } = this.props;

    return (
      <Media greaterThan='mobile'>
        <Menu borderless fixed='top' size='large'>
          <Container>
            <LogoButton img='/img/PAYearned-logo-long.png' size='small'/>
            <HomeButton/>
            {
              (auth?.initialized && auth?.user?.id)
                ?
                <Menu.Item
                  as={Link}
                  to='/general-contractors'
                  active={document.location.pathname === '/general-contractors'}
                >
                  General Contractors
                </Menu.Item>
                : ''
            }
            {
              (!isLoading && !subscriptions?.length)
                ?
                <Menu.Item
                  as={Link}
                  to='/pricing'
                  active={document.location.pathname === '/pricing'}
                >
                  Pricing
                </Menu.Item>
                : ''
            }
            {
              (auth?.initialized && auth?.user?.id)
                ?
                <Menu.Item
                  as={Link}
                  to='/contact'
                  active={document.location.pathname === '/contact'}
                  className='contact-us-menu-btn'
                >
                  Contact Us
                </Menu.Item>
                : ''
            }
            <Menu.Item
              href="https://payearned.com/help"
              target="_blank"
              className='help-menu-btn'
            >
              <Icon name="question circle"/>
              How To Video Tutorials
            </Menu.Item>
            {contextMenu ?
              (
                <ContextMenu menus={contextMenu}/>
              ) : ''
            }
            <LoginLogoutButton subscriptions={subscriptions}/>
          </Container>
        </Menu>
        {children}
      </Media>
    );
  }
}

interface MobileAppMenuState {
  isLoading: boolean;
  plans: Array<Plan> | undefined;
  sidebarOpened: boolean;
  subscriptions: Array<Subscription> | undefined;
}

@inject('auth')
@inject('payment')
@observer
class MobileAppMenu extends React.Component<AppMenuProps, MobileAppMenuState> {

  state: MobileAppMenuState = {
    isLoading: false,
    plans: undefined,
    sidebarOpened: false,
    subscriptions: undefined
  }

  async componentDidMount(): Promise<void> {
    this.setState({ isLoading: true });
    try {
      const plans = await this.props.payment?.getPaymentPlans();
      const subscriptions = await this.props.payment?.getSubscriptions();
      this.setState({ plans, subscriptions, isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  }

  handleSidebarHide = (): void => this.setState({ sidebarOpened: false })
  handleToggle = (): void => this.setState({ sidebarOpened: true })

  async logout(): Promise<void> {
    await this.props.auth?.logout();
    this.logLogoutEventToGTM();
  }

  logLogoutEventToGTM(): void {
    const dataLayerArgs: DataLayerArgs = {
      dataLayer: {
        event: 'Logout'
      }
    };
    TagManager.dataLayer(dataLayerArgs);
  }

  render(): JSX.Element {
    const { sidebarOpened, subscriptions, isLoading, plans } = this.state;
    const { auth, contextMenu, children } = this.props;

    let menuItems = (
      <div>
        <HomeButton/>
        <Menu.Item as={Link} to='/login' active={document.location.pathname === '/login'}>Login</Menu.Item>
        <Menu.Item as={Link} to='/signup' active={document.location.pathname === '/signup'}>Signup</Menu.Item>
      </div>
    );
    if (auth?.initialized && auth?.user?.id) {
      menuItems = (
        <div>
          <HomeButton/>
          <Menu.Item as={Link} to='/profile' active={document.location.pathname === '/profile'}>Profile</Menu.Item>
          {
            (!isLoading && !subscriptions?.length)
              ?
              <Menu.Item as={Link} to='/pricing' active={document.location.pathname === '/pricing'}>Pricing</Menu.Item>
              : ''
          }
          <Menu.Item
            as={Link}
            to={typeof plans !== 'undefined' ? '/subscription/manage' : '/subscription/select'}
            active={document.location.pathname === '/subscription'}>
            {typeof plans !== 'undefined' ? 'Manage Subscription' : 'Subscribe'}
          </Menu.Item>
          <Menu.Item as={Link} to='/receipts' active={document.location.pathname === '/receipts'}>Receipts</Menu.Item>
          <Menu.Item as={Link} to='/contact' active={document.location.pathname === '/contact'}>Contact Us</Menu.Item>
          <Menu.Item as={Link} to='/' onClick={(): Promise<void> | undefined => this.logout()}>Logout</Menu.Item>
        </div>
      );
    }
    // zIndex: 99, position: 'relative' style={{ height: 'calc(100vh - 290px)' }}
    return (
      <Media at={'mobile'}>
        <Sidebar.Pushable style={{ zIndex: 99 }}>
          <Sidebar
            as={Menu}
            animation='overlay'
            // inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
            direction='right'
          >
            {menuItems}
          </Sidebar>
          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              vertical
            >
              <Menu fixed='top' borderless size='large'>
                <Container>
                  <LogoButton img='/img/PAYearned-logo-square.png' size='mini'/>
                  {contextMenu ?
                    (
                      <ContextMenu menus={contextMenu}/>
                    ) : ''
                  }
                  <Menu.Item onClick={this.handleToggle} position='right'>
                    <Icon name='sidebar'/>
                  </Menu.Item>
                </Container>
              </Menu>
            </Segment>
            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    );
  }
}
