import React from 'react';
import { Header, Image, Message } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { AuthServiceInterface } from '../services/AuthService';
import CenteredGrid from '../components/CenteredGrid';
import Page from '../components/Page';
import { Redirect } from 'react-router-dom';
import EmailPasswordLogin from '../components/auth/EmailPasswordLogin';

export interface AuthProps {
  auth?: AuthServiceInterface;
  location?: { state: { email: string } };
}

@inject('auth')
@observer
export default class LoginPage extends React.Component<AuthProps> {

  render(): React.ReactNode {
    if (this.props.auth?.user?.id) {
      return <Redirect to="/"/>;
    }

    return (
      <Page>
        <CenteredGrid>
          <Image centered style={{ height: '100px' }} src='/img/PAYearned-logo.png'/>
          <Header as='h2' color='blue' textAlign='center'>Login to your account</Header>
          <EmailPasswordLogin {...this.props} />
          <Message>
            <a href={'/forgot-password'}>Forgot password</a>
          </Message>
        </CenteredGrid>
      </Page>
    );
  }

}
