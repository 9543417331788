import React from 'react';

import { Form, FormButton, FormCheckbox, Message } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { CardElement } from '@stripe/react-stripe-js';
import { inject, observer } from 'mobx-react';
import Joi from '@hapi/joi';
import { StripeCardElementChangeEvent, StripeElements, StripeCardElement, Stripe } from '@stripe/stripe-js';
import { FirebaseAuthService } from '../../../services/AuthService';
import { AlertService } from '../../../services/AlertService';
import { CheckboxProps } from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import CookieService from '../../../services/CookieService';
import PaymentService, { PaymentMethod } from '../../../services/PaymentService';

const RequiredErrMsg = 'Required field';

export interface SubscriptionCommonProps {
  stripe: Stripe;
  elements: StripeElements;
  auth?: FirebaseAuthService;
  alert?: AlertService;
  location?: any;
  cookie?: CookieService;
  payment?: PaymentService;
  history?: any;
}


interface AddPaymentMethodState {
  name: string;
  cardholderNameError: string;
  cardError: string;
  paymentError?: string;
  validForm: boolean;
  isLoading: boolean;
  isChecked: boolean;
}


export interface AddPaymentMethodProps extends SubscriptionCommonProps {
  onChangePaymentMethods: (defaultPayMethodId: string | undefined) => Promise<void>;
  hidden?: boolean;
  paymentMethods: Array<PaymentMethod> | undefined;
}


interface BaseOptions {
  [prop: string]: string | BaseOptions;
}

interface StyleOptions {
  base: BaseOptions;
  invalid: BaseOptions;
}

interface CardOptions {
  style: StyleOptions;
}

const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  & .StripeElement {
    width: 100%;
    padding: 10px;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
  } & .StripeElement--invalid {
    border: 1px solid #e0b4b4;
    border-radius: .28571429rem;
    background-color: #fff6f6;
    box-shadow: none;
  }
  `;

@inject('auth')
@inject('alert')
@observer
export default class AddPaymentMethod extends React.Component<AddPaymentMethodProps, AddPaymentMethodState> {

  private cardElementOptions?: CardOptions;

  state: AddPaymentMethodState = {
    name: '',
    cardholderNameError: '',
    cardError: 'uninitialized',
    validForm: false,
    isLoading: false,
    isChecked: true,
  }

  isFormValid(): boolean {
    const { name, cardholderNameError } = this.state;
    return !(cardholderNameError.length > 0) &&
      (name.length > 0);
  }

  validateCardholderName(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const result = Joi.string().required().messages({ 'string.base': RequiredErrMsg })
      .validate(e.target.value, { abortEarly: true });
    return this.validate(result);
  }

  async validate(validation: Joi.ValidationResult): Promise<void> {
    if (validation.error) {
      const tempResult = validation.error.details.map(errorDetail => {
        return errorDetail.message.replace(/[^a-zA-Z0-9 ]/g, '')
          .replace('value', 'Field');
      }).join('\n');
      await this.setState({ cardholderNameError: tempResult });
    } else {
      await this.setState({ cardholderNameError: '' });
    }
    const formStatus = this.isFormValid();
    await this.setState({ validForm: formStatus });
  }

  async onToggleChange(event: React.FormEvent<HTMLInputElement>, data: CheckboxProps): Promise<void> {
    return this.setState({ isChecked: data.checked || false });
  }

  async handleFormSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    const billingDetails = {
      name: this.state.name,
      email: this.props.auth?.user?.email as string,
      address: {
        city: undefined,
        line1: undefined,
        state: undefined
      }
    };
    this.setState({ isLoading: true });
    const clientSecret = this.props.payment?.createSetupIntent();

    if (clientSecret) {
      const card = this.props.elements.getElement(CardElement);
      const result = await this.props.stripe?.confirmCardSetup(await clientSecret, {
        // eslint-disable-next-line @typescript-eslint/camelcase
        payment_method: {
          card: card as StripeCardElement,
          // eslint-disable-next-line @typescript-eslint/camelcase
          billing_details: billingDetails
        }
      });

      if (result && result.setupIntent !== undefined) {
        let defaultPaymentMethodId;
        if (this.state.isChecked || this.props.paymentMethods?.length === 0) {
          const paymentMethodId = result.setupIntent.payment_method;
          defaultPaymentMethodId = await this.props.payment?.setPaymentMethodAsDefault(paymentMethodId as string);
        }
        await this.props.onChangePaymentMethods(defaultPaymentMethodId);

        this.setState({ isLoading: false });
        this.props.alert?.createAlert({
          message: 'Card added',
          title: 'Success',
          type: 'success'
        });
      }
      if (result?.error) {
        this.setState({ paymentError: result.error.message, isLoading: false });
        this.props.alert?.createAlert({
          message: 'Please try again or let us know about it in Contact Us page.',
          title: 'Error',
          type: 'error'
        });
      }
    } else {
      this.setState({ paymentError: 'Add Credit Card Failed. Please try again.', isLoading: false });
      this.props.alert?.createAlert({
        message: 'Please try again or let us know about it in Contact Us page.',
        title: 'Error',
        type: 'error'
      });
    }
  }

  onCardFocus(): void {
    this.setState({ cardError: this.state.cardError });
  }

  onCardChange(stripeCardChangeEvent: StripeCardElementChangeEvent): void {
    if (stripeCardChangeEvent.error) {
      this.setState({ cardError: stripeCardChangeEvent.error.message });
    } else if (!stripeCardChangeEvent.complete) {
      this.setState({ cardError: 'uninitialized' });
    } else {
      this.setState({ cardError: '' });
    }
  }

  displayErrorMessage(): React.ReactNode {
    if (this.state.paymentError) {
      return (
        <Message large negative>
          {this.state.paymentError}
        </Message>
      );
    }
  }

  render(): React.ReactNode {
    const { paymentMethods } = this.props;
    this.cardElementOptions = {
      style: {
        base: {
          fontSize: '1em',
          fontFamily: 'Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif',
          color: 'rgba(0,0,0,.87)',
          '::placeholder': {
            color: 'lightgrey'
          },
          border: 'none',
        },
        invalid: {
          color: '#9f3a38',
          border: 'none',
        }
      },
      //   hidePostalCode: false,
    };

    return (
      <div>
        <Form onSubmit={(e): Promise<void> => this.handleFormSubmit(e)}>
          <Form.Input
            width="16"
            type="text"
            name="name"
            placeholder="Cardholder Name"
            value={this.state.name}
            error={this.state.cardholderNameError ? this.state.cardholderNameError : null}
            onChange={(e, d): void => this.setState({ name: d.value })}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>): Promise<void> => this.validateCardholderName(e)}
          />
          <CardElementContainer>
            <CardElement
              options={this.cardElementOptions}
              onFocus={(): void => this.onCardFocus()}
              onChange={(e): void => this.onCardChange(e)}
            />
          </CardElementContainer>
          <div style={{ position: 'relative', top: '-20.99px', zIndex: -1 }}>
            <Form.Input
              type="hidden"
              error={this.state.cardError !== '' && this.state.cardError !== 'uninitialized' ? this.state.cardError : false}
            />
          </div>
          {
            paymentMethods?.length === 0 ? null : <FormCheckbox toggle
              label={{ children: 'Set this card as default' }}
              checked={this.state.isChecked}
              onChange={(e: React.FormEvent<HTMLInputElement>, d: CheckboxProps): Promise<void> => this.onToggleChange(e, d)}/>
          }
          <FormButton
            primary
            loading={this.state.isLoading}
            disabled={!(this.state.validForm && this.props.stripe && this.state.cardError !== 'uninitialized' && this.state.cardError === '' && !this.state.isLoading)}
          >
            Add Card
          </FormButton>
          {this.displayErrorMessage()}
        </Form>
      </div>
    );
  }
}
