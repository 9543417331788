import {
  View,
  Text,
  StyleSheet,
} from '@react-pdf/renderer';
import React from 'react';
import ActiveApplication from '../../schemas/applications/ActiveApplication';

const styles = StyleSheet.create({
  materialTransactionSummary: {
    width: 300,
  },
  title: {
    fontSize: '12',
    marginTop: 15,
    fontWeight: 'heavy',
  },
  table: {
    display: 'table',
    width: 'auto',
    marginTop: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    alignSelf: 'auto',
    borderLeftWidth: 1,
  },
  headingRow: {
    margin: 'auto',
    flexDirection: 'row',
    alignSelf: 'auto',
    borderLeftWidth: 1,
    backgroundColor: '#ededed',
    fontWeight: 'heavy',
  },
  shadedRow: {
    margin: 'auto',
    flexDirection: 'row',
    alignSelf: 'auto',
    borderLeftWidth: 1,
    backgroundColor: '#ededed',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'right',
  },
  tableExtraWideCol: {
    width: '75%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'left',
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
});

interface MaterialTransactionSummaryProps {
  application: ActiveApplication;
  changeOrderSummary?: boolean;
}

export default class MaterialTransactionSummary extends React.Component<MaterialTransactionSummaryProps> {
  render() {
    if (!this.props.application.grandTotalMaterialThisPeriod && !this.props.application.grandTotalMaterialReversalThisPeriod) { return <View/>; }

    const enUs = 'en-US';
    const currencyStyle = { style: 'currency', currency: 'USD' };
    const newMaterial = this.props.changeOrderSummary
      ? this.props.application.changeOrdersMaterialThisPeriod
      : this.props.application.grandTotalMaterialThisPeriod;
    const materialReversals = this.props.changeOrderSummary
      ? this.props.application.changeOrdersMaterialReversalThisPeriod
      : this.props.application.grandTotalMaterialReversalThisPeriod;
    const incrementalBillAmount = this.props.changeOrderSummary
      ? this.props.application.totalIncrementalBillAmountChangeOrders
      : this.props.application.grandTotalIncrementalBillAmount;
    const totalIncludingRetainage =
      incrementalBillAmount
      + newMaterial
      + materialReversals;
    const retainage = this.props.changeOrderSummary
      ? this.props.application.changeOrdersRetainageThisPeriod
      : this.props.application.grandTotalRetainageThisPeriod;
    return (
      <View style={styles.materialTransactionSummary}>
        <Text style={styles.title}>Materials Stored Summary</Text>
        <View style={styles.table}>
          <View style={styles.headingRow}>
            <View style={styles.tableExtraWideCol}>
              <Text style={styles.tableCell}>Work Completed This Period</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{incrementalBillAmount.toLocaleString(enUs, currencyStyle)}</Text>
            </View>
          </View>
          {
            newMaterial
              ?
              <View style={styles.tableRow}>
                <View style={styles.tableExtraWideCol}>
                  <Text style={styles.tableCell}>New Materials Stored this Period</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{newMaterial.toLocaleString(enUs, currencyStyle)}</Text>
                </View>
              </View>
              : <View />
          }
          {
            materialReversals
              ?
              <View style={styles.tableRow}>
                <View style={styles.tableExtraWideCol}>
                  <Text style={styles.tableCell}>Less Material Used, Previously Billed</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{materialReversals.toLocaleString(enUs, currencyStyle)}</Text>
                </View>
              </View>
              : <View />
          }
          <View style={styles.shadedRow}>
            <View style={styles.tableExtraWideCol}>
              <Text style={styles.tableCell}>Total Earned This Period Including Retainage</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{
                totalIncludingRetainage.toLocaleString(enUs, currencyStyle)
              }</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableExtraWideCol}>
              <Text style={styles.tableCell}>Less Retainage</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{
                (-retainage).toLocaleString(enUs, currencyStyle)
              }</Text>
            </View>
          </View>
          <View style={styles.headingRow}>
            <View style={styles.tableExtraWideCol}>
              <Text style={styles.tableCell}>Current Payment Due</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{
                (totalIncludingRetainage - retainage).toLocaleString(enUs, currencyStyle)
              }</Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
}
