import React from 'react';
import { WidgetProps } from '@rjsf/core';
import { DataJSON, DataService, SchemaEndpoint } from '../../services/DataService';
import { Button, Divider, Dropdown, Form, Grid, Icon, Loader, Modal, Segment } from 'semantic-ui-react';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { GeneralContractorJSON } from '../GeneralContractorJSONSchema';
import GCSchemaForm from '../../pages/general-contractors/components/GCSchemaForm';
import { AlertService } from '../../services/AlertService';
import { JSONSchema7 } from 'json-schema';

interface RecordSelectorState {
  loading: boolean;
  selectedRecord: DataJSON | undefined;
  records: Array<DataJSON>;
  isError: boolean;
  isModalOpened: boolean;
  isGCCreationFailed: boolean;
  isGCCreationSucceeded: boolean;
  generalContractor?: GeneralContractorJSON | undefined;
}

interface NewFeatureRecordSelectorProps extends WidgetProps {
  options: {
    alert: AlertService;
    selectedRecordId: string;
    endpoint: SchemaEndpoint;
    listFields: JSONSchema7;
    displayFieldName: string;
    displayFieldSchema: JSONSchema7;
    parent: DataJSON;
  };
}

// TODO: this duplicated code should be removed when we agree upon new feature.
export default class NewFeatureRecordSelector extends React.Component<NewFeatureRecordSelectorProps,
  RecordSelectorState> {
  state: RecordSelectorState = {
    isModalOpened: false,
    loading: true,
    selectedRecord: undefined,
    records: [],
    isError: false,
    isGCCreationFailed: false,
    isGCCreationSucceeded: false,
  };
  dataService = new DataService();

  async componentWillUnmount(): Promise<void> {
    this.state.records
      .forEach(record => {
        delete record['text'];
        delete record['value'];
      });
  }

  async componentDidMount(): Promise<void> {
    let records = await this.dataService.list(this.props.uiSchema.options.endpoint);
    if (!records || records.length === 0) {
      records = [];
    }
    records.forEach(record => {
      record['value'] = record.id;
      record['text'] = record.name;
    });

    records = records.sort((a, b) => String(a?.name).localeCompare(String(b.name)));
    const selectedGeneralContractor = this.props.options.selectedRecordId ?
      records.find(record => record.id === this.props.options.selectedRecordId)
      : undefined;
    this.setState({
      loading: false,
      records,
      selectedRecord: selectedGeneralContractor
    });
  }

  handleDropdownChange(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void {
    let selectedRecord: DataJSON | undefined = undefined;

    // getting GC based on dropdown click
    const selectedGC = Object.values(event.currentTarget)[1];

    if (typeof selectedGC.id !== 'undefined') {

      if (typeof data.options !== 'undefined') {
        selectedRecord = data.options.find(option => option.id === selectedGC.id);
      }
      this.setState({
        selectedRecord: selectedRecord as DataJSON,
        isError: false
      });
      if (typeof selectedRecord !== 'undefined' && selectedRecord?.id !== null) {
        this.props.onChange(selectedRecord?.id);
      }

    } else {
      this.setState({
        isError: true,
        selectedRecord: undefined
      });
      this.props.onChange(selectedRecord);
    }
  }

  async performOnSuccess(record: DataJSON | undefined): Promise<void> {
    if (typeof record !== 'undefined') {
      this.props.options.alert.createAlert({
        message: 'Successfully created General Contractor',
        title: 'Success',
        type: 'success'
      });
      record['value'] = record.id;
      record['text'] = record.name;
      const { records } = this.state;
      records.push(record);

      this.setState({
        isModalOpened: false,
        isGCCreationSucceeded: true,
        records: records,
        selectedRecord: record as DataJSON,
        isError: false
      });

      if (typeof record !== 'undefined' && record.id !== null) {
        this.props.onChange(record.id);
      }
    }
  }

  async performOnError(): Promise<void> {
    this.props.options.alert.createAlert({
      message: 'Failed to create General Contractor. Please try again.',
      title: 'Error',
      type: 'error'
    });
    this.setState({
      isGCCreationFailed: true
    });
  }

  onModalClose(): void {
    this.setState({
      isModalOpened: false,
    });
  }

  render(): React.ReactNode {
    const { records, isModalOpened, isError, selectedRecord, loading } = this.state;
    const { required, label } = this.props;
    if (loading) {
      return (
        <Loader/>
      );
    }

    if (isModalOpened) {
      return (
        <Modal
          closeIcon
          onClose={(): void => this.onModalClose()}
          size='large'
          open={isModalOpened}
        >
          <Modal.Header>Create General Contractor</Modal.Header>
          <Modal.Content scrolling>
            <GCSchemaForm
              onCancelButtonClick={(): void => this.setState({ isModalOpened: false })}
              onSuccessfulGCCreationCallback={(record: DataJSON | undefined): Promise<void> => this.performOnSuccess(record)}
              onErrorGCCreationCallback={(): Promise<void> => this.performOnError()}
            />
          </Modal.Content>
        </Modal>
      );
    }

    return (
      <Form.Field required={required}>
        {
          records.length > 0 ?
            <div>
              <label
                style={{ fontWeight: 'bold', fontSize: '13px' }}
                className={required ? 'requiredStar' : ''}
              >
                {label}
              </label>
              <Segment>
                <Grid columns={2} stackable style={{ textAlign: 'center' }}>
                  <Grid.Column>
                    <Dropdown
                      id='gc_select'
                      clearable
                      search
                      selection
                      error={isError}
                      style={{ width: '279px' }}
                      placeholder='Select Existing General Contractor'
                      onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void => this.handleDropdownChange(event, data)}
                      text={selectedRecord?.name as string || ''}
                      value={selectedRecord?.id as string || ''}
                      options={records}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      primary
                      icon
                      labelPosition='left'
                      onClick={(): void => this.setState({ isModalOpened: true })}
                    >
                      <Icon name='user plus'/>
                      Create New General Contractor
                    </Button>
                  </Grid.Column>
                </Grid>
                <Divider vertical>Or</Divider>
              </Segment>
            </div>
            :
            <div>
              <label
                style={{ fontWeight: 'bold', fontSize: '13px' }}
                className={required ? 'requiredStar' : ''}
              >
                {label}
              </label>
              <Segment compact>
                <Button
                  primary
                  icon
                  labelPosition='left'
                  onClick={(): void => this.setState({ isModalOpened: true })}
                >
                  <Icon name='user plus'/>
                  Create New General Contractor
                </Button>
              </Segment>
            </div>
        }
      </Form.Field>
    );
  }
}
