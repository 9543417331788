import React from 'react';
import { inject, observer } from 'mobx-react';
import { SubscriptionCommonProps } from './components/AddPaymentMethod';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import Page, { BreadcrumbObject } from '../../components/Page';
import { Redirect } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import Checkout from './components/Checkout';
import ThankYouModal from './components/ThankYouModal';
import TagManagerService from '../../services/TagManagerService';
import { PaymentMethod, Plan, StripeCustomer, Subscription } from '../../services/PaymentService';
import SubscriptionConfirmationWithTrial from './components/SubscriptionConfirmationWithTrial';
import SubscriptionConfirmationWithoutTrial from './components/SubscriptionConfirmationWithoutTrial';


export default class ActivateSubscriptionPageContainer extends React.Component<SubscriptionCommonProps> {
  render(): React.ReactNode {
    return (
      <ElementsConsumer>
        {
          ({ stripe, elements }): React.ReactNode => (
            <ActivateSubscriptionPage
              {...this.props}
              location={this.props.location}
              stripe={stripe as Stripe}
              elements={elements as StripeElements}
            />
          )
        }
      </ElementsConsumer>
    );
  }
}

interface ActivateSubscriptionPageState {
  initialized: boolean;
  isLoading: boolean;
  openedThankYouModal: boolean;
  paymentMethods: Array<PaymentMethod> | undefined;
  showAddCardModal: boolean;
  stripeCustomer: StripeCustomer | undefined;
  subscriptions: Array<Subscription> | undefined;
  trialDays: number | undefined;
}


@inject('stripe')
@inject('auth')
@inject('alert')
@inject('cookie')
@inject('payment')
@observer
class ActivateSubscriptionPage extends React.Component<SubscriptionCommonProps, ActivateSubscriptionPageState> {

  state: ActivateSubscriptionPageState = {
    initialized: false,
    isLoading: false,
    openedThankYouModal: false,
    paymentMethods: undefined,
    showAddCardModal: false,
    stripeCustomer: undefined,
    subscriptions: undefined,
    trialDays: undefined,
  }

  async componentDidMount(): Promise<void> {
    let stripeCustomer;
    if (this.props.auth?.user?.stripeCustomerId) {
      stripeCustomer = await this.props.payment?.getStripeCustomer(this.props.auth?.user?.stripeCustomerId);
    }
    const [ paymentMethods, subscriptions, trialDays ] = await Promise.all([
      await this.props.payment?.getPaymentMethods(this.props.auth?.user?.stripeCustomerId as string),
      await this.props.payment?.getSubscriptions(),
      await this.props.payment?.getTrialPeriodDays(),
    ]);
    this.setState({
      initialized: true,
      subscriptions,
      stripeCustomer,
      paymentMethods,
      trialDays,
    });
  }

  async onChangePaymentMethods(defaultPaymentMethodId?: string): Promise<void> {
    const stripeCustomer = this.state.stripeCustomer;
    const paymentMethods = await this.props.payment?.getPaymentMethods(this.props.auth?.user?.stripeCustomerId as string);

    if (stripeCustomer && defaultPaymentMethodId) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      stripeCustomer.invoice_settings.default_payment_method = defaultPaymentMethodId;
      this.setState({
        stripeCustomer,
      });
    }
    this.setState({
      initialized: true,
      paymentMethods,
      showAddCardModal: false
    });
  }

  async triggerFinalSubscription(plan: Plan): Promise<void> {
    this.setState({ isLoading: true });
    const subscription = await this.props.payment?.subscribeToPlan(
      this.state.stripeCustomer as StripeCustomer,
      plan as Plan,
      this.state.trialDays as number
    );
    if (subscription) {
      this.setState({
        isLoading: false,
        openedThankYouModal: true,
        subscriptions: [ subscription ]
      });
      TagManagerService.logPurchaseEvent(subscription.plan.amount);
    }
  }

  getBreadcrumbUrlsList(): Array<BreadcrumbObject> {
    const { projectUrl } = this.props.location;
    if (projectUrl) {
      return [
        { key: 'home', href: '/', content: 'Home', link: true },
        { key: 'project', href: projectUrl, content: 'Project', link: true },
        { key: 'subscription_select', href: '/subscription/select', content: 'Subscription Selection', link: true },
        { key: 'activate', content: 'Subscription Activation', link: false }
      ];
    }
    return [
      { key: 'home', href: '/', content: 'Home', link: true },
      { key: 'subscription_select', href: '/subscription/select', content: 'Subscription Selection', link: true },
      { key: 'subscription_activate', content: 'Subscription Activation', link: false },
    ];
  }

  closeThankYouModal(): void {
    this.setState({ openedThankYouModal: false });
  }

  render(): React.ReactNode {
    const { initialized, paymentMethods, showAddCardModal, isLoading, openedThankYouModal, subscriptions, stripeCustomer, trialDays } = this.state;
    const { elements, stripe, auth, payment } = this.props;
    const { plan, projectUrl } = this.props.location;
    const payMethodsQuantity = this.state.paymentMethods?.length;
    const defaultPaymentMethodId = this.state.stripeCustomer?.invoice_settings.default_payment_method;

    if (typeof plan === 'undefined') return <Redirect to='/subscription/select'/>;

    if (!initialized) return <Loader active/>;

    return (
      <Page pageTitle='Subscription Activation' breadcrumb={
        this.getBreadcrumbUrlsList()
      }>
        <Checkout
          auth={auth}
          creditCardDetails={paymentMethods}
          defaultPaymentMethodId={defaultPaymentMethodId as string}
          elements={elements}
          isLoading={isLoading}
          onCloseAddCardModal={(): void => this.setState({ showAddCardModal: false })}
          onChangePaymentMethods={(defaultPaymentMethodId: string | undefined): Promise<void> => this.onChangePaymentMethods(defaultPaymentMethodId)}
          onOpenAddCardModal={(): void => this.setState({ showAddCardModal: true })}
          onTriggerFinalActionClick={(): Promise<void> => this.triggerFinalSubscription(plan)}
          payment={payment}
          paymentMethods={paymentMethods}
          payMethodsQuantity={payMethodsQuantity}
          plan={plan}
          showAddCardModal={showAddCardModal}
          stripe={stripe}
          stripeCustomer={stripeCustomer}
          subscriptions={subscriptions}
          trialDays={trialDays as number}
        />
        <ThankYouModal
          header='Thank You'
          openedThankYouModal={openedThankYouModal}
          onClose={(): void => this.closeThankYouModal()}
          navigateToAfterClose={this.props.auth?.profileComplete
            ? typeof projectUrl !== 'undefined' ? projectUrl : ''
            : '/profile/update'}
        >
          {
            this.state.trialDays && this.state.trialDays > 0
              ? <SubscriptionConfirmationWithTrial plan={plan} trialDays={this.state.trialDays!} />
              : <SubscriptionConfirmationWithoutTrial plan={plan} trialDays={this.state.trialDays!} />
          }
        </ThankYouModal>
      </Page>
    );

  }
}
