import { observable } from 'mobx';
import JSCookie from 'js-cookie';

interface AppCookies {
  seenGuideModal: boolean;
  ignoreMissingProfileInfo: Array<string>;
  referral: string | null;
  passwordlessEmail: string | null;
}

export default class CookieService {

  @observable appCookies: AppCookies = {
    seenGuideModal: false,
    ignoreMissingProfileInfo: [],
    referral: null,
    passwordlessEmail: null,
  };

  constructor() {
    const rawCookies = JSCookie.get();
    if (rawCookies.seenGuideModal) {
      this.appCookies.seenGuideModal = JSON.parse(rawCookies.seenGuideModal);
    }
    if (rawCookies.ignoreMissingProfileInfo) {
      this.appCookies.ignoreMissingProfileInfo = JSON.parse(rawCookies.ignoreMissingProfileInfo);
    }
    if (rawCookies.referral) {
      this.appCookies.referral = JSON.parse(rawCookies.referral);
    }
    if (rawCookies.passwordlessEmail) {
      this.appCookies.passwordlessEmail = JSON.parse(rawCookies.passwordlessEmail);
    }
  }

  setSeenGuideModal(isSeen: boolean): void {
    this.appCookies.seenGuideModal = isSeen;
    JSCookie.set('seenGuideModal',
      JSON.stringify(isSeen)
    );
  }

  ignoreMissingProfile(id: string): void {
    const ids = this.appCookies.ignoreMissingProfileInfo;
    ids.push(id);
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.appCookies.ignoreMissingProfileInfo = [ ...new Set(ids) ];
    JSCookie.set(
      'ignoreMissingProfileInfo',
      JSON.stringify(this.appCookies.ignoreMissingProfileInfo),
      { expires: 365 }
    );
  }

  setReferralId(rId: string): void {
    this.appCookies.referral = rId;
    JSCookie.set('referral',
      JSON.stringify(this.appCookies.referral),
      { expires: 365 }
    );
  }

  isIgnored(id: string): boolean {
    return Boolean(this.appCookies.ignoreMissingProfileInfo.find(existingId => existingId === id));
  }

  setPasswordlessEmail(email: string): void {
    this.appCookies.passwordlessEmail = email.toLowerCase().trim();
    JSCookie.set('passwordlessEmail',
      JSON.stringify(email)
    );
  }
}

// Export a singleton CookieServiceClass
export const Cookie = new CookieService();
