import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';

export function BrandFooter(
  customStyles: any | undefined = {}
): React.ReactElement {
  return (
    <View fixed style={{ position: 'absolute', left: 0, bottom: -30, fontSize: 10, ...customStyles }}>
      <View style={{ position: 'absolute', left: 0, top: 0 }}>
        <Image src='/img/PAYearned-logo.png' style={{ width: 40 }} />
      </View>
      <View style={{ position: 'absolute', left: 50, top: 5 }}>
        <Text>Easy, accurate pay apps by PAYearned.  Visit https://payearned.com</Text>
      </View>
    </View>
  );
}
