import React from 'react';
import {
  Button,
  Header,
  Icon,
  Image,
  ListContent,
  ListHeader,
  ListItem,
  Modal,
  ModalActions,
  ModalContent,
  ModalDescription,
  ModalHeader,
  Radio
} from 'semantic-ui-react';
import visa from 'payment-icons/min/flat/visa.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import discover from 'payment-icons/min/flat/discover.svg';
import jcb from 'payment-icons/min/flat/jcb.svg';
import amex from 'payment-icons/min/flat/amex.svg';
import { SubscriptionCommonProps } from './AddPaymentMethod';
import { inject, observer } from 'mobx-react';
import PaymentService, { PaymentMethod, StripeCustomer } from '../../../services/PaymentService';

interface PaymentMethodEntryProps extends SubscriptionCommonProps {
  onChangePaymentMethods: (defaultPaymentMethodId?: string) => Promise<void>;
  checked: boolean;
  paymentMethod: PaymentMethod;
  paymentMethods: Array<PaymentMethod> | undefined;
  isLoading: boolean;
  label?: string;
  payment?: PaymentService;
  stripeCustomer?: StripeCustomer;
  value: string;
}

interface PaymentMethodEntryState {
  paymentMethods: Array<PaymentMethod> | undefined;
  isLoading: boolean;
  isModalOpened: boolean;
}

@inject('auth')
@inject('alert')
@observer
export default class PaymentMethodEntry extends React.Component<PaymentMethodEntryProps, PaymentMethodEntryState> {

  state: PaymentMethodEntryState = {
    paymentMethods: [],
    isLoading: false,
    isModalOpened: false
  }

  getDefaultPaymentMethodId(stripeCustomer: StripeCustomer | undefined): string {
    return stripeCustomer ? stripeCustomer.invoice_settings.default_payment_method : null as unknown as string;
  }

  private months = [
    'January', 'February', 'March', 'April', 'May',
    'June', 'July', 'August', 'September',
    'October', 'November', 'December'
  ];

  private monthNumToName(monthNum: number): string {
    return this.months[monthNum - 1] || '';
  }

  private async triggerModalOnClick(): Promise<void> {
    this.setState({ isModalOpened: true });
  }

  async changeDefaultPaymentMethod(paymentMethod: PaymentMethod): Promise<void> {
    const defaultPaymentMethodId = await this.props.payment?.setPaymentMethodAsDefault(paymentMethod.id as string);
    if (defaultPaymentMethodId) {
      await this.props.onChangePaymentMethods(defaultPaymentMethodId);
      const paymentMethod = await this.props.payment?.getPaymentMethod(defaultPaymentMethodId);
      const last4Digits = paymentMethod?.card.last4;
      this.props.alert?.createAlert({
        message: '****' + last4Digits + ' card has been set as default!',
        title: 'Success',
        type: 'success'
      });
    } else {
      this.props.alert?.createAlert({
        message: 'Something went wrong please try again.',
        title: 'Error',
        type: 'error'
      });
    }
  }

  async triggerDeletePayMethod(paymentMethodToDelete: PaymentMethod): Promise<void> {
    const deletedPaymentMethod = await this.props.payment?.deletePaymentMethod(paymentMethodToDelete);
    this.setState({ isLoading: true });
    if (deletedPaymentMethod) {
      const payMethodIndex = this.props.paymentMethods?.findIndex((method: PaymentMethod) => method.id === deletedPaymentMethod.id);
      const updatedPaymentMethods = this.props.paymentMethods?.splice(payMethodIndex || 0, 1);
      this.setState({
        paymentMethods: updatedPaymentMethods,
        isLoading: false
      });
      if (updatedPaymentMethods) {
        await this.props.onChangePaymentMethods();
        this.props.alert?.createAlert({
          message: '****' + updatedPaymentMethods[0].card.last4 + ' card has been removed!',
          title: 'Success',
          type: 'success'
        });
      } else {
        this.props.alert?.createAlert({
          message: 'Something went wrong please try again.',
          title: 'Error',
          type: 'error'
        });
      }
    }
    this.setState({ isModalOpened: false });
  }


  render(): React.ReactNode {
    const { paymentMethod, checked, value, label, isLoading, stripeCustomer } = this.props;

    return (

      <ListItem>
        <Radio
          name='radioGroup'
          checked={checked}
          value={value}
          label={label}
          onChange={(): Promise<void> => this.changeDefaultPaymentMethod(paymentMethod)}
          style={{ marginTop: '10px' }}
        />
        {paymentMethod.card.brand === 'visa' ? <Image spaced="right" src={visa} size="mini" style={{
          marginTop: '7px',
          marginLeft: '7px'
        }}/> : null}
        {paymentMethod.card.brand === 'amex' ? <Image spaced="right" src={amex} size="mini" style={{
          marginTop: '7px',
          marginLeft: '7px'
        }}/> : null}
        {paymentMethod.card.brand === 'jcb' ? <Image spaced="right" src={jcb} size="mini" style={{
          marginTop: '7px',
          marginLeft: '7px'
        }}/> : null}
        {paymentMethod.card.brand === 'mastercard' ? <Image spaced="right" src={mastercard} size="mini"
          style={{
            marginTop: '7px',
            marginLeft: '7px'
          }}/> : null}
        {paymentMethod.card.brand === 'discover' ? <Image spaced="right" src={discover} size="mini" style={{
          marginTop: '7px',
          marginLeft: '7px'
        }}/> : null}
        <ListContent>
          <ListHeader> **** {paymentMethod.card.last4}</ListHeader>
          Expires on {this.monthNumToName(paymentMethod.card.exp_month)} {paymentMethod.card.exp_year}
        </ListContent>
        {
          this.getDefaultPaymentMethodId(stripeCustomer) === paymentMethod.id ?
            <Modal centered={false}
              open={this.state.isModalOpened}
              onClose={(): void => this.setState({ isModalOpened: false })}
              trigger={
                <Button
                  size='mini'
                  icon='delete'
                  onClick={(): void => this.setState({ isModalOpened: true })}
                  style={{ float: 'right', marginTop: '4px' }}
                />
              }
            >
              <ModalHeader>Attempt to delete default Card</ModalHeader>
              <ModalContent>
                <ModalDescription>You are trying to delete **** {paymentMethod.card.last4} which is set as default.
                  Please make another payment method as default</ModalDescription>
              </ModalContent>
              <Modal.Actions>
                <Button primary onClick={(): void => this.setState({ isModalOpened: false })}>OK</Button>
              </Modal.Actions>
            </Modal> : <Modal
              open={this.state.isModalOpened}
              onClose={(): Promise<void> => this.triggerModalOnClick()}
              size='large'
              trigger={
                <Button
                  size='mini'
                  icon='delete'
                  onClick={(): Promise<void> => this.triggerModalOnClick()}
                  style={{ float: 'right', marginTop: '4px' }}
                />
              }>
              <Header icon='credit card alternative' content='Delete Payment Method'/>
              <ModalContent>
                <p>
                  Confirm that you want to delete **** {paymentMethod.card.last4}
                </p>
              </ModalContent>
              <ModalActions>
                <Button primary negative onClick={(): void => this.setState({ isModalOpened: false })}>
                  <Icon name='cancel'/> Cancel
                </Button>
                <Button
                  primary
                  disabled={isLoading}
                  positive
                  onClick={(): Promise<void> => this.triggerDeletePayMethod(paymentMethod)}
                >
                  <Icon name='checkmark'/> Confirm
                </Button>
              </ModalActions>
            </Modal>
        }
      </ListItem>
    );
  }
}
