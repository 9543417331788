import React, { ChangeEvent } from 'react';
import { AuthServiceInterface } from '../services/AuthService';
import { Link, RouteProps } from 'react-router-dom';
import Page from '../components/Page';
import CenteredGrid from '../components/CenteredGrid';
import { Button, Form, Header, Icon, Message, Segment } from 'semantic-ui-react';
import PasswordStrengthBar from 'react-password-strength-bar';
import { inject, observer } from 'mobx-react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { UserCredential } from 'firebase/auth';
import { AlertService } from '../services/AlertService';

interface ChangePasswordPageProps extends RouteProps {
  auth: AuthServiceInterface;
  alert: AlertService;
}

interface ChangePasswordPageState {
  currentPassword: string;
  password: string;
  confirmPassword: string;
  passwordStrength: number;
  touched: Array<string>;
  focused: string;
  errorMessage: string;
}

const currentPassword = 'currentPassword';
const password = 'password';
const confirmPassword = 'confirmPassword';

@inject('auth')
@inject('alert')
@observer
export default class ChangePasswordPage extends React.Component<ChangePasswordPageProps, ChangePasswordPageState> {

  state: ChangePasswordPageState = {
    currentPassword: '',
    password: '',
    confirmPassword: '',
    passwordStrength: 0,
    touched: [],
    focused: '',
    errorMessage: ''
  }

  displayErrorMessage(): React.ReactNode {
    if (this.invalidStrength()) {
      return <Message error content='Weak password. Add another uncommon word or two'/>;
    }

    if (this.notMatchingPasswords() && this.state.confirmPassword.length > 0) {
      return (
        <Message error content="Passwords don't match" />
      );
    }

    if (this.state.errorMessage) {
      return (
        <Message error content={this.state.errorMessage} />
      );
    }
  }

  invalidStrength(): boolean {
    return this.state.password.length > 0 && this.state.touched.includes(password) && this.state.passwordStrength < 2;
  }

  notMatchingPasswords(): boolean {
    return this.state.password !== this.state.confirmPassword;
  }

  onChangePassword(e: React.ChangeEvent<HTMLInputElement>): void {
    let touched = this.state.touched;
    touched.push(password);
    touched = touched.filter((v, i, a) => a.indexOf(v) === i);
    this.setState({ password: e.target.value.trim(), touched: touched });
  }

  onChangScore(score: number): void {
    this.setState({ passwordStrength: score });
  }

  onFocus(fieldName: string): void {
    this.setState({ focused: fieldName });
  }

  reauthenticate = async (currentPassword: string): Promise<UserCredential> => {
    const user = this.props.auth.user;
    if (user?.email != undefined) {
      try {
        const cred = await this.props.auth.credential(
          user.email, currentPassword);
        return await this.props.auth.reauthenticateWithCredential(cred);
      } catch (e) {
        this.setState({ errorMessage: e.message });
      }
    }
    return Promise.reject();
  }

  changePassword = async (): Promise<void> => {
    await this.reauthenticate(this.state.currentPassword);
    try {
      await this.props.auth.updatePassword(this.state.password);
      this.setState({ currentPassword: '', password: '', confirmPassword: '', errorMessage: '' });
      this.props.alert.createAlert({
        message: 'Successfully updated password',
        title: 'Success',
        type: 'success'
      });
    } catch (e) {
      this.setState({ errorMessage: e.message });
    }
  }


  render(): React.ReactNode {
    return(
      <Page>
        <CenteredGrid>
          <Header as='h2' color='blue' textAlign='center'>Change password</Header>
          <div style={{ width: '420px', height: '60px', display: 'block' }}>
            {this.displayErrorMessage()}
          </div>
          <Form size='large'>
            <Segment stacked>
              <Form.Input
                required
                type="password"
                placeholder="Current Password"
                value={this.state.currentPassword}
                onFocus={(): void => this.onFocus(currentPassword)}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => this.setState({ currentPassword: e.target.value.trim() })}
              />
              <Form.Input
                required
                type="password"
                error={this.invalidStrength()}
                placeholder="New Password"
                value={this.state.password}
                onFocus={(): void => this.onFocus(password)}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => this.onChangePassword(e)}
              />
              <PasswordStrengthBar password={this.state.password} onChangeScore={(score): void => this.onChangScore(score)}/>
              <Form.Input
                required
                type="password"
                error={this.notMatchingPasswords()}
                placeholder="Confirm Password"
                value={this.state.confirmPassword}
                onFocus={(): void => this.onFocus(confirmPassword)}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => this.setState({ confirmPassword: e.target.value.trim() })}
              />
              <Button as={Link} to={{ pathname: '/profile' }}><Icon name='cancel'/>Cancel</Button>
              <Button
                disabled={!this.state.currentPassword || !this.state.password || !this.state.confirmPassword ||
              this.invalidStrength() || this.notMatchingPasswords()}
                color='blue'
                onClick={this.changePassword}
              >
                <Icon name='lock'/>
                Change Password
              </Button>
            </Segment>
          </Form>
        </CenteredGrid>
      </Page>
    );
  }

}
