import React, { Fragment } from 'react';
import ActiveApplication from '../../../schemas/applications/ActiveApplication';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { RouteChildrenProps } from 'react-router-dom';
import ActiveProject from '../../../schemas/projects/ActiveProject';
import { MAX_PHONE_WIDTH_MEDIA_QUERY } from '../../../common/Constants';

interface NewApplicationProps extends RouteChildrenProps<{}> {
  application: ActiveApplication;
  onSave: () => void;
  asMenu?: boolean;
}

interface NewApplicationState {
  active: boolean;
}

export default class NewApplication extends React.Component<NewApplicationProps, NewApplicationState> {
  state: NewApplicationState = { active: false, }

  async completeApplication(): Promise<void> {
    this.setState({ active: false });
    this.props.onSave();

    // Complete the application
    await this.props.application.complete();

    // Load the updated project
    const updatedProject = new ActiveProject(this.props.application.projectId);
    await updatedProject.load();

    // Load the new application form
    this.props.history.push('/applications/new', { project: updatedProject });
  }

  async checkForFirstApplication(): Promise<void> {
    // If we only have 1 application that isn't completed, show the modal
    if (
      this.props.application.project?.applications
      && this.props.application.sequenceNumber === this.props.application.project?.applications[0].sequenceNumber
      && this.props.application.status?.toLowerCase() !== 'completed'
    ) {
      this.setState({ active: true });
    } else if (this.props.application.status?.toLowerCase() !== 'completed') {
      // Complete the project if it's not already completed
      this.completeApplication();
    } else {
      // If it's already completed, load the project and then create the new application
      const project = new ActiveProject(this.props.application.projectId);
      await project.load();
      this.props.application.project = project;
      this.props.history.push('/applications/new', { project });
    }
  }

  render(): React.ReactNode {
    // Return nothing, if the application cannot be submitted or we are 100% billed with no stored amount
    if (
      !this.props.application.allowSubmit
      || (this.props.application.project?.billedPercent === 1 && this.props.application.totalCompletedAndStored === 0)
    ) {
      return '';
    }

    const isMobileView: boolean = window.matchMedia(MAX_PHONE_WIDTH_MEDIA_QUERY).matches;
    const hiddenOrVisibleOnMobile: string = isMobileView ? 'none' : 'block';

    return (
      <Fragment>
        {
          this.props.asMenu ? (
            <span onClick={(): Promise<void> => this.checkForFirstApplication()}>Create Application #{Number(this.props.application.sequenceNumber)+1}</span>
          ): (
            <div style={{ display: hiddenOrVisibleOnMobile }}>
              <Button primary onClick={(): Promise<void> => this.checkForFirstApplication()}>
                <Icon name='plus' />
              Create Application #{Number(this.props.application.sequenceNumber)+1}
              </Button>
            </div>
          )
        }
        <Modal
          onClose={(): void => this.setState({ active: false })}
          open={this.state.active}
          style={{ width: '30%' }}
        >
          <Modal.Header>Create Application #{Number(this.props.application.sequenceNumber)+1}</Modal.Header>
          <Modal.Content>
            <p>
              If you proceed with creating application #{Number(this.props.application.sequenceNumber)+1}, you will no longer be able to make any changes to the
              original contract amount or the original project line items.  Any subsequent changes to the contract
              value and contract line items will need to be made via change orders.
            </p>
            <p>
              <b>Do you want to proceed with creating Application #{Number(this.props.application.sequenceNumber)+1}?</b>
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={(): void => this.setState({ active: false })}>No</Button>
            <Button color='green' onClick={(): Promise<void> => this.completeApplication()}>Yes</Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}
