import React from 'react';

export const DISABLE_AUTOMATIC_BILLING_MESSAGE = (
  <React.Fragment>
    <p>
      You may disable automatic billing of your subscription at any time.  If you disable automatic billing, you will
      continue to have full access to all PAYearned features until the end of the subscription period already paid
      for.
    </p>
    <p>
      Please be aware that after the final subscription period, you will no longer have access to
      any online PDF documents (pay apps, continuation sheets, change orders, etc.).
    </p>
  </React.Fragment>
);
