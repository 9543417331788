import { JSONSchema7 } from 'json-schema';
import CurrencyWidget from './widgets/currency/CurrencyWidget';
import { UiSchema, FormValidation } from '@rjsf/core';
import { TableCellFieldTemplate } from './templates/TableCellFieldTemplate';
import PercentWidget from './widgets/PercentWidget';

export type ChangeOrderType = 'A' | 'R+' | 'R-' | 'D';

export interface ProjectLineJSON {
  id?: string;
  sequenceNumber?: number;
  projectId?: string;
  lineType?: 'original'|'changeOrder';
  changeOrderType?: ChangeOrderType;
  description?: string;
  scheduledValue?: number;
  retainage?: number;
  billedToDate?: number;
  storedToDate?: number;
  parentId?: string;
  firstApplicationSequenceNumber?: number;
}

export const ProjectLineJSONSchema: JSONSchema7 = {
  type: 'object',
  required: [ 'description', 'scheduledValue', 'retainage' ],
  properties: {
    description: {
      type: 'string',
      title: 'Line Description',
    },
    scheduledValue: {
      type: 'number',
      title: 'Scheduled Value',
    },
    retainage: {
      type: 'number',
      title: 'Retainage',
    },
  },
};

export const ProjectLineUISchema: UiSchema = {
  description: {
    'ui:FieldTemplate': TableCellFieldTemplate,
    'ui:options': {
      placeholder: 'Required',
    }
  },
  scheduledValue: {
    'ui:FieldTemplate': TableCellFieldTemplate,
    'ui:widget': CurrencyWidget,
    'ui:options': {
      placeholder: 'Required',
    }
  },
  retainage: {
    'ui:FieldTemplate': TableCellFieldTemplate,
    'ui:widget': PercentWidget,
    'ui:options': {
      placeholder: 'Required',
    }
  },
};

export const validateProjectLine = (formData: ProjectLineJSON, errors: FormValidation): FormValidation => {
  // Do some cool custom validation in here
  // If there is an error you can use errors.addError()
  return errors;
};
