import React from 'react';
import Page from '../../components/Page';
import {
  GeneralContractorJSON,
  GeneralContractorJSONSchema, GeneralContractorUISchema,
  validateGeneralContractor
} from '../../schemas/GeneralContractorJSONSchema';
import { DataService, DataJSON } from '../../services/DataService';
import { RouteChildrenProps } from 'react-router-dom';
import Page404 from '../Page404';
import { BreadcrumbSectionProps, Loader, SemanticShorthandCollection } from 'semantic-ui-react';
import { AjvError, FormValidation, IChangeEvent } from '@rjsf/core';
import { FieldTemplate } from '../../schemas/templates/FieldTemplate';
import CalculationSchemaForm from '../../components/CalculationSchemaForm';
import { ProjectJSON } from '../../schemas/projects/ProjectJSON';
import { AlertService } from '../../services/AlertService';
import { inject, observer } from 'mobx-react';

export interface EditGeneralContractorLocation extends Location {
  state: {
    parent?: ProjectJSON;
  };
}

export interface EditGeneralContractorProps extends RouteChildrenProps<{id: string}> {
  location: EditGeneralContractorLocation;
  alert?: AlertService;
  isUpdateCGInitialized?: boolean;
}

interface EditGeneralContractorState {
  loading: boolean;
  error: boolean;
  generalContractor?: GeneralContractorJSON;
}

@inject('alert')
@observer
export default class EditGeneralContractor extends React.Component<EditGeneralContractorProps, EditGeneralContractorState> {
  state: EditGeneralContractorState = {
    loading: true,
    error: false,
  }
  dataService = new DataService();

  async componentDidMount(): Promise<void> {
    let loading = true;
    let error = true;
    let generalContractor: GeneralContractorJSON;

    // Proceed if we have an id
    if (this.props.match?.params.id) {
      // Load the record
      generalContractor = await this.dataService.get('general-contractors', this.props.match.params.id) as unknown as GeneralContractorJSON;
      error = !generalContractor;
    } else {
      // If we don't have an ID, it's because we have a new record
      error = false;
    }
    loading = false;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.setState({ loading, error, generalContractor });
  }

  async save(e: IChangeEvent<GeneralContractorJSON>): Promise<void> {
    // Exit if we're already saving
    if (this.state.loading) { return; }

    // Show the loader
    this.setState({ loading: true });

    // Create a new object w/ the form data
    const generalContractor: GeneralContractorJSON = JSON.parse(JSON.stringify(e.formData));

    // Add the role
    generalContractor.role = 'GENERAL_CONTRACTOR';
    let newGeneralContractor;
    // Create a new generalContractor
    if (!generalContractor.id) {
      try {
        newGeneralContractor = await this.dataService.create('general-contractors', generalContractor as unknown as DataJSON) as unknown as GeneralContractorJSON;
        generalContractor.id = newGeneralContractor.id;
      } catch (e) {
        generalContractor.licenseNumber = '';
        generalContractor.email = '';
        this.setState({
          loading: false,
          generalContractor: generalContractor
        });

        this.props.alert?.createAlert({
          message: 'General contractor already exists with the same email address or license number.',
          title: 'License number and email address should be unique.',
          type: 'error'
        });
        return;

      }
    }
    // Meaning that GC is already created and linked to the project we are coming from
    let updatedGeneralContractor;
    if(this.extractGCIdFromPathname(this.props.location) === generalContractor.id){
      try{
        updatedGeneralContractor =  await this.dataService.save('general-contractors',generalContractor.id as string,generalContractor as unknown as DataJSON) as unknown as GeneralContractorJSON;
      }catch (e){
        this.setState({
          loading: false,
          generalContractor: generalContractor
        });
      }
    }

    // If we were passed in a project, go back to the project edit page
    if (this.props.location.state?.parent) {
      // Build the url
      const pathname = this.props.location.state.parent?.id ? `/projects/${this.props.location.state.parent?.id}/edit` : '/projects/new';
      // Update the project with the GC info
      const project = this.props.location.state.parent;
      project.generalContractor = generalContractor;
      project.generalContractorId = generalContractor.id;

      // Forward back to the project edit page
      this.props.history.push({
        pathname: pathname,
        state: {
          project: project
        }
      });
    } else {
      // Change the URL to go back to display mode
      this.props.history.push(`/general-contractors/${generalContractor.id}`);
    }
    // forward to GC profile after you updated it
    if(updatedGeneralContractor && this.props.location.hash){
      this.props.history.push({
        pathname: this.props.location.hash.substring(1)
      });
      this.props.alert?.createAlert({
        message: '',
        title: 'General Contractor info has been successfully updated',
        type: 'success'
      });
    }
  }

  extractGCIdFromPathname(location: EditGeneralContractorLocation): string{
    if(typeof location.pathname !== 'undefined'){
      return   location.pathname.substring(location.pathname.indexOf('s/')+2, location.pathname.indexOf('/e'));
    }
    return '';
  }

  transformErrors (errors: Array<AjvError>): Array<AjvError> {
    errors.forEach(error => {
      if (error.property === '.phoneNumber') {
        error.property = 'Phone Number';
        error.message = 'Invalid phone number';
      }
    });
    return errors;
  }

  render(): React.ReactNode {
    const callBackUrl =  this.props.location?.hash.substring(1);
    // Show the loader
    if (this.state.loading) { return <Loader active />; }

    // Show the error page
    if (this.state.error) { return <Page404 {...this.props} />; }

    // Build the breadcrumb going back to the project, if we were passed in one
    let breadcrumb: SemanticShorthandCollection<BreadcrumbSectionProps>;
    if (this.props.location.state?.parent) {
      breadcrumb = [
        { key: 'home', href: '/general-contractors', content: 'General Contractors', link: true },
        { key: 'action', content: 'Create a New General Contractor', active: true },
      ];
    } else {
      breadcrumb = [
        { key: 'home', href: '/general-contractors', content: 'General Contractors', link: true },
        { key: 'general-contractor', href: `/general-contractors/${this.state.generalContractor?.id}`, content: this.state.generalContractor?.name, link: true },
        { key: 'action', content: 'Update General Contractor', active: true },
      ];
    }

    return (
      <Page pageTitle={`${this.props.match?.params.id ? 'Edit' : 'Create a New'} General Contractor`} breadcrumb={breadcrumb}>
        <CalculationSchemaForm
          onCancel={(): void => {
            window.history.back();
          }}
          projectUrl={callBackUrl}
          liveValidate
          schema={GeneralContractorJSONSchema}
          uiSchema={GeneralContractorUISchema}
          FieldTemplate={FieldTemplate}
          formData={this.state.generalContractor}
          onSubmit={(e: IChangeEvent<GeneralContractorJSON>): Promise<void> => this.save(e)}
          validate={(formData: GeneralContractorJSON, errors: FormValidation): FormValidation => validateGeneralContractor(formData, errors)}
          transformErrors={(errors: Array<AjvError>): Array<AjvError> => this.transformErrors(errors)}
        />
      </Page>
    );
  }
}
