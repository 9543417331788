import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'mobx-react';
import 'semantic-ui-css/semantic.min.css';
import { Auth } from './services/AuthService';
import { Alert } from './services/AlertService';
import { Cookie } from './services/CookieService';
import { Payment } from './services/PaymentService';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import { ErrorBoundary } from './components/ErrorBoundary';
import LogService from './services/LogService';
import { FeatureFlag } from './services/FeatureFlagService';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY as string);
ReactDOM.render(
  <Provider auth={Auth} alert={Alert} payment={Payment} cookie={Cookie} featureFlag={FeatureFlag}>
    <React.StrictMode>
      <Elements stripe={stripePromise}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Elements>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

window.addEventListener('error', (event: ErrorEvent) => {
  const { error } = event;
  LogService.error(error, error.message);
});

window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
  event.preventDefault();
  LogService.error(event.reason, {});
});
