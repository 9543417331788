import React from 'react';
import { observer, inject } from 'mobx-react';
import { Loader } from 'semantic-ui-react';
import ProtectedRoute, { ProtectedRouteProps } from './ProtectedRoute';
import { RouteProps } from 'react-router-dom';
import SubscriptionRequiredPage from '../pages/SubscriptionRequiredPage';
import PaymentService, { Subscription } from '../services/PaymentService';
import PDFRender from '../pdf-templates/components/PDFRender';

interface PDFSubscribeRouteProps extends ProtectedRouteProps {
  payment?: PaymentService;
}

interface PDFSubscribeRouteState {
  loading: boolean;
  subscriptions: Array<Subscription> | undefined;
}


@inject('auth')
@inject('payment')
@observer
export default class PDFSubscribeRoute extends React.Component<PDFSubscribeRouteProps, PDFSubscribeRouteState> {

  state: PDFSubscribeRouteState = {
    loading: false,
    subscriptions: undefined
  }

  async componentDidMount(): Promise<void> {
    try {
      this.setState({ loading: true });
      const subscriptions = await this.props.payment?.getSubscriptions();
      this.setState({ loading: false, subscriptions });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render(): React.ReactNode {
    const { loading, subscriptions } = this.state;
    const { auth } = this.props;
    // If auth isn't initialized or the component is still loading, show a spinner
    if (!auth?.initialized || loading) {
      return <Loader active={ true }>
        <div>Generating PDF Document</div>
      </Loader>;
    }

    console.log('Subscriptions: ', subscriptions);
    console.log('state: ', this.state);
    console.log('props: ', this.props);

    const content = subscriptions && subscriptions.length > 0 ? PDFRender :
      (props: RouteProps): JSX.Element => {
        return <SubscriptionRequiredPage { ...{ ...props }}/>;
      };

    const props = {
      ...this.props,
      component: content
    };

    return <ProtectedRoute { ...props as ProtectedRouteProps }/>;
  }
}
