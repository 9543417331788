import { observable, action } from 'mobx';

export class AlertService {

  @observable alerts: AlertProps[];

  constructor() {
    this.alerts = [];
  }

  @action
  public  createAlert({ message, title, type }: AlertProps): void {
    const id = Math.random().toString();
    const alert: AlertProps = { message, title, type, id };
    this.alerts.push(alert);
    setTimeout(() => {
      this.dismissAlert(id);
    }, 10000);
  }

  @action
  public dismissAlert(id: string|undefined): void {
    if (id) {
      this.alerts.splice(this.alerts.findIndex(alert => alert.id === id), 1);
    }
  }
}

type AlertType = 'info' | 'warning' | 'success' | 'error';

export interface AlertProps {
  message: string;
  title: string;
  type: AlertType;
  id?: string;
}

export const Alert = new AlertService();
