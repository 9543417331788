import React, { FunctionComponent } from 'react';
import { Container, Divider, Grid, Icon, Image, List, Segment } from 'semantic-ui-react';
import { COPYRIGHTS, SALES_EMAIL, SALES_PHONE_NUMBER } from '../common/Constants';
import { Link } from 'react-router-dom';

export const Footer: FunctionComponent = () =>
  <Segment vertical style={{ margin: '2em 0em 0em', padding: '1.2em 0em', backgroundColor: '#2D3B4C' }}>
    <Container textAlign='center'>
      <Grid divided inverted stackable>
        <Grid.Column width={4}>
          <List link inverted>
            <List.Item as='a' href='https://www.facebook.com/PAYearned' target="_blank"><Icon name='facebook'/> Facebook </List.Item>
            <List.Item as='a' href='https://www.youtube.com/channel/UCeGfCeEkKuMKhyl6xfS_q8w' target="_blank"><Icon name='youtube'/> YouTube </List.Item>
            <List.Item as='a' href='https://www.linkedin.com/company/payearned' target="_blank"><Icon name='linkedin'/> Linkedin</List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={8}>
          <Image centered size='small' src='/img/PAYearned-logo-long.png' />
          <List horizontal inverted divided link size='small'>
            {/*<List.Item as='a' href='/contact'>*/}
            {/*Contact Us*/}
            <List.Item as={Link} to='/terms'>
              Terms of Service
            </List.Item>
            <List.Item as={Link} to='/privacy'>
              Privacy Policy
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={4}>
          <List>
            <List.Item style={{ color: '#A3A5A8' }}>
              {SALES_PHONE_NUMBER}
            </List.Item>
            <List.Item>
              <a href={'mailto: ' + SALES_EMAIL}>{SALES_EMAIL}</a>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid>
      <Divider inverted section />
      <List>
        <List.Item style={{ color: '#A3A5A8' }}>
          {COPYRIGHTS}
        </List.Item>
      </List>
    </Container>
  </Segment>;

