import axios from 'axios';
import { Auth } from './AuthService';

const BASE_URL = process.env.REACT_APP_API_SERVICE_URL;

// Feature names, needs to be maintained when adding or removing features
export const MAINTENANCE_MODE_FEATURE = 'maintenance_mode';
export const PAGINATION_FEATURE = 'pagination_feature';

export default class FeatureFlagService {

  async getIfFeatureEnabled(feature: string): Promise<boolean> {
    let params = undefined;
    let userName = undefined;

    if (typeof Auth !== 'undefined') {
      userName = Auth.user?.email;
    }

    if (userName) {
      params = {
        user: userName
      };
    }
    const { data } = await axios({
      method: 'GET',
      baseURL: BASE_URL + '/feature-flags/' + feature,
      params: params
    });
    return data.enabled;
  }
}

export const FeatureFlag = new FeatureFlagService();
