import React from 'react';
import {
  Document,
  Page as PDFPage,
  Text,
  View,
  StyleSheet,
  Font
} from '@react-pdf/renderer';
import { ApplicationJSON } from '../schemas/applications/ApplicationJSON';
import ActiveApplication from '../schemas/applications/ActiveApplication';
import { PDFHeader } from './components/PDFHeader';
import { ProjectJSON } from '../schemas/projects/ProjectJSON';
import filterApplicationChangeOrders from './FilterApplicationChangeOrders';
import { BrandFooter } from './components/BrandFooter';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/fonts/roboto/Roboto-Regular.ttf', fontStyle: 'normal' },
    { src: '/fonts/roboto/Roboto-Italic.ttf', fontStyle: 'italic' },
    { src: '/fonts/roboto/Roboto-Thin.ttf', fontStyle: 'normal', fontWeight: 'thin' },
    { src: '/fonts/roboto/Roboto-ThinItalic.ttf', fontStyle: 'italic', fontWeight: 'thin' },
    { src: '/fonts/roboto/Roboto-Light.ttf', fontStyle: 'normal', fontWeight: 'light' },
    { src: '/fonts/roboto/Roboto-LightItalic.ttf', fontStyle: 'italic', fontWeight: 'light' },
    { src: '/fonts/roboto/Roboto-Light.ttf', fontStyle: 'normal', fontWeight: 'light' },
    { src: '/fonts/roboto/Roboto-LightItalic.ttf', fontStyle: 'italic', fontWeight: 'light' },
    { src: '/fonts/roboto/Roboto-Medium.ttf', fontStyle: 'normal', fontWeight: 'medium' },
    { src: '/fonts/roboto/Roboto-MediumItalic.ttf', fontStyle: 'italic', fontWeight: 'medium' },
    { src: '/fonts/roboto/Roboto-Bold.ttf', fontStyle: 'normal', fontWeight: 'bold' },
    { src: '/fonts/roboto/Roboto-BoldItalic.ttf', fontStyle: 'italic', fontWeight: 'bold' },
    { src: '/fonts/roboto/Roboto-Black.ttf', fontStyle: 'normal', fontWeight: 'heavy' },
    { src: '/fonts/roboto/Roboto-BlackItalic.ttf', fontStyle: 'italic', fontWeight: 'heavy' },
  ]
});
Font.registerHyphenationCallback(word => [ word ]);

const styles = StyleSheet.create({
  downloadButton: {
    color: 'white'
  },
  page: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    fontFamily: 'Roboto',
  },
  main: {
    margin: 31,
    width: '10in',
    height: '7.5in'
  },
  leftColumn: {
    position: 'absolute',
    top: '2in',
    left: 7,
    width: '5.25in',
    fontSize: 12,
  },
  rightColumn: {
    position: 'absolute',
    top: '1in',
    right: 0,
    width: '3.75in',
    fontSize: 12,
  },
  contactDataLeft: {
    position: 'absolute',
    top: 0,
    right: 0,
    maxWidth: '2in',
  },
  contactDataRight: {
    position: 'absolute',
    top: 21,
    right: 0,
    maxWidth: '2.75in'
  }
});

export function ApplicationPDF(applicationJSON: ApplicationJSON, projectJSON: ProjectJSON, logo?: { url: string; width: number; height: number } | undefined): React.ReactElement {
  const application = new ActiveApplication(applicationJSON);

  // Filter change orders
  filterApplicationChangeOrders(application);

  // Format currencies
  const originalApplicationdate = application!.applicationDate as string;
  const applicationDate = originalApplicationdate.substr(5,2) + '/' + originalApplicationdate.substr(8,2) + '/' + originalApplicationdate.substr(0,4);
  const originalPeriodTo = application!.periodTo as string;
  const periodTo = originalPeriodTo.substr(5,2) + '/' + originalPeriodTo.substr(8,2) + '/' + originalPeriodTo.substr(0,4);
  const originalContractAmount = application.originalContractAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  const changeOrders = application.netApprovedChangeOrders.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  const totalAdjusted = application.totalAdjustedContractAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  const completedAndStored = application.grandTotalCompletedAndStoredToDate.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  const retainage = application.grandTotalRetained.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  const totalEarnedLessRetainage = application.grandTotalEarnedLessRetainage.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  const lessPreviousApplications = application.previousApplicationForPayment.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  const currentPaymentDue = application.currentPaymentDue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  const balanceToFinish = application.grandTotalBalanceToFinishIncludingRetainage.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  return (
    <Document>
      <PDFPage
        size="LETTER"
        orientation="landscape"
        style={styles.page}
      >
        <View style={styles.main}>
          {/* Header Box */}
          {PDFHeader(applicationJSON, projectJSON, 'application', undefined, logo)}

          {/* Left Column */}
          <View style={styles.leftColumn}>
            {/* General Contractor Contact Info */}

            {/* Top Section above financials */}
            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: 60 }}>
                <Text style={{ fontSize: '18', fontWeight: 'bold' }}>GENERAL CONTRACTOR</Text>
                <Text style={{ fontWeight: 'bold' }}>Name</Text>
                <Text style={{ fontWeight: 'bold' }}>Address 1</Text>
                <Text style={{ fontWeight: 'bold' }}>Address 2</Text>
                <Text style={{ fontWeight: 'bold' }}>City, State</Text>
                <Text style={{ fontWeight: 'bold' }}>Zip</Text>
                <Text style={{ fontWeight: 'bold' }}>Phone</Text>
              </View>
              <View style={{ width: '50%' }}>
                <Text style={{ fontSize: '18', fontWeight: 'bold' }}> </Text>
                <Text>{projectJSON.generalContractor?.name} </Text>
                <Text>{projectJSON.generalContractor?.addressLine1} </Text>
                <Text>{projectJSON.generalContractor?.addressLine2} </Text>
                <Text>{projectJSON.generalContractor?.city}, {projectJSON.generalContractor?.state}</Text>
                <Text>{projectJSON.generalContractor?.zip} </Text>
                <Text>{projectJSON.generalContractor?.phoneNumber} </Text>
              </View>
              <View>
                <Text style={{ fontSize: '18', fontWeight: 'bold' }}>PROJECT ADDRESS</Text>
                <Text> </Text>
                <Text>{projectJSON?.addressLine1} </Text>
                <Text>{projectJSON?.addressLine2} </Text>
                <Text>{projectJSON?.city}, {projectJSON.generalContractor?.state}</Text>
                <Text>{projectJSON?.zip} </Text>
                <Text> </Text>
              </View>
            </View>

            {/* Total Contracted Amounts */}
            <View style={{ top: 10 }}>
              <Text style={{ fontWeight: 'bold', fontSize: 14 }}>1. Original Subcontract Sum</Text>
              <Text style={{ fontStyle: 'italic', fontSize: 10, left: 17 }}>Application for payment is supported by the</Text>
              <Text style={{ fontStyle: 'italic', fontSize: 10, left: 17 }}>attached Continuation Sheet</Text>
              <View style={{ position: 'absolute', top: 0, right: 0, fontWeight: 'bold', fontSize: 14 }}>
                <Text>{originalContractAmount}</Text>
              </View>
            </View>
            <View style={{ top: 12 }}>
              <Text style={{ fontWeight: 'bold', fontSize: 14 }}>2. Net Change from Change Order(s)</Text>
              <Text style={{ fontStyle: 'italic', fontSize: 10, left: 17 }}>See net change order detail on Change Order Sheet</Text>
              <View style={{ position: 'absolute', top: 0, right: 0, fontWeight: 'bold', fontSize: 14 }}>
                <Text>{changeOrders}</Text>
              </View>
            </View>

            {/* Table with Calculations */}
            <View style={{ top: 22, left: -7, display: 'table', width: '102.9%', borderTopWidth: 2, borderLeftWidth: 2 }}>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '70%', borderBottomWidth: 2 }}>
                  <Text style={{ marginTop: 2, marginBottom: 2, left: 5, fontSize: 14, fontWeight: 'bold' }}>3. Subcontract Sum to Date</Text>
                </View>
                <View style={{ width: '30%', borderBottomWidth: 2, borderRightWidth: 2, textAlign: 'right' }}>
                  <Text style={{ marginTop: 2, marginBottom: 2, right: 2, fontSize: 14, fontWeight: 'bold' }}>{totalAdjusted}</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '70%', borderBottomWidth: 2 }}>
                  <Text style={{ marginTop: 2, marginBottom: 2, left: 5, fontSize: 11 }}>4. Total Completed & Stored to Date</Text>
                  <Text style={{ marginTop: 2, marginBottom: 2, left: 5, fontSize: 11 }}>5. Retainage to Date</Text>
                </View>
                <View style={{ width: '30%', borderBottomWidth: 2, borderRightWidth: 2, textAlign: 'right' }}>
                  <Text style={{ marginTop: 2, marginBottom: 2, right: 2 }}>{completedAndStored}</Text>
                  <Text style={{ marginTop: 2, marginBottom: 2, right: 2 }}>{retainage}</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '70%', borderBottomWidth: 2 }}>
                  <Text style={{ marginTop: 2, marginBottom: 2, left: 5, fontWeight: 'bold' }}>6. Total Earned Less Retainage</Text>
                </View>
                <View style={{ width: '30%', borderBottomWidth: 2, borderRightWidth: 2, textAlign: 'right' }}>
                  <Text style={{ marginTop: 2, marginBottom: 2, right: 2, fontWeight: 'bold' }}>{totalEarnedLessRetainage}</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '70%', borderBottomWidth: 2 }}>
                  <Text style={{ marginTop: 2, marginBottom: 2, left: 5, fontSize: 11 }}>7. Less Previous Applications & Certificates for Payment</Text>
                  <Text style={{ marginTop: 2, marginBottom: 2, left: 19, fontSize: 10, fontStyle: 'italic' }}>Excluding Retainage</Text>
                  <View style={{ backgroundColor: '#ededed' }}>
                    <Text style={{ marginTop: 2, marginBottom: 2, left: 5, fontSize: 14, fontWeight: 'bold' }}>8. Current Payment Due</Text>
                    <Text style={{ marginTop: 2, marginBottom: 2, left: 19, fontSize: 10, fontStyle: 'italic' }}>Excluding Retainage</Text>
                  </View>
                  {
                    application.status !== 'Final'
                      ? (
                        <Text style={{ marginTop: 2, marginBottom: 2, left: 5, fontSize: 10 }}> </Text>
                      ) : (
                        <View>
                          <Text style={{ left: 5, fontSize: 7 }}> </Text>
                          <View style={{ backgroundColor: '#ededed' }}>
                            <Text style={{ left: 5, fontSize: 14, fontWeight: 'bold' }}>8a. Retainage Payment Due</Text>
                          </View>
                        </View>
                      )
                  }
                </View>
                <View style={{ width: '30%', borderRightWidth: 2, borderBottomWidth: 2, textAlign: 'right' }}>
                  <Text style={{ marginTop: 2, marginBottom: 2, right: 2 }}>{lessPreviousApplications}</Text>
                  <Text style={{ marginTop: 2, marginBottom: 2, left: 5, fontSize: 10 }}> </Text>
                  <View style={{ backgroundColor: '#ededed' }}>
                    <Text style={{ marginTop: 2, marginBottom: 2, right: 2, fontSize: 14, fontWeight: 'bold' }}>{currentPaymentDue}</Text>
                    <Text style={{ marginTop: 2, marginBottom: 2, left: 5, fontSize: 10 }}> </Text>
                  </View>
                  {
                    application.status !== 'Final'
                      ? (
                        <Text style={{ marginTop: 2, marginBottom: 2, left: 5, fontSize: 10 }}> </Text>
                      ): (
                        <View>
                          <Text style={{ left: 5, fontSize: 7 }}> </Text>
                          <View style={{ backgroundColor: '#ededed' }}>
                            <Text style={{ right: 2, fontSize: 14, fontWeight: 'bold' }}>{retainage}</Text>
                          </View>
                        </View>
                      )
                  }
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '70%', borderBottomWidth: 2 }}>
                  <Text style={{ marginTop: 2, marginBottom: 2, left: 5, fontSize: 14, fontWeight: 'bold' }}>9. Balance to Finish</Text>
                  <Text style={{ marginTop: 2, marginBottom: 2, left: 19, fontSize: 10, fontStyle: 'italic' }}>Including Total Retainage</Text>
                </View>
                <View style={{ width: '30%', borderRightWidth: 2, borderBottomWidth: 2, textAlign: 'right' }}>
                  <Text style={{ marginTop: 2, marginBottom: 2, right: 2, fontSize: 14, fontWeight: 'bold' }}>{balanceToFinish}</Text>
                  <Text style={{ marginTop: 2, marginBottom: 2, left: 5, fontSize: 10 }}> </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.rightColumn}>
            <Text style={{ fontSize: '18', fontWeight: 'bold' }}>SUBCONTRACTOR</Text>

            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: 80 }}>
                <Text style={{ fontWeight: 'bold' }}>Name</Text>
                <Text style={{ fontWeight: 'bold' }}>Address 1</Text>
                <Text style={{ fontWeight: 'bold' }}>Address 2</Text>
                <Text style={{ fontWeight: 'bold' }}>City, State</Text>
                <Text style={{ fontWeight: 'bold' }}>Zip</Text>
                <Text style={{ fontWeight: 'bold' }}>Phone</Text>
              </View>
              <View>
                <Text>{projectJSON.subcontractor?.name} </Text>
                <Text>{projectJSON.subcontractor?.addressLine1} </Text>
                <Text>{projectJSON.subcontractor?.addressLine2} </Text>
                <Text>{projectJSON.subcontractor?.city ? `${projectJSON.subcontractor?.city}, ` : ''}{projectJSON.subcontractor?.state} </Text>
                <Text>{projectJSON.subcontractor?.zip} </Text>
                <Text>{projectJSON.subcontractor?.phoneNumber} </Text>
              </View>
            </View>
            <Text style={{ fontSize: 4 }}> </Text>
            <Text style={{ fontSize: 10, fontStyle: 'italic' }}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              The undersigned Subcontractor certifies that to the best of the Subcontractor's knowledge,
              information and belief the work covered by this application for Payment has been completed
              in accordance with the Subcontract Documents, that all amounts have been paid by the contractor
              for Work which previous certificates for payment were issued and payements received from the
              contractor and that the current payment shown herein is now due.
            </Text>
            <Text style={{ fontSize: 4 }}> </Text>
            <Text>Date: {applicationDate}</Text>
            <View style={{ height: '0.4in', fontWeight: 'bold' }}><Text>Subcontractor Signature:</Text></View>
            <View>
              <Text>
                _________________________________________________________
              </Text>
            </View>
            <View>
              <Text>{projectJSON.subcontractor?.contactName} </Text>
            </View>
            <Text style={{ fontSize: 10 }}> </Text>
            <View>
              <Text>State of: </Text>
              <Text style={{ position: 'absolute', top: 0, right: 0 }}>_______________________</Text>
            </View>
            <Text style={{ fontSize: 10 }}> </Text>
            <View>
              <Text>County of: </Text>
              <Text style={{ position: 'absolute', top: 0, right: 0 }}>_______________________</Text>
            </View>
            <Text style={{ fontSize: 10 }}> </Text>
            <Text style={{ fontWeight: 'bold' }}>Subscribed and sworn to before me</Text>
            <Text style={{ fontSize: 10 }}> </Text>
            <Text>this _______ day of _______ 20______</Text>
            <View style={{ height: '0.4in', fontWeight: 'bold' }}><Text>Notary Public:</Text></View>
            <View>
              <Text>
                _________________________________________________________
              </Text>
            </View>
            <Text> </Text>
            <View>
              <Text style={{ fontWeight: 'bold' }}>My commission expires: </Text>
              <Text style={{ position: 'absolute', top: 0, right: 0 }}>_______________________</Text>
            </View>
          </View>
          {BrandFooter({ bottom: -15, left: 0 })}
        </View>
      </PDFPage>
    </Document>
  );
}
