import React from 'react';
import ActiveProject from '../../../../schemas/projects/ActiveProject';
import ProjectLineItemAudit from './ProjectLineItemAudit';
import ApplicationSummary from './ApplicationSummary';

interface ProjectLinesProps {
  project: ActiveProject | undefined;
}

export default class ProjectSummary extends React.Component<ProjectLinesProps> {
  render(): React.ReactNode {
    if (!this.props.project?.projectLines) { return ''; }

    if (this.props.project.excessShortfall !== 0) {
      return <ProjectLineItemAudit project={this.props.project} />;
    }

    return <ApplicationSummary project={this.props.project} />;
  }
}
