import axios from 'axios';
import { Auth } from './AuthService';

const BASE_URL = process.env.REACT_APP_API_SERVICE_URL;

export interface BankAccountObj {
  id: string;
  account_holder_name: string;
  account_holder_type: string;
  bank_name: string;
  currency: string;
  customer: string;
  fingerprint: string;
  last4: string;
  routing_number: string;
  status: string;
}

export interface Card {
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
}

export interface PaymentMethod {
  id: string;
  card: Card;
}

export interface Invoice {
  id?: string;
  amount_paid?: number;
  created?: number;
  customer?: string;
  customer_email?: string;
  hosted_invoice_url: string;
  invoice_pdf?: string;
  lines?: LineData;
  subtotal: number;
  total: number;
}

export interface LineData {
  data?: Array<LineItem>;
}

export interface LineItem {
  id: string;
  amount: number;
  currency: string;
  description: string;
  period?: PayPeriod;
  plan?: Plan;
  price?: Price;
}

export interface PayPeriod {
  end?: number;
  start?: number;
}

export interface Plan {
  id: string | number;
  active: boolean;
  amount: number;
  created: number;
  interval: string;
  interval_count: string;
  nickname: string;
}

export interface Receipt {
  id: string;
  amount: number;
  balance_transaction: string;
  created: number;
  customer: string;
  invoice: string;
  payment_intent: string;
  payment_method: string;
  receipt_email: string;
  receipt_url: string;
}

export interface StripeCustomer {
  id: string;
  default_source: string;
  email: string;
  invoice_settings: InvoiceSettings;
  name: string;
  sources: AccountData;
  subscriptions?: Array<Subscription> | undefined;
}

export interface Subscription {
  id: string;
  active: boolean;
  collection_method: string;
  current_period_end: number;
  current_period_start: number;
  default_payment_method: string;
  latest_invoice?: string;
  plan: Plan;
  trial_end: number;
  trial_start: number;
  status: string;
  cancel_at: number;
}

interface AccountData {
  data: Array<BankAccountObj>;
}

interface InvoiceSettings {
  default_payment_method: string;
}

interface Price {
  id: string;
  created: number;
  currency: string;
  nickname: string;
}


export default class PaymentService {

  public bankAccounts: Array<BankAccountObj> = [] // TODO Remove when create BankAccountPage

  async getStripeCustomer(stripeCustomerId: string): Promise<StripeCustomer> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    try {
      const { data } = await axios({
        method: 'GET',
        baseURL: BASE_URL + '/stripe/customers/' + stripeCustomerId,
        headers: {
          Authorization: `Bearer ${Auth.user.accessToken}`
        },
        data: {}
      });
      return data;
    } catch (e) {
      console.log(`Error retrieving stripeCustomer for stripeCustomerId=${stripeCustomerId} with message=${e.message}`);
      throw e;
    }
  }

  async createSetupIntent(): Promise<string> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    try {
      const { data } = await axios({
        method: 'POST',
        baseURL: BASE_URL + '/stripe/setup-intents',
        headers: {
          Authorization: `Bearer ${Auth.user.accessToken}`
        },
        data: {}
      });
      return data.client_secret as string;
    } catch (e) {
      console.log(`Error creating setup intent for userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }

  async getPaymentPlans(): Promise<Array<Plan>> {
    try {
      const { data } = await axios({
        method: 'GET',
        baseURL: BASE_URL + '/stripe/plans',
        data: {}
      });
      return data.data;
    } catch (e) {
      console.log(`Error getting PaymentPlans for userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }

  async getPaymentMethod(paymentMethodId: string): Promise<PaymentMethod> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    try {
      const { data } = await axios({
        method: 'GET',
        baseURL: BASE_URL + '/stripe/payment-methods/' + paymentMethodId,
        headers: {
          Authorization: `Bearer ${Auth.user.accessToken}`
        },
        data: {}
      });
      return data;

    } catch (e) {
      console.log(`Error retrieving PaymentMethod for userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }

  async getPaymentMethods(stripeCustomerId: string): Promise<Array<PaymentMethod>> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }

    try {
      const { data } = await axios({
        method: 'GET',
        baseURL: `${BASE_URL}/stripe/payment-methods?customer=${stripeCustomerId}&type=card`,
        headers: {
          Authorization: `Bearer ${Auth.user.accessToken}`
        },
        data: {
          type: 'card'
        }
      });
      return data.data;

    } catch (e) {
      console.log(`Error retrieving PaymentMethods for userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }

  async deletePaymentMethod(paymentMethod: PaymentMethod): Promise<PaymentMethod> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    try {
      const { data } = await axios({
        method: 'DELETE',
        baseURL: BASE_URL + '/stripe/payment-methods/' + paymentMethod.id,
        headers: {
          Authorization: `Bearer ${Auth.user.accessToken}`
        }
      });
      return data;
    } catch (e) {
      console.log(`Error deleting PaymentMethod for userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }

  async subscribeToPlan(stripeCustomer: StripeCustomer, plan: Plan, trialDays: number): Promise<Subscription> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    try {
      const { data } = await axios({
        method: 'POST',
        baseURL: BASE_URL + '/stripe/subscriptions',
        headers: {
          Authorization: `Bearer ${Auth.user.accessToken}`
        },
        data: {
          items: [ { price: plan.id } ],
          // eslint-disable-next-line @typescript-eslint/camelcase
          trial_period_days: trialDays,
          // eslint-disable-next-line @typescript-eslint/camelcase
          default_payment_method: stripeCustomer.invoice_settings.default_payment_method,
          metadata: { 'referral': Auth.user.referral }
        }
      });
      return data;
    } catch (e) {
      console.log(`Error subscribing to Plan for userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }

  async getSubscriptions(): Promise<Array<Subscription>> {
    if (!Auth.user?.accessToken || !Auth.user?.id) {
      throw new Error('Data service unavailable, user not logged in');
    }
    try {
      const { data } = await axios({
        method: 'GET',
        baseURL: BASE_URL + '/stripe/subscriptions/',
        params: {
          userId: Auth.user?.id
        },
        headers: {
          Authorization: `Bearer ${Auth.user.accessToken}`
        }
      });
      return data.data;
    } catch (e) {
      console.log(`Error retrieving subscriptions for userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }

  async getTrialPeriodDays(): Promise<number> {
    if (!Auth.user?.accessToken || !Auth.user?.id) {
      throw new Error('Data service unavailable, user not logged in');
    }
    try {
      const { data } = await axios({
        method: 'GET',
        baseURL: BASE_URL + '/stripe/subscriptions/',
        params: {
          userId: Auth.user?.id,
          status: 'all',
          limit: 100,
        },
        headers: {
          Authorization: `Bearer ${Auth.user.accessToken}`
        }
      });
      const canceledSubscriptions = data.data;
      if (canceledSubscriptions.length === 0) {
        return 30;
      }
      const oldestSubscriptionDate = canceledSubscriptions
        .map((subscription: any) => subscription.created*1000)
        .reduce((oldest: number, current: number) => (current < oldest) ? current : oldest);

      const daysSinceOldestSubscription = (new Date().getTime() - oldestSubscriptionDate) / (1000*60*60*24);
      if (daysSinceOldestSubscription > 30) return 0;
      return 30 - Math.floor(daysSinceOldestSubscription);
    } catch (e) {
      console.log(`Error retrieving subscriptions for userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }

  async setPaymentMethodAsDefault(paymentMethodId: string): Promise<string> {
    if (!Auth.user?.accessToken || !Auth.user?.id) {
      throw new Error('Data service unavailable, user not logged in');
    }
    try {
      const { data } = await axios({
        method: 'PATCH',
        baseURL: BASE_URL + '/stripe/customers/' + Auth.user?.id,
        data: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          invoice_settings: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            default_payment_method: paymentMethodId
          }
        },
        headers: {
          Authorization: `Bearer ${Auth.user.accessToken}`
        },
      });
      return data.invoice_settings.default_payment_method;

    } catch (e) {
      console.log(`Error setting PaymentMethod as default userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }

  async getAllInvoices(): Promise<Array<Invoice>> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    try {
      const { data } = await axios({
        method: 'GET',
        baseURL: BASE_URL + '/stripe/invoices/',
        headers: {
          Authorization: `Bearer ${Auth.user.accessToken}`
        }
      });
      return data.data;
    } catch (e) {
      console.log(`Error retrieving all invoices for userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }

  async getInvoice(invoiceId: string): Promise<Invoice> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    try {
      const { data } = await axios({
        method: 'GET',
        baseURL: BASE_URL + '/stripe/invoices/' + invoiceId,
        headers: {
          Authorization: `Bearer ${Auth.user.accessToken}`
        }
      });
      return data;
    } catch (e) {
      console.log(`Error retrieving invoice invoiceId=${invoiceId} for userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }

  async deleteSubscription(subscriptionId: string): Promise<Subscription> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    try {
      const { data } = await axios({
        method: 'DELETE',
        baseURL: BASE_URL + '/stripe/subscriptions/' + subscriptionId,
        headers: {
          Authorization: `Bearer ${Auth.user.accessToken}`
        },
        data: {
          prorate: true,
        }
      });
      return data;
    } catch (e) {
      console.log(`Error deleting Subscription for userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }

  async disableAutomaticBilling(subscriptionId: string): Promise<Subscription> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    try {
      const { data } = await axios({
        method: 'PATCH',
        baseURL: BASE_URL + '/stripe/subscriptions/' + subscriptionId,
        headers: {
          Authorization: `Bearer ${Auth.user.accessToken}`
        },
        data: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          cancel_at_period_end: true
        }
      });
      return data;
    } catch (e) {
      console.log(`Error deleting Subscription for userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }

  async reEnableAutomaticBilling(subscriptionId: string): Promise<Subscription> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    try {
      const { data } = await axios({
        method: 'PATCH',
        baseURL: BASE_URL + '/stripe/subscriptions/' + subscriptionId,
        headers: {
          Authorization: `Bearer ${Auth.user.accessToken}`
        },
        data: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          cancel_at_period_end: false
        }
      });
      return data;
    } catch (e) {
      console.log(`Error deleting Subscription for userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }

  async changeSubscription(
    subscriptionId: string,
    stripeCustomer: StripeCustomer,
    newPlan: Plan,
    trialDays: number
  ): Promise<Subscription> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    try {
      await this.deleteSubscription(subscriptionId);
      return this.subscribeToPlan(stripeCustomer, newPlan, trialDays);
    } catch (e) {
      console.log(`Error upgrading Subscription for userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }

  async attachBankAccountToCustomer(bankToken: string): Promise<string> {
    if (!Auth.user?.accessToken || !Auth.user?.id) {
      throw new Error('Data service unavailable, user not logged in');
    }
    try {
      const { data } = await axios({
        method: 'PATCH',
        baseURL: BASE_URL + '/stripe/customers/' + Auth.user?.id,
        headers: {
          Authorization: `Bearer ${Auth.user.accessToken}`
        },
        data: {
          source: bankToken
        }
      });
      return data.default_source;
    } catch (e) {
      console.log(`Error attaching BankAccount to Customer for userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }

  async verifyBankAccount(bankAccountId: string, smallDeposits: Array<number>): Promise<BankAccountObj> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    try {
      const { data } = await axios({
        method: 'POST',
        baseURL: BASE_URL + '/stripe/bank-verification',
        headers: {
          Authorization: `Bearer ${Auth.user.accessToken}`
        },
        data: {
          id: bankAccountId as string,
          amounts: smallDeposits as Array<number>
        },
      },
      );
      return data;
    } catch (e) {
      console.log(`Error verifying BankAccount for userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }

  async getAllReceipts(): Promise<Array<Receipt>> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    try {
      const { data } = await axios({
        method: 'GET',
        baseURL: BASE_URL + '/stripe/charges/',
        headers: {
          Authorization: `Bearer ${Auth.user.accessToken}`
        }
      });
      return data.data;
    } catch (e) {
      console.log(`Error retrieving all receipts for userId=${Auth.user?.id} with message=${e.message}`);
      throw e;
    }
  }
}

export const Payment = new PaymentService();
