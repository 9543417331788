import React from 'react';
import { Button, Modal, ModalContent, ModalHeader } from 'semantic-ui-react';

interface PaymentModalProps {
  showAddCardModal: boolean;
  paymentMethodsQuantity: number | undefined;
  onCloseAddCardModal: () => void;
  onOpenAddCardModal: () => void;
}

export default class PaymentModal extends React.Component<PaymentModalProps>{

  async closeAddCardModal(): Promise<void> {
    await this.props.onCloseAddCardModal();
  }

  async openAddCardModal(): Promise<void>{
    await this.props.onOpenAddCardModal();
  }

  render(): React.ReactNode {
    const { showAddCardModal, paymentMethodsQuantity } = this.props;
    return (
      <Modal closeIcon onClose={ (): Promise<void> => this.closeAddCardModal() }
        open={ showAddCardModal }
        trigger={
          paymentMethodsQuantity !== 0 ?   <Button fluid
            primary
            onClick={ (): Promise<void> => this.openAddCardModal()}
          >
                 Add Payment Method
          </Button> : null
        }>
        <ModalHeader>Add Credit Card</ModalHeader>
        <ModalContent>
          {this.props.children}
        </ModalContent>
      </Modal>
    );
  }
}
