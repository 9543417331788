import React from 'react';
import Page from '../../components/Page';
import JSONSchemaForm from '@rjsf/semantic-ui';
import {
  ApplicationJSONSchema,
  validateApplication
} from '../../schemas/applications/ApplicationJSONSchema';
import { DataService } from '../../services/DataService';
import { RouteChildrenProps } from 'react-router-dom';
import Page404 from '../Page404';
import { Header, Loader } from 'semantic-ui-react';
import { FormValidation, IChangeEvent } from '@rjsf/core';
import { FieldTemplate } from '../../schemas/templates/FieldTemplate';
import CalculationSchemaForm, { UpdatedField } from '../../components/CalculationSchemaForm';
import { ApplicationJSON } from '../../schemas/applications/ApplicationJSON';
import { ApplicationUiSchema } from '../../schemas/applications/ApplicationUISchema';
import ActiveApplication from '../../schemas/applications/ActiveApplication';
import { SemanticShorthandCollection } from 'semantic-ui-react/dist/commonjs/generic';
import { BreadcrumbSectionProps } from 'semantic-ui-react/dist/commonjs/collections/Breadcrumb/BreadcrumbSection';
import { ProjectJSON } from '../../schemas/projects/ProjectJSON';

import './application.css';
import { ApplicationLineJSON } from '../../schemas/ApplicationLineJSONSchema';
import { JSONSchema7 } from 'json-schema';
import FirstApplication from './components/FirstApplication';

interface EditApplicationLocation extends Location {
  state: { project?: ProjectJSON };
}

interface EditApplicationProps extends RouteChildrenProps<{id: string}> {
  location: EditApplicationLocation;
}

interface EditApplicationState {
  loading: boolean;
  error: boolean;
  application?: ActiveApplication;
  firstApplication: boolean;
  schema?: any;
}

export default class EditApplication extends React.Component<EditApplicationProps, EditApplicationState> {
  state: EditApplicationState = {
    loading: true,
    error: false,
    firstApplication: false,
  }
  dataService = new DataService();
  form?: JSONSchemaForm<ApplicationJSON>;

  async componentDidMount(): Promise<void> {
    const application = new ActiveApplication(this.props.match?.params.id);
    await application.load();
    const error = application.error;
    const schema = JSON.parse(JSON.stringify(ApplicationJSONSchema));
    const applicationLinesTableTitle: string = schema.dependencies.periodTo.properties.originalApplicationLines.title;
    const changeOrdersTableTitle: string = schema.dependencies.periodTo.properties.changeOrderLines.title;

    // Update the project, if one was passed in
    if (!application.error && this.props.location.state?.project) {
      // Set the projectId and project
      application.projectId = this.props.location.state.project.id;
      application.project = this.props.location.state.project;

      // Increment the sequenceNumber if it's a new application
      if (!application.sequenceNumber) {
        application.sequenceNumber = application.project?.applications?.length
          ? Number(application.project.applications[application.project.applications.length-1].sequenceNumber)+1
          : 1;
      }

      // Build the application lines
      application.buildApplicationLines();
    }

    // If there are no change orders, remove it from the schema
    if (application.changeOrderLines.length === 0) {
      delete schema.dependencies.periodTo.properties.changeOrderLines;
    }

    // appending the table titles to display also application #
    schema.dependencies.periodTo.properties.originalApplicationLines.title = applicationLinesTableTitle + `\u00A0\u00A0for Application #${String(application.sequenceNumber)}`;

    if(typeof schema.dependencies.periodTo.properties.changeOrderLines !== 'undefined'){
      schema.dependencies.periodTo.properties.changeOrderLines.title = changeOrdersTableTitle + `\u00A0\u00A0for Application #${String(application.sequenceNumber)}`;
    }

    const loading = false;
    const firstApplication =
      !application?.project?.applications?.length
      || application.sequenceNumber === application.project.applications[0].sequenceNumber;
    this.setState({ loading, error, application, firstApplication, schema });
  }

  sequenceNumberChange(sequenceNumber: number): void {
    if (this.state.application) {
      const application = this.state.application;
      const schema = JSON.parse(JSON.stringify(this.state.schema));
      application.sequenceNumber = sequenceNumber;
      schema.dependencies.periodTo.properties.originalApplicationLines.title =
        schema.dependencies.periodTo.properties.originalApplicationLines.title
          .replace(/Application #(\d+)/, `Application #${sequenceNumber}`);
      this.setState({ application, schema });
    }
  }

  async save(e: IChangeEvent<ApplicationJSON>): Promise<void> {
    // Exit if we're already saving
    if (this.state.loading) { return; }

    // Show the loader
    this.setState({ loading: true });

    // Update the sequence number if it's a new application
    if (this.state.firstApplication) { e.formData.sequenceNumber = this.state.application?.sequenceNumber; }

    // Save the application
    await this.state.application!.save(e.formData);

    // Go back to the project
    this.props.history.push(`/projects/${this.state.application!.projectId}`);
  }

  calculate(formData: ApplicationJSON, updatedField?: UpdatedField | null): ApplicationJSON {
    // See if an application line or change order line was updated
    if (updatedField && updatedField.path.match(/Lines/)) {
      let rowNumber: RegExpMatchArray | string | number | null = updatedField.path.match(/\d+/);
      if (rowNumber !== null) {
        rowNumber = Number(rowNumber[0]);
        const searchArray = (updatedField.path.match(/originalApplicationLines/)
          ? this.state.application?.originalApplicationLines
          : this.state.application?.changeOrderLines) as Array<ApplicationLineJSON>;
        const updatedRow = searchArray[rowNumber] as ApplicationLineJSON;
        const index = this.state.application?.applicationLines.indexOf(updatedRow);
        if (typeof index !== 'undefined' && formData.applicationLines) {
          const formDataArray = (updatedField.path.match(/originalApplicationLines/)
            ? formData?.originalApplicationLines
            : formData?.changeOrderLines) as Array<ApplicationLineJSON>;
          formData.applicationLines[index] = formDataArray[rowNumber];
          updatedField.path = updatedField.path
            .replace(/(originalApplication|changeOrder)Lines.\d+/, `applicationLines.${index}`);
        }
      }
    }
    const application = new ActiveApplication(formData);
    return application.calculate(updatedField);
  }

  render(): React.ReactNode {
    // Show the loader
    if (this.state.loading) { return <Loader active>Loading...</Loader>; }

    // Show the error page
    if (this.state.error) { return <Page404 {...this.props} />; }

    // If there is no project, show an error
    if (!this.state?.application?.project) {
      return (
        <Page pageTitle={'Invalid Request'}>
          { this.state?.application?.id === 'new'
            ? 'Cannot create an orphaned Application.  Applications must be created from a project.'
            : `Application ${this.state?.application?.id} is orphaned.  Please update the database to assign a Project ID`
          }
        </Page>
      );
    }

    const breadcrumb: SemanticShorthandCollection<BreadcrumbSectionProps> = [
      { key: 'home', href: '/', content: 'Your Projects', link: true },
      { key: 'project', href: `/projects/${this.state?.application?.projectId}`, content: 'Project Summary', link: true },
    ];
    if (this.props.match?.params.id) {
      breadcrumb.push({ key: 'edit', content: 'Application', active: true });
    } else {
      breadcrumb.push({ key: 'new', content: `Application #${this.state.application.sequenceNumber}`, active: true });
    }

    return (
      <Page pageTitle={this.props.match?.params.id ? 'Edit Application' : 'Create Application'} breadcrumb={breadcrumb}>
        <Header as='h2' className='edit-application-header'>{
          this.props.match?.params.id && this.props.match?.params.id !== 'new'
            ? 'Edit Application'
            : 'Create Application'
        } #{this.state.application.sequenceNumber} for {this.state.application.project.name}</Header>
        <FirstApplication
          sequenceNumber={this.state.application.sequenceNumber}
          firstApplication={this.state.firstApplication}
          onChange={(sequenceNumber) => this.sequenceNumberChange(sequenceNumber)}
        />
        <CalculationSchemaForm
          schema={this.state.schema as JSONSchema7}
          uiSchema={ApplicationUiSchema}
          FieldTemplate={FieldTemplate}
          formData={this.state.application}
          onCalculate={(formData: ApplicationJSON, updatedField?: UpdatedField | null): ApplicationJSON => this.calculate(formData, updatedField)}
          onSubmit={(e: IChangeEvent<ApplicationJSON>): Promise<void> => this.save(e)}
          validate={(formData: ApplicationJSON, errors: FormValidation): FormValidation => validateApplication(formData, errors)}
          saveButtonLabel={`Save Application #${this.state.application.sequenceNumber ? this.state.application.sequenceNumber : 1}`}
        />
      </Page>
    );
  }
}
