import {
  Image,
  Text,
  View,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import React from 'react';
import { ApplicationJSON } from '../../schemas/applications/ApplicationJSON';
import ActiveApplication from '../../schemas/applications/ActiveApplication';
import { ProjectJSON } from '../../schemas/projects/ProjectJSON';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/fonts/roboto/Roboto-Regular.ttf', fontStyle: 'normal' },
    { src: '/fonts/roboto/Roboto-Italic.ttf', fontStyle: 'italic' },
    { src: '/fonts/roboto/Roboto-Thin.ttf', fontStyle: 'normal', fontWeight: 'thin' },
    { src: '/fonts/roboto/Roboto-ThinItalic.ttf', fontStyle: 'italic', fontWeight: 'thin' },
    { src: '/fonts/roboto/Roboto-Light.ttf', fontStyle: 'normal', fontWeight: 'light' },
    { src: '/fonts/roboto/Roboto-LightItalic.ttf', fontStyle: 'italic', fontWeight: 'light' },
    { src: '/fonts/roboto/Roboto-Light.ttf', fontStyle: 'normal', fontWeight: 'light' },
    { src: '/fonts/roboto/Roboto-LightItalic.ttf', fontStyle: 'italic', fontWeight: 'light' },
    { src: '/fonts/roboto/Roboto-Medium.ttf', fontStyle: 'normal', fontWeight: 'medium' },
    { src: '/fonts/roboto/Roboto-MediumItalic.ttf', fontStyle: 'italic', fontWeight: 'medium' },
    { src: '/fonts/roboto/Roboto-Bold.ttf', fontStyle: 'normal', fontWeight: 'bold' },
    { src: '/fonts/roboto/Roboto-BoldItalic.ttf', fontStyle: 'italic', fontWeight: 'bold' },
    { src: '/fonts/roboto/Roboto-Black.ttf', fontStyle: 'normal', fontWeight: 'heavy' },
    { src: '/fonts/roboto/Roboto-BlackItalic.ttf', fontStyle: 'italic', fontWeight: 'heavy' },
  ]
});
Font.registerHyphenationCallback(word => [ word ]);

const styles = StyleSheet.create({
  page: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    fontFamily: 'Roboto',
  },
  headerRow: {
    flexDirection: 'row'
  },
  headerRowWithMargin: {
    flexDirection: 'row',
    marginTop: 10,
    padding: 3,
    width: '60%',
    border: '#0090c9',
    borderWidth: 3,
    fontSize: 15
  },
  header: {
    width: '100%',
    height: '0.9in',
    border: '#0090c9',
    borderWidth: 3,
    backgroundColor: '#ededed',
  },
  headerLeft: {
    width: '60%',
    height: '0.9in',
    border: '#0090c9',
    borderWidth: 3,
    backgroundColor: '#ededed',
  },
  headerRight: {
    marginLeft: 17,
    padding: 3,
    width: '3.82in',
    border: '#0090c9',
    borderWidth: 3,
    fontSize: 15,
  },
  headerRightData: {
    position: 'absolute',
    padding: 3,
    right: 0,
  },
  projectInfo: {
    width: 150
  },
  typeInfo: {
    marginLeft: 40,
    marginTop: 10,
    height: '65%',
    padding: 3,
    width: '12%',
    border: '#000000',
    borderWidth: 1,
    fontSize: '11',
    color: '#008000'
  },
  projectInfoData: {
    position: 'absolute',
    padding: 3,
  },
  titleContainer: {
    left: 60,
    top: 0,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '17',
  },
  logo: {
    position: 'absolute',
    top: -4,
    margin: 10,
    width: '1.4in',
    fontSize: '11',
  },
  formNumber: {
    right: 5,
    top: 5,
    position: 'absolute',
    fontWeight: 'heavy',
    fontSize: '25',
  },
  changeOrderFormNumber: {
    right: 5,
    top: 5,
    position: 'absolute',
    fontWeight: 'heavy',
    fontSize: '25',
    color: '#FF0000'
  },
});

const generatedBy = {
  application: StyleSheet.create({
    subtitle: {
      top: 4,
      fontSize: 10,
      right: 123,
      textAlign: 'right',
    },
    subtitleLogo: {
      width: 58,
      top: -6.5,
      left: '4.24in',
    },
  }),
  continuation: StyleSheet.create({
    subtitle: {
      top: 25,
      fontSize: 10,
      right: 115,
      textAlign: 'right',
    },
    subtitleLogo: {
      width: 50,
      top: 15.5,
      left: '4.43in',
    },
  }),
  changeOrder: StyleSheet.create({
    subtitle: {
      top: 25,
      fontSize: 10,
      right: 114,
      textAlign: 'right',
    },
    subtitleLogo: {
      width: 50,
      top: 15.5,
      left: '4.36in',
    },
  })
};

export function PDFHeader(
  applicationJSON: ApplicationJSON,
  projectJSON: ProjectJSON,
  pdfType: 'application' | 'continuation' | 'changeOrder',
  customStyles: any = {},
  logo?: { url: string; width: number; height: number } | undefined
): React.ReactElement {
  const application = new ActiveApplication(applicationJSON);
  const pageTitle =
    pdfType === 'application'
      ? (
        <View>
          <Text style={{ top: 5 }}>
            Application & Certificate
          </Text>
          <Text>
            for Payment
          </Text>
        </View>
      )
      : pdfType === 'continuation'
        ? (
          <Text style={{ top: 5 }}>Continuation Sheet</Text>
        )
        : (
          <Text style={{ top:5, color: '#FF0000' }}>Change Orders</Text>
        );
  const originalapplicationDate = application!.applicationDate as string;
  const applicationDate = originalapplicationDate.substr(5,2) + '/' + originalapplicationDate.substr(8,2) + '/' + originalapplicationDate.substr(0,4);
  const originalPeriodTo = application!.periodTo as string;
  const periodTo = originalPeriodTo.substr(5,2) + '/' + originalPeriodTo.substr(8,2) + '/' + originalPeriodTo.substr(0,4);
  if (logo) {
    delete styles.logo.height;
    let width = logo.width / logo.height * 0.65;
    if (width > 2.2) width = 2.2;
    styles.logo.width = `${width}in`;
  }

  return (
    <View style={customStyles}>
      {/* Header Box */}
      <View style={styles.headerRow}>
        <View style={styles.headerLeft}>
          <View style={styles.titleContainer}>
            {pageTitle}
            {
              logo
                ?
                <View>
                  <Text style={generatedBy[pdfType].subtitle}>Generated by</Text>
                  <Image style={generatedBy[pdfType].subtitleLogo} src='/img/PAYearned-logo-long.png' />
                </View>
                : <Text> </Text>
            }
          </View>
          <View style={styles.logo}>
            <Image src={logo ? logo.url : '/img/PAYearned-logo.png'}/>
            <Text>{logo ? ' ' : 'https://payearned.com' }</Text>
          </View>
        </View>
        {/* Right side Header Box */}
        <View style={styles.headerRight}>
          <Text style={{ fontWeight: 'bold' }}>Application #</Text>
          <Text style={{ fontWeight: 'bold' }}>Application Date</Text>
          <Text style={{ fontWeight: 'bold' }}>Period To</Text>
          <View style={styles.headerRightData}>
            <Text>{application.sequenceNumber}</Text>
            <Text>{applicationDate}</Text>
            <Text>{periodTo}</Text>
          </View>
        </View>
      </View>

      {/* Project Information Box */}
      <View style={{ flexDirection: 'row' }}>
        <View style={styles.headerRowWithMargin}>
          <View style={styles.projectInfo}>
            <Text style={{ fontWeight: 'bold' }}>Project Name</Text>
            <Text style={{ fontWeight: 'bold' }}>Project #</Text>
            <Text style={{ fontWeight: 'bold' }}>Subcontractor Name</Text>
          </View>
          <View>
            <Text>{projectJSON.name} </Text>
            <Text>{projectJSON.projectId} </Text>
            <Text>{projectJSON.subcontractor?.name} </Text>
          </View>
        </View>

        {/* Type Description Box (change order only) */}
        {
          pdfType === 'changeOrder'
            ? (
              <View style={styles.typeInfo}>
                <Text style={{ fontWeight: 'bold' }}>Type Legend</Text>
                <Text>A = Addition</Text>
                <Text>R = Revised +/-</Text>
              </View>
            ) : (
              <View />
            )
        }
      </View>
    </View>
  );
}
