import React, { ReactElement } from 'react';
import { FirebaseAuthService } from '../services/AuthService';
import { inject, observer } from 'mobx-react';
import ProfileRecommended from './ProfileRecommended';
import CookieService from '../services/CookieService';
import SubscriptionRequiredScreen from './SubscriptionRequiredScreen';
import { Subscription } from '../services/PaymentService';

interface SubscriptionRequiredProps {
  auth?: FirebaseAuthService;
  cookie?: CookieService;
  applicationId: string;
  subscribedComponent: ReactElement;
  notSubscribedComponent: ReactElement;
  subscriptions: Array<Subscription> | undefined;
}

interface SubscriptionRequiredState {
  modalDisplayed: boolean;
}

@inject('auth')
@inject('cookie')
@observer
export default class SubscriptionRequired extends React.Component<SubscriptionRequiredProps, SubscriptionRequiredState> {

  state: SubscriptionRequiredState = {
    modalDisplayed: false,
  }

  render(): React.ReactNode {
    // If the profile isn't complete, render ProfileRecommended
    if (!this.props.auth?.profileComplete && !this.props.cookie?.isIgnored(this.props.applicationId)) {
      return <ProfileRecommended
        applicationId={ this.props.applicationId }
        preventAction={ this.props.notSubscribedComponent }
        allowAction={ this.props.subscribedComponent }
      />;
    }
    return (
      <React.Fragment>
        <SubscriptionRequiredScreen
          modalDisplayed={ this.state.modalDisplayed }
          onCloseClick={(): void => this.setState({ modalDisplayed: false })}
        />
        {
          (Array.isArray(this.props.subscriptions) && this.props.subscriptions.length > 0) ? this.props.subscribedComponent :
            <span onClick={ (): void => this.setState({ modalDisplayed: true }) }>
              { this.props.notSubscribedComponent }
            </span>
        }
      </React.Fragment>
    );
  }
}
