import React from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { ApplicationJSON } from '../../../schemas/applications/ApplicationJSON';
import { DataService } from '../../../services/DataService';
import ActiveApplication from '../../../schemas/applications/ActiveApplication';
import CalculationSchemaForm from '../../../components/CalculationSchemaForm';
import { IChangeEvent } from '@rjsf/core';
import ActiveProject from '../../../schemas/projects/ActiveProject';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { AlertService } from '../../../services/AlertService';

interface CloseProjectProps {
  project?: ActiveProject;
  asMenu?: boolean;
  onClose: (finalApplication: ApplicationJSON) => Promise<void>;
  alert?: AlertService;
}

interface CloseProjectState {
  showConfirmation: boolean;
  showDatePrompt: boolean;
  closing: boolean;
  applicationDate?: string;
  periodTo?: string;
  alert?: AlertService;
}

@inject('alert')
@observer
export default class CloseProject extends React.Component<CloseProjectProps, CloseProjectState> {
  state: CloseProjectState = {
    showConfirmation: false,
    showDatePrompt: false,
    closing: false,
  };
  dataService = new DataService();

  async closeProject(): Promise<void> {
    this.setState({ closing: true });

    // Get the current application
    const currentApplication = this.props.project?.currentApplication as ActiveApplication;

    // Set the status
    currentApplication.status = 'Completed';

    // Save the current application
    await currentApplication.save();
    currentApplication.calculate();

    // Create the final application
    const finalApplication = new ActiveApplication();

    // Set the attributes
    finalApplication.projectId = this.props.project?.id;
    finalApplication.status = 'Final';
    finalApplication.sequenceNumber = Number(currentApplication.sequenceNumber)+1;
    finalApplication.applicationDate = this.state.applicationDate;
    finalApplication.periodTo = this.state.periodTo;

    // Save the application
    await finalApplication.save();

    // Save closedAt field in the Project
    const params = new URLSearchParams();
    params.append('id', this.props.project?.id as string);
    const json = { closedAt: moment(Date.now()).toDate() };
    try {
      await this.dataService.patch('projects', params, json);
        this.props.alert?.createAlert({
          message: 'Project successfully closed.',
          title: 'Success',
          type: 'success'
        });
    } catch (e) {
         this.props.alert?.createAlert({
           message: 'Please try again or let us know about it in Contact Us page.',
           title: 'Error',
           type: 'error'
         });
    }


    // Recalculate
    finalApplication.calculate();

    // Update the current project in the project applications
    if (this.props?.project?.applications) {
      this.props.project.applications[this.props.project.applications.length - 1] = currentApplication.toJSON();
    }

    this.setState({ closing: false, showDatePrompt: false });
    await this.props.onClose(finalApplication);
  }

  render(): React.ReactNode {
    return (
      <React.Fragment>
        {
          this.props.asMenu
            ?
            <span onClick={(): void => this.setState({ showConfirmation: true })}>Close Project</span>
            :
            <Button primary onClick={(): void => this.setState({ showConfirmation: true })}>
              <Icon name="lock"/>
                Close Project
            </Button>
        }
        <Modal open={this.state.showConfirmation}>
          <Modal.Header>Close Project</Modal.Header>
          <Modal.Content>
            <p>
              This project has been 100% billed, less retainage.  Closing the project will mark the current
              application as Complete.  The final application for the retainage will also be created.  You will
              not be able to create any additional applications or make any other updates to the project.
            </p>
            <p>
              Are you sure you want to close the project?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              disabled={this.state.closing}
              color='red'
              onClick={(): void => this.setState({ showConfirmation: false })}
            >No</Button>
            <Button
              disabled={this.state.closing}
              loading={this.state.closing}
              color='green'
              onClick={(): void => this.setState( { showConfirmation: false, showDatePrompt: true })}
            >Yes</Button>
          </Modal.Actions>
        </Modal>
        <Modal open={this.state.showDatePrompt}>
          <Modal.Header>Set Final Application Dates</Modal.Header>
          <Modal.Content>
            <CalculationSchemaForm
              schema={{
                type: 'object',
                properties: {
                  applicationDate: {
                    type: 'string',
                    title: 'Application Date',
                    format: 'date',
                  },
                  periodTo: {
                    type: 'string',
                    title: 'Period To',
                    format: 'date',
                  },
                }
              }}
              disableButtons={true}
              onChange={(e: IChangeEvent<{applicationDate: string; periodTo: string}>): void => this.setState({
                applicationDate: e.formData.applicationDate,
                periodTo: e.formData.periodTo,
              })}
            > </CalculationSchemaForm>
          </Modal.Content>
          <Modal.Actions>
            <Button
              disabled={this.state.closing || !this.state.applicationDate || !this.state.periodTo}
              color='red'
              onClick={(): void => this.setState({ showDatePrompt: false })}
            >Cancel</Button>
            <Button
              disabled={this.state.closing || !this.state.applicationDate || !this.state.periodTo}
              loading={this.state.closing}
              color='green'
              onClick={(): Promise<void> => this.closeProject()}
            >Close Project</Button>
          </Modal.Actions>
        </Modal>
        <br/><br/>
      </React.Fragment>
    );
  }
}
