import { SubscriptionCommonProps } from './components/AddPaymentMethod';
import React from 'react';
import Page from '../../components/Page';
import { Table, TableHeader } from 'semantic-ui-react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { inject, observer } from 'mobx-react';
import ReceiptRow from './components/ReceiptRow';
import { Receipt } from '../../services/PaymentService';

export default class ReceiptsPageContainer extends React.Component{
  render(): React.ReactNode {
    return (
      <ElementsConsumer>
        {( { stripe, elements } ): React.ReactNode => (
          <ReceiptsPage stripe={stripe as Stripe} elements={elements as StripeElements}/>
        )}
      </ElementsConsumer>
    );
  }
}

interface ReceiptsPageState {
  receipts: Array<Receipt> | undefined;
}

@inject('stripe')
@inject('auth')
@inject('payment')
@observer
class ReceiptsPage extends React.Component<SubscriptionCommonProps>{

  state: ReceiptsPageState = {
    receipts: undefined
  }

  async componentDidMount(): Promise<void> {
    const receipts = await this.props.payment?.getAllReceipts();
    this.setState({
      receipts
    });
  }

  render(): React.ReactNode {

    const { receipts } = this.state;

    return (
      <Page pageTitle='Receipts'  breadcrumb={[
        { key: 'home', href: '/', content: 'Your Projects', link: true },
        { key: 'receipts', content: 'Receipts', active: true },
      ]}>
        <p>
          Below are your receipts from your PAYearned subscription
        </p>
        <Table celled sortable fixed size='small' unstackable>
          <TableHeader>
            <Table.HeaderCell>
              Created
            </Table.HeaderCell>
            <Table.HeaderCell>
              Amount
            </Table.HeaderCell>
            <Table.HeaderCell>
              View
            </Table.HeaderCell>
          </TableHeader>
          <ReceiptRow receipts={receipts}/>
        </Table>
      </Page>
    );
  }
}
