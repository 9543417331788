import React from 'react';
import { Plan } from '../../../services/PaymentService';
import {DISABLE_AUTOMATIC_BILLING_MESSAGE} from "./DisableAutomaticBillingMessage";

interface SubscriptionConfirmationWithTrialProps {
  plan?: Plan;
  trialDays: number;
}
export default class SubscriptionConfirmationWithTrial extends React.Component<SubscriptionConfirmationWithTrialProps> {
  render(): React.ReactNode {
    if (!this.props.plan) return '';
    const amount = (this.props.plan.amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    return <React.Fragment>
      <p>
        Congratulations, your subscription has been activated.  You now have full access to all PAYearned
        features.  If you cancel your subscription within the first {
          this.props.trialDays
        } days, your credit card will not be charged.  After the first {
          this.props.trialDays
        } days, your credit card will automatically be charged {
          this.props.plan.interval === 'year' ? 'annually' : 'monthly'
        } <b>in advance.</b>
      </p>
      { DISABLE_AUTOMATIC_BILLING_MESSAGE }
    </React.Fragment>;
  }
}
