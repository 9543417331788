import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

interface ConfirmDuplicateNameProps {
  onClose: (allowDuplicateName: boolean) => void;
  name: string;
}

export default class ConfirmDuplicateName extends React.Component<ConfirmDuplicateNameProps> {
  render(): React.ReactNode {
    return (
      <Modal open={true}>
        <Modal.Header>Duplicate Project Name</Modal.Header>
        <Modal.Content>
          <p>
            The project name &quot;{this.props.name.trim()}&quot; has already been used in another project.  It is highly
            recommended you use distinct project names.  Using duplicate project names can result in different
            applications being presented to the general contractor, resulting in billing issues.
          </p>
          <p>
            Are you sure you want to duplicate this project name?  (We recommend you click&nbsp;
            <b style={{ color: 'green' }}>No</b>, and change the project name)
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color='red'
            onClick={(): void => this.props.onClose(true)}
          >Yes</Button>
          <Button
            color='green'
            onClick={(): void => this.props.onClose(false)}
          >No</Button>
        </Modal.Actions>
      </Modal>);
  }
}
