import React, { ChangeEvent } from 'react';
import { Button, Form, Header, Image, Message, Segment } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import CenteredGrid from '../CenteredGrid';
import CookieService from '../../services/CookieService';


export type role = 'SUB_CONTRACTOR' | 'GENERAL_CONTRACTOR' | 'unassigned' | 'ADMINISTRATOR';

interface EmailPromptToCompletePasswordlessProps {
  cookie?: CookieService;
  badEmail: boolean;
}

interface EmailPromptToCompletePasswordlessState {
  email: string;
  errorMessage: string | undefined;
  touched: Array<string>;
  focused: string;
  verifyEmail: boolean;
}

const email = 'email';

@inject('cookie')
@observer
export default class EmailPromptToCompletePasswordless extends React.Component<EmailPromptToCompletePasswordlessProps, EmailPromptToCompletePasswordlessState> {

  state: EmailPromptToCompletePasswordlessState = {
    email: '',
    errorMessage: this.props.badEmail ? 'Email address does not match.  Please re-enter your email address.' : undefined,
    touched: [],
    focused: '',
    verifyEmail: false,
  }

  displayErrorMessage(): React.ReactNode {

    if (this.invalidEmail()) {
      return <Message error content='Invalid email address'/>;
    }

    if (this.state.errorMessage) {
      return (
        <Message negative content={this.state.errorMessage}/>
      );
    }
  }

  invalidEmail(): boolean {
    return this.state.focused !== email
      && this.state.touched.includes(email)
      && this.state.email.match(/^[a-z0-9][a-z0-9+.]*@[a-z0-9-]+(\.[a-z0-9-])+/) === null;
  }

  onChangeEmail(e: React.ChangeEvent<HTMLInputElement>): void {
    let touched = this.state.touched;
    touched.push(email);
    touched = touched.filter((v, i, a) => a.indexOf(v) === i);
    this.setState({ email: e.target.value.trim(), touched: touched });
  }

  onFocus(fieldName: string): void {
    this.setState({ focused: fieldName });
  }

  confirmEmail(): void {
    this.props.cookie?.setPasswordlessEmail(this.state.email);
    document.location.href = String(document.location.href);
  }

  render(): React.ReactNode {
    return (
      <CenteredGrid>
        <Image centered style={{ height: '100px' }} src='/img/PAYearned-logo.png'/>
        <Header as='h2' color='blue' textAlign='center'>Confirm Your Email Address</Header>
        <div style={{ width: '420px', height: '60px', display: 'block', marginBottom: '10px' }}>
          {this.displayErrorMessage()}
        </div>
        <Form size='large'>
          <Segment stacked>
            <Form.Input
              required
              type="email"
              error={this.invalidEmail()}
              placeholder="Confirm Your E-mail address"
              value={this.state.email}
              onFocus={(): void => this.onFocus(email)}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => this.onChangeEmail(e)}
            />
            <Button
              disabled={!this.state.email || this.invalidEmail()}
              color='blue'
              fluid
              size='large'
              onClick={(): void => this.confirmEmail()}
            >
              Confirm Email
            </Button>
          </Segment>
        </Form>
      </CenteredGrid>
    );
  }
}
