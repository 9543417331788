import React from 'react';
import { ArrayFieldTemplateProps } from '@rjsf/core';
import { Button, Container, Form, Header, Popup, Table } from 'semantic-ui-react';
import { JSONSchema7 } from 'json-schema';
import { Link } from 'react-router-dom';

import './ArrayFieldTableTemplate.css';

// Extends to add the key to the schema definition
// So we can create an array of schema definitions along with
interface JSONSchemaWithKey extends JSONSchema7 {
  key: string;
}

export const ArrayFieldTableTemplate: React.FC<ArrayFieldTemplateProps> = (props: ArrayFieldTemplateProps) => {
  // Make sure we have items
  if (props.schema.items) {
    const items = props.schema.items as JSONSchema7;
    const properties = items.properties as { [p: string]: JSONSchema7 };
    if (properties) {
      const schemaFields = Object.keys(properties).map((fieldName) => {
        if (items.properties) {
          const field = items.properties[fieldName] as JSONSchemaWithKey;
          field.key = fieldName;
          return field;
        }
      }) as Array<JSONSchemaWithKey>;
      return (
        <Container>
          <Form.Group>
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan={schemaFields.length}>
                    {props.schema.title}
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  {
                    !props.uiSchema['ui:options']?.disableDelete ?
                      <Table.HeaderCell> </Table.HeaderCell> : ''
                  }
                  {
                    schemaFields.map(field => {
                      return (
                        <Table.HeaderCell key={field.title ? field.title : field.key} style={{ width: '100%' }}>
                          {
                            field.title ? field.title : field.key
                          }
                        </Table.HeaderCell>
                      );
                    })
                  }
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  props.items.map((element) => {
                    return (
                      <Table.Row key={element.children.props.formData.id}>
                        {
                          !props.uiSchema['ui:options']?.disableDelete ?
                            <Table.Cell>
                              <Popup content="Delete row" trigger={
                                <Button
                                  icon='delete'
                                  as={Link}
                                  color='red'
                                  size='mini'
                                  onClick={element.onDropIndexClick(element.index)}
                                />
                              }/>
                            </Table.Cell> : ''
                        }
                        {element.children}
                      </Table.Row>
                    );
                  })
                }
              </Table.Body>
            </Table>
          </Form.Group>
          {
            !props.uiSchema['ui:options']?.disableAdd ?
              <Form.Group>
                <Popup content="Add new row" trigger={
                  <Button
                    icon='add'
                    as={Link}
                    color='green'
                    size='mini'
                    onClick={props.onAddClick}
                  />
                }/>
              </Form.Group> : ''
          }
        </Container>
      );
    }
    return null; // No props.schema.items.properties
  }
  return null; // No props.schema.items
};
