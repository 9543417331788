import React from 'react';
import Page from '../components/Page';
import {
  Form, FormButton,
  FormInput,
  Grid,
  TextArea
} from 'semantic-ui-react';
import { FeedbackService } from '../services/FeedbackService';
import Joi from '@hapi/joi';
import ThankYouModal from './subscription/components/ThankYouModal';

const RequiredSubjectTxt = 'Please enter a subject';
const RequiredMessageTxt = 'Message should be at least 10 characters long';

interface ContactUsFormState {
  title: string;
  titleError: string;
  isLoading: boolean;
  description: string;
  descriptionError: string;
  validFeedbackForm: boolean;
  openedThankYouModal: boolean;
}

export default class CustomerFeedbackPage extends React.Component<{}, ContactUsFormState> {
  private _feedbackService = new FeedbackService();

  state: ContactUsFormState = {
    title: '',
    titleError: '',
    isLoading: false,
    description: '',
    descriptionError: '',
    validFeedbackForm: false,
    openedThankYouModal: false
  }

  /* async handleSelect(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): Promise<void>{
    await this.setState({ subjectOption: data.value });
    const result = Joi.string()
      .required()
      .messages({ 'string.base': RequiredErrMsg })
      .validate(data.value, { abortEarly: true });
    return this.checkFormValid(result, 'subjectOptionError');
  }*/ //TODO: will be used in further iterations

  async validateTitleInput(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const validation = Joi.string().required().validate(e.target.value, { abortEarly: true });

    if (validation.error) {
      this.setState({ 'titleError': RequiredSubjectTxt });
    } else {
      this.setState({ 'titleError': '' });
    }
    const formStatus = this.isFormValid();
    this.setState({ validFeedbackForm: formStatus });
  }

  async validateTextInput(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const validation = Joi.string()
      .min(10)
      .required()
      .validate(e.target.value, { abortEarly: true });
    if (validation.error) {
      this.setState({ 'descriptionError': RequiredMessageTxt });
    } else {
      this.setState({ 'descriptionError': '' });
    }
    const formStatus = this.isFormValid();
    this.setState({ validFeedbackForm: formStatus });
  }

  isFormValid(): boolean {
    const { titleError, title, description, descriptionError } = this.state;
    return (!(titleError.length > 0) && (title.length > 0)) &&
      (!(descriptionError.length > 0) && (description.length > 0));
  }

  clearForm(): void {
    this.setState({
      title: '',
      titleError: '',
      isLoading: false,
      description: '',
      descriptionError: '',
      validFeedbackForm: false,
      openedThankYouModal: false
    });
  }

  async handleFeedbackSubmit(): Promise<void> {
    this.setState({ isLoading: true });
    const { title, description } = this.state;
    const result = await this._feedbackService.sendFeedback({
      title: title,
      description: description
    });
    if (result) {
      this.setState({
        isLoading: false,
        openedThankYouModal: true
      });
    }
  }

  render(): React.ReactNode {
    /*  const dropDownOptions = [
        { key: 'support', text: 'Technical Support', value: 'technical support' },
        { key: 'training', text: 'Training', value: 'training' },
        { key: 'other', text: 'Other', value: 'other' },
      ];*/ //TODO: will be used in further iterations
    const { title, titleError, isLoading, description, descriptionError, validFeedbackForm, openedThankYouModal } = this.state;

    return (
      <Page
        breadcrumb={[
          { key: 'home', href: '/', content: 'Your Projects', link: true },
          { key: 'contact us', content: 'Contact Us', active: true },
        ]}
        pageTitle='PAYearned User Contact Form'
      >
        <Grid textAlign='center' style={{ height: '45vh' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 400 }}>
            <Form onSubmit={(): Promise<void> => this.handleFeedbackSubmit()}>
              <FormInput
                placeholder='Subject'
                width='16'
                type='text'
                name='title'
                value={title}
                error={titleError ? titleError : null}
                onChange={(e, d): void => this.setState({ title: d.value })}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): Promise<void> => this.validateTitleInput(e)}
              />
              {/*              <Dropdown //TODO : will be used in further iterations
                fluid
                search
                selection
                placeholder={subjectOption === null ? 'Regarding...' : ''}
                options={ dropDownOptions }
                onChange={(e: React.SyntheticEvent<HTMLElement>, d: DropdownProps): Promise<void> => this.handleSelect(e,d)}
              />*/}
              <FormInput
                placeholder='Message...(10 character minimum)'
                width='16'
                control={TextArea}
                type='text'
                name='description'
                value={description}
                error={descriptionError ? descriptionError : null}
                onChange={(e, d): void => this.setState({ description: d.value })}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>): Promise<void> => this.validateTextInput(e)}
              />
              <FormButton
                primary
                circular
                style={{ paddingLeft: '40px', paddingRight: '40px' }}
                loading={isLoading}
                disabled={!(validFeedbackForm && !isLoading)}
              >
                Submit
              </FormButton>
            </Form>
          </Grid.Column>
        </Grid>
        <ThankYouModal
          header='Submitted'
          openedThankYouModal={openedThankYouModal}
          onClose={(): void => this.clearForm()}
          navigateToAfterClose='/'
        >
          Our team has received your message. We will contact you shortly. Thank you!
        </ThankYouModal>
      </Page>
    );
  }
}
