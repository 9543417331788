import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import EmailVerificationPage from '../pages/EmailVerificationPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import PasswordlessLoginSuccess from '../pages/PasswordlessLoginSuccessPage';

const VERIFY_EMAIL = 'verifyEmail';
const RESET_PASSWORD = 'resetPassword';
const PASSWORDLESS_LOGIN = 'signIn';

export default class AccountHelp extends React.Component<RouteProps> {

  getParameterByName(name: string): string {
    const search = this.getUrlParams();
    return search.get(name) || '';
  }

  getUrlParams(): URLSearchParams {
    if (!this.props?.location?.search) return new URLSearchParams();
    return new URLSearchParams(this.props.location.search);
  }

  render(): React.ReactNode {
    // Get the action to complete.
    const mode = this.getParameterByName('mode');
    // Get the one-time code from the query parameter.
    const actionCode = this.getParameterByName('oobCode');

    if (mode === VERIFY_EMAIL && actionCode) {
      return <EmailVerificationPage mode={mode} actionCode={actionCode}/>;
    } else if (mode === RESET_PASSWORD && actionCode) {
      return <ResetPasswordPage mode={mode} actionCode={actionCode}/>;
    } else if (mode === PASSWORDLESS_LOGIN && actionCode) {
      return <PasswordlessLoginSuccess mode={mode} actionCode={actionCode}/>;
    }

    return(
      <Redirect to='/'/>
    );
  }
}
