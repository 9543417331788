import { UiSchema } from '@rjsf/core';
import CurrencyWidget from '../widgets/currency/CurrencyWidget';
import StatusWidget from '../widgets/StatusWidget';
import { ArrayFieldTableTemplate } from '../templates/ArrayFieldTableTemplate';
import { ApplicationLineUISchema } from '../ApplicationLineJSONSchema';
import { ApplicationLineChangeOrderUISchema } from '../ApplicationLineChangeOrderJSONSchema';

export const ApplicationUiSchema: UiSchema = {
  project: {
    originalContractAmount: {
      'ui:widget': CurrencyWidget,
    }
  },
  status: {
    'ui:widget': StatusWidget,
    options: {
      info: [ 'Submitted' ],
      success: [ 'Open', 'Accepted' ],
      danger: [ 'Rejected' ],
      done: [ 'Completed' ],
    }
  },
  originalApplicationLines: {
    'ui:ArrayFieldTemplate': ArrayFieldTableTemplate,
    'ui:options': {
      disableDelete: true,
      disableAdd: true,
    },
    items: ApplicationLineUISchema
  },
  changeOrderLines: {
    'ui:ArrayFieldTemplate': ArrayFieldTableTemplate,
    'ui:options': {
      disableDelete: true,
      disableAdd: true,
    },
    items: ApplicationLineChangeOrderUISchema
  },
};
