import React from 'react';
import './maintenance.css';
import { Container, Header, Image } from 'semantic-ui-react';
import { Media, MediaContextProvider } from '../../components/Page';

const verbiage = ' PAYearned is currently undergoing maintenance.  Please check back again later.';

export default class GlobalMaintenanceComponent extends React.Component {
  render(): JSX.Element {
    return (
      <MediaContextProvider>
        <MobileMaintenanceComponent/>
        <DesktopMaintenanceComponent/>
      </MediaContextProvider>
    );
  }
}

class MobileMaintenanceComponent extends React.Component {
  render(): React.ReactNode {
    return (
      <Media at={'mobile'}>
        <div>
          <Container textAlign='center'>
            <Header
              image
              style={ {
                marginTop: '7em',
                marginBottom: '1em'
              } }
            >
              <Image className='hero-image' src='/img/PAYearned-logo.png'/>
            </Header>
            <h3>
              {verbiage}
            </h3>
          </Container>
          <OuterCraneContainer/>
        </div>
      </Media>
    );
  }
}

class DesktopMaintenanceComponent extends React.Component {
  render(): React.ReactNode {
    return (
      <Media greaterThan='mobile'>
        <div>
          <Container textAlign='center'>
            <Header
              image
              style={ {
                marginTop: '5em',
                marginBottom: '1em'
              } }
            >
              <Image className='hero-image' src='/img/PAYearned-logo.png'/>
            </Header>
            <h1>
              {verbiage}
            </h1>
          </Container>
          <OuterCraneContainer/>
        </div>
      </Media>
    );
  }
}

class OuterCraneContainer extends React.Component {
  render(): React.ReactNode {
    return (
      <div id="outerCraneContainer">
        <div className="buildings">
          <div />
          <div className="1" />
          <div className="2" />
          <div className="3" />
          <div className="4" />
        </div>
        <div className="crane craneThree">
          <div className="line lineOne" />
          <div className="line lineTwo" />
          <div className="line lineThree" />
          <div className="stand" />
          <div className="weight" />
          <div className="cabin" />
          <div className="arm" />
        </div>
        <div className="crane craneTwo">
          <div className="line lineOne" />
          <div className="line lineTwo" />
          <div className="line lineThree" />
          <div className="stand" />
          <div className="weight" />
          <div className="cabin" />
          <div className="arm" />
        </div>
        <div className="crane craneOne">
          <div className="line lineOne" />
          <div className="line lineTwo" />
          <div className="line lineThree" />
          <div className="stand" />
          <div className="weight" />
          <div className="cabin" />
          <div className="arm" />
        </div>
      </div>
    );
  }
}
