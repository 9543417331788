import { JSONSchema7 } from 'json-schema';
import { FormValidation, UiSchema } from '@rjsf/core';
import { DataJSON } from '../services/DataService';
import ImageUploadWidget from './widgets/ImageUploadWidget';

export interface UserJSON extends DataJSON {
  id?: string;
  name?: string;
  hasLogo?: boolean;
  logo?: string;
  contactName?: string;
  email?: string;
  licenseNumber?: string;
  phoneNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  emailVerified?: boolean;
}

export const UserJSONSchema: JSONSchema7 = {
  type: 'object',
  $comment: 'Profile',
  required: [ 'name', 'addressLine1', 'city', 'state', 'zip', 'phoneNumber', 'contactName' ],
  properties: {
    name: {
      type: 'string',
      title: 'Your Company Name',
    },
    logo: {
      type: 'string',
      title: 'Your Company Logo (will appear on forms)'
    },
    contactName: {
      type: 'string',
      title: 'Application for Payment Signer',
    },
    licenseNumber: {
      type: 'string',
      title: 'License Number',
    },
    phoneNumber: {
      type: 'string',
      title: 'Phone Number',
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    addressLine1: {
      type: 'string',
      title: 'Address Line 1',
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    addressLine2: {
      type: 'string',
      title: 'Address Line 2',
    },
    city: {
      type: 'string',
      title: 'City',
    },
    state: {
      type: 'string',
      title: 'State',
      enum: [ 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY' ]
    },
    zip: {
      type: 'string',
      title: 'Zip',
    },
  },
};

export const UserUISchema: UiSchema = {
  logo: {
    'ui:widget': ImageUploadWidget,
    options: {
    }
  },
};

export const validateUser = (formData: UserJSON, errors: FormValidation): FormValidation => {
  // Do some cool custom validation in here
  // If there is an error you can use errors.addError()
  return errors;
};
