import React from 'react';
import { WidgetProps } from '@rjsf/core';
import { Form, InputOnChangeData } from 'semantic-ui-react';

interface PercentWidgetState {
  displayValue?: string;
}
export default class PercentWidget extends React.Component<WidgetProps, PercentWidgetState> {
  state: PercentWidgetState = {};

  onChange(e: React.ChangeEvent<EventTarget>, data: InputOnChangeData): void {
    // Clean up the input
    const inputValue = data.value.trim().replace(/[^\d.]|^\s*\s*$/g, '').replace(/\.(\d{1,2}).*$/, '.$1');
    let value = inputValue !== '' ? Math.trunc(Number(inputValue) * 100) / (100*100) : undefined;
    let displayValue = String(inputValue);

    // If the value is greater than 100%, change it to 100%
    if (value !== undefined && value > 1) {
      value = 1;
      displayValue = '100';
    }

    // Call onChange if the value is actually different
    if (Number(Math.trunc(Number(this.props.value) * 100 * 100)) / 100 !== Number(inputValue) || inputValue === '') {
      this.props.onChange(value);
      this.setState({ displayValue: displayValue.match(/\.$/) ? displayValue : undefined });
    } else {
      this.setState({ displayValue });
    }
  }

  render(): React.ReactNode {
    let value: number|string|undefined = isNaN(this.props.value * 100) ? undefined : Math.round(this.props.value * 100*100) / 100;
    const displayValue = this.state.displayValue ? this.state.displayValue : typeof value !== 'undefined' ? String(value) : '';
    // If it's a read-only field, output the value post-fixed with a % sign
    if (this.props.schema.readOnly) {
      value = isNaN(Number(value) / 100) ? undefined : Number(value) / 100;
      return (
        <Form.Field>
          <label>{this.props.schema.title ? this.props.schema.title : this.props.id.replace(/^root_/, '')}</label>
          <span>{value ? value.toLocaleString('en-US', { style: 'percent' }) : ''}</span>
        </Form.Field>
      );
    }

    return (
      <Form.Input
        type="string"
        label={this.props.schema.title}
        icon="percent"
        value={displayValue}
        error={false}
        onChange={(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void =>
          this.onChange(e, data)
        }
        style={{ width: '110px' }}
        onBlur={(): void => this.props.onBlur(this.props.id, displayValue)}
        onFocus={(): void => this.props.onFocus(this.props.id, displayValue)}
        required={this.props.required}
        placeholder={this.props.uiSchema['ui:options']?.placeholder}
      />
    );
  }
}
