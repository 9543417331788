import React from 'react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import AddPaymentMethod, { SubscriptionCommonProps } from './components/AddPaymentMethod';
import { inject, observer } from 'mobx-react';
import Page from '../../components/Page';
import { Link, Redirect } from 'react-router-dom';
import {
  Button, Grid, GridColumn, GridRow,
  List, ListContent, ListHeader, ListItem, Loader
} from 'semantic-ui-react';
import InvoiceRow from './components/InvoiceRow';
import PaymentModal from './components/PaymentModal';
import ReusableModal from '../../components/ReusableModal';
import ThankYouModal from './components/ThankYouModal';
import TagManagerService from '../../services/TagManagerService';
import { PaymentMethod, Invoice, StripeCustomer, Subscription } from '../../services/PaymentService';
import PaymentMethodEntry from './components/PaymentMethodEntry';


export default class ManageSubscriptionPageContainer extends React.Component<SubscriptionCommonProps> {
  render(): React.ReactNode {
    return (
      <ElementsConsumer>
        {({ stripe, elements }): React.ReactNode => (
          <ManageSubscriptionPage
            {...this.props}
            location={this.props.location}
            stripe={stripe as Stripe}
            elements={elements as StripeElements}
          />
        )}
      </ElementsConsumer>
    );
  }
}

interface ManageSubscriptionPageState {
  currentSubscription: Subscription | undefined;
  invoice: Invoice | undefined;
  isChangeInitialized: boolean;
  isLoading: boolean;
  openedThankYouModal: boolean;
  paymentMethods: Array<PaymentMethod> | undefined;
  showAddCardModal: boolean;
  stripeCustomer: StripeCustomer | undefined;
  changeState?: 'cancelTrial' | 'disableAutomaticBilling' | 'reEnableAutomaticBilling';
}

@inject('stripe')
@inject('auth')
@inject('alert')
@inject('payment')
@observer
class ManageSubscriptionPage extends React.Component<SubscriptionCommonProps, ManageSubscriptionPageState> {

  state: ManageSubscriptionPageState = {
    currentSubscription: undefined,
    invoice: undefined,
    isChangeInitialized: false,
    isLoading: true,
    openedThankYouModal: false,
    paymentMethods: undefined,
    showAddCardModal: false,
    stripeCustomer: undefined
  }

  async componentDidMount(): Promise<void> {
    const paymentMethods = await this.props.payment?.getPaymentMethods(this.props.auth?.user?.stripeCustomerId as string);
    const subscriptions = await this.props.payment?.getSubscriptions();
    let currentSubscription = undefined;
    if (Array.isArray(subscriptions) && subscriptions.length > 0) {
      currentSubscription = subscriptions[0];
    }
    let stripeCustomer;
    if (this.props.auth?.user?.stripeCustomerId) {
      stripeCustomer = await this.props.payment?.getStripeCustomer(this.props.auth?.user?.stripeCustomerId);
    }
    this.setState({
      currentSubscription,
      isLoading: false,
      paymentMethods,
      showAddCardModal: false,
      stripeCustomer
    });
  }

  capitalize(word: string | undefined): string | null {
    if (typeof word !== 'undefined') {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    return null;
  }

  async onChangePaymentMethods(defaultPaymentMethodId: string | undefined): Promise<void> {
    this.setState({
      isLoading: true
    });
    const stripeCustomer = this.state.stripeCustomer;
    const paymentMethods = await this.props.payment?.getPaymentMethods(this.props.auth?.user?.stripeCustomerId as string);

    if (stripeCustomer && defaultPaymentMethodId) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      stripeCustomer.invoice_settings.default_payment_method = defaultPaymentMethodId;
      this.setState({
        stripeCustomer
      });
    }
    this.setState({
      isLoading: false,
      paymentMethods,
      showAddCardModal: false
    });
  }

  getCalculatedRefund(amount: number): string {
    return (Number(amount * -1) / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  async triggerChangeSubscription(): Promise<void> {
    this.setState({ isLoading: true });

    let changeState: 'cancelTrial' | 'disableAutomaticBilling' | 'reEnableAutomaticBilling';
    if (this.state.currentSubscription?.id) {
      if (this.state.currentSubscription.status === 'trialing') {
        await this.props.payment?.deleteSubscription(this.state.currentSubscription.id);
        changeState = 'cancelTrial';
      } else if (!this.state.currentSubscription.cancel_at) {
        await this.props.payment?.disableAutomaticBilling(this.state.currentSubscription.id);
        changeState = 'disableAutomaticBilling';
      } else  {
        await this.props.payment?.reEnableAutomaticBilling(this.state.currentSubscription.id);
        changeState = 'reEnableAutomaticBilling';
      }
      this.setState({
        changeState,
        isChangeInitialized: false,
        openedThankYouModal: true
      });
    } else {
      this.showErrorAlert();
      console.error(`Error cancelling subscription for ${this.props.auth?.user?.id}`);
    }
    this.setState({
      isLoading: false,
    });
  }

  showErrorAlert(): void {
    this.props.alert?.createAlert({
      message: 'Please try again or let us know about it in Contact Us page.',
      title: 'Error',
      type: 'error'
    });
  }

  getSubscriptionCancellationMessage(invoice: Invoice): string {
    if (typeof invoice !== 'undefined' && typeof invoice.lines?.data !== 'undefined') {
      const amount = invoice.lines?.data[0].amount;
      if (amount !== 0) {
        return `${this.state.currentSubscription?.plan?.nickname.includes('monthly') ? 'Monthly' : 'Annual' as string} subscription has been cancelled. You will receive
       a refund of ${this.getCalculatedRefund(amount)} for the reminder of the billing period.`;
      }
    }
    return `${this.state.currentSubscription?.plan?.nickname.includes('monthly') ? 'Monthly' : 'Annual' as string} subscription has been cancelled within evaluation period.
    Your credit card will not be charged.`;
  }

  closeThankYouModal(): void {
    this.setState({ openedThankYouModal: false });
  }

  generatePlanDetailsTxt(): string {
    const subscriptionAmount = (Number(typeof this.state.currentSubscription?.plan !== 'undefined' ? this.state.currentSubscription?.plan.amount : 0) / 100)
      .toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    let capitalizedNickname: string | null = 'Time period';
    if (this.state.currentSubscription?.plan.nickname) {
      capitalizedNickname = this.capitalize(this.state.currentSubscription?.plan.nickname === 'Monthly' ? 'Month' : 'Year');
    }
    return subscriptionAmount + ' For 1 ' + capitalizedNickname;
  }

  render(): React.ReactNode {
    const {
      invoice,
      isChangeInitialized,
      isLoading,
      openedThankYouModal,
      paymentMethods,
      showAddCardModal,
      stripeCustomer,
      currentSubscription
    } = this.state;
    const { elements, stripe, auth, payment } = this.props;
    const payMethodsQuantity = paymentMethods?.length;
    const defaultPaymentMethodId = stripeCustomer?.invoice_settings.default_payment_method;

    if (isLoading) return <Loader active/>;

    if (!currentSubscription?.plan && !isLoading) return <Redirect to='/subscription/select'/>;

    return (
      <Page pageTitle='Manage Subscription' breadcrumb={[
        { key: 'home', href: '/', content: 'Home', link: true },
        { key: 'subscription', content: 'Manage Subscription', active: true },
      ]}>
        <Grid divided='vertically' textAlign='left' style={{ height: '50' }} stackable>
          <GridRow columns={2}>
            <GridColumn style={{ maxWidth: 400 }}>
              <List stackable columns={2} relaxed style={{ marginTop: 30, }}>
                <ListItem>
                  <ListContent>
                    <ListHeader style={{ color: 'grey' }}>
                      CURRENT PLAN
                    </ListHeader>
                  </ListContent>
                </ListItem>

                <ListItem>
                  <ListContent>
                    <ListHeader>
                      {typeof currentSubscription?.plan !== 'undefined' ? (currentSubscription?.plan.interval === 'year' ? 'Annual' : 'Monthly') : undefined} Plan
                    </ListHeader>
                    <div style={{ marginTop: '6px', fontSize: '14px', color: 'grey' }}>
                      {this.generatePlanDetailsTxt()}
                    </div>
                    {
                      currentSubscription?.status === 'trialing'
                        ? <div style={{ marginTop: '10px', fontSize: '12px' }}>
                          Your credit card will be charged
                          on {
                            InvoiceRow.secondsToISOString(currentSubscription?.current_period_end as number)
                          } when your trial ends
                        </div>
                        : !currentSubscription?.cancel_at
                          ? <div style={{ marginTop: '10px', fontSize: '12px' }}>
                            The next charge to your credit card will be
                            on {InvoiceRow.secondsToISOString(currentSubscription?.current_period_end as number)}
                          </div>
                          : ''
                    }
                    {
                      currentSubscription?.cancel_at
                        ? <div style={{ marginTop: '10px', fontSize: '12px' }}>
                          Automatic billing of your subscription has been disabled.
                          Your subscription will terminate on {
                            InvoiceRow.secondsToISOString(currentSubscription?.cancel_at as number)
                          }.  Please note that you will no longer have access to your PDF documents after that date.
                        </div>
                        : <div style={{ marginTop: '10px', fontSize: '12px' }}>
                          Your credit card will be charged automatically.
                        </div>
                    }
                    <div style={{ marginTop: '20px' }}>
                      {
                        currentSubscription?.plan.interval === 'month'
                          ? (
                            <Button size='medium'
                              style={{ marginBottom: '10px' }}
                              primary
                              as={Link}
                              to={{
                                query: { callbackUrl: window.location.pathname },
                                pathname: '/subscription/select',
                                plan: currentSubscription?.plan
                              }}
                            >
                              Change plan
                            </Button>
                          ) : ''
                      }
                      <Button
                        size='medium'
                        onClick={(): void => this.setState({ isChangeInitialized: true })}
                        color={
                          currentSubscription?.cancel_at
                            ? 'green'
                            : 'red'
                        }
                      >
                        {
                          currentSubscription?.cancel_at
                            ? 'Re-enable Automatic Billing'
                            : currentSubscription?.status == 'trialing'
                              ? 'Cancel'
                              : 'Disable Automatic Billing'
                        }
                      </Button>
                    </div>
                    <ReusableModal
                      isInitialized={isChangeInitialized}
                      leftButtonColor={'red'}
                      leftButtonContent={'No'}
                      rightButtonColor={'green'}
                      rightButtonContent={'Yes'}
                      isLoading={isLoading}
                      onLeftButtonAction={(): void => this.setState({ isChangeInitialized: false })}
                      onRightButtonAction={(): Promise<void> => this.triggerChangeSubscription()}
                      headerContent={
                        currentSubscription?.cancel_at
                          ? 'Re-enable Automatic Billing'
                          : currentSubscription?.status == 'trialing'
                            ? 'Cancel'
                            : 'Disable Automatic Billing'
                      }
                    >
                      {
                        currentSubscription?.cancel_at
                          ? <div>
                            Do you want to re-enable automatic billing of your subscription?
                          </div>
                          : currentSubscription?.status == 'trialing'
                            ? <div>
                              Do you want to cancel your trial?  If you cancel now, your credit card will not
                              be charged.
                            </div>
                            : <div>
                              Do you want to disable automatic billing of your subscription?
                            </div>
                      }
                    </ReusableModal>
                  </ListContent>
                </ListItem>

                <ListItem>
                  <ListHeader style={{ marginTop: '60px', color: 'grey' }}>
                    PAYMENT METHODS
                  </ListHeader>
                  <ListContent>
                    <List>
                      {
                        paymentMethods?.map(paymentMethod =>
                          <PaymentMethodEntry
                            checked={paymentMethod.id === defaultPaymentMethodId}
                            elements={elements}
                            isLoading={isLoading}
                            key={paymentMethod.id}
                            onChangePaymentMethods={(defaultPayMethodId?: string): Promise<void> => this.onChangePaymentMethods(defaultPayMethodId)}
                            payment={payment}
                            paymentMethod={paymentMethod}
                            paymentMethods={paymentMethods}
                            stripe={stripe}
                            stripeCustomer={stripeCustomer}
                            value={paymentMethod.id}
                          />
                        )
                      }
                    </List>
                  </ListContent>
                </ListItem>
                <ListItem>
                  <PaymentModal
                    showAddCardModal={showAddCardModal}
                    paymentMethodsQuantity={payMethodsQuantity}
                    onCloseAddCardModal={(): void => this.setState({ showAddCardModal: false })}
                    onOpenAddCardModal={(): void => this.setState({ showAddCardModal: true })}
                  >
                    <AddPaymentMethod
                      auth={auth}
                      elements={elements}
                      onChangePaymentMethods={(defaultPaymentMethodId: string | undefined): Promise<void> => this.onChangePaymentMethods(defaultPaymentMethodId)}
                      payment={payment}
                      paymentMethods={paymentMethods}
                      stripe={stripe}
                    />
                  </PaymentModal>
                </ListItem>
              </List>
            </GridColumn>
          </GridRow>
        </Grid>
        <ThankYouModal
          header={
            this.state.changeState === 'cancelTrial'
              ? 'Trial Cancelled'
              : this.state.changeState === 'disableAutomaticBilling'
                ? 'Automatic Billing Disabled'
                : 'Automatic Billing Re-Enabled'
          }
          openedThankYouModal={openedThankYouModal}
          onClose={(): void => this.closeThankYouModal()}
          navigateToAfterClose='/'
        >
          {
            this.state.changeState === 'cancelTrial'
              ? <div>
                Your trial subscription has been cancelled, and your credit card will
                not be charged.  Thank you for trying PAYearned.
              </div>
              : this.state.changeState === 'disableAutomaticBilling'
                ? <div>
                  Automatic billing has been disabled.  Your subscription will terminate on {
                    InvoiceRow.secondsToISOString(currentSubscription?.current_period_end as number)
                  }
                </div>
                : <div>
                  Automatic billing has been re-enabled.  Your credit card will be charged automatically.  The
                  next charge to your credit card will be on {
                    InvoiceRow.secondsToISOString(currentSubscription?.current_period_end as number)
                  }
                </div>
          }
        </ThankYouModal>
      </Page>
    );
  }
}
