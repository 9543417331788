import React from 'react';
import { Message } from 'semantic-ui-react';

interface AlertProps {
  title?: string;
  message?: string;
  alertType?: string;
  onDismiss: () => void;
}

const styles = {
  alertMessage: {
    display: 'inline-block',
    width: 'auto',
    marginTop: 10,
    opacity: 0.95,
    zIndex: 200
  }
};

export default class Alert extends React.Component<AlertProps> {
  render(): React.ReactNode {
    const { message, title, alertType = 'info' } = this.props;
    return (
      <div>
        <Message
          style={styles.alertMessage}
          onDismiss={(): void => this.props.onDismiss()}
          {...{ [alertType]: true }} >
          {title && (<Message.Header>{title}</Message.Header>)}
          <p>{message}</p>
        </Message>
      </div>
    );
  }
}
