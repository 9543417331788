import React from 'react';
import { Text, View } from '@react-pdf/renderer';

export function PDFFooter(
  customStyles: any | undefined = {}
): React.ReactElement {
  return (
    <View fixed>
      <Text
        fixed
        style={{
          position: 'absolute',
          bottom: -45,
          right: 0,
          fontSize: 10,
          ...customStyles
        }}
        render={({ pageNumber, totalPages }): string => {
          return `Page ${pageNumber} of ${totalPages}`;
        }
        }
      />
    </View>
  );
}
