type Data = object | string;

const BASE_URL = process.env.REACT_APP_API_SERVICE_URL;

const LogService = {

  debug(data: Data): void {
    console.log(data);
  },

  error(error: Error, data: Data): void {
    const request = new Request(`${BASE_URL}/errors`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ error: error.message, stack: error.stack, data })
    });
    fetch(request)
      .then(() => console.log('Successfully submitted error data'))
      .catch(err => console.error('There was a problem submitting error data', err));
  }

};

export default LogService;
