import React from 'react';
import { Grid, GridColumn, GridRow, List } from 'semantic-ui-react';
import AddPaymentMethod, { SubscriptionCommonProps } from './AddPaymentMethod';
import PaymentModal from './PaymentModal';
import PaymentPlanSummary from './PaymentPlanSummary';
import { inject } from 'mobx-react';
import PaymentService, { PaymentMethod, Plan, StripeCustomer, Subscription } from '../../../services/PaymentService';
import PaymentMethodEntry from './PaymentMethodEntry';

interface CheckoutProps extends SubscriptionCommonProps {
  creditCardDetails: Array<PaymentMethod> | undefined;
  defaultPaymentMethodId: string;
  isLoading: boolean;
  onCloseAddCardModal: () => void;
  onChangePaymentMethods: (defaultPaymentMethodId?: string) => Promise<void>;
  onOpenAddCardModal: () => void;
  onTriggerFinalActionClick: (plan: Plan) => Promise<void>;
  payment?: PaymentService;
  payMethodsQuantity?: number;
  paymentMethods: Array<PaymentMethod> | undefined;
  plan: Plan;
  showAddCardModal: boolean;
  subscriptions: Array<Subscription> | undefined;
  stripeCustomer: StripeCustomer | undefined;
  trialDays: number;
}

@inject('alert')
export default class Checkout extends React.Component<CheckoutProps> {

  async onTriggerFinalSubscription(plan: Plan): Promise<void> {
    await this.props.onTriggerFinalActionClick(plan);
  }

  onCloseAddCardModal(): void {
    this.props.onCloseAddCardModal();
  }

  onOpenAddCardModal(): void {
    this.props.onOpenAddCardModal();
  }

  render(): React.ReactNode {
    const {
      auth,
      defaultPaymentMethodId,
      elements,
      isLoading,
      onChangePaymentMethods,
      payment,
      paymentMethods,
      payMethodsQuantity,
      plan,
      showAddCardModal,
      stripe,
      stripeCustomer,
      subscriptions,
      trialDays
    } = this.props;

    return (
      <Grid divided='vertically' textAlign='left' style={{ height: '50', marginTop: '2%' }} stackable>
        <GridRow columns={2}>
          <GridColumn style={{ maxWidth: 400 }}>
            {
              payMethodsQuantity !== 0 ? <List celled>
                {
                  paymentMethods?.map(paymentMethod =>
                    <PaymentMethodEntry
                      checked={paymentMethod.id === defaultPaymentMethodId}
                      elements={elements}
                      isLoading={isLoading}
                      key={paymentMethod.id}
                      onChangePaymentMethods={(defaultPaymentMethodId: string | undefined): Promise<void> => onChangePaymentMethods(defaultPaymentMethodId)}
                      payment={payment}
                      paymentMethod={paymentMethod}
                      paymentMethods={paymentMethods}
                      stripe={stripe}
                      stripeCustomer={stripeCustomer}
                      value={paymentMethod.id}
                    />
                  )
                }
              </List> :
                <AddPaymentMethod
                  auth={auth}
                  elements={elements}
                  onChangePaymentMethods={(defaultPaymentMethodId: string | undefined): Promise<void> => onChangePaymentMethods(defaultPaymentMethodId)}
                  payment={payment}
                  paymentMethods={paymentMethods}
                  stripe={stripe}
                />
            }
            <PaymentModal
              onCloseAddCardModal={(): void => this.onCloseAddCardModal()}
              onOpenAddCardModal={(): void => this.onOpenAddCardModal()}
              paymentMethodsQuantity={payMethodsQuantity as number}
              showAddCardModal={showAddCardModal}
            >
              <AddPaymentMethod
                auth={auth}
                elements={elements}
                onChangePaymentMethods={(defaultPaymentMethodId: string | undefined): Promise<void> => onChangePaymentMethods(defaultPaymentMethodId)}
                payment={payment}
                paymentMethods={paymentMethods}
                stripe={stripe}
              />
            </PaymentModal>
          </GridColumn>
          <GridColumn>
            <PaymentPlanSummary
              auth={auth}
              isLoading={isLoading}
              onSubscribe={(): Promise<void> => this.onTriggerFinalSubscription(plan)}
              payment={payment}
              paymentMethods={paymentMethods}
              plan={plan}
              subscriptions={subscriptions}
              trialDays={trialDays}
            />
          </GridColumn>
        </GridRow>
      </Grid>
    );
  }
}
