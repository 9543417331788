import React from 'react';
import Page, { MediaContextProvider, Media } from '../../components/Page';
import { DataService } from '../../services/DataService';
import { Link, RouteChildrenProps } from 'react-router-dom';
import Page404 from '../Page404';
import { Button, Container, Form, Grid, Header, Icon, Label, Loader, Card } from 'semantic-ui-react';
import CloseProject from './components/CloseProject';
import { ApplicationJSON } from '../../schemas/applications/ApplicationJSON';
import ActiveApplication from '../../schemas/applications/ActiveApplication';
import ProjectSummary from './components/summary/ProjectSummary';
import ActiveProject from '../../schemas/projects/ActiveProject';
import NewApplication from '../applications/components/NewApplication';
import './project.css';
import { MAX_PHONE_WIDTH_MEDIA_QUERY } from '../../common/Constants';
import ReusableModal from '../../components/ReusableModal';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { AlertService } from '../../services/AlertService';
import { FirebaseAuthService } from '../../services/AuthService';
import ThankYouModal from '../subscription/components/ThankYouModal';
import DesktopApplicationList from './components/DesktopApplicationList';
import MobileApplicationList from './components/MobileApplicationList';

// The following line has been added to prevent an issue that is introduced
// only when the project is minified when compiled (see defect #737)
// eslint-disable-next-line @typescript-eslint/camelcase
const patch_noop_do_not_remove = (): React.ReactElement => (<Card />);

interface ShowProjectProps extends RouteChildrenProps<{ id: string }> {
  alert?: AlertService;
  auth?: FirebaseAuthService;
}

interface ShowProjectState {
  loading: boolean;
  error: boolean;
  project?: ActiveProject | null;
  isMoveToArchiveInitialized: boolean;
  openedThankYouModal: boolean;
}

@inject('auth')
@observer
export default class ShowProject extends React.Component<ShowProjectProps, ShowProjectState> {

  state: ShowProjectState = {
    loading: true,
    error: false,
    isMoveToArchiveInitialized: false,
    openedThankYouModal: false
  }
  dataService = new DataService();

  componentDidMount(): void {
    let error = true;
    let project: ActiveProject | null = null;

    // Proceed if we have an id
    if (this.props.match?.params.id) {
      project = new ActiveProject(this.props.match.params.id);
      error = project?.error || false;
      project.load()
        .then(() => {
          error = project?.error || false;
          this.setState({ project, error, loading: false });
        }).catch(err => {
          this.setState({ error: true, loading: false, project: null });
        });
    } else {
      project = new ActiveProject();
      this.setState({ project, loading: false, error });
    }
  }


  async onProjectClose(finalApplication: ApplicationJSON): Promise<void> {
    this.setState({ loading: true });
    const currentApplication = new ActiveApplication(finalApplication.id);
    await currentApplication.load();
    if (this.state.project?.applications) {
      this.state.project.applications.push(finalApplication);
    } else if (this.state.project) {
      this.state.project.applications = [ finalApplication ];
    }
    await this.state.project?.calculate();
    this.setState({ project: this.state.project, loading: false });
  }


  async triggerMoveProjectToArchive(project: ActiveProject): Promise<void> {
    this.setState({ loading: true });
    try {
      const params = new URLSearchParams();
      params.append('id', project.id as string);
      const json = { archivedAt: moment(Date.now()).toDate() };
      await this.dataService.patch('projects', params, json);
      this.setState({
        isMoveToArchiveInitialized: false,
        loading: false,
        openedThankYouModal: true
      });
    } catch (e) {
      this.setState({ isMoveToArchiveInitialized: false, loading: false });
      this.props.alert?.createAlert({
        message: 'Error while moving project to archive. Please try again.',
        title: 'Error',
        type: 'error'
      });
    }
  }

  closeThankYouModal(): void {
    this.setState({ openedThankYouModal: false });
  }

  render(): React.ReactNode {
    const { isMoveToArchiveInitialized, loading, project, error, openedThankYouModal } = this.state;
    // Show the loader
    if (loading) {
      return <Loader active>Loading...</Loader>;
    }

    // Show the error page
    if (error) {
      return <Page404 { ...this.props } />;
    }
    // if project can't be found display 404
    if(project === null){
      return <Page404 {...this.props} />;
    }

    const isMobileView: boolean = window.matchMedia(MAX_PHONE_WIDTH_MEDIA_QUERY).matches;
    const hiddenOrVisibleOnMobile: string = isMobileView ? 'none' : 'block';
    return (
      <MediaContextProvider>
        <Page
          pageTitle='Project Summary'
          breadcrumb={ [
            { key: 'home', href: '/', content: 'Your Projects' },
            { key: 'projects', content: 'Project Summary', active: true },
          ] }
        >
          <Grid stackable>
            <Grid.Column width={ 8 }>
              <Form>
                <Form.Field>
                  <Grid stackable>
                    <Grid.Column width={ 11 }>
                      <Header as="h3">
                        { this.state.project?.name }<br/>
                        { this.state.project?.addressLine1 }<br/>
                        {
                          this.state.project?.addressLine2 ?
                            this.state.project?.addressLine2 + '<br/>' : ''
                        }
                        { this.state.project?.city }, { this.state.project?.state } { this.state.project?.zip }
                      </Header>
                    </Grid.Column>
                    <ReusableModal
                      isInitialized={ isMoveToArchiveInitialized }
                      headerContent={ 'Archive this project' }
                      leftButtonColor={ 'grey' }
                      leftButtonContent={ 'Cancel' }
                      rightButtonColor={ 'red' }
                      rightButtonContent={ 'Confirm' }
                      isLoading={ loading }
                      onLeftButtonAction={ (): void => this.setState({ isMoveToArchiveInitialized: false }) }
                      onRightButtonAction={ (): Promise<void> => this.triggerMoveProjectToArchive(this.state.project as ActiveProject) }>
                      <Container>
                        Archiving this project will send it to the archive, and it will no longer appear in
                        { <b> Your Projects</b> }.
                        You can permanently delete projects in your archive, or restore them if you wish.
                        Are you sure you want to archive this project?
                      </Container>
                    </ReusableModal>
                  </Grid>
                </Form.Field>

                <Form.Field>
                  <label>General Contractor</label>
                  <span>{this.state.project?.generalContractor?.name}</span>
                </Form.Field>


                <Form.Field>
                  <label>Project #</label>
                  <span>{ this.state.project?.projectId }</span>
                </Form.Field>
                <Form.Field>
                  <label>Subcontractor #</label>
                  <span>{ this.state.project?.subcontractNumber }</span>
                </Form.Field>
                <Form.Field>
                  <label>Status</label>
                  {
                    this.state.project?.currentApplication?.status === 'Final' ? (
                      <Label style={ { marginLeft: 0 } } color='grey'>Closed</Label>
                    ) : (
                      <Label style={ { marginLeft: 0 } } color='green'>Open</Label>
                    )
                  }
                </Form.Field>

                {
                  !this.state.project?.hasCompletedApplications ? (
                    <React.Fragment>
                      <Form.Group style={ { display: hiddenOrVisibleOnMobile } }>
                        <Media at={ 'mobile' }>
                          <div/>
                        </Media>
                        <Media greaterThan={ 'mobile' }>
                          <Button color="green" as={ Link }
                            style={ {
                              paddingLeft: 40,
                              paddingRight: 40,
                              left: 10,
                              marginLeft: 8
                            } }
                            to={ {
                              pathname: `/projects/${ this.state.project?.id }/edit`,
                              search: window.location.pathname
                            } }>
                            <Icon name='pencil'/>
                            Edit Project Info
                          </Button>
                        </Media>
                      </Form.Group>
                      <Form.Group style={ { display: hiddenOrVisibleOnMobile } }>
                        <Media at={ 'mobile' }>
                          <div/>
                        </Media>
                        <Media greaterThan={ 'mobile' }>
                          <Button color="green" as={ Link }
                            style={{
                              left: 7,
                              marginLeft: 7
                            }}
                            to={ {
                              pathname: `/projects/${ this.state.project?.id }/edit-project-lines`,
                              state: { project: this.state.project }
                            } }>
                            <Icon name='list'/>
                            { this.state.project?.projectLines?.length === 0 ? 'Add' : 'Edit' } Project Line Items
                          </Button>
                        </Media>
                      </Form.Group>
                    </React.Fragment>
                  ) : ''
                }

                {
                  // Add a change orders button if the project isn't closed and we have a valid project
                  !this.state.project?.closed && this.state.project?.excessShortfall === 0 ? (
                    <Form.Group className="change-orders-button">
                      <Button color="red" as={ Link }
                        style={ {
                          marginLeft: 7,
                          paddingRight: 44,
                          paddingLeft: 44

                        } }
                        to={ `/projects/${ this.state.project?.id }/change-orders` }>
                        <Icon name='file alternate'/>
                        Change Orders
                      </Button>
                    </Form.Group>
                  ) : <div/>
                }

                {
                  project?.archivedAt ? null :
                    <Button
                      style={ {
                        paddingLeft: 42,
                        paddingRight: 42
                      } }
                      onClick={ (): void => this.setState({ isMoveToArchiveInitialized: true }) }
                    >
                      <Icon name={ 'archive' }
                      />
                      Archive Project
                    </Button>
                }
              </Form>
              <br/>
              <Grid.Row>
                <Media at={ 'mobile' }>
                  <div style={ {
                    position: 'relative',
                    right: 13
                  } }>
                    <ProjectSummary project={ this.state.project as ActiveProject }/>
                  </div>
                </Media>
                <Media greaterThan={ 'mobile' }>
                  <ProjectSummary project={ this.state.project as ActiveProject}/>
                </Media>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={ 8 } style={ { bottom: 25 } }>
              <Grid.Row>
                {
                  this.state.project?.allowClose ? (
                    <p>
                      <CloseProject project={ this.state.project }
                        onClose={ (finalApplication): Promise<void> => this.onProjectClose(finalApplication) }/>
                    </p>
                  ) : ''
                }
              </Grid.Row>
              <Grid.Row>
                <br/>
                <Media at={'mobile'}>
                  <div/>
                </Media>
                <Media greaterThan={'mobile'}>
                  {
                    this.state.project?.currentApplication ? (
                      <React.Fragment>
                        {
                          this.state.project.currentApplication.status !== 'Completed' && this.state.project.currentApplication.status !== 'Final' && this.state.project?.excessShortfall === 0 ? (
                            <p style={ { display: hiddenOrVisibleOnMobile } }>
                              <Button primary as={ Link }
                                style={ { paddingLeft: 29, paddingRight: 29 } }
                                to={ `/applications/${ this.state.project.currentApplication.id }/edit` }>
                                <Icon name='pencil'/>
                                Edit Application #{ this.state.project.currentApplication.sequenceNumber }
                              </Button>
                            </p>
                          ) : ''
                        }
                        {
                          this.state.project?.excessShortfall === 0 && this.state.project?.allowApplications ? (
                            <p>
                              <NewApplication application={ this.state.project.currentApplication }
                                onSave={ (): void => this.setState({ loading: true }) } { ...this.props } />
                            </p>
                          ) : ''
                        }
                      </React.Fragment>
                    ) : !this.state.project?.currentApplication && this.state.project?.excessShortfall === 0 ? (
                      <p style={ { display: hiddenOrVisibleOnMobile } }>
                        <Button primary as={ Link } to={ {
                          pathname: '/applications/new',
                          state: { project: this.state.project }
                        } }>
                          <Icon name='plus'/>
                          Create Application #1
                        </Button>
                      </p>
                    ) : ''
                  }
                </Media>
              </Grid.Row>
              <br/>
              {
                project?.excessShortfall === 0 && ( <Grid.Row>
                  <DesktopApplicationList
                    key={project.projectId}
                    project={this.state.project as ActiveProject}
                  />
                  <MobileApplicationList
                    key={project.projectId}
                    project={this.state.project as ActiveProject}/>
                </Grid.Row>)
              }
            </Grid.Column>
          </Grid>
          <ThankYouModal
            header='Archived'
            openedThankYouModal={openedThankYouModal}
            onClose={(): void => this.closeThankYouModal()}
            navigateToAfterClose='/'
          >
            You successfully moved project to archive.
          </ThankYouModal>
        </Page>
      </MediaContextProvider>
    );
  }
}
