import * as React from 'react';
import { BlobProvider, PDFViewer } from '@react-pdf/renderer';
import { ContinuationSheetPDF } from '../ContinuationSheetPDF';
import { RouteChildrenProps } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { FirebaseAuthService } from '../../services/AuthService';
import {
  APPLICATION_FOR_PAYMENT_PDF_PATH,
  CHANGE_ORDERS_PDF_PATH,
  CONTINUATION_SHEET_PDF_PATH
} from '../../common/Constants';
import { ChangeOrdersPDF } from '../ChangeOrdersPDF';
import { ApplicationPDF } from '../ApplicationPDF';
import moment from 'moment';
import { MediaContextProvider, Media } from '../../components/Page';
import ActiveApplication from '../../schemas/applications/ActiveApplication';
import { ProjectJSON } from '../../schemas/projects/ProjectJSON';
import firebase from 'firebase';
import { CustomLoader } from '../../components/CustomLoader';
import { inject } from 'mobx-react';

interface PDFRenderState {
  application: ActiveApplication | null;
  pdf: React.ReactElement | null;
  filename: string | null;
}

interface PDFRenderProps extends RouteChildrenProps<{ id: string }> {
  auth?: FirebaseAuthService;
}

@inject('auth')
export default class PDFRender extends React.Component<PDFRenderProps> {

  state: PDFRenderState = {
    application: null,
    pdf: null,
    filename: null,
  };

  async componentDidMount(): Promise<void> {
    if (this.props.match?.params.id) {
      const application = new ActiveApplication(this.props.match.params.id);
      await application.load();
      if (application) {
        const logo = await this.props.auth?.getLogo();
        const [ filename, pdf ] = this.generatePDF(application, logo);
        this.setState({ application, pdf, filename });
        document.title = filename;
      }
    }
  }

  generatePDF(application: ActiveApplication, logo?: {url: string; width: number; height: number}): [string, null | React.ReactElement] {
    const path = this.props.location.pathname;
    const project = application.project as ProjectJSON;
    let filename = String(application?.project?.name);
    let pdf: null | React.ReactElement = null;
    firebase.analytics().setCurrentScreen(window.location.pathname);

    if (path.startsWith(CONTINUATION_SHEET_PDF_PATH)) {
      pdf = ContinuationSheetPDF(application, project, logo);
      filename += '_continuation_';
      firebase.analytics().logEvent('download_continuation_sheet');
    }
    if (path.startsWith(CHANGE_ORDERS_PDF_PATH)) {
      pdf = ChangeOrdersPDF(application, project, logo);
      filename += '_change_orders_';
      firebase.analytics().logEvent('download_change_order');
    }
    if (path.startsWith(APPLICATION_FOR_PAYMENT_PDF_PATH)) {
      pdf = ApplicationPDF(application, project, logo);
      filename += '_payapp_';
      firebase.analytics().logEvent('download_application_for_payment');
    }
    filename += moment(project.updatedAt).add(new Date().getTimezoneOffset(), 'minutes')
      .format(moment.HTML5_FMT.DATETIME_LOCAL)
      .replace(/:/g, '-');
    return [ filename, pdf ];
  }

  render(): React.ReactNode {
    const { pdf, filename } = this.state;
    if (pdf) {
      return (
        <MediaContextProvider>
          <BlobProvider document={ pdf as React.ReactElement }>
            { ({ blob, loading }): JSX.Element => {
              if (loading) {
                return <CustomLoader loadingMessage={ 'Generating PDF Document...' }/>;
              }
              if (blob) {
                const url = URL.createObjectURL(blob);
                return (
                  <React.Fragment>
                    <Media greaterThan="mobile">
                      <div>
                        <PDFViewer className="PDFViewer" style={ {
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%'
                        } }>
                          {
                            pdf as React.ReactElement
                          }
                        </PDFViewer>
                        <div
                          style={ {
                            position: 'fixed',
                            top: 0,
                            width: '100%',
                            height: '56px',
                            background: 'white',
                          } }
                        >
                          <Button
                            download={ filename }
                            href={ url }
                            icon="download"
                            content={ 'Download' }
                            style={ {
                              position: 'fixed',
                              top: 10,
                              right: 100,
                            } }
                            className="ui primary button"
                          />
                        </div>
                      </div>
                      <div>
                        <div>
                          <Button
                            onClick={ (): void => {
                              const doc = document.getElementsByClassName('PDFViewer')[0] as any;
                              doc?.contentWindow.print();
                            } }
                            icon="print"
                            content={ 'Print' }
                            style={ {
                              position: 'fixed',
                              top: 10,
                              right: 225,
                              marginLeft: '8px',
                              marginRight: '8px'
                            } }
                            className="ui primary button"/>
                        </div>

                      </div>
                    </Media>

                    <Media at="mobile">
                      <div style={ {
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        textAlign: 'center',
                      } }>
                        <Button
                          icon="download"
                          content={ 'Download' }
                          download={ filename }
                          href={ url }
                          style={ {
                            marginTop: '50%',
                          } }
                          className="ui primary button"
                        />
                      </div>
                    </Media>
                  </React.Fragment>
                );
              } else {
                return <CustomLoader loadingMessage={ 'Generating PDF Document...' }/>;
              }
            } }
          </BlobProvider>
        </MediaContextProvider>
      );
    } else {
      return <CustomLoader loadingMessage={ 'Generating PDF Document...' }/>;
    }
  }
}
