import React, { ChangeEvent } from 'react';
import { Button, Form, Message, Segment, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import TagManagerService from '../../services/TagManagerService';
import { AuthProps } from '../../pages/LoginPage';

interface LoginPageState {
  errorMessage: string;
  email: string;
  password: string;
  loading: boolean;
  hidePassword: boolean;
  isEmailValid: boolean;
  isTypingEmail: boolean;
}

@inject('auth')
@observer
export default class EmailPasswordLogin extends React.Component<AuthProps, LoginPageState> {

  state: LoginPageState = {
    errorMessage: '',
    email: '',
    password: '',
    loading: false,
    hidePassword: true,
    isEmailValid: true,
    isTypingEmail: false
  }

  async componentDidMount(): Promise<void> {
    if (typeof this.props.location !== 'undefined') {
      const email = this.props.location.state ? this.props.location.state.email : '';
      this.setState({ email });
    }
  }

  private async login(): Promise<void> {
    this.setState({ loading: true });
    if (this.props.auth) {
      try {
        await this.props.auth.login(this.state.email as string, this.state.password as string);
        this.setState({ errorMessage: '' });
        TagManagerService.logLoginEvent(this.state.email);
      } catch (e) {
        this.setState({ errorMessage: e.message, loading: false });
      }
    }
  }

  displayErrorMessage(): React.ReactNode {
    if (this.state.errorMessage) {
      return (
        <Message large negative>
          {this.state.errorMessage}
        </Message>
      );
    }

    if (!this.state.isEmailValid && !this.state.isTypingEmail) {
      return <Message error content='Invalid email address'/>;
    }
  }

  async validateEmailInput(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.setState({ isTypingEmail: false });
    const valid = e.target.value.match(/\S+@\S+\.\S+/);
    if(valid?.length){
      this.setState({ isEmailValid: true });
    }else {
      this.setState({ isEmailValid: false });
    }
  }

  onEmailChange(e: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ isTypingEmail: true });
    const valid = e.target.value.trim().match(/\S+@\S+\.\S+/);
    if(valid?.length){
      this.setState({ isEmailValid: true });
    }else {
      this.setState({ isEmailValid: false });
    }
    this.setState({ email: e.target.value.trim() });
  }

  render(): React.ReactNode {
    if (this.props.auth?.user?.id) {
      this.setState({ loading: false });
      return <Redirect to="/"/>;
    }

    return (
      <span>
        <div style={{ width: '420px', height: '60px', display: 'block', marginBottom: '10px' }}>
          {this.displayErrorMessage()}
        </div>
        <Form size='large'>
          <Segment stacked>
            <Form.Input
              required
              type="email"
              error={!this.state.isEmailValid && !this.state.isTypingEmail}
              placeholder="E-mail address"
              value={this.state.email}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => this.onEmailChange(e) }
              onFocus={(): void => this.setState({ isEmailValid: true })}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>): Promise<void> => this.validateEmailInput(e)}
            />
            <Form.Input
              required
              icon={<Icon
                name={this.state.hidePassword ? 'eye slash' : 'eye'}
                link
                onClick={(): void => this.setState({ hidePassword: !this.state.hidePassword })}
                style={{
                  color: !this.state.hidePassword ? '#2185d0' : 'black',
                  opacity: !this.state.hidePassword ? 1 : 0.5
                }}
              />}
              type={this.state.hidePassword ? 'password' : 'text'}
              placeholder="Password"
              value={this.state.password}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => this.setState({ password: e.target.value.trim() })}
            />
            <Button
              primary
              fluid
              loading={this.state.loading}
              size='large'
              disabled={!this.state.email || !this.state.password || !this.state.isEmailValid || this.state.loading}
              onClick={(): Promise<void> => this.login()}
            >
              {
                this.state.loading
                  ? <span>&nbsp;</span>
                  : 'Login'
              }
            </Button>
          </Segment>
        </Form>
      </span>
    );
  }

}
