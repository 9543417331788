export default class Currency {
  private _value!: number | undefined;
  private _displayValue!: string;

  set value(value: number | string | undefined | null) {
    // Set the values if a number is entered
    if (typeof value === 'number') {
      this._value = isNaN(value) ? undefined : value;
      this._displayValue = value.toLocaleString();
      return;
    }

    // Conditions for setting to undefined
    if (
      // If we don't get a string
      typeof value !== 'string' ||
      (
        typeof value === 'string'
        // Test for empty string, with optional
        // minus or decimal
        && value.trim().match(/^-?\.?$/)
      )
    ) {
      this._value = undefined;
      this._displayValue = typeof value === 'string' && value.trim().match(/^-?\.?$/) ? value.trim() : '';
      return;
    }

    // Cleanse the string
    let cleanValue: string;
    const splitValue = value.split('.');
    if (splitValue.length === 1) {
      cleanValue = value.replace(/[^\d-.]/g, '').replace(/(\.\d{1,2}).*$/, '$1');
    } else {
      cleanValue =
        splitValue[0].replace(/[^\d-]/g, '') +
        '.' +
        splitValue[1].replace(/[^\d]/g, '').substr(0, 2);
    }

    // Set the value
    this._value = Number(cleanValue);

    // Set to 0 if isNaN
    if (isNaN(this._value)) this._value = 0;

    // See if we have a trailing period (or period with zeros)
    const trailingPeriod = cleanValue.match(/(\.0{0,2})$/);

    // Set the display value along w/ trailing period
    this._displayValue =
      this._value.toLocaleString() + (trailingPeriod ? trailingPeriod[0] : '');
  }

  get value(): number | string | undefined | null {
    return this._value;
  }

  get displayValue(): string {
    return this._displayValue;
  }

  constructor(value?: string | null) {
    this.value = value;
  }
}
