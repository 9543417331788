import React from 'react';
import { Container, Icon, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import {
  APPLICATION_FOR_PAYMENT_PDF_PATH,
  CHANGE_ORDERS_PDF_PATH,
  CONTINUATION_SHEET_PDF_PATH
} from '../../../common/Constants';
import ActiveProject from '../../../schemas/projects/ActiveProject';
import { FirebaseAuthService } from '../../../services/AuthService';
import { inject, observer } from 'mobx-react';
import { ApplicationJSON } from '../../../schemas/applications/ApplicationJSON';
import ActiveApplication from '../../../schemas/applications/ActiveApplication';
import { Media } from '../../../components/Page';


export interface DesktopApplicationListProps {
  auth?: FirebaseAuthService;
  project?: ActiveProject;
}


export function isChangeOrdersEmpty(appSequenceNumber: number, project: ActiveProject): boolean {
  const length = project.changeOrders?.filter(order => order.firstApplicationSequenceNumber === appSequenceNumber || appSequenceNumber > Number(order.firstApplicationSequenceNumber)).length;
  return !!length;
}

@inject('auth')
@inject('cookie')
@observer
export default class DesktopApplicationList extends React.Component<DesktopApplicationListProps> {

  render(): React.ReactNode {
    const { project } = this.props;
    const hasChangeOrders: boolean | undefined = project?.projectLines && project.projectLines.filter(p => p.lineType === 'changeOrder').length > 0;


    if (typeof project !== 'undefined')
      if (!project.applications || (project.applications && project.applications.length < 1)) {
        return '';
      }
    return (
      <Media greaterThan={'mobile'}>
        <Container>
          <Table celled stackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ fontSize: '1.2em' }} textAlign={'center'}
                  colSpan={hasChangeOrders ? 5 : 4}>
                  Application Document History
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell textAlign={'center'}>App #</Table.HeaderCell>
                <Table.HeaderCell textAlign={'center'}>Date</Table.HeaderCell>
                <Table.HeaderCell textAlign={'center'}>Application</Table.HeaderCell>
                <Table.HeaderCell textAlign={'center'}>Continuation Sheet</Table.HeaderCell>
                {
                  hasChangeOrders ?
                    <Table.HeaderCell textAlign={'center'}>Change Orders</Table.HeaderCell> : null
                }
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                typeof project?.applications !== 'undefined' && project?.applications
                  .sort((a1: ApplicationJSON, a2: ApplicationJSON) => {
                    if (typeof a1.sequenceNumber !== 'undefined' && typeof a2.sequenceNumber !== 'undefined') {
                      return a2.sequenceNumber - a1.sequenceNumber;
                    }
                    return 0;
                  })
                  .map(application => {
                    const app = new ActiveApplication(application);
                    const applicationDate = new Date(app.applicationDate as Date | string).toLocaleDateString();
                    return (
                      <Table.Row key={app.id}>
                        <Table.Cell style={{ marginLeft: '4px', textAlign: 'center' }}>
                          <div style={{ marginLeft: '5px' }}>{app.sequenceNumber}</div>
                        </Table.Cell>

                        <Table.Cell textAlign={'center'}>
                          {applicationDate}
                        </Table.Cell>

                        <Table.Cell textAlign={'center'} style={{ color: '#2670BF' }}>
                          <Link target='_blank' to={APPLICATION_FOR_PAYMENT_PDF_PATH + `${app.id}`}>
                            <Icon size={'large'} name={'file pdf'}/>
                          </Link>
                        </Table.Cell>

                        <Table.Cell style={{ color: '#2670BF' }} textAlign={'center'}>
                          <Link target='_blank' to={CONTINUATION_SHEET_PDF_PATH + `${app.id}`}>
                            <Icon size={'large'} name={'file pdf'}/>
                          </Link>
                        </Table.Cell>

                        {
                          hasChangeOrders ? <Table.Cell style={{ color: '#2670BF' }} textAlign={'center'}>
                            {
                              isChangeOrdersEmpty(app.sequenceNumber as number, project)
                                ?
                                <Link target='_blank' to={CHANGE_ORDERS_PDF_PATH + `${app.id}`}><Icon size={'large'}
                                  name={'file pdf'}/></Link> : null
                            }
                          </Table.Cell> : null
                        }
                      </Table.Row>
                    );
                  })
              }
            </Table.Body>
          </Table>
        </Container>
      </Media>
    );
  }
}
