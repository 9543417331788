import React, { ReactElement } from 'react';
import { FirebaseAuthService } from '../services/AuthService';
import { inject, observer } from 'mobx-react';
import { Button, Checkbox, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import CookieService from '../services/CookieService';

interface ProfileRecommendedProps {
  auth?: FirebaseAuthService;
  cookie?: CookieService;
  applicationId: string;
  preventAction: ReactElement;
  allowAction: ReactElement;
}

interface ProfileRecommendedState {
  modalDisplayed: boolean;
  ignoreMissingProfile: boolean;
}

@inject('auth')
@inject('cookie')
@observer
export default class ProfileRecommended extends React.Component<ProfileRecommendedProps, ProfileRecommendedState> {
  state: ProfileRecommendedState;

  constructor(props: ProfileRecommendedProps) {
    super(props);
    this.state = {
      modalDisplayed: false,
      ignoreMissingProfile: false,
    };
  }

  clickedChildren(): void {
    // If the cookie isn't set, show the modal
    if (!this.props.cookie?.isIgnored(this.props.applicationId) && !this.state.modalDisplayed) {
      this.setState({ modalDisplayed: true });
    }
  }

  closeModal(): void {
    if (this.state.ignoreMissingProfile) {
      this.props.cookie?.ignoreMissingProfile(this.props.applicationId);
    }
    this.setState({ modalDisplayed: false });
  }

  render(): React.ReactNode {
    // If the profile is complete or we're ignoring, just render the children
    if (this.props.auth?.profileComplete || this.props.cookie?.isIgnored(this.props.applicationId)) {
      return this.props.allowAction;
    }

    return (
      <span onClick={(): void => this.clickedChildren()}>
        <Modal open={this.state.modalDisplayed}>
          <Modal.Header>Profile Recommended</Modal.Header>
          <Modal.Content>
            <p>
                We recommend you update your profile, as it will be displayed on the application for payment you submit.
                Without completing your profile, your company name and contact info will not appear on the application for payment.
            </p>
            <p>
                Do you wish to update your profile?
            </p>
            <p>
              <Checkbox
                label='Do not show this message again for this application'
                onClick={(): void => this.setState({ ignoreMissingProfile: !this.state.ignoreMissingProfile })}
              />
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={ (): void => this.closeModal()}>No</Button>
            <Button color='green' as={Link} to={{ pathname: '/profile/update', query: { callbackUrl: window.location.pathname } }}>Yes</Button>
          </Modal.Actions>
        </Modal>
        {this.props.preventAction}
      </span>
    );
  }
}
