import React from 'react';
import { Header, Image } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import { AuthServiceInterface } from '../services/AuthService';
import CenteredGrid from '../components/CenteredGrid';
import Page from '../components/Page';
import { Redirect, } from 'react-router-dom';
import EmailVerificationRequiredPage from './EmailVerificationRequiredPage';
import CookieService from '../services/CookieService';
import EmailNoPasswordSignup from '../components/auth/EmailNoPasswordSignup';


export interface AuthProps {
  auth?: AuthServiceInterface;
  cookie?: CookieService;
}

@inject('auth')
@observer
export default class NoPasswordSignupPage extends React.Component<AuthProps> {
  render(): React.ReactNode {

    if (this.props.auth?.user?.id) {
      setTimeout(() => {
        this.setState({ loading: false });
      });
      if (!this.props.auth.user.emailVerified) {
        return <EmailVerificationRequiredPage firstLogin={true} />;
      }
      return <Redirect to="/"/>;
    }

    return (
      <Page>
        <CenteredGrid>
          <Image centered style={{ height: '100px' }} src='/img/PAYearned-logo.png'/>
          <Header as='h2' color='blue' textAlign='center'>Signup for a free account</Header>
          <EmailNoPasswordSignup />
        </CenteredGrid>
      </Page>
    );
  }
}
