import Page from '../components/Page';
import React from 'react';
import { AlertService } from '../services/AlertService';
import { Header, Message } from 'semantic-ui-react';
import { BASE_URL, DataService } from '../services/DataService';
import { inject, observer } from 'mobx-react';
import { ProjectColumnName } from '../common/Constants';
import { ProjectJSON } from '../schemas/projects/ProjectJSON';
import { ReactTabulator } from 'react-tabulator';
import { RouteChildrenProps } from 'react-router-dom';
import { Method, PaginationType, TabulatorOptions } from '../common/tabulator-utils/SchemaDefinition';
import { Auth } from '../services/AuthService';
import { ajaxURLGenerate, mapAjaxResponse } from '../common/tabulator-utils/AjaxUtils';
import ClearProjectFilters from '../components/ClearProjectFilters';

interface UnbilledRetainagePageProps extends RouteChildrenProps<{id: string}> {
  alert?: AlertService;
}

interface UnbilledRetainagePageState {
  loading: boolean;
  projects: Array<ProjectJSON>;
  filtered: boolean;
  alert?: AlertService;
}

@inject('alert')
@observer
export default class UnbilledRetainagePage extends
  React.Component<UnbilledRetainagePageProps, UnbilledRetainagePageState> {
  ref?: React.ElementRef<any>;
  dataService = new DataService();

  state: UnbilledRetainagePageState = {
    loading: true,
    projects: [],
    filtered: false,
  }

  columns = [
    {
      title: ProjectColumnName.CreateDate, field: 'createdAt',
      formatter: 'link',
      formatterParams: this.getDateFormatterParams(),
      width: 125,
    },
    {
      title: ProjectColumnName.ProjectName, field: 'name',
      formatter: 'link',
      formatterParams: this.getFormatterParams(),
      headerFilter: 'input',
      headerFilterPlaceholder: 'Search project name',
    },
    {
      title: ProjectColumnName.Location, field: 'addressLine1',
      formatter: 'link',
      formatterParams: this.getFormatterParams(),
      headerFilter: 'input',
      headerFilterPlaceholder: 'Search location',
    },
    {
      title: ProjectColumnName.GeneralContractorName, field: 'generalContractor.name',
      formatter: 'link',
      formatterParams: this.getFormatterParams(),
      headerFilter: 'input',
      headerFilterPlaceholder: 'Search general contractor',
    },
  ]

  async componentDidMount(): Promise<void> {
    await this.getUnbilledRetainage();
  }

  getDateFormatterParams(): any {
    return {
      label: (cell: any) => {
        const value: Date = new Date(cell._cell.row.data.createdAt);
        return value.toLocaleDateString();
      },
      url: (cell: any) => {
        const value: number | string | null | undefined = cell._cell.row.data.id;
        return '/projects/' + value;
      }
    };
  }

  getFormatterParams(): any {
    return {
      url: (cell: any) => {
        const value: number | string | null | undefined = cell._cell.row.data.id;
        return '/projects/' + value;
      }
    };
  }

  async getUnbilledRetainage(): Promise<void> {
    let projects = await this.dataService?.listProjects(false, false, true) as Array<ProjectJSON>;
    if (!projects) projects = [];
    projects = projects.map(p => {
      p.addressLine1 = p.addressLine1?.concat(', ' + p.city || '', ' ' + p.zip || '');
      return p;
    });
    this.setState({ loading: false, projects });
  }

  rowClick = (e: any, row: any): void => {
    const value: number | string | null | undefined = row._row.data.id;
    this.props.history.push('/projects/' + value);
  };

  clearFilters(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.ref.table.clearHeaderFilter();
    this.setState({ filtered: false });
  }

  render(): React.ReactNode {

    const tabulatorOptions: TabulatorOptions = {
      pagination: PaginationType.REMOTE,
      paginationSize:10,
      ajaxConfig: {
        method: Method.GET,
        headers: {
          'Authorization': `Bearer ${ Auth.user?.accessToken }`,
        }
      },
      ajaxURL: `${ BASE_URL }/projects`,
      ajaxURLGenerator: ajaxURLGenerate,
      ajaxResponse: mapAjaxResponse,
      ajaxLoaderLoading: '<span>Loading.</span>',
      ajaxFiltering: true,
      ajaxParams: {
        closed: false,
        archived: false,
        unbilledRetainage:true
      },
      layout:'fitColumns',
      responsiveLayout: true
    };

    return(
      <Page pageTitle='Unbilled Retainage' breadcrumb={[
        { key: 'home', href: '/', content: 'Your Projects', link: true },
        { key: 'unbilledRetainage', content: 'Unbilled Retainage', active: true }
      ]}>
        <Message warning className='free-account-warning'>
          <Message.Header>Unbilled Retainage</Message.Header>
          <p>
            Here you will see projects that have had all lines billed to 100% completion, but have not yet had
            the retainage billed to the general contractor.  You should contact the general contractors on the projects
            listed here, to see if they approve the work completed, so that the final retainage bill can be sent.
          </p>
        </Message>
        <ClearProjectFilters filtered={this.state.filtered} clearFilters={() => this.clearFilters()} />
        {
          this.state.projects.length > 0 ? (
            <ReactTabulator
              ref={(ref: any) => (this.ref = ref)}
              // @ts-ignore
              columns={this.columns}
              options={tabulatorOptions}
              data={this.state.projects}
              rowClick={this.rowClick}
              dataFiltered={(): void => {
                let filtered = false;
                document.querySelectorAll('div.tabulator .tabulator-header-filter input').forEach((input) => {
                  if (!filtered) { filtered = (input as HTMLInputElement).value !== ''; }
                });
                if (filtered !== this.state.filtered) {
                  this.setState({ filtered });
                }
              }}
            />
          ) : (
            <Header as='h3'>There are no projects with unbilled retainage.</Header>
          )
        }
      </Page>
    );
  }
}
