import React from 'react';
import { Button, Container, Modal, ModalContent, ModalHeader } from 'semantic-ui-react';

interface ThankYouModalProps {
  header?: string;
  openedThankYouModal: boolean;
  onClose: () => void;
  navigateToAfterClose: string;
}

export default class ThankYouModal extends React.Component<ThankYouModalProps> {
  private closeModal(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();
    window.location.href = this.props.navigateToAfterClose;
  }

  render(): React.ReactNode {
    return (
      <Container>
        <Modal
          open={this.props.openedThankYouModal}
          onClose={(): void => this.props.onClose()}
        >
          <ModalHeader>{ this.props.header }</ModalHeader>
          <ModalContent>
            <Modal.Description>
              { this.props.children }
            </Modal.Description>
          </ModalContent>
          <Modal.Actions>
            <Button primary onClick={ (e: React.MouseEvent<HTMLButtonElement>): void => this.closeModal(e) }>
              {this.props.navigateToAfterClose === '/profile/update' ? 'Create Account Profile' : 'Ok'}
            </Button>
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}
