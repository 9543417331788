export interface TabulatorOptions {
  pagination: PaginationType;
  paginationSize: number;
  ajaxConfig: {
    method: Method;
    headers: {};
  };
  ajaxParams?: {
    closed: boolean;
    archived: boolean;
    unbilledRetainage?: boolean;
  };
  ajaxURL: string;
  ajaxURLGenerator: (url: any, config: any, params: any) => string;
  ajaxResponse: (url: any, params: any, response: any) => void;
  ajaxLoaderLoading: string;
  ajaxFiltering: boolean;
  [key: string]: any;
}

export enum PaginationType {
  REMOTE ='remote',
  LOCAL = 'local'
}

export enum Method {
  GET= 'get',
  FETCH = 'fetch'
}

export const tabulatorParamMap: any = {
  page: '$skip',
  size: '$limit',
  licenseNumber: 'license',
  contactName: 'contact',
  name: 'name',
  city: 'city',
  addressLine1: 'location',
  generalContractorname: 'gc',
  closed: 'closed',
  archived: 'archived',
  unbilledRetainage: 'unbilledRetainage'
};
