import React from 'react';
import { Link, Redirect, RouteProps } from 'react-router-dom';
import { AuthServiceInterface } from '../services/AuthService';
import { Button, Header, Image, Loader, Message, Segment } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import CenteredGrid from '../components/CenteredGrid';
import CookieService from '../services/CookieService';
import EmailPromptToCompletePasswordless from '../components/auth/EmailPromptToCompletePasswordless';

interface PasswordlessLoginSuccessProps extends RouteProps {
  mode: string;
  actionCode: string;
  auth?: AuthServiceInterface;
  cookie?: CookieService;
}

interface PasswordlessLoginSuccessState {
  confirmationMessage: string;
  errorMessage: string;
  redirect: boolean;
  displayEmailPrompt: boolean;
  badEmail: boolean;
}

@inject('auth')
@inject('cookie')
@observer
export default class PasswordlessLoginSuccess extends React.Component<PasswordlessLoginSuccessProps, PasswordlessLoginSuccessState> {

  state: PasswordlessLoginSuccessState = {
    confirmationMessage: '',
    errorMessage: '',
    redirect: false,
    displayEmailPrompt: false,
    badEmail: false,
  };

  async componentDidMount(): Promise<void> {
    await this.verifyLink();
  }

  async verifyLink(): Promise<void> {
    // Try to apply the email verification code.
    if (this.props.auth) {
      try {
        await this.props.auth.verifyLoginLink(this.props.cookie!.appCookies.passwordlessEmail!);
        setTimeout(() => this.setState({ redirect: true }), 5000);
        this.setState({ confirmationMessage: 'You have been logged into the app.' });
      } catch (e) {
        if (e.message === 'Email is missing' || e.message === 'Your email address is not valid. Please verify your email and try again') {
          const displayEmailPrompt = true;
          const badEmail = e.message === 'Your email address is not valid. Please verify your email and try again';
          this.setState( { displayEmailPrompt, badEmail });
        } else {
          this.setState({ errorMessage: e.message });
        }
      }
    }
  }

  displayMessage(): React.ReactNode {
    if (this.state.confirmationMessage) {
      return (
        <Message large success>
          {this.state.confirmationMessage}
        </Message>
      );
    } else if (this.state.errorMessage) {
      return (
        <Message large negative>
          {this.state.errorMessage}
        </Message>
      );
    }
  }

  render(): React.ReactNode {
    // Redirect user after verification to Home page
    if (this.state.redirect) {
      return <Redirect to='/'/>;
    }

    // Display email prompt
    if (this.state.displayEmailPrompt) {
      return <EmailPromptToCompletePasswordless badEmail={this.state.badEmail} />;
    }

    // If Auth service or Messages are not loaded, then show Loader
    if ((!this.props.auth?.initialized || !this.state.confirmationMessage) && !this.state.errorMessage) {
      return <Loader active={true}>Loading...</Loader>;
    }

    return (
      <CenteredGrid>
        <Image centered style={{ height: '100px' }} src='/img/PAYearned-logo.png'/>
        <Header as='h2' color='blue' textAlign='center'>{process.env.REACT_APP_APP_NAME} Login Complete</Header>
        <Segment stacked>
          {this.displayMessage()}
          <Button primary as={Link} to='/'>
            Go to {process.env.REACT_APP_APP_NAME}
          </Button>
        </Segment>
      </CenteredGrid>
    );
  }
}
