import * as React from 'react';
import { Image, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FirebaseAuthService } from '../../services/AuthService';
import { inject, observer } from 'mobx-react';

interface LogoButtonProps {
  auth?: FirebaseAuthService;
  img: string;
  size: 'mini' | 'small';
}

@inject('auth')
@observer
export default class LogoButton extends React.Component<LogoButtonProps> {

  render(): JSX.Element {
    return (
      <Menu.Item>
        <Image
          src={this.props.img}
          as={Link}
          to='/'
          size={this.props.size}
        />
      </Menu.Item>
    );
  }
}
