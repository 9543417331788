import React from 'react';
import { Grid } from 'semantic-ui-react';

export default class CenteredGrid extends React.Component {
  render(): React.ReactNode {
    return (
      <Grid textAlign='center' verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450, marginTop: '2em' }}>
          {this.props.children}
        </Grid.Column>
      </Grid>
    );
  }
}
