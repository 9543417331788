import { JSONSchema7 } from 'json-schema';
import CurrencyWidget from './widgets/currency/CurrencyWidget';
import { UiSchema } from '@rjsf/core';
import { ProjectLineJSON } from './ProjectLineSchema';
import PercentWidget from './widgets/PercentWidget';
import { TableCellFieldTemplate } from './templates/TableCellFieldTemplate';
import { ApplicationJSON } from './applications/ApplicationJSON';

export interface ApplicationLineJSON {
  id?: string;
  applicationId?: string;
  application?: ApplicationJSON;
  projectLineId?: string;
  projectLine?: ProjectLineJSON;
  sequenceNumber?: number;
  originalSequenceNumber?: number;
  lineDescription?: string;
  scheduledValue?: number;
  retainage?: number;
  billedToDate?: number;
  incrementalBilledToDate?: number;
  percentComplete?: number;
  incrementalBillAmount?: number;
  retained?: number;
  storedToDate?: number;
  incrementalStoredAmount?: number;
  incrementalStoredToDate?: number;
  balanceToFinish?: number;
}

export const ApplicationLineJSONSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    sequenceNumber: {
      type: 'number',
      title: '#',
      readOnly: true,
    },
    lineDescription: {
      type: 'string',
      title: 'Description',
      readOnly: true,
    },
    scheduledValue: {
      type: 'number',
      title: 'Scheduled Value',
      readOnly: true,
    },
    billedToDate: {
      type: 'number',
      title: 'Billed to Date',
      readOnly: true,
    },
    storedToDate: {
      type: 'number',
      title: 'Stored to Date',
      readOnly: true,
    },
    incrementalBillAmount: {
      type: 'number',
      title: 'Work Completed This Period'
    },
    incrementalStoredAmount: {
      type: 'number',
      title: 'Materials Stored/(Used) This Period'
    },
    percentComplete: {
      type: 'number',
      title: 'Total Percent of Completion'
    },
  }
};

export const ApplicationLineUISchema: UiSchema = {
  sequenceNumber: {
    'ui:FieldTemplate': TableCellFieldTemplate,
  },
  lineDescription: {
    'ui:FieldTemplate': TableCellFieldTemplate,
  },
  scheduledValue: {
    'ui:FieldTemplate': TableCellFieldTemplate,
    'ui:widget': CurrencyWidget,
  },
  retainage: {
    'ui:FieldTemplate': TableCellFieldTemplate,
    'ui:widget': PercentWidget,
  },
  billedToDate: {
    'ui:FieldTemplate': TableCellFieldTemplate,
    'ui:widget': CurrencyWidget,
  },
  storedToDate: {
    'ui:FieldTemplate': TableCellFieldTemplate,
    'ui:widget': CurrencyWidget,
  },
  percentComplete: {
    'ui:FieldTemplate': TableCellFieldTemplate,
    'ui:widget': PercentWidget,
  },
  incrementalBillAmount: {
    'ui:FieldTemplate': TableCellFieldTemplate,
    'ui:widget': CurrencyWidget,
  },
  incrementalStoredAmount: {
    'ui:FieldTemplate': TableCellFieldTemplate,
    'ui:widget': CurrencyWidget,
  },
};
