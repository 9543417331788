import React from 'react';
import { Button, Card, Grid, Header, Label, Placeholder } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { PaymentMethod, Plan, Subscription } from '../../../services/PaymentService';


export interface PaymentPlansProps {
  paymentMethod?: PaymentMethod;
  paymentPlans?: Array<Plan> | undefined;
  projectUrl?: string;
  subscriptions: Array<Subscription> | undefined;
}


// Currently there are 2 payment plans, this used to display placeholders
const PaymentCardPlaceholderIndexes: Array<number> = [ 1, 2 ];

const PaymentCardPlaceholder = (props: {key: number}): JSX.Element =>
  <Card
    key={props.key}
    color="green"
    style={{ textAlign: 'center', height: '400px', marginTop: '30px', marginLeft: '30px' }}
  >
    <Card.Content>
      <Placeholder>
        <Placeholder.Image square/>
        <Placeholder.Line length="full"/>
        <Placeholder.Line length="full"/>
        <Placeholder.Line length="full"/>
        <Placeholder.Line length="full"/>
      </Placeholder>
    </Card.Content>
  </Card>;


@inject('auth')
@observer
export default class PaymentPlans extends React.Component<PaymentPlansProps> {

  private getCalculatedDiscount(): string {
    const paymentPlans = this.props.paymentPlans;
    if (typeof paymentPlans !== 'undefined') {
      const monthlyAmount = paymentPlans.filter(plan => plan.interval === 'month')
        .map(plan => plan.amount / 100);
      const annualAmountToBePaid = paymentPlans.filter(plan => plan.interval === 'year')
        .map(plan => plan.amount / 100);
      const monthlyAmountToBePaidFor12Months = Number(monthlyAmount) * 12;
      const finalDiscount = ((monthlyAmountToBePaidFor12Months - Number(annualAmountToBePaid)) / monthlyAmountToBePaidFor12Months) * 100;
      return String(Math.round(finalDiscount)) + '%';
    }
    return '';
  }

  private getPaymentPlanButtonName(planId: string | number): string {
    const subscriptions = this.props.subscriptions;

    // Display 'Subscribe now' if there are no Subscriptions
    if (!subscriptions || (Array.isArray(subscriptions) && subscriptions.length === 0)) {
      return 'Subscribe now';
    }

    // Display 'Subscribed' if there are Subscriptions and one of them is the same as current plan
    for (const subscription of subscriptions) {
      if (subscription.plan.id === planId) {
        return 'Subscribed';
      }
    }

    // Or display 'Change plan' if there are Subscriptions and it's not as current plan
    return 'Change plan';
  }

  render(): React.ReactNode {
    const { projectUrl, subscriptions } = this.props;

    return (
      <Grid textAlign='center' stackable>
        <Grid.Row columns={2}>
          {!this.props.paymentPlans ?
          // Display Placeholders if paymentPlans are not loaded yet
            PaymentCardPlaceholderIndexes.map(i =>
              <Grid.Column key={i} width={5}>
                <FadeIn>
                  <PaymentCardPlaceholder key={i}/>
                </FadeIn>
              </Grid.Column>
            )
            :
            // Display paymentPlan if they are loaded
            this.props.paymentPlans?.map(plan =>
              <Grid.Column key={plan.id} width={5}>
                <FadeIn>
                  <Card
                    key={plan.id}
                    color='green'
                    style={{ textAlign: 'center', height: '400px', marginTop: '30px', marginLeft: '30px' }}
                  >
                    {
                      plan.interval === 'year' ?
                        <Label size='huge' ribbon floating color='green' style={{
                          left: '5px',
                          top: '0px'
                        }}>
                          {this.getCalculatedDiscount()} Savings
                        </Label> :
                        <Label size='huge' ribbon floating style={{ opacity: '0' }}>&nbsp;</Label>
                    }
                    {
                      <div>
                        <Card.Content>
                          <Card.Header style={{ marginTop: '20%' }}>
                            <Header size='huge'>
                              {plan.interval === 'year' ? 'Annual' : 'Monthly'}
                              <p style={{ fontWeight: 'normal', fontSize: '0.5em' }}>
                                <span style={{ color: 'red' }}>*</span>First 30 Days $0
                              </p>
                            </Header>
                          </Card.Header>
                          <Header size='huge' style={{ marginTop: '25%' }}>
                            {'$' + (plan.amount / 100).toFixed(0)}
                          </Header>
                          <Card.Meta style={{ height: '20px' }}>
                            {
                              plan.interval === 'year' ? `equivalent to $${plan.amount / 100 / 12} per month` : null
                            }
                          </Card.Meta>
                        </Card.Content>
                        <Card.Content extra
                          style={{
                            marginTop: '50px'
                          }}>
                          <Button
                            as={Link}
                            to={{
                              pathname: subscriptions?.length === 0 ? '/subscription/activate' : '/subscription/change',
                              plan: plan,
                              projectUrl: projectUrl
                            }}
                            disabled={subscriptions?.find(s => s.plan.id === plan.id) !== undefined}
                            primary
                            color='blue'
                            size='large'
                          >
                            {
                              this.getPaymentPlanButtonName(plan.id)
                            }
                          </Button>
                        </Card.Content>
                      </div>
                    }
                  </Card>
                </FadeIn>
              </Grid.Column>)
          }
        </Grid.Row>
      </Grid>
    );
  }
}
