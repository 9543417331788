import React from 'react';
import { Button, Modal, ModalActions, ModalContent, ModalHeader, SemanticCOLORS } from 'semantic-ui-react';


interface ReusableModalProps {
  isInitialized: boolean;
  headerContent?: string;
  leftButtonColor: SemanticCOLORS;
  leftButtonContent: string;
  rightButtonColor: SemanticCOLORS;
  rightButtonContent: string;
  isLoading?: boolean;
  onLeftButtonAction?: () => void;
  onRightButtonAction?: () => Promise<void>;

}

export default class ReusableModal extends React.PureComponent<ReusableModalProps>{

  async triggerLeftButtonAction(): Promise<void>{
    if(typeof this.props.onLeftButtonAction !== 'undefined')
      await this.props.onLeftButtonAction();
  }

  async triggerRightButtonAction(): Promise<void>{
    if(typeof this.props.onRightButtonAction !== 'undefined')
      await this.props.onRightButtonAction();
  }

  render(): React.ReactNode {
    const { isInitialized, headerContent,
      leftButtonColor, leftButtonContent,
      rightButtonColor, rightButtonContent, isLoading } = this.props;
    return (
      <Modal open={isInitialized}>
        <ModalHeader>{headerContent}</ModalHeader>
        <ModalContent>
          {this.props.children}
        </ModalContent>
        <ModalActions>
          <Button
            color={leftButtonColor}
            onClick={(): Promise<void> => this.triggerLeftButtonAction()}
          >
            {leftButtonContent}
          </Button>
          <Button
            color={rightButtonColor}
            loading={isLoading}
            onClick={(): Promise<void> => this.triggerRightButtonAction()}
          >
            {rightButtonContent}
          </Button>
        </ModalActions>
      </Modal>
    );
  }
}
