import React from 'react';
import FadeIn from 'react-fade-in';
import {
  StepperAction,
  StepperContent,
  StepperContext
} from 'react-material-stepper';
import { Image } from 'semantic-ui-react';
import { GUIDE_STEP_2 } from './GuideStep2';

export const GUIDE_STEP_3 = 'guide-step-three';

interface GuideStep3Props {
  onClose: () => void;
}

const GuideStep3: React.FunctionComponent<GuideStep3Props> = ({ onClose }: GuideStep3Props) => {
  const { resolve, goAt } = React.useContext(StepperContext);
  const goToPreviousStep = (): void => goAt(GUIDE_STEP_2);


  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    resolve('');
  };

  return (
    <StepperContent
      onSubmit={onSubmit}
      actions={
        <React.Fragment>
          <StepperAction onClick={ (): void => onClose() }>Close</StepperAction>
          <StepperAction align='right' type="button" onClick={goToPreviousStep}>Previous</StepperAction>
          <StepperAction onClick={ (): void => onClose() } type='submit'>Close</StepperAction>
        </React.Fragment>
      }
    >
      <FadeIn>
        <Image src='/gif/3-Get-paid.gif' size='big' centered rounded/>
      </FadeIn>
    </StepperContent>
  );
};

export default GuideStep3;
