import React from 'react';
import ActiveProject from '../../../../schemas/projects/ActiveProject';
import { Container, Table } from 'semantic-ui-react';
import './summary.css';

interface ProjectLineItemAuditProps {
  project: ActiveProject;
}

export default class ApplicationSummary extends React.Component<ProjectLineItemAuditProps> {
  render(): React.ReactNode {
    return <Container>
      <Table celled >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ fontSize: '1.2em' }} colSpan='2'>
              {this.props.project.applications?.length ? `Application #${this.props.project?.currentApplication?.sequenceNumber}` : 'Project'} Summary
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              Original Contract Sum
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'right' }}>
              {
                this.props.project?.originalContractAmount
                  ? this.props.project.originalContractAmount.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  })
                  : ''
              }
            </Table.Cell>
          </Table.Row>
          <Table.Row style={{ color: 'red' }}>
            <Table.Cell>Net Change from Change Order(s)</Table.Cell>
            <Table.Cell style={{ textAlign: 'right' }}>
              {!this.props.project.changeOrderAmount ? '$0.00' : this.props.project.changeOrderAmount.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              }).replace(/^-\$(.+)$/, '$($1)')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Subcontract Sum to Date
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'right' }}>
              {this.props.project.totalAdjustedAmount.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Total Completed & Stored to Date
              {
                this.props.project.currentApplication && this.props.project.currentApplication.status === 'Final' ? ' Including Retainage' : ''
              }
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'right' }}>
              {!this.props.project.billedAndStoredToDate ? '$0.00' : this.props.project.billedAndStoredToDate.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })}
            </Table.Cell>
          </Table.Row>
          {
            !(this.props.project.currentApplication && this.props.project.currentApplication.status === 'Final') ? (
              <Table.Row>
                <Table.Cell>
                  Retainage to Date
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }}>
                  {this.props.project.retainage.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  })}
                </Table.Cell>
              </Table.Row>
            ) : ''
          }
          {
            !(this.props.project.currentApplication && this.props.project.currentApplication.status === 'Final') ? (
              <Table.Row>
                <Table.Cell>
                  Total Earned Less Retainage
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }}>
                  {this.props.project.totalLessRetainage.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  })}
                </Table.Cell>
              </Table.Row>
            ) : ''
          }
          {
            !(this.props.project.currentApplication && this.props.project.currentApplication.status === 'Final') ? (
              <Table.Row>
                <Table.Cell>
                  Less Previous Applications & Certificates for Payment
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }}>
                  {!this.props.project.currentApplication?.previousApplicationForPayment
                    ? '$0.00'
                    : this.props.project.currentApplication.previousApplicationForPayment.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD'
                    })
                  }
                </Table.Cell>
              </Table.Row>
            ) : ''
          }
          {
            !(this.props.project.currentApplication && this.props.project.currentApplication.status === 'Final') ? (
              <Table.Row>
                <Table.Cell>
                  Current Payment Due
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }}>
                  {!this.props.project.currentApplication?.currentPaymentDue
                    ? '$0.00'
                    : this.props.project.currentApplication.currentPaymentDue.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD'
                    })
                  }
                </Table.Cell>
              </Table.Row>
            ) : ''
          }
          <Table.Row>
            <Table.Cell>
              Balance to Finish Including Total Retainage
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'right' }}>
              {this.props.project.balanceToFinish.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Container>;
  }
}
