import React from 'react';
import Page from '../components/Page';
import CalculationSchemaForm from '../components/CalculationSchemaForm';
import { JSONSchema7 } from 'json-schema';
import { UiSchema } from '@rjsf/core';
import PercentWidget from '../schemas/widgets/PercentWidget';
import CurrencyWidget from '../schemas/widgets/currency/CurrencyWidget';

interface CalculatedFieldJSON {
  number1?: number;
  number2?: number;
  sum?: number;
  percentTest?: number;
}
export default class CalculationTest extends React.Component {
  schema: JSONSchema7 = {
    type: 'object',
    title: 'Calculation Demo Record',
    required: [ 'number1', 'number2' ],
    properties: {
      number1: {
        type: 'number',
        title: 'First Number',
        minimum: 10,
      },
      number2: {
        type: 'number',
        title: 'Second Number',
        maximum: 10,
      },
      sum: {
        type: 'number',
        title: 'Calculated Sum',
        readOnly: true,
      },
      readonlyCurrency: {
        type: 'string',
        title: 'Read-only Currency',
        readOnly: true,
        default: '1000',
      },
      percentTest: {
        type: 'number',
        title: 'Read-only Percent Test',
        maximum: 1,
        readOnly: true,
      },
      subform: {
        type: 'object',
        title: 'Subform to Test Nested Fields',
        properties: {
          foo: {
            type: 'string'
          },
          anotherNestedForm: {
            type: 'object',
            title: 'Another Nested Form',
            required: [ 'lastNestedField' ],
            properties: {
              lastNestedField: {
                type: 'number',
                title: 'Nested Field',
                minimum: 100,
              }
            }
          },
          list: {
            type: 'array',
            title: 'List in Nested Form',
            items: {
              type: 'object',
              required: [ 'requiredText' ],
              properties: {
                requiredText: {
                  type: 'string',
                  title: 'Field in Array in Nested Form'
                },
              }
            }
          }
        }
      }
    }
  }

  uiSchema: UiSchema = {
    readonlyCurrency: {
      'ui:widget': CurrencyWidget
    },
    percentTest: {
      'ui:widget': PercentWidget,
    }
  }

  calculate(formData: CalculatedFieldJSON): CalculatedFieldJSON {
    const number1 = typeof formData.number1 === 'undefined' ? 0 : Number(formData.number1);
    const number2 = typeof formData.number2 === 'undefined' ? 0 : Number(formData.number2);
    formData.sum = number1 + number2;
    return formData;
  }

  render(): React.ReactNode {
    return (
      <Page pageTitle="Form Calculation Test">
        <CalculationSchemaForm
          schema={this.schema}
          uiSchema={this.uiSchema}
          formData={{ number1: 32, percentTest: 0.27 }}
          onSubmit={(): void => alert('Submitted!')}
          onCalculate={(formData: CalculatedFieldJSON): CalculatedFieldJSON => this.calculate(formData)}
        />
      </Page>
    );
  }
}
