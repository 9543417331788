import axios from 'axios';
import { Auth } from './AuthService';

const BASE_URL = process.env.REACT_APP_API_SERVICE_URL;

interface UserFeedback {
  title: string;
  description: string;
}

export class FeedbackService {

  async sendFeedback(userFeedback: UserFeedback): Promise<UserFeedback> {
    if (!Auth.user?.accessToken) {
      throw new Error('Data service unavailable, user not logged in');
    }
    const { data } = await axios({
      method: 'POST',
      baseURL: BASE_URL + '/helpdesk-tickets',
      headers: {
        Authorization: `Bearer ${ Auth.user.accessToken }`
      },
      data: userFeedback
    });
    return data;
  }

}
