import React from 'react';
import _ from 'lodash';
import ActiveProject from '../../schemas/projects/ActiveProject';
import { ProjectLineJSON } from '../../schemas/ProjectLineSchema';

import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/semantic-ui/tabulator_semantic-ui.min.css';
import { RouteChildrenProps } from 'react-router-dom';
import Page from '../../components/Page';
import { Button, Icon, Loader } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { AlertService } from '../../services/AlertService';
import { DataJSON, DataService } from '../../services/DataService';
import ProjectLineItemsSummary from './LineItemsSummary';
import ProjectLinesTable from './ProjectLinesTable';

interface EditProjectLinesProps extends RouteChildrenProps<{ id: string }> {
  project?: ActiveProject;
  alert?: AlertService;
}

interface EditProjectLinesState {
  project: ActiveProject;
  loading: boolean;
  projectLines: Array<ProjectLineJSON>;
  tableData: any | undefined;
  currentTotalScheduledValue: number;
  originalContractAmount: number;
  shortfallExcess: number;
  footerHeadingWidth: number;
  footerAmountWidth: number;
}

interface ColumnData {
  field?: string;
  title?: string;
  visible?: boolean;
  rowHandle?: boolean;
  headerSort?: boolean;
  frozen?: boolean;
  width?: number;
  minWidth?: number;
  editor?: boolean | string;
  cellContextMenu?: any;
  validator?: string | Array<string> | ((cell: any, value: any, parameters: any) => boolean);
  formatter?: string | ((cell: any, formatterParams?: any, onRendered?: any) => any);
  formatterParams?: { [key: string]: string };
}

interface RowData {
  [key: string]: string | number | undefined;
}

interface SpreadsheetData {
  columns: Array<ColumnData>;
  rows: Array<RowData>;
}


@inject('alert')
@observer
export default class EditProjectLines extends React.Component<EditProjectLinesProps, EditProjectLinesState> {
  state: EditProjectLinesState = {
    project: this.props.project ? this.props.project : new ActiveProject(),
    loading: true,
    projectLines: this.props.project?.projectLines ? JSON.parse(JSON.stringify(this.props.project.projectLines)) : [],
    tableData: undefined,
    currentTotalScheduledValue: this.lineTotals(this.props.project?.projectLines),
    originalContractAmount: 0,
    shortfallExcess: 0,
    footerHeadingWidth: 0,
    footerAmountWidth: 0,
  }
  ref?: React.ElementRef<any>;
  dataService = new DataService();

  constructor(props: EditProjectLinesProps) {
    super(props);
    this.errors = this.errors.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const project = this.state.project;
    // If there's no id and one was passed in, load the project
    if (!project.id && this.props.match?.params.id) {
      project.id = this.props.match.params.id;
      await project.load();
    }
    const projectLines = this.state.project.projectLines && this.state.project.projectLines.length > 0
      ? JSON.parse(JSON.stringify(this.state.project.projectLines.filter(line => line.lineType === 'original')))
      : [ {
        sequenceNumber: 1,
        lineType: 'original',
        projectId: project.id,
        retainage: project?.defaultRetainage !== undefined ? project.defaultRetainage : null
      } ];
    const currentTotalScheduledValue = this.lineTotals(projectLines);
    const originalContractAmount = this.state.project?.originalContractAmount ? this.state.project.originalContractAmount : 0;
    const shortfallExcess = Math.round(100*(currentTotalScheduledValue - originalContractAmount)) / 100;
    this.setState({
      loading: false,
      project,
      projectLines,
      currentTotalScheduledValue,
      originalContractAmount,
      shortfallExcess,
    });
    window.addEventListener('resize', (): void => this.updateFooterWidth());
  }

  lineTotals(projectLines?: Array<ProjectLineJSON>): number {
    if (!projectLines || projectLines && projectLines.length === 0) { return 0; }
    return projectLines
      .map(line => _.toNumber(line.scheduledValue ? _.toString(line.scheduledValue).replace(/[^-\d.]/g, '') : 0))
      .reduce((total, current) => total+current);
  }

  get data(): SpreadsheetData {
    const data: SpreadsheetData = {
      columns: [
        { rowHandle: true, formatter: 'handle', headerSort: false, frozen: true, width: 45, minWidth: 45 },
        { field: 'id', visible: false },
        { field: 'sequenceNumber', width: 45, minWidth: 45, title: '#' },
        { field: 'lineType', visible: false },
        { field: 'projectId', visible: false },
        { field: 'description', title: 'Description', editor: true, validator: '' },
        {
          field: 'scheduledValue',
          title: 'Scheduled Value',
          editor: 'input',
          formatter: (cell: any): string => this.dollarFormatter(cell),
          validator: [ '' ],
          width: 160,
          minWidth: 160,
          formatterParams: {
            decimal: '.',
            thousand: ',',
            symbol: '$',
          }
        },
        {
          field: 'retainage',
          title: 'Retainage %',
          editor: 'input',
          width: 125,
          minWidth: 125,
          validator: [ '', 'min:0' ],
          formatter: (cell: any): string => this.percentFormatter(cell)
        },
      ],
      rows: [],
    };
    data.rows = this.state.projectLines
      .filter(line => line.lineType === 'original')
      .map(line => {
        const { id, sequenceNumber, lineType, projectId, description, scheduledValue, retainage } = line;
        return { id, sequenceNumber, lineType, projectId, description, scheduledValue, retainage };
      });
    return data;
  }

  percentFormatter(cell: any): string {
    let value: number | string | null | undefined = cell._cell.value;
    if (typeof value === 'string') {
      // Remove non-digit characters and convert to a number
      value = Number(value.replace(/[^\d.]/g, ''));

      // If it's NaN, set to 0%
      if (isNaN(value)) {
        value = 0;
      }

      // If it's greater than 1, divide by 100
      if (value > 1) {
        value = value / 100;
      }
    } else if (typeof value !== 'number') {
      value = '';
    }
    // If it's not defined, set to the project default retainage if we have one
    if (value === '' && this.state.project?.defaultRetainage !== undefined) {
      value = this.state.project.defaultRetainage;
    }

    return value.toLocaleString('en-US', { style: 'percent', maximumFractionDigits: 1 });
  }

  dollarFormatter(cell: any): string {
    let value: number | string | null | undefined = cell._cell.value;
    if (typeof value === 'string') {
      // Remove non-digit characters and convert to a number
      value = _.toNumber(value.replace(/[^-\d.]/g, ''));

      // If it's NaN, set to an empty string
      if (isNaN(value)) {
        return '';
      }
    } else if (typeof value !== 'number') {
      return '';
    }
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  getTableData(): Array<ProjectLineJSON> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const table = this?.ref?.table;
    return table.getData() as Array<ProjectLineJSON>;
  }

  getTable(): any {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return this?.ref?.table;
  }

  addRow(): void {
    const projectLines: Array<ProjectLineJSON> = this.getTableData();
    const newLine: ProjectLineJSON = {
      sequenceNumber: this.state.projectLines.length + 1,
      lineType: 'original',
      projectId: this.state.project.id,
    };
    if (this.state.project.defaultRetainage !== undefined) {
      newLine.retainage = this.state.project.defaultRetainage;
    }
    projectLines.push(newLine);
    this.setState({ projectLines });

    // Scroll down to the new row
    // use setTimeout so the table will re-render after adding the new line
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const table = this?.ref?.table;
      const rows = table.getRows();
      rows[rows.length - 1].scrollTo();
    });
  }

  parseClipboard(clipboard: any): ProjectLineJSON[] {
    const projectLines: Array<ProjectLineJSON> = this.getTableData();
    try {
      // If we have a single project line, if it has nothing, remove it
      if (projectLines.length === 1) {
        const firstrow = projectLines[0];
        if (!firstrow.description && !firstrow.scheduledValue) {
          this.getTable().deleteRow(0);
          projectLines.pop();
          this.setState({ projectLines });
        }
      }

      const projectLineCount = projectLines.length;
      const newProjectLines: Array<RowData> = clipboard
        // Split the data into rows
        .split(/\r?\n/)
        // Parse the row data
        .map((row: string, index: number) => {
          // Set project ID, line type, and sequence number
          const projectId = this.state.project.id;
          const lineType = 'original';
          const sequenceNumber = projectLineCount + index + 1;

          // Set defaults
          let description = '';
          let scheduledValue: string | number = '';
          let retainage: string | number = 0;

          // Split the columns
          const columns = row.split('\t');
          // Set the values
          columns.forEach(column => {
            // Make sure we don't have an empty string
            if (column !== '') {
              const testNum = Number(column.trim().replace(/[$,%]/g, ''));
              // If it's NaN, set the description if there isn't one
              if (isNaN(testNum)) {
                if (description === '') {
                  description = column;

                  // Wipe out scheduledValue & retainage, since these should be to the right of description
                  scheduledValue = '';
                  retainage = 0;
                }
              } else {
                // If we don't have a scheduled value yet, set it
                if (scheduledValue === '') {
                  scheduledValue = testNum;
                } else if (!retainage) {
                  // If we don't have retainage set it
                  retainage = testNum;

                  // If the value is greater than 1, divide by 100
                  if (retainage > 1) {
                    retainage /= 100;
                  }
                }
              }
            }
          });
          // If retainage isn't set, and we have a default, set it
          if (!retainage && this.state.project.defaultRetainage !== undefined) {
            retainage = this.state.project.defaultRetainage;
          }
          // Return our parsed row
          return { sequenceNumber, lineType, description, scheduledValue, retainage, projectId };
        });
      return projectLines.concat(newProjectLines);
    } catch (err) {
      // If we get an error, just return an empty array
      return projectLines;
    }
  }

  get rightClickMenu(): any {
    return [
      // Paste menu item
      {
        label: '<i aria-hidden="true" class="clipboard icon"></i> Paste Line Items',
        action: async (): Promise<void> => {
          try {
            const clipboardData = await navigator.clipboard.readText();
            const projectLines: Array<ProjectLineJSON> = this.parseClipboard(clipboardData);
            this.dataChanged(projectLines);
          } catch (err) {
            console.error('Unable to read clipboard');
            this.props.alert?.createAlert({
              message: 'You need to allow this page access to the clipboard, in order to be able to paste in project lines.',
              title: 'Need Clipboard Permissions',
              type: 'error'
            });
          }
        }
      },
      // Delete menu item
      {
        label: '<i aria-hidden="true" class="x icon"></i> Delete Line Item', action: (e: Event, row: any): void => {
          const deletedLineSequenceNumber = row._row.data.sequenceNumber;
          const projectLines: Array<ProjectLineJSON> = this.getTableData()
            .filter((line: ProjectLineJSON) => line.sequenceNumber !== deletedLineSequenceNumber);

          // Re-number the projectLines
          projectLines
            // First sort by sequence number
            .sort((a, b) => Number(a.sequenceNumber) - Number(b.sequenceNumber))
            // Renumber
            .forEach((line, index) => {
              line.sequenceNumber = index + 1;
            });
          this.dataChanged(projectLines);
        }
      }
    ];
  }

  resort(): void {
    const projectLines: Array<ProjectLineJSON> = this.getTableData();
    projectLines.forEach((line, index) => line.sequenceNumber = index + 1);
    this.setState({ projectLines });
  }

  errors(): Array<string> {
    // If there's no table, do nothing
    if (!this.getTable()) {
      return [];
    }

    // Wrap in a setTimeout to allow table updates to propagate
    const errors: Array<string> = [];
    this.getTableData().forEach((line: ProjectLineJSON) => {
      if (!line.description?.trim()) {
        errors.push(`Line #${line.sequenceNumber} needs a description, or put your cursor on the line item and tap your right mouse button and select Delete Line Item.`);
      }
      if (!line.scheduledValue) {
        errors.push(`Line #${line.sequenceNumber} needs a scheduled value, or put your cursor on the line item and tap your right mouse button and select Delete Line Item.`);
      }
      if (line.retainage === undefined || line.retainage < 0) {
        errors.push(`Line #${ line.sequenceNumber } needs a retainage value, or put your cursor on the line item and tap your right mouse button and select Delete Line Item.`);
      }
    });
    return errors;
  }

  async save(): Promise<void> {
    // If we have errors, display the errors and exit
    if (this.errors().length > 0) {
      this.errors().forEach(error => {
        this.props.alert?.createAlert({
          message: error,
          title: 'Validation Error',
          type: 'error'
        });
      });
      return;
    }

    this.setState({ loading: true });
    const originalLines = this.state.project.projectLines?.filter(line => line.lineType === 'original');
    const projectLines: Array<ProjectLineJSON> = this.getTableData();
    const newLines = this.removeNonNumeric(projectLines.filter(line => !line.id));
    const updatedLines = this.removeNonNumeric(projectLines.filter(line => line.id));
    const deletedLines = originalLines?.filter(
      line => !projectLines.find(updatedLine => updatedLine.id === line.id)
    );
    const promises: Array<Promise<any>> = [];
    // Add new lines
    if (newLines && newLines.length > 0) {
      newLines.forEach(line => promises.push(this.dataService.create('project-lines', line as DataJSON)));
    }
    // Update existing lines
    if (updatedLines && updatedLines.length > 0) {
      updatedLines.forEach(line => promises.push(this.dataService.save('project-lines', line.id as string, line as DataJSON)));
    }
    // Delete removed lines
    if (deletedLines && deletedLines.length > 0) {
      deletedLines.forEach(line => promises.push(this.dataService.del('project-lines', line.id as string)));
    }
    // Wait for everything to finish
    await Promise.all(promises);

    // Redirect back to the project page
    this.props.history.push(`/projects/${this.state.project.id}`);
  }

  removeNonNumeric(lines: Array<ProjectLineJSON>): Array<ProjectLineJSON> {
    lines.forEach(line => {
      line.scheduledValue = Number(line.scheduledValue ? String(line.scheduledValue).replace(/[^-\d.]/g, '') : 0);
      line.retainage = Number(line.retainage ? String(line.retainage).replace(/[^-\d.]/g, '') : 0);
      if (isNaN(line.scheduledValue)) line.scheduledValue = 0;
      if (isNaN(line.retainage)) line.retainage = 0;
    });
    return lines;
  }

  cellEdited(cell: any) {
    switch (cell.getField()) {
    case 'retainage':
    // If the value gives a percentage over 100%, divide the value by 100 to make it a decimal
      if (cell.getValue() >= 1) cell.setValue(cell.getValue() / 100);
      // If the scheduledValue is negative, set the retainage to 0
      if (cell._cell.row.cells[6] && cell._cell.row.cells[6].getValue() && Number(cell._cell.row.cells[6].getValue()) < 0) {
        cell.setValue(0);
      }
      break;

    case 'scheduledValue':
      // If the value is 0, clear it out
      if (
        !cell.getValue()
        || Number(cell.getValue().replace(/[^-\d.]/g, '')) === 0
        || isNaN(Number(cell.getValue().replace(/[^-\d.]/g, '')))
      ) {
        cell.setValue(undefined);
      } else if(cell.getValue() && Number(cell.getValue()) < 0) {
        // If a negative amount is set, make sure to zero-out the retainage
        cell._cell.row.cells[7].setValue(0);
      }
    }
  }

  dataChanged(data: any): any {
    const currentTotalScheduledValue = this.lineTotals(data);
    const originalContractAmount = this.state.project?.originalContractAmount ? this.state.project.originalContractAmount : 0;
    const shortfallExcess = Math.round(100*(currentTotalScheduledValue - originalContractAmount)) / 100;
    const tableHolder = (document.querySelector('div.tabulator-tableHolder') as HTMLDivElement);
    const scrollTop = tableHolder.scrollTop;
    this.setState({
      projectLines: data,
      currentTotalScheduledValue,
      shortfallExcess,
    });
    // Because of setting the new state, the scroll position in the table is lost
    // The code below attempts to restore the position, but it doesn't seem to work quite right, it's off by 26 pixels
    tableHolder.scrollTop = scrollTop;
    setTimeout(() => tableHolder.scrollTop = scrollTop);
    return data;
  }

  updateFooterWidth(): void {
    const headers: Array<HTMLDivElement> = Array.from(document.querySelectorAll('.tabulator-col-content'));
    if (headers.length === 0) { return; }
    const footerHeadingWidth = headers
      .map(node => node.offsetWidth)
      .splice(0, 6)
      .reduce((total, current) => total+current)+2;
    const footerAmountWidth = headers[6].offsetWidth;
    if (this.state.footerHeadingWidth !== footerHeadingWidth) { this.setState({ footerHeadingWidth, footerAmountWidth }); }
  }

  render(): React.ReactNode {
    if (this.state.loading) {
      return <Loader active>Loading...</Loader>;
    }
    const data = this.data;
    setTimeout((): void => this.updateFooterWidth());
    return (
      <Page
        pageTitle='Edit Project Line Items'
        breadcrumb={[
          { key: 'home', href: '/', content: 'Your Projects', link: true },
          { key: 'project', href: `/projects/${this.state.project.id}`, content: 'Project Summary' },
          {
            key: 'edit-project-lines',
            content: `${
              this.state.project.projectLines && this.state.project.projectLines.length > 0 ? 'Edit' : 'Add'
            } Project Line Items`,
            active: true
          },
        ]}
      >
        <p>
          You can copy descriptions and schedule values from a spreadsheet and paste them into the table below.
        </p>
        To paste in your copied descriptions and values, you can right-click anywhere in the table and then click on
        <Icon name='clipboard'/><b>Paste Line Items</b>. Be sure to allow the page access to the clipboard when
        prompted.
        <p style={{ marginTop: '1em' }}>
          The sum of all Scheduled values should be equal to {
          this.state.project?.originalContractAmount
            ? this.state.project.originalContractAmount.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD'
            })
            : 0
          }
        </p>
        <ProjectLinesTable
          ref={(node: any) => { this.ref = node?.ref; }}
          data={data}
          onCellEdited={this.cellEdited}
          onDataChanged={(data: any) => this.dataChanged(data)}
          resort={this.resort}
          rightClickMenu={this.rightClickMenu}
          parseClipboard={this.parseClipboard}
        />
        {
          this.state.shortfallExcess !== 0 ?
            <ProjectLineItemsSummary
              footerHeadingWidth={this.state.footerHeadingWidth}
              footerAmountWidth={this.state.footerAmountWidth}
              originalContractAmount={this.state.originalContractAmount}
              shortfallExcess={this.state.shortfallExcess}
              currentTotalScheduledValue={this.state.currentTotalScheduledValue}
            /> : ''
        }
        <div>
          <Button color='green' onClick={(): void => this.addRow()}
            style={{
              marginTop: '20px',
              paddingLeft: '90px',
              paddingRight: '90px'
            }}
          >
            <Icon name='add'/>
            Add Line Item
          </Button>
        </div>
        <div style={{ marginTop: '5px', marginBottom: '2em' }}>
          <Button onClick={ (): void => { this.props.history.push(`/projects/${ this.state.project.id }`); }}
            style={ {
              borderLeftWidth: '20px',
              paddingLeft: '30px',
              paddingRight: '33px'
            } }
          >
            <Icon name='cancel'/>
            Cancel
          </Button>

          <Button primary onClick={(): Promise<void> => this.save()}>
            <Icon name='save'/>
            Save Line Items
          </Button>
        </div>
      </Page>
    );
  }
}
