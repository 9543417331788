import React from 'react';
import Page from '../../components/Page';
import { DataService } from '../../services/DataService';
import { Button, Icon, Loader, Message } from 'semantic-ui-react';
import { Link, RouteChildrenProps } from 'react-router-dom';
import { GeneralContractorJSON } from '../../schemas/GeneralContractorJSONSchema';
import { ReactTabulator } from 'react-tabulator';
import '../../styles/pagination-footer.css';

interface ListGeneralContractorsState {
  loading: boolean;
  generalContractors: Array<GeneralContractorJSON>;
  filtered: boolean;
}

export default class OldBeforePaginationFeatureListGeneralContractors extends React.Component<RouteChildrenProps, ListGeneralContractorsState> {
  ref?: React.ElementRef<any>;
  columns = [
    {
      title: 'General Contractor Name',
      field: 'name',
      formatter: 'link',
      formatterParams: this.getFormatterParams(),
      headerFilter: 'input',
      headerFilterPlaceholder: 'Search general contractor name',
    },
    {
      title: 'Contact Name',
      field: 'contactName',
      formatter: 'link',
      formatterParams: this.getFormatterParams(),
      headerFilter: 'input',
      headerFilterPlaceholder: 'Search contact name',
    },
    {
      title: 'License Number',
      field: 'licenseNumber',
      formatter: 'link',
      formatterParams: this.getFormatterParams(),
      headerFilter: 'input',
      headerFilterPlaceholder: 'Search license number',
    },
    {
      title: 'City',
      field: 'city',
      formatter: 'link',
      formatterParams: this.getFormatterParams(),
      headerFilter: 'input',
      headerFilterPlaceholder: 'Search city',
    }
  ]
  state: ListGeneralContractorsState = {
    loading: true,
    generalContractors: [],
    filtered: false,
  }
  dataService = new DataService();

  async componentDidMount(): Promise<void> {
    const generalContractors = await this.dataService.list('general-contractors') as unknown as Array<GeneralContractorJSON>;
    this.setState({ loading: false, generalContractors });
  }

  getFormatterParams(): any {
    return {
      url: (cell: any) => {
        const value: number | string | null | undefined = cell._cell.row.data.id;
        return '/general-contractors/' + value;
      }
    };
  }

  rowClick = (e: any, row: any): void => {
    const value: number | string | null | undefined = row._row.data.id;
    this.props.history.push('/general-contractors/' + value);
  };

  clearFilters(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.ref.table.clearHeaderFilter();
    this.setState({ filtered: false });
  }

  render(): React.ReactNode {
    // Show the loader
    if (this.state.loading) { return <Loader active />; }

    let generalContractorList;
    if (this.state.generalContractors && this.state.generalContractors.length > 0) {
      generalContractorList = (
        <ReactTabulator
          ref={(ref: any) => (this.ref = ref)}
          // @ts-ignore
          columns={this.columns}
          data={this.state.generalContractors}
          rowClick={this.rowClick}
          dataFiltered={(): void => {
            let filtered = false;
            document.querySelectorAll('div.tabulator .tabulator-header-filter input').forEach((input) => {
              if (!filtered) {
                filtered = (input as HTMLInputElement).value !== '';
              }
            });
            if (filtered !== this.state.filtered) {
              this.setState({ filtered });
            }
          }}
        />
      );
    }

    return (
      <Page breadcrumb={[
        { key: 'general-contractors', content: 'General Contractors', active: true },
      ]}>
        <Button primary as={Link} to={'/general-contractors/new'}>
          <Icon name="plus" />
          New General Contractor
        </Button>
        {
          this.state.filtered
            ?
            <Message warning>
              <Message.Header>General Contractor List Filtered</Message.Header>
              <p>
                You are currently filtering the general contractor list. Click the button below to go back to the full
                general contractor list.
              </p>
              <Button onClick={(): void => this.clearFilters()}>
                <Icon color="red" name="delete"/>
                Clear Filters
              </Button>
            </Message>
            : ''
        }
        { generalContractorList }
      </Page>
    );
  }
}
