import React from 'react';
import Page from '../../components/Page';
import { TermsOfService } from './components/TermsOfService';

export default class TermsOfServicePage extends React.Component {

  componentDidMount(): void {
    window.scrollTo(0, 0);
  }

  render(): React.ReactNode {
    return(
      <Page pageTitle='Terms of Use/Service'>
        <TermsOfService/>
      </Page>
    );
  }
}
