import React from 'react';
import { inject, observer } from 'mobx-react';
import Page from '../../components/Page';
import { Container, Header } from 'semantic-ui-react';
import PaymentPlans from './components/PaymentPlans';
import { SubscriptionCommonProps } from './components/AddPaymentMethod';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { Plan, Subscription } from '../../services/PaymentService';
import { SemanticShorthandCollection } from 'semantic-ui-react/dist/commonjs/generic';
import { BreadcrumbSectionProps } from 'semantic-ui-react/dist/commonjs/collections/Breadcrumb/BreadcrumbSection';
import BillingInfo from '../../components/BillingInfo';


export default class SelectSubscriptionPageContainer extends React.Component<SubscriptionCommonProps> {
  render(): React.ReactNode {
    return (
      <ElementsConsumer>
        { ({ stripe, elements }): React.ReactNode => (
          <SelectSubscriptionPage
            { ...this.props }
            location={ this.props.location }
            stripe={ stripe as Stripe }
            elements={ elements as StripeElements }
          />

        ) }
      </ElementsConsumer>
    );
  }
}

interface SelectSubscriptionPageState {
  projectUrl?: string;
  paymentPlans: Array<Plan> | undefined;
  subscriptions: Array<Subscription> | undefined;
  isLoading: boolean;
}

@inject('auth')
@inject('stripe')
@inject('payment')
@observer
class SelectSubscriptionPage extends React.Component<SubscriptionCommonProps, SelectSubscriptionPageState> {

  state: SelectSubscriptionPageState = {
    isLoading: true,
    projectUrl: this.props.location?.query?.callbackUrl,
    paymentPlans: undefined,
    subscriptions: undefined
  }

  async componentDidMount(): Promise<void> {
    const paymentPlans = await this.props.payment?.getPaymentPlans();
    const subscriptions = await this.props.payment?.getSubscriptions();
    this.setState({ isLoading: false, paymentPlans, subscriptions });
  }

  getBreadcrumb(): SemanticShorthandCollection<BreadcrumbSectionProps> {
    const callbackUrl = this.props.location?.query?.callbackUrl;
    const { projectUrl } = this.state;
    const manageSubscriptionUrl = '/subscription/manage';

    if (callbackUrl === manageSubscriptionUrl) {
      return [
        { key: 'home', href: '/', content: 'Home', link: true },
        { key: 'subscription_manage', href: manageSubscriptionUrl, content: 'Manage Subscription', link: true },
        { key: 'subscription', content: 'Subscription Selection', link: false }
      ];
    }
    if (projectUrl) {
      return [
        { key: 'home', href: '/', content: 'Home', link: true },
        { key: 'project', href: projectUrl, content: 'Project', link: true },
        { key: 'subscription', content: 'Subscription Selection', link: false }
      ];
    }
    return [
      { key: 'home', href: '/', content: 'Home', link: true },
      { key: 'subscription', content: 'Subscription Selection', link: false }
    ];
  }

  render(): React.ReactNode {

    return (
      <Page pageTitle='Subscription Selection' breadcrumb={
        this.getBreadcrumb()
      }>
        <Container textAlign='center'>
          <Header size='huge' style={{ marginTop: '2%' }}>
            <span style={{ color: 'red' }}>*</span>First 30 Days $0
          </Header>
          <Header size='medium' style={{ marginTop: '2%' }}>
            With your subscription, you get unlimited full access to PDFs for applications for payment, continuation
            sheets, and change order sheets.
          </Header>
        </Container>
        <PaymentPlans
          projectUrl={this.state.projectUrl}
          paymentPlans={this.state.paymentPlans}
          subscriptions={this.state.subscriptions}
        />
        <br/>
        <div style={{ fontSize: '1.2em' }}>
          <p><b>No Risk</b></p>
          <BillingInfo />
        </div>
      </Page>
    );
  }
}
