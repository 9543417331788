import { OfferedOption } from './PricesComponents';
import { RISK_FREE_OFFER } from '../../common/Constants';
import { Plan } from '../../services/PaymentService';

const hardcodedPaymentPlans: Array<Plan> = [
  {
    id: 1,
    nickname: 'Free',
    amount: 0,
    active: false,
    created: 0,
    interval: '',
    // eslint-disable-next-line @typescript-eslint/camelcase
    interval_count: ''
  },
  {
    id: 2,
    nickname: 'Standart monthly plan',
    amount: 7900,
    active: true,
    created: 0,
    interval: '',
    // eslint-disable-next-line @typescript-eslint/camelcase
    interval_count: ''
  },
  {
    id: 3,
    nickname: 'Standart annual plan',
    amount: 72000,
    active: true,
    created: 0,
    interval: '',
    // eslint-disable-next-line @typescript-eslint/camelcase
    interval_count: ''
  },
];

const offeredOptions: OfferedOption[] = [
  {
    id: 1,
    description: 'Unlimited - Data entry for projects',
    supportedByTrial: true,
    supportedByMonthly: true,
    supportedByAnnual: true
  },

  {
    id: 2,
    description: 'Unlimited - Data entry for change orders',
    supportedByTrial: true,
    supportedByMonthly: true,
    supportedByAnnual: true
  },

  {
    id: 3,
    description: 'Unlimited - Data entry for applications for payment',
    supportedByTrial: true,
    supportedByMonthly: true,
    supportedByAnnual: true
  },

  {
    id: 4,
    description: 'Unlimited - PDF downloads for applications for payment',
    supportedByTrial: false,
    supportedByMonthly: true,
    supportedByAnnual: true
  },

  {
    id: 5,
    description: 'Unlimited - PDF downloads for continuation sheets',
    supportedByTrial: false,
    supportedByMonthly: true,
    supportedByAnnual: true
  },

  {
    id: 6,
    description: 'Unlimited - PDF downloads for change order sheets',
    supportedByTrial: false,
    supportedByMonthly: true,
    supportedByAnnual: true
  },

];

export { offeredOptions, hardcodedPaymentPlans };
