import React from 'react';
import { SubscriptionCommonProps } from './AddPaymentMethod';
import { Icon, Menu, TableBody, TableCell, TableRow } from 'semantic-ui-react';
import { Invoice } from '../../../services/PaymentService';

export interface InvoiceRowProps extends SubscriptionCommonProps {
  invoices: Array<Invoice> | undefined;
}

export default class InvoiceRow extends React.Component<InvoiceRowProps> {

  public static secondsToISOString(seconds: number): string {
    const date = new Date(seconds * 1000 + new Date().getTimezoneOffset()*1000*60);
    return date.toLocaleDateString();
  }

  render(): React.ReactNode {

    return (
      <TableBody>
        {
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.props.invoices?.map(({ id, created, amount_paid, hosted_invoice_url, invoice_pdf }) => (
            <TableRow key={ id }>
              <TableCell>
                <div style={{ marginLeft: '50px' }}>
                  { InvoiceRow.secondsToISOString(created as number) }
                </div>
              </TableCell>
              {/* eslint-disable-next-line @typescript-eslint/camelcase */ }
              <TableCell>
                <div style={{ marginLeft: '50px' }}>
                  { (Number(amount_paid) / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }
                </div>
              </TableCell>
              {/* eslint-disable-next-line @typescript-eslint/camelcase */ }
              <TableCell>
                {/* eslint-disable-next-line @typescript-eslint/camelcase */ }
                <Menu.Item href={ hosted_invoice_url } target='_blank'>
                  <Icon name='file pdf outline' size='large' style={ { marginLeft: '60px' } }/>
                  {/* eslint-disable-next-line @typescript-eslint/camelcase */ }
                </Menu.Item>
              </TableCell>
              <TableCell>
                {/* eslint-disable-next-line @typescript-eslint/camelcase */ }
                <Menu.Item href={ invoice_pdf }>
                  <Icon name='download' size='large' style={ { marginLeft: '60px' } }/>
                </Menu.Item>
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    );
  }
}
