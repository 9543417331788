import React from 'react';
import { WidgetProps } from '@rjsf/core';
import { Form, Label } from 'semantic-ui-react';

interface StatusWidgetProps extends WidgetProps {
  uiSchema: {
    options: {
      info?: Array<string>;
      danger?: Array<string>;
      success?: Array<string>;
      done?: Array<string>;
    };
  };
}
export default class StatusWidget extends React.Component<StatusWidgetProps> {
  getColor(value: string | null): 'red'|'green'|'blue'|'grey' {
    if (typeof value !== 'string') {
      return 'blue';
    }
    if (this.props.uiSchema.options.info?.find(infoValue => infoValue.toLowerCase() === value.toLowerCase())) {
      return 'blue';
    }
    if (this.props.uiSchema.options.danger?.find(infoValue => infoValue.toLowerCase() === value.toLowerCase())) {
      return 'red';
    }
    if (this.props.uiSchema.options.success?.find(infoValue => infoValue.toLowerCase() === value.toLowerCase())) {
      return 'green';
    }
    if (this.props.uiSchema.options.done?.find(infoValue => infoValue.toLowerCase() === value.toLowerCase())) {
      return 'grey';
    }
    return 'blue';
  }

  render(): React.ReactNode {
    const color = this.getColor(this.props.value);
    return (
      <Form.Field>
        <Label color={ color }>{ this.props.value ? this.props.value : 'None' }</Label>
      </Form.Field>
    );
  }
}
