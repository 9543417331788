import React from 'react';
import { Card, CardGroup, Icon, Table } from 'semantic-ui-react';
import { hardcodedPaymentPlans, offeredOptions } from './PlanDescriptionData';
import { RISK_FREE_OFFER } from '../../common/Constants';

export interface OfferedOption {
  id: number;
  description: string;
  supportedByTrial: boolean;
  supportedByMonthly: boolean;
  supportedByAnnual: boolean;
}

interface OptionRowProps {
  option: OfferedOption;
}

const freePlan = hardcodedPaymentPlans?.find(plan => plan.amount === 0);
const monthlyPlan = hardcodedPaymentPlans?.find(plan => plan.amount === 7900);
const annualPlan = hardcodedPaymentPlans?.find(plan => plan.amount === 72000);

class MobilePriceCards extends React.Component{
  render(): React.ReactNode {
    return (
      <CardGroup style={ { marginTop: '1em' } }>
        <Card fluid centered>
          <Card.Content textAlign='left'>
            <Card.Header textAlign='center'>
              { freePlan !== undefined ? 'Free' : null }
            </Card.Header>
            <Card.Header textAlign='center'>
              { freePlan !== undefined ? `$${freePlan.amount}` : null }
            </Card.Header>
            <Card.Description>
              {
                offeredOptions.filter(o => o.description !== RISK_FREE_OFFER).map(option => {
                  return <div key={ option.id }>
                    { option.description.replace('Unlimited - ', '') + '' }
                    {
                      option.supportedByTrial ?
                        <Icon color='green' name='checkmark' size='large'
                          style={ { position: 'absolute', right: '10px' } }/> :
                        <Icon color='red' name='x' size='large' style={ { position: 'absolute', right: '10px' } }/>
                    }
                  </div>;
                })
              }
            </Card.Description>
          </Card.Content>
        </Card>

        <Card fluid centered>
          <Card.Content textAlign='left'>
            <Card.Header textAlign='center'>
              { monthlyPlan !== undefined ? 'Monthly' : null }
            </Card.Header>
            <Card.Header textAlign='center'>
              { monthlyPlan !== undefined ? `$${ (monthlyPlan.amount / 100).toFixed(0) }` : null }
            </Card.Header>
            <Card.Description>
              {
                offeredOptions.map(option => {
                  return <div key={ option.id }>
                    { option.description.replace('Unlimited - ', '') + '' }
                    {
                      option.supportedByMonthly ?
                        <Icon color='green' name='checkmark' size='large'
                          style={ { position: 'absolute', right: '10px' } }/> :
                        <Icon color='red' name='x' size='large' style={ { position: 'absolute', right: '10px' } }/>
                    }
                  </div>;
                })
              }
            </Card.Description>
          </Card.Content>
        </Card>
        <Card fluid centered>
          <Card.Content textAlign='left'>
            <Card.Header textAlign='center'>
              { annualPlan !== undefined ? 'Annual' : null }
            </Card.Header>
            <Card.Header textAlign='center'>
              { annualPlan !== undefined ? `$${ (annualPlan.amount / 100 / 12).toFixed(0) }` : null }
              <Card.Meta textAlign='center' style={ { fontSize: '0.8em' } }>
                { annualPlan !== undefined ? `$${ (annualPlan.amount / 100).toFixed(0) } Prepaid Annual Billing` : null }
              </Card.Meta>
            </Card.Header>
            <Card.Description>
              {
                offeredOptions.map(option => {
                  return <div key={ option.id }>
                    { option.description.replace('Unlimited - ', '') + '' }
                    {
                      option.supportedByAnnual ?
                        <Icon color='green' name='checkmark' size='large'
                          style={ { position: 'absolute', right: '10px' } }/> :
                        <Icon color='red' name='x' size='large' style={ { position: 'absolute', right: '10px' } }/>
                    }
                  </div>;
                })
              }
            </Card.Description>
          </Card.Content>
        </Card>
      </CardGroup>
    );
  }
}

class DesktopPriceTableHeaderRow extends React.Component {
  render(): React.ReactNode {
    return (
      <Table.Row style={ { fontSize: '1.25em' } }>
        <Table.HeaderCell rowSpan='2' style={ { paddingLeft: 220, padding: 40 } }/>
        <Table.HeaderCell rowSpan='2' textAlign='center'
          style={ { padding: '0 4em' } }>{ freePlan !== undefined ? 'Free' : null }</Table.HeaderCell>
        <Table.HeaderCell rowSpan='2' textAlign='center'
          style={ {  padding: '0 4em' } }>{ monthlyPlan !== undefined
            ? (
              <React.Fragment>
                <p style={{ marginBottom: '0px' }}>
                  Monthly
                </p>
                <p style={{ fontWeight: 'normal', fontSize: '0.75em' }}>
                  <span style={{ color: 'red' }}>*</span>First 30 Days $0
                </p>
              </React.Fragment>
            )
            : null
          }
        </Table.HeaderCell>
        <Table.HeaderCell rowSpan='2' textAlign='center'
          style={ { paddingRight: 0 } }>{ annualPlan !== undefined
            ? (
              <React.Fragment>
                <p style={{ marginBottom: '0px' }}>
                  Annual
                </p>
                <p style={{ fontWeight: 'normal', fontSize: '0.75em' }}>
                  <span style={{ color: 'red' }}>*</span>First 30 Days $0
                </p>
              </React.Fragment>
            )
            : null
          }</Table.HeaderCell>
      </Table.Row>
    );
  }
}

class DesktopPriceAmountRow extends React.Component {
  render(): React.ReactNode {
    return (
      <Table.Row>
        <Table.Cell textAlign='center' style={ { fontSize: '2em' } }>Price</Table.Cell>
        <Table.Cell style={ { fontSize: '2em' } } textAlign='center'>
          { freePlan !== undefined ? `$${ freePlan.amount }` : null }
        </Table.Cell>
        <Table.Cell style={ { fontSize: '2em' } } textAlign='center'>
          { monthlyPlan !== undefined ? `$${ (monthlyPlan.amount / 100).toFixed(0) }` : null }
        </Table.Cell>
        <Table.Cell style={ { fontSize: '2em' } } textAlign='center'>
          <div style={ { marginTop: 20.7 } }/>
          { annualPlan !== undefined ? `$${ (annualPlan.amount / 100 / 12).toFixed(0) }` : null }
          <div style={ { fontSize: 'small', color: 'grey', marginTop: 5 } }>
            { annualPlan !== undefined ? `$${ (annualPlan.amount / 100).toFixed(0) } Prepaid Annual Billing` : null }
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }
}


class DesktopOptionDescriptionRow extends React.Component<OptionRowProps> {
  render(): React.ReactNode {
    const { option } = this.props;
    return (
      <Table.Row>
        <Table.Cell style={ { fontSize: '1.25em' } }>{ option.description }</Table.Cell>
        <Table.Cell textAlign='center'>
          {
            option.supportedByTrial ? <Icon color='green' name='checkmark' size='large'/> :  ''
          }
        </Table.Cell>
        <Table.Cell textAlign='center'>
          {
            option.supportedByMonthly ? <Icon color='green' name='checkmark' size='large'/> : null
          }
        </Table.Cell>
        <Table.Cell textAlign='center'>
          {
            option.supportedByAnnual ? <Icon color='green' name='checkmark' size='large'/> : null
          }
        </Table.Cell>
      </Table.Row>
    );
  }
}

export {
  MobilePriceCards,
  DesktopPriceTableHeaderRow,
  DesktopPriceAmountRow,
  DesktopOptionDescriptionRow
};
