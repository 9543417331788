import AccountHelp from './components/AccountHelp';
import ActivateSubscriptionPageContainer from './pages/subscription/ActivateSubscriptionPage';
import AlertContainer from './components/AlertContainer';
import OldBeforePaginationFeatureArchivedProjectsPage from './pages/OldBeforePaginationFeatureArchivedProjectsPage';
import BankAccountsContainer from './pages/subscription/BankAccountsPage';
import CalculationTest from './pages/CalculationTest';
import ChangePasswordPage from './pages/ChangePasswordPage';
import ChangeSubscriptionPageContainer from './pages/subscription/ChangeSubscriptionPage';
import OldBeforePaginationFeatureClosedProjectsPage from './pages/OldBeforePaginationFeatureClosedProjectsPage';
import CustomerFeedbackPage from './pages/CustomerFeedbackPage';
import DisplayInvoicesContainer from './pages/subscription/InvoicesPage';
import DisplayPricesPage from './pages/prices-page/DisplayPricesPage';
import EditApplication from './pages/applications/EditApplication';
import EditGeneralContractor from './pages/general-contractors/EditGeneralContractor';
import EditProfile from './pages/profile/EditProfile';
import EditProject from './pages/projects/EditProject';
import EditProjectLines from './pages/projects/EditProjectLines';
import FeatureFlagService, { MAINTENANCE_MODE_FEATURE, PAGINATION_FEATURE } from './services/FeatureFlagService';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import GlobalMaintenanceComponent from './pages/maintenance-page/GlobalMaintenanceComponent';
import InvoicesPageContainer from './pages/subscription/InvoicesPage';
import ListApplications from './pages/applications/ListApplications';
import OldBeforePaginationFeatureListGeneralContractors from './pages/general-contractors/OldBeforePaginationFeatureListGeneralContractors';
import LoginPage from './pages/LoginPage';
import ManageSubscriptionPageContainer from './pages/subscription/ManageSubscriptionPage';
import NewListChangeOrders from './pages/change-orders/NewListChangeOrders';
import Page404 from './pages/Page404';
import PaymentService from './services/PaymentService';
import PrivacyPage from './pages/PrivacyPage';
import ProtectedRoute from './components/ProtectedRoute';
import React from 'react';
import ReceiptsPageContainer from './pages/subscription/ReceiptsPage';
import ReferralComponent from './components/ReferralComponent';
import SelectSubscriptionPageContainer from './pages/subscription/SelectSubscriptionPage';
import ShowApplicationPage from './pages/applications/ShowApplicationPage';
import ShowGeneralContractor from './pages/general-contractors/ShowGeneralContractor';
import ShowProfile from './pages/profile/ShowProfile';
import ShowProject from './pages/projects/ShowProject';
import SignupPage from './pages/SignupPage';
import TagManager from 'react-gtm-module';
import TermsOfServicePage from './pages/terms/TermsOfServicePage';
import TestPage from './pages/TestPage';
import OldBeforePaginationFeatureUnbilledRetainagePage from './pages/OldBeforePaginationFeatureUnbilledRetainagePage';
import OldBeforePaginationFeatureUserHomePage from './pages/OldBeforePaginationFeatureUserHomePage';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { FirebaseAuthService } from './services/AuthService';
import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx';
import { tagManagerArgs } from './common/Constants';
import PDFSubscribeRoute from './components/PDFSubscribeRoute';
import NoPasswordSignupPage from './pages/NoPasswordSignupPage';
import CheckEmailForPasswordPage from './pages/CheckEmailForPasswordPage';
import ListGeneralContractors from './pages/general-contractors/ListGeneralContractors';
import UserHomePage from './pages/UserHomePage';
import ClosedProjectsPage from './pages/ClosedProjectsPage';
import UnbilledRetainagePage from './pages/UnbilledRetainagePage';
import ArchivedProjectsPage from './pages/ArchivedProjectsPage';


TagManager.initialize(tagManagerArgs);

interface AppProps {
  auth?: FirebaseAuthService;
  payment?: PaymentService;
  featureFlag?: FeatureFlagService;
}

interface AppState {
  maintenanceMode: boolean;
  isPaginationEnabled: boolean;
}

@inject('auth')
@inject('payment')
@inject('featureFlag')
@observer
export default class App extends React.Component<AppProps, AppState> {
  dispose: () => void = () => { /* noop fn */ }

  state: AppState = {
    maintenanceMode: false,
    isPaginationEnabled: false
  }

  async componentDidMount(): Promise<void> {
    this.dispose = autorun(async() => {
      const [ maintenanceMode, isPaginationEnabled ] = await Promise.all([
        this.props?.featureFlag?.getIfFeatureEnabled(MAINTENANCE_MODE_FEATURE),
        this.props?.featureFlag?.getIfFeatureEnabled(PAGINATION_FEATURE)
      ]);

      this.setState({
        maintenanceMode: maintenanceMode as boolean,
        isPaginationEnabled: isPaginationEnabled as boolean
      });
    });
  }

  componentWillUnmount(): void {
    this.dispose();
  }

  render(): JSX.Element {

    if (this.state.maintenanceMode) {
      return <GlobalMaintenanceComponent/>;
    }

    return (
      <div>
        <BrowserRouter>
          <Switch>
            <ProtectedRoute path="/contact" component={ CustomerFeedbackPage }/>
            <ProtectedRoute exact path="/pdf/:pdfName/:id" component={ PDFSubscribeRoute }/>
            <ProtectedRoute path="/receipts" component={ ReceiptsPageContainer }/>
            <ProtectedRoute path="/bank-accounts" component={ BankAccountsContainer }/>
            <ProtectedRoute path="/invoices" component={ InvoicesPageContainer }/>
            <ProtectedRoute path="/subscription/activate" component={ ActivateSubscriptionPageContainer }/>
            <ProtectedRoute path="/subscription/change" component={ ChangeSubscriptionPageContainer }/>
            <ProtectedRoute path="/subscription/manage" component={ ManageSubscriptionPageContainer }/>
            <ProtectedRoute path="/subscription/select" component={ SelectSubscriptionPageContainer }/>
            <ProtectedRoute path="/display-invoices" component={ DisplayInvoicesContainer }/>
            <ProtectedRoute path="/projects/:id/edit-project-lines" component={ EditProjectLines }/>
            <ProtectedRoute path="/projects/:id/change-orders" component={ NewListChangeOrders }/>
            <ProtectedRoute path="/projects/:id/edit" component={ EditProject }/>
            <ProtectedRoute path="/projects/new" component={ EditProject}/>
            <ProtectedRoute path="/projects/closed" component={this.state.isPaginationEnabled ? ClosedProjectsPage : OldBeforePaginationFeatureClosedProjectsPage}/>
            <ProtectedRoute path="/projects/unbilled-retainage" component={this.state.isPaginationEnabled ? UnbilledRetainagePage :
              OldBeforePaginationFeatureUnbilledRetainagePage }/>
            <ProtectedRoute path="/projects/archived" component={this.state.isPaginationEnabled ?  ArchivedProjectsPage : OldBeforePaginationFeatureArchivedProjectsPage }/>
            <ProtectedRoute path="/projects/:id" component={ShowProject}/>
            <ProtectedRoute path="/applications/:id/edit" component={ EditApplication }/>
            <ProtectedRoute path="/applications/new" component={ EditApplication }/>
            <ProtectedRoute path="/applications/:id" component={ ShowApplicationPage }/>
            <ProtectedRoute path="/applications" component={ ListApplications }/>
            <ProtectedRoute path="/general-contractors/:id/edit" component={ EditGeneralContractor }/>
            <ProtectedRoute path="/general-contractors/new" component={ EditGeneralContractor }/>
            <ProtectedRoute path="/general-contractors/:id" component={ ShowGeneralContractor }/>
            <ProtectedRoute path="/general-contractors" component={this.state.isPaginationEnabled ?
              ListGeneralContractors : OldBeforePaginationFeatureListGeneralContractors }/>
            <ProtectedRoute path="/profile/update" component={ EditProfile }/>
            <ProtectedRoute path="/profile" component={ ShowProfile }/>
            <ProtectedRoute path="/change-password" component={ ChangePasswordPage }/>
            <ProtectedRoute exact path="/test" component={ TestPage }/>
            <ProtectedRoute exact path="/" component={ this.state.isPaginationEnabled ?
              UserHomePage : OldBeforePaginationFeatureUserHomePage }/>
            <Route path="/r/:rId" component={ ReferralComponent }/>
            <Route path="/login" component={ LoginPage }/>
            <Route path="/signup" component={ SignupPage }/>
            <Route path="/signup-no-password" component={ NoPasswordSignupPage }/>
            <Route path="/check-email" component={ CheckEmailForPasswordPage }/>
            <Route path="/account-help" component={ AccountHelp }/>
            <Route path="/pricing" component={ DisplayPricesPage }/>
            <Route path="/terms" component={ TermsOfServicePage }/>
            <Route path="/forgot-password" component={ ForgotPasswordPage }/>
            <Route path="/calculation-test" component={ CalculationTest }/>
            <Route path="/privacy" component={PrivacyPage}/>
            <Route component={ Page404 }/>
          </Switch>
        </BrowserRouter>
        <AlertContainer/>
      </div>
    );
  }
}
