import * as React from 'react';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FirebaseAuthService } from '../../services/AuthService';
import { inject, observer } from 'mobx-react';

interface HomeButtonProps {
  auth?: FirebaseAuthService;
}

@inject('auth')
@observer
export default class HomeButton extends React.Component<HomeButtonProps> {
  render(): JSX.Element | string {
    if (this.props.auth?.initialized && this.props.auth?.user) {
      return (
        <Menu.Item
          as={Link}
          to='/'
          active={document.location.pathname === '/'}
        >
          Your Projects
        </Menu.Item>
      );
    }
    return '';
  }
}
