import React from 'react';
import ActiveProject from '../../../../schemas/projects/ActiveProject';
import { Container, Grid, Header, Message, Table } from 'semantic-ui-react';
import './summary.css';

interface ProjectLineItemAuditProps {
  project: ActiveProject;
}

export default class ProjectLineItemAudit extends React.Component<ProjectLineItemAuditProps> {
  render(): React.ReactNode {
    return (
      <Container>
        <Grid stackable>
          <Grid.Column width={8}>
            <Grid.Row>
              <Header as='h3'>Project Line Item Audit</Header>
              <Table celled className='project-summary'>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      Total Original Contract Amount
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: 'right' }}>
                      {
                        this.props.project?.originalContractAmount
                          ? this.props.project.originalContractAmount.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD'
                          })
                          : ''
                      }
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      Project Line Item Total
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: 'right' }}>
                      {
                        this.props.project?.lineTotal.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD'
                        })
                      }
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row style={{ color: 'red' }}>
                    <Table.Cell>
                      Project Line Item Excess/(Shortfall)
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: 'right' }}>
                      {this.props.project.excessShortfall.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD'
                      }).replace(/^-\$(.+)$/, '$($1)')}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid.Row>
              <Message negative className='audit-message'>
                <Message.Header>Project Line Item {this.props.project.excessShortfall > 0 ? 'Excess' : 'Shortfall'}</Message.Header>
                {
                  this.props.project.excessShortfall > 0 ? (
                    <React.Fragment>
                      <p>
                        The sum of the scheduled values for the project line items exceeds the original contract amount
                        by {
                          this.props.project.excessShortfall.toLocaleString('en-US', {
                            style: 'currency',currency: 'USD'
                          })
                        }. Please update the project so that the total scheduled values of the line items equals the original contract amount.
                      </p>
                      <p>
                        You will not be able to proceed with creating Application #1 until this is resolved.
                      </p>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <p>
                        The sum of the scheduled values for the project line items is less than the original contract amount
                        by {
                          this.props.project.excessShortfall.toLocaleString('en-US', {
                            style: 'currency',currency: 'USD'
                          }).replace(/-/, '')
                        }.  Please update the project so that the total scheduled values of the line items equals the original contract amount.
                      </p>
                      <p>
                        You will not be able to proceed with creating Application #1 until this is resolved.
                      </p>
                    </React.Fragment>
                  )
                }
              </Message>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}
