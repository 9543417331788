import React from 'react';
import Page, { BreadcrumbObject } from '../components/Page';
import { Button, Grid } from 'semantic-ui-react';
import { ProtectedRouteProps } from '../components/ProtectedRoute';
import { Link } from 'react-router-dom';
import { CustomLoader } from '../components/CustomLoader';

type SubscriptionRequiredPageProps = ProtectedRouteProps

interface SubscriptionRequiredPageState {
  loading: boolean;
}


export default class SubscriptionRequiredPage extends React.Component<SubscriptionRequiredPageProps, SubscriptionRequiredPageState> {

  state: SubscriptionRequiredPageState = {
    loading: true,
  }

  async componentDidMount(): Promise<void> {
    this.setState({
      loading: false,
    });
  }

  render(): React.ReactNode {
    const { loading } = this.state;
    const crumbs: Array<BreadcrumbObject> = [
      { key: 'projects', href: '/', content: 'Projects', link: true },
      { key: 'not_subscribe', content: 'Subscription Required', link: false }
    ];

    if (loading) {
      return <CustomLoader loadingMessage={this.props.loaderContent}/>;
    }

    return (
      <Page pageTitle='Subscription Required' breadcrumb={ crumbs }>
        <Grid columns={2} style={ { marginTop: '1em' } }>
          <Grid.Row>
            <Grid.Column width={13}>
              <p style={ { fontSize: '1.5em' } }>
                <p>
                  <b>Subscription required for full access to the { process.env.REACT_APP_APP_NAME } solution</b>
                </p>
              </p>
              <p style={ { fontSize: '1.25em' } }>
                <p>
                  <b>Get your first Month free!</b>  If you are not fully satisfied during your free month, sign into your
                  account profile, select subscriptions, and click the cancel button.
                  It&apos;s that simple, no phone call, no email, just click cancel.
                </p>
                <p>
                  With a paid subscription to { process.env.REACT_APP_APP_NAME }, you
                  will have access to all PDF documents for you to submit to general contractors:
                </p>
                <p>
                  <ul>
                    <li>Applications & Certificates for payment</li>
                    <li>Continuation Sheets</li>
                    <li>Change Order Sheets</li>
                  </ul>
                </p>
                <p>
                  { process.env.REACT_APP_APP_NAME } will automatically generate the forms for you! All you need to do is
                  print, sign, and deliver them to your general contractors. No more having to fill these forms out every
                  time you want to get paid. Let PAYearned do it for you!
                </p>
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Button as={Link} to={'/'}>Close</Button>
            <Button color='green' as={ Link }
              to={ { pathname: '/subscription/select', query: { callbackUrl: window.location.pathname } } }>Subscribe
              Now
            </Button>
          </Grid.Row>
        </Grid>
      </Page>
    );
  }
}
