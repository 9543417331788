import CurrencyWidget from '../widgets/currency/CurrencyWidget';
import PercentWidget from '../widgets/PercentWidget';
import { ArrayFieldTableTemplate } from '../templates/ArrayFieldTableTemplate';
import { ProjectLineUISchema } from '../ProjectLineSchema';
import NewFeatureDropdownSelector from '../widgets/NewFeatureDropdownSelector';
import { UiSchema } from '@rjsf/core';

export const ProjectUISchema: UiSchema = {
  generalContractorId: {
    'ui:widget': NewFeatureDropdownSelector,
    options: {
      endpoint: 'general-contractors',
      listFields: {
        name: {
          type: 'string',
          title: 'Name'
        },
        licenseNumber: {
          type: 'string',
          title: 'License Number',
        },
      },
      displayFieldName: 'name',
      displayFieldSchema: {
        type: 'string',
        title: 'General Contractor Name'
      },
    }
  },
  originalContractAmount: {
    'ui:widget': CurrencyWidget,
  },
  defaultRetainage: {
    'ui:widget': PercentWidget,
  },
  projectLines: {
    'ui:ArrayFieldTemplate': ArrayFieldTableTemplate,
    items: ProjectLineUISchema,
  },
};
