import * as React from 'react';
import Stepper, { Step } from 'react-material-stepper';
import 'react-material-stepper/dist/react-stepper.css';
import './style.css';
import { StepId } from 'react-material-stepper/src/typings';
import { ProjectJSON } from '../../../schemas/projects/ProjectJSON';
import { ProjectLineJSON } from '../../../schemas/ProjectLineSchema';
import Step4, { STEP4 } from './steps/step4';

type EditChangeOrderStepperProps = {
  project: ProjectJSON;
  closeModalCallback: (stepId: StepId) => void;
  changeOrderToEdit: ProjectLineJSON | undefined;
}

const EditChangeOrderStepper: React.FunctionComponent<EditChangeOrderStepperProps> = (editChangeOrderStepperProps) => (

  <Stepper className='custom-theme' onResolve={(stepId: StepId): void => editChangeOrderStepperProps.closeModalCallback(stepId)}>
    <Step stepId={STEP4} title='Edit Change Order'>
      <Step4 project={editChangeOrderStepperProps.project} changeOrder={editChangeOrderStepperProps.changeOrderToEdit}/>
    </Step>
  </Stepper>
);

export default EditChangeOrderStepper;
