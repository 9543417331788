import React from 'react';
import { Button, Icon, Message } from 'semantic-ui-react';

interface ClearProjectFiltersProps {
  filtered: boolean;
  clearFilters: () => void;
}

export default class ClearProjectFilters extends React.Component<ClearProjectFiltersProps> {
  render(): React.ReactNode {
    if (!this.props.filtered) return '';
    return (
      <Message warning>
        <Message.Header>Project List Filtered</Message.Header>
        <p>
          You are currently filtering the project list. Click the button below to go back to the full
          project list.
        </p>
        <Button onClick={(): void => this.props.clearFilters()}>
          <Icon color="red" name="delete"/>
          Clear Filters
        </Button>
      </Message>
    );
  }
}
