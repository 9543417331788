import React from 'react';
import FadeIn from 'react-fade-in';
import {
  StepperAction,
  StepperContent,
  StepperContext
} from 'react-material-stepper';
import { GUIDE_STEP_1 } from './GuideStep1';
import { Image } from 'semantic-ui-react';

export const GUIDE_STEP_2 = 'guide-step-two';

interface Step2Props {
  onClose: () => void;
}

const GuideStep2: React.FunctionComponent<Step2Props> = ({ onClose }: Step2Props) => {
  const { resolve, goAt } = React.useContext(StepperContext);
  const goToPreviousStep = (): void => goAt(GUIDE_STEP_1);


  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    resolve('');
  };

  return (
    <StepperContent
      onSubmit={onSubmit}
      actions={
        <React.Fragment>
          <StepperAction onClick={ (): void => onClose() }>Close</StepperAction>
          <StepperAction align='right' type="button" onClick={goToPreviousStep}>Previous</StepperAction>
          <StepperAction align='right' type="submit">Next</StepperAction>
        </React.Fragment>
      }
    >
      <FadeIn>
        <Image src='/gif/2-Download-Documents.gif' size='big' centered rounded/>
      </FadeIn>
    </StepperContent>
  );
};

export default GuideStep2;
