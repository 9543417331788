export const AIA_TRADE_MARKS = 'AIA®, G702® & G703® are Registered trademarks of the American Institute of Architects';
export const APPLICATION_FOR_PAYMENT_PDF_PATH = '/pdf/application-for-payment/';
export const CHANGE_ORDERS_PDF_PATH = '/pdf/change-orders/';
export const CONTINUATION_SHEET_PDF_PATH = '/pdf/continuation-sheet/';
export const COPYRIGHTS = 'Copyright © 2021 PAYearned. All rights reserved.';
export const MAX_PHONE_WIDTH_MEDIA_QUERY = '(max-width: 600px)';
export const RISK_FREE_OFFER = '*Risk-free 30-day evaluation';
export const SALES_EMAIL = 'sales@payearned.com';
export const SALES_PHONE_NUMBER = '954-399-2774';
export const EMAIL_IS_ALREADY_IN_USE_ERROR = 'This email address already has an account.';

export enum ApplicationLinesType {
  ProjectLineItems = 'Project Line Items',
  ChangeOrders = 'Change Orders'
}

export enum ProjectColumnName {
  CreateDate = 'Create Date',
  PercentComplete = '% Complete',
  ProjectName = 'Project Name',
  Location = 'Location',
  GeneralContractorName = 'General Contractor Name'
}

export const tagManagerArgs = {
  gtmId: 'GTM-MJ5RSJN'
};
