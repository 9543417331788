import { ProjectJSON } from './ProjectJSON';
import { GeneralContractorJSON } from '../GeneralContractorJSONSchema';
import { ApplicationJSON } from '../applications/ApplicationJSON';
import { ProjectLineJSON } from '../ProjectLineSchema';
import { DataService } from '../../services/DataService';
import ActiveApplication from '../applications/ActiveApplication';

export default class ActiveProject implements ProjectJSON {
  id?: string;
  name?: string;
  originalContractAmount?: number;
  changeOrderAmount?: number;
  projectId?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  closedAt?: Date;
  archivedAt?: Date;
  deletedAt?: Date;
  updatedAt?: string;
  subcontractNumber?: string;
  generalContractorId?: string;
  generalContractor?: GeneralContractorJSON;
  applications?: Array<ApplicationJSON>;
  projectLines?: Array<ProjectLineJSON>;
  changeOrders?: Array<ProjectLineJSON>;
  defaultRetainage?: number;
  billedToDate?: number;
  storedToDate?: number;
  billedAndStoredToDate?: number;
  billedPercent?: number;
  dataService = new DataService();
  error = true;
  loading = false;
  saving = false;
  private _onLoad: Array<(value?: void | PromiseLike<void> | undefined) => void> = [];
  lineTotal = 0;
  excessShortfall = 0;
  allowApplications = false;
  allowClose = false;
  closed = false;
  hasOpenApplication = false;
  hasCompletedApplications = false;
  currentApplication?: ActiveApplication;
  totalAdjustedAmount = 0;
  retainage = 0;
  totalLessRetainage = 0;
  balanceToFinish = 0;

  constructor();
  constructor(id?: string);
  constructor(json?: ProjectJSON);
  constructor(id?: string | ProjectJSON) {
    // If nothing was passed in, do almost nothing
    if (!id) {
      this.error = false;
    } else if (typeof id === 'string') {
      // Load the application
      this.id = id;
      this.load();
    } else {
      // Create it from JSON
      this.error = false;
      this.fromJSON(id);
    }
  }

  async load(): Promise<void> {
    if (this.id && !this.loading) {
      this.loading = true;

      const project = await this.dataService.get('projects', this.id) as ProjectJSON;
      this.error = !project;

      // Build the project if we have one
      if (!this.error) {
        return this.fromJSON(project);
      }else {
        this.loading = false;
        // Copy the onLoad array and reset it
        const onLoad = this._onLoad.slice();
        this._onLoad = [];

        // Make the callbacks from the copy
        onLoad.forEach(callback => callback());
      }
    } else if (this.loading) {
      // If we're already loading, return a promise, and add queue up the callback
      return new Promise(callback => {
        this._onLoad.push(callback);
      });
    }
  }

  async fromJSON(project: ProjectJSON): Promise<void> {
    // Set variables for active project
    this.name = project.name;
    this.originalContractAmount = project.originalContractAmount;
    this.changeOrderAmount = project.changeOrderAmount;
    this.projectId = project.projectId;
    this.addressLine1 = project.addressLine1;
    this.addressLine2 = project.addressLine2;
    this.city = project.city;
    this.state = project.state;
    this.zip = project.zip;
    this.subcontractNumber = project.subcontractNumber;
    this.generalContractorId = project.generalContractorId;
    this.generalContractor = project.generalContractor;
    this.applications = project.applications?.length !== undefined ? project.applications.sort((a, b) =>
      Number(a.sequenceNumber) - Number(b.sequenceNumber)
    ) : [];
    this.archivedAt = project.archivedAt;
    this.updatedAt = project.updatedAt;
    this.projectLines = project.projectLines ? project.projectLines : [];
    this.changeOrders = project.changeOrders;
    this.defaultRetainage = (project.defaultRetainage) ? project.defaultRetainage : 0;
    this.billedToDate = project.billedToDate ? project.billedToDate : 0;
    this.billedPercent = project.billedPercent ? project.billedToDate : 0;
    return this.calculate();
  }

  async calculate(): Promise<void> {
    // Sort the project lines
    this.projectLines = this.projectLines?.length !== undefined ? this.projectLines
      .sort((a, b) => Number(a.sequenceNumber) - Number(b.sequenceNumber)) : [];

    // Total the project lines
    this.lineTotal = this.projectLines.filter(projectLine => projectLine.lineType === 'original').length > 0
      ? this.projectLines
        .filter(projectLine => projectLine.lineType === 'original')
        .map(projectLine => projectLine.scheduledValue)
        .reduce((total, current) => Number(current) + Number(total)) as number
      : 0;
    this.lineTotal = Math.round(this.lineTotal * 100) / 100;

    // Set hasCompletedApplications
    this.hasCompletedApplications = Boolean(this.applications?.find(application => application.status?.toLowerCase() === 'completed'));

    // See if we have an open application
    this.hasOpenApplication = Boolean(this.applications?.find(application => application.status?.toLowerCase() === 'open'));

    // Get the closed project status
    this.closed = this.applications?.length === 0 ? false : this.applications?.[this.applications.length - 1].status === 'Final';

    // Get the total billed amount
    this.billedToDate = this.projectLines.length > 0 ? this.projectLines
      // Get billedToDate
      .map(line => {
        // If the balance to finish is less than 1, add the difference
        const balanceToFinsh = Math.round((Number(line.scheduledValue) - Number(line.billedToDate) - Number(line.storedToDate)) * 100) / 100;
        if (Math.abs(balanceToFinsh) < 1) {
          return Number(line.billedToDate) + balanceToFinsh;
        }
        return line.billedToDate;
      })
      // Total the billedToDate
      .reduce((prior, current) => (current ? current : 0) + (prior ? prior : 0)) : 0;

    // Get the total stored to date amount
    this.storedToDate = this.projectLines.length > 0 ? this.projectLines
      // Get billedToDate
      .map(line => line.storedToDate)
      // Total the billedToDate
      .reduce((prior, current) => (current ? current : 0) + (prior ? prior : 0)) : 0;

    this.billedAndStoredToDate = Number(this.billedToDate) + Number(this.storedToDate);

    // Get the change orders
    const changeOrders = this.projectLines
      // Filter to change orders
      .filter(line => line.lineType === 'changeOrder');
    this.changeOrders = changeOrders;

    // Get the scheduled value for change orders
    this.changeOrderAmount = changeOrders.length === 0 ? 0 : changeOrders
      // Get scheduledValue
      .map(line => line.scheduledValue)
      // Total the scheduledValue
      .reduce((prior, current) => (current ? current : 0) + (prior ? prior : 0)) as number;

    // Get the billed percent
    this.billedPercent = this.originalContractAmount
      ? Math.round(((Number(this.billedToDate) + Number(this.storedToDate)) / (this.originalContractAmount + this.changeOrderAmount) * 10000)) / 10000
      : 0;

    // Get the retainage
    this.retainage = this.projectLines.length > 0 ? this.projectLines.map(projectLine => {
      // If the balance to finish is less than 1, add the difference
      let total = Math.round((Number(projectLine.billedToDate) + Number(projectLine.storedToDate))*100)/100;
      const balanceToFinish = Math.round((Number(projectLine.scheduledValue) - total) * 100) / 100;
      // If the balance to finish is less than 1, set the total to the scheduled value
      if (Math.abs(balanceToFinish) < 1) {
        total = Number(projectLine.scheduledValue);
      }
      return Math.trunc(Number(projectLine.retainage) * total * 100) / 100;
    }).reduce((total, current) => total+current) : 0;

    // Allow applications
    this.allowApplications =
      // if the line totals match the original contract
      this.lineTotal === this.originalContractAmount
      // and we're not 100% billed or have a stored amount
      && (this.billedPercent !== 1 || this.storedToDate !== 0)
      // and the project isn't closed
      && !this.closed;

    // Get the current application if we have one
    if (this.applications && this.applications.length > 0) {
      this.currentApplication = new ActiveApplication(this.applications[this.applications.length - 1].id);
      await this.currentApplication.load();
    }

    // Allow close if
    this.allowClose =
      // we're 100% billed
      this.billedPercent === 1
      // there's no stored amount remaining
      && !this.currentApplication?.grandTotalMaterialPresentlyStored
      // and the project isn't closed
      && !this.closed;

    // Calculate the remaining totals
    this.totalAdjustedAmount = Number(this.originalContractAmount) + this.changeOrderAmount;
    this.excessShortfall = this.lineTotal - Number(this.originalContractAmount);
    this.totalLessRetainage = Number(this.billedToDate) + Number(this.storedToDate) - this.retainage;
    this.balanceToFinish = this.currentApplication && this.currentApplication.status === 'Final' ? 0 : this.totalAdjustedAmount - this.totalLessRetainage;

    this.loading = false;
    // Copy the onLoad array and reset it
    const onLoad = this._onLoad.slice();
    this._onLoad = [];

    // Make the callbacks from the copy
    onLoad.forEach(callback => callback());
  }

  toString(): string {
    return JSON.stringify(this.toJSON(), null, 2);
  }

  toJSON(): ProjectJSON {
    const {
      id, name, originalContractAmount, changeOrderAmount, projectId, addressLine1, addressLine2, city, state, zip,
      subcontractNumber, generalContractorId, generalContractor, applications, projectLines, changeOrders,
      defaultRetainage, billedToDate, billedPercent, archivedAt, closedAt
    } = this;
    return JSON.parse(JSON.stringify({
      id, name, originalContractAmount, changeOrderAmount, projectId, addressLine1, addressLine2, city, state, zip,
      subcontractNumber, generalContractorId, generalContractor, applications, projectLines, changeOrders,
      defaultRetainage, billedToDate, billedPercent, archivedAt, closedAt
    }));
  }
}
