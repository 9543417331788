import React, { CSSProperties } from 'react';
import { Card, CardGroup, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import {
  APPLICATION_FOR_PAYMENT_PDF_PATH,
  CHANGE_ORDERS_PDF_PATH,
  CONTINUATION_SHEET_PDF_PATH
} from '../../../common/Constants';
import ActiveApplication from '../../../schemas/applications/ActiveApplication';
import { ApplicationJSON } from '../../../schemas/applications/ApplicationJSON';
import { isChangeOrdersEmpty } from './DesktopApplicationList';
import ActiveProject from '../../../schemas/projects/ActiveProject';
import { Media } from '../../../components/Page';
import { FirebaseAuthService } from '../../../services/AuthService';

const styles = {
  leftColumnDiv: {
    position: 'relative',
    fontWeight: 700,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  pdfIconDiv: {
    position: 'relative' ,
    left: 225,
    bottom: 30
  }
};

export interface MobileApplicationListProps {
  auth?: FirebaseAuthService;
  project?: ActiveProject;
}


export default class MobileApplicationList extends React.Component<MobileApplicationListProps> {

  render(): React.ReactNode {
    const { project } = this.props;
    const hasChangeOrders: boolean | undefined = project?.projectLines && project.projectLines.filter(p => p.lineType === 'changeOrder').length > 0;

    if (typeof project !== 'undefined')
      if (!project.applications || (project.applications && project.applications.length < 1)) {
        return '';
      }

    return (
      <Media at={ 'mobile' }>
        <CardGroup style={{
          right: 13
        }}>
          <Card centered style={ {
            marginLeft: 21,
            marginRight: 21,
            right: 12 } }>
            <Card.Content textAlign={ 'left' }>
              <Card.Header style={ { fontSize: '1.2em' } }>
                Application Document History
              </Card.Header>
              {
                typeof project?.applications !== 'undefined' && project?.applications
                  .sort((a1: ApplicationJSON, a2: ApplicationJSON) => {
                    if (typeof a1.sequenceNumber !== 'undefined' && typeof a2.sequenceNumber !== 'undefined') {
                      return a2.sequenceNumber - a1.sequenceNumber;
                    }
                    return 0;
                  })
                  .map(application => {
                    const app = new ActiveApplication(application);
                    const applicationDate = new Date(app.applicationDate as Date | string).toLocaleDateString();
                    return (
                      <>
                        <hr style={ {
                          marginTop: '17px',
                          marginLeft:
                            '-0.4cm',
                          marginRight: '-0.4cm',
                          borderColor: 'rgba(34, 36, 38, 0.15)',
                          backgroundColor: 'rgba(34, 36, 38, 0.15)',
                          color: 'rgba(34, 36, 38, 0.15)',
                        } }/>
                        <Card.Description>
                          <div style={ styles.leftColumnDiv as CSSProperties }>
                            App#
                            <div style={ {
                              fontWeight: 500,
                              position: 'relative',
                              left: 233,
                              bottom: 20

                            } }>{ app.sequenceNumber }</div>
                          </div>

                          <div style={ styles.leftColumnDiv as CSSProperties }>
                            Date
                            <div style={ {
                              position: 'relative',
                              left: 205,
                              bottom: 18,
                              fontWeight: 500,
                            } }>
                              { applicationDate }
                            </div>
                          </div>

                          <div style={ styles.leftColumnDiv as CSSProperties }>
                            Application
                            <div  style={ styles.pdfIconDiv as CSSProperties }>
                              <Link target='_blank' to={ APPLICATION_FOR_PAYMENT_PDF_PATH + `${ app.id }` }
                              >
                                <Icon name='file pdf outline' size='big'/>
                              </Link>
                            </div>
                          </div>

                          <div style={ styles.leftColumnDiv as CSSProperties }>
                            Continuation Sheet
                            <div  style={ styles.pdfIconDiv as CSSProperties }>
                              <Link target='_blank'  to={ CONTINUATION_SHEET_PDF_PATH + `${ app.id }` }
                              >
                                <Icon name='file pdf outline' size='big'/>
                              </Link>
                            </div>
                          </div>
                          {
                            hasChangeOrders && isChangeOrdersEmpty(app.sequenceNumber as number, this.props.project as ActiveProject) ?
                              <div style={ styles.leftColumnDiv as CSSProperties }
                              >
                                Change Orders
                                <div style={styles.pdfIconDiv as CSSProperties}>
                                  <Link target='_blank'  to={ CHANGE_ORDERS_PDF_PATH + `${ app.id }` }
                                  >

                                    <Icon name='file pdf outline' size='big'/>
                                  </Link>
                                </div>
                              </div> : null
                          }

                        </Card.Description>
                      </>
                    );
                  })
              }
            </Card.Content>
          </Card>
        </CardGroup>
      </Media>
    );
  }
}
