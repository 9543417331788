import React from 'react';
import {
  GeneralContractorJSON,
  GeneralContractorJSONSchema,
  GeneralContractorUISchema, validateGeneralContractor
} from '../../../schemas/GeneralContractorJSONSchema';
import CalculationSchemaForm from '../../../components/CalculationSchemaForm';
import { FieldTemplate } from '../../../schemas/templates/FieldTemplate';
import { AjvError, FormValidation, IChangeEvent } from '@rjsf/core';
import { DataJSON, DataService } from '../../../services/DataService';
import { EditGeneralContractorLocation } from '../EditGeneralContractor';
import { AlertService } from '../../../services/AlertService';


interface CreateGCSchemaFormProps {
  projectUrl?: string;
  alert?: AlertService;
  location?: any;
  history?: any;
  onSuccessfulGCCreationCallback?: (record: DataJSON | undefined) => Promise<void>;
  onErrorGCCreationCallback?: () => Promise<void>;
  onCancelButtonClick?: () => void;
}

interface CreateGCSchemaFormState {
  loading: boolean;
  error: boolean;
  generalContractor?: GeneralContractorJSON;
}

export default class GCSchemaForm extends React.Component<CreateGCSchemaFormProps, CreateGCSchemaFormState> {

  state: CreateGCSchemaFormState = {
    loading: false,
    error: false,
  }
  dataService = new DataService();

  async save(e: IChangeEvent<GeneralContractorJSON>): Promise<void> {
    this.setState({ loading: true });

    // Create a new object w/ the form data
    const generalContractor: GeneralContractorJSON = JSON.parse(JSON.stringify(e.formData));
    // Add the role
    generalContractor.role = 'GENERAL_CONTRACTOR';
    let newGeneralContractor;
    // Create a new generalContractor
    if (!generalContractor.id) {
      try {
        newGeneralContractor = await this.dataService.create('general-contractors', generalContractor as unknown as DataJSON) as unknown as GeneralContractorJSON;
        generalContractor.id = newGeneralContractor.id;
        this.setState({
          loading: false,
          generalContractor: newGeneralContractor,
        });
      } catch (e) {
        generalContractor.licenseNumber = '';
        generalContractor.email = '';
        this.setState({
          loading: false,
          generalContractor: generalContractor,
        });
        if (typeof this.props.onErrorGCCreationCallback !== 'undefined') {
          await this.props.onErrorGCCreationCallback();
        }
      }
    }

    if (typeof this.props.location !== 'undefined') {
      await this.updateGcBasedOnLocationProp(generalContractor);
    } else {
      if (typeof this.props.onSuccessfulGCCreationCallback !== 'undefined') {
        await this.props.onSuccessfulGCCreationCallback(newGeneralContractor);
      }
    }
  }

  async updateGcBasedOnLocationProp(generalContractor: GeneralContractorJSON): Promise<void> {
    // Meaning that GC is already created and linked to the project we are coming from
    let updatedGeneralContractor;
    if (this.extractGCIdFromPathname(this.props.location) === generalContractor.id) {
      try {
        updatedGeneralContractor = await this.dataService.save('general-contractors', generalContractor.id as string, generalContractor as unknown as DataJSON) as unknown as GeneralContractorJSON;
      } catch (e) {
        this.setState({
          loading: false,
          generalContractor: generalContractor
        });
      }
    }

    // If we were passed in a project, go back to the project edit page
    if (this.props.location.state?.parent) {
      // Build the url
      const pathname = this.props.location.state.parent?.id ? `/projects/${this.props.location.state.parent?.id}/edit` : '/projects/new';
      // Update the project with the GC info
      const project = this.props.location.state.parent;
      project.generalContractor = generalContractor;
      project.generalContractorId = generalContractor.id;

      // Forward back to the project edit page
      this.props.history.push({
        pathname: pathname,
        state: {
          project: project
        }
      });
    } else {
      // Change the URL to go back to display mode
      this.props.history.push(`/general-contractors/${generalContractor.id}`);
    }

    // forward to GC profile after you updated it
    if (updatedGeneralContractor && this.props.location.hash) {
      this.props.history.push({
        pathname: this.props.location.hash.substring(1)
      });
      this.props.alert?.createAlert({
        message: '',
        title: 'General Contractor info has been successfully updated',
        type: 'success'
      });
    }
  }

  transformErrors(errors: Array<AjvError>): Array<AjvError> {
    errors.forEach(error => {
      if (error.property === '.phoneNumber') {
        error.property = 'Phone Number';
        error.message = 'Invalid phone number';
      }
    });
    return errors;
  }

  extractGCIdFromPathname(location: EditGeneralContractorLocation): string {
    if (typeof location.pathname !== 'undefined') {
      return location.pathname.substring(location.pathname.indexOf('s/') + 2, location.pathname.indexOf('/e'));
    }
    return '';
  }

  onCancelClick(): void {
    if (typeof this.props.onCancelButtonClick !== 'undefined') {
      this.props.onCancelButtonClick();
    }
  }

  render(): React.ReactNode {
    const { projectUrl } = this.props;
    return (
      <CalculationSchemaForm
        onCancel={(): void => this.onCancelClick()}
        isButtonLoading={this.state.loading}
        projectUrl={projectUrl}
        liveValidate
        schema={GeneralContractorJSONSchema}
        uiSchema={GeneralContractorUISchema}
        FieldTemplate={FieldTemplate}
        formData={this.state.generalContractor}
        onSubmit={(e: IChangeEvent<GeneralContractorJSON>): Promise<void> => this.save(e)}
        validate={(formData: GeneralContractorJSON, errors: FormValidation): FormValidation => validateGeneralContractor(formData, errors)}
        transformErrors={(errors: Array<AjvError>): Array<AjvError> => this.transformErrors(errors)}
      />
    );
  }
}
