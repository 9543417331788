import React from 'react';
import Page from '../../components/Page';
import { Link, RouteChildrenProps } from 'react-router-dom';
import Page404 from '../Page404';
import { Button, Form, Icon, Loader } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { FirebaseAuthService } from '../../services/AuthService';

interface ShowProfileProps extends RouteChildrenProps {
  auth: FirebaseAuthService;
  location: any;
}

interface ShowProfileState {
  loading: boolean;
}

@observer
@inject('auth')
export default class ShowProfile extends React.Component<ShowProfileProps, ShowProfileState> {
  state: ShowProfileState = { loading: true }
  async componentDidMount(): Promise<void> {
    const loading = false;
    if (this.props.auth.user?.hasLogo) {
      const logoData = await this.props.auth.getLogo();
      if (logoData) {
        this.props.auth.user.logo = logoData.url;
      }
    }
    this.setState({ loading });
  }

  render(): React.ReactNode {
    if (this.state.loading) {
      return <Loader>Loading...</Loader>;
    }

    const callbackUrl = this.props.location?.query?.callbackUrl;
    // Show the error page
    if (!this.props?.auth.user?.id) { return <Page404 {...this.props} />; }

    return (
      <Page pageTitle='Account Profile'  breadcrumb={[
        callbackUrl ?  { key: 'project', href: callbackUrl, content: 'Back', link: true } :
          { key: 'home', href: '/', content: 'Your Projects', link: true },
        { key: 'profile', content: 'Account Profile', active: true },
      ]}>
        <Form>
          <Form.Field>
            <label>Your Company Name</label>
            <span>{this.props.auth.user.name}</span>
          </Form.Field>
          <Form.Field>
            <label>Your Logo (to display on PDFs)</label>
            <span>{
              this.props.auth.user.logo
                ?
                <img
                  style={{ width: '200px' }}
                  src={this.props.auth.user.logo}
                />
                : <i style={{ color: 'grey' }}>Not Uploaded</i>
            }</span>
          </Form.Field>
          <Form.Field>
            <label>Application for Payment Signer</label>
            <span>{this.props.auth.user.contactName}</span>
          </Form.Field>
          <Form.Field>
            <label>Email Address</label>
            <span>{this.props.auth.user.email}</span>
          </Form.Field>
          {
            this.props.auth.user.licenseNumber ? <Form.Field>
              <label>License Number</label>
              <span>{this.props.auth.user.licenseNumber}</span>
            </Form.Field> : null
          }
          <Form.Field>
            <label>Phone number</label>
            <span>{this.props.auth.user.phoneNumber}</span>
          </Form.Field>
          <Form.Field>
            <label>Address Line 1</label>
            <span>{this.props.auth.user.addressLine1}</span>
          </Form.Field>
          <Form.Field>
            <label>Address Line 2</label>
            <span>{this.props.auth.user.addressLine2}</span>
          </Form.Field>
          <Form.Field>
            <label>City</label>
            <span>{this.props.auth.user.city}</span>
          </Form.Field>
          <Form.Field>
            <label>State</label>
            <span>{this.props.auth.user.state}</span>
          </Form.Field>
          <Form.Field>
            <label>Zip</label>
            <span>{this.props.auth.user.zip}</span>
          </Form.Field>
          <Form.Group>
            <Button as={Link} to={ { pathname: '/change-password' } }>
              <Icon name='lock'/>
              Change Password
            </Button>
            <Button primary as={Link} to={ { pathname: '/profile/update', query: { callbackUrl: callbackUrl } } }>
              <Icon name='user'/>
              Update Profile
            </Button>
            <Button primary as={Link} to={ { pathname: '/', query: { callbackUrl: callbackUrl } } }>
              Go To Your Projects
            </Button>
          </Form.Group>
        </Form>
      </Page>
    );
  }
}
