import { observable } from 'mobx';
import firebase from 'firebase';
import firebaseConfig from '../config/firebase.config';
import { role } from '../components/auth/EmailPasswordSignup';
import { UserJSON } from '../schemas/UserJSONSchema';
import FeatureFlagService from './FeatureFlagService';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { AuthCredential, UserCredential } from 'firebase/auth';
import { EMAIL_IS_ALREADY_IN_USE_ERROR } from '../common/Constants';

firebase.initializeApp(firebaseConfig);
const FIREBASE_AUTH = firebase.auth();
const FIREBASER_EMAIL_AUTH_PROVIDER = firebase.auth.EmailAuthProvider;

export interface User extends UserJSON {
  firebaseUserId: string;
  accessToken?: string;
  stripeCustomerId?: string;
  referral?: string;
  role?: role;
  emailVerified: boolean;
}

export interface AuthServiceInterface {
  initialized: boolean;
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  emailLoginLink: (email: string) => Promise<void>;
  verifyLoginLink: (email: string) => Promise<void>;
  signup: (email: string, password: string, role: role, referral: string) => Promise<void>;
  logout: () => Promise<void>;
  credential: (email: string, password: string) => Promise<AuthCredential>;
  reauthenticateWithCredential: (authCredential: AuthCredential) => Promise<UserCredential>;
  updatePassword: (newPassword: string) => Promise<void>;
  applyActionCode: (actionCode: string) => Promise<void>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  verifyPasswordResetCode: (code: string) => Promise<string>;
  sendVerificationEmail: () => Promise<void>;
  confirmPasswordReset: (code: string, newPassword: string) => Promise<void>;
}

export class FirebaseAuthService implements AuthServiceInterface {
  @observable user: User | null = null;
  @observable initialized = false;
  private readonly authInvalidActionCode = 'auth/invalid-action-code';
  private readonly expiredActionCode = 'auth/expired-action-code';
  private readonly userDisabled = 'auth/user-disabled';
  private readonly userNotFound = 'auth/user-not-found';
  private readonly invalidEmail = 'auth/invalid-email';
  private readonly emailAlreadyInUse = 'auth/email-already-in-use';
  private readonly tooManyRequests = 'auth/too-many-requests';
  private readonly weakPassword = 'auth/weak-password';
  private readonly wrongPassword = 'auth/wrong-password';
  private readonly userMismatch = 'auth/user-mismatch';
  private readonly invalidCredential = 'auth/invalid-credential';
  private readonly invalidVerificationCode = 'auth/invalid-verification-code';
  private readonly invalidVerificationId = 'auth/invalid-verification-id';
  private readonly requiresRecentLogin = 'auth/requires-recent-login';
  private readonly emailMissing = 'auth/argument-error';
  private readonly somethingWentWrong = 'Something went wrong. Please try again';
  private _tokenRefreshHandle?: NodeJS.Timeout;

  constructor() {
    FIREBASE_AUTH.onAuthStateChanged(async (firebaseUser) => {
      // If the user hasn't changed, exit
      if (this.user && firebaseUser && this.user.firebaseUserId === firebaseUser.uid && this.user.emailVerified === firebaseUser.emailVerified) {
        return;
      }

      // Load the user data
      await this._loadUserData(firebaseUser);
    });

    // Set up a regular refresh of the token every 30 min
    setInterval(async () => {
      if (this.user && firebase.auth().currentUser !== null) {
        this.user.accessToken = await firebase.auth().currentUser?.getIdToken(true);
      }
    }, 60*1000*30);
  }

  private async _loadUserData(firebaseUser: firebase.User | null): Promise<void> {
    // Clear the refresh handler
    if (this._tokenRefreshHandle) {
      clearTimeout(this._tokenRefreshHandle);
      delete this._tokenRefreshHandle;
    }

    // If we don't have a user set it to null and exit
    if (!firebaseUser) {
      this.user = null;
      this.initialized = true;
      return;
    }
    // Firebase has to reload a user because it might have outdated data like verifiedEmail which is causing issues.
    await firebaseUser.reload();
    // Get the access token
    const accessToken = await firebaseUser.getIdToken();

    // Get the user data
    const response = firebaseUser.uid ? await fetch(
      `${process.env.REACT_APP_API_SERVICE_URL}/users/${firebaseUser.uid}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      }
    ) : null;

    // Load the user data and set the access token
    if (response && response.ok) {
      await this.processUserData(firebaseUser, response);
    } else {
      // Try to create the database user if we didn't receive a valid response
      await this.createDatabaseUser(firebaseUser);
    }

    if (this.user?.accessToken && this.user.stripeCustomerId) {
      // Set up the payment service TODO need to remove it from here
      this.initialized = true;
      this._tokenRefreshHandle = setInterval(async () => {
        if (this.user) {
          this.user.accessToken = await firebase.auth().currentUser?.getIdToken();
        }
      }, 1000*60*15);
    } else {
      this.initialized = true;
    }
  }

  async processUserData(firebaseUser: firebase.User, response: Response): Promise<void> {
    try {
      const user = await response.json() as User;
      user.accessToken = await firebaseUser.getIdToken();
      this.user = user;
      this.user.emailVerified = firebaseUser.emailVerified;
    } catch(err) {
      console.error('Unable to fetch user data.');
      throw new Error('Unable to fetch user data.');
    }
  }

  async createDatabaseUser(firebaseUser: firebase.User): Promise<void> {
    const accessToken = await firebaseUser.getIdToken();
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVICE_URL}/users`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          firebaseUserId: firebaseUser.uid,
          email: firebaseUser.email,
          role: 'SUB_CONTRACTOR',
        })
      }
    );
    await this.processUserData(firebaseUser, response);
  }

  get profileComplete(): boolean {
    return Boolean(
      this.user
      && this.user.name
      && this.user.addressLine1
      && this.user.city
      && this.user.state
      && this.user.zip
      && this.user.phoneNumber
    );
  }

  async login(email: string, password: string): Promise<void> {
    try {
      await FIREBASE_AUTH.signInWithEmailAndPassword(email.toLowerCase().trim(), password);
    } catch (e) {
      // Handle error from Firebase Auth.
      console.error('Error in login() - ' + e.code + ': ' + e.message);
      switch (e.code) {
      case this.invalidEmail:
        e.message = 'Your email address is not valid. Please verify your email and try again';
        break;
      case this.userDisabled:
        e.message = 'Your account has been disabled. Please sign up again';
        break;
      case this.userNotFound:
        e.message = 'Account not found. Please check the spelling of the email, or sign up for an account';
        break;
      case this.wrongPassword:
        e.message = 'Invalid password. Please try another password';
        break;
      default:
        e.message = this.somethingWentWrong;
        break;
      }
      throw e;
    }
  }

  async emailLoginLink(email: string) {
    try {
      await FIREBASE_AUTH.sendSignInLinkToEmail(email.toLowerCase(), {
        url: document.location.href.replace(/\/[^/]+$/, '/'),
        handleCodeInApp: true,
      });
    } catch(e) {
      switch (e.code) {
      case this.invalidEmail:
        e.message = 'Your email address is not valid. Please verify your email and try again';
        break;
      default:
        e.message = this.somethingWentWrong;
        break;
      }
      throw e;
    }
  }

  async verifyLoginLink(email: string) {
    try {
      await FIREBASE_AUTH.signInWithEmailLink(email?.toLowerCase());
    } catch(e) {
      switch (e.code) {
      case this.invalidEmail:
        e.message = 'Your email address is not valid. Please verify your email and try again';
        break;
      case this.emailMissing:
        e.message = 'Email is missing';
        break;
      case this.authInvalidActionCode:
        e.message = 'Invalid link, it may have expired.  Please try again.';
        break;
      default:
        e.message = this.somethingWentWrong;
        break;
      }
      throw e;
    }
  }

  logout(): Promise<void> {
    return FIREBASE_AUTH.signOut();
  }

  async signup(email: string, password: string, role: role, rId: string): Promise<void> {
    // SignupPage via the backend
    const methods =  await FIREBASE_AUTH.fetchSignInMethodsForEmail(email.toLowerCase().trim());
    if(methods.length > 0) {
      throw new Error(EMAIL_IS_ALREADY_IN_USE_ERROR);
    }

    const referral = !(rId === null) ? rId : 'payearned';
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVICE_URL}/signup`,
      {
        method: 'POST',
        body: JSON.stringify({ email: email.toLowerCase(), password, role, referral }),
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );

    const result = await response.json();
    if (result.code === 500) {
      throw new Error(result.message);
    }

    // Log in the user
    await this.login(email.toLowerCase().trim(), password);
  }

  async credential(email: string, password: string): Promise<AuthCredential> {
    try {
      return await FIREBASER_EMAIL_AUTH_PROVIDER.credential(email, password);
    } catch (e) {
      console.error('Error in credential() - ' + e.code + ': ' + e.message);
      e.message = 'Error occurred. Please re-login to perform this operation';
      throw e;
    }
  }

  async reauthenticateWithCredential(authCredential: AuthCredential): Promise<UserCredential> {
    try {
      return await FIREBASE_AUTH.currentUser?.reauthenticateWithCredential(authCredential);
    } catch (e) {
      console.error('Error in reauthenticateWithCredential() - ' + e.code + ': ' + e.message);
      switch (e.code) {
      case this.userMismatch:
      case this.invalidVerificationCode:
      case this.invalidVerificationId:
      case this.userNotFound:
      case this.invalidEmail:
        e.message = 'Please re-login to perform this operation';
        break;
      case this.invalidCredential:
      case this.wrongPassword:
        e.message = 'Invalid password. Please try another password';
        break;
      default:
        e.message = this.somethingWentWrong;
        break;
      }
      throw e;
    }
  }

  async updatePassword(newPassword: string): Promise<void> {
    try {
      return await FIREBASE_AUTH.currentUser?.updatePassword(newPassword);
    } catch (e) {
      console.error('Error in updatePassword() - ' + e.code + ': ' + e.message);
      switch (e.code) {
      case this.weakPassword:
        e.message = 'Your password is not strong enough. Please try another password';
        break;
      case this.requiresRecentLogin:
        e.message = 'Error occurred. Please re-login to perform this operation';
        break;
      default:
        e.message = this.somethingWentWrong;
        break;
      }
      throw e;
    }
  }

  async sendVerificationEmail(): Promise<void> {
    return FIREBASE_AUTH.currentUser?.sendEmailVerification();
  }

  async applyActionCode(actionCode: string): Promise<void> {
    try {
      await FIREBASE_AUTH.applyActionCode(actionCode);
      return;
    } catch (e) {
      // Handle error from Firebase Auth.
      console.error('Error in applyActionCode() - ' + e.code + ': ' + e.message);
      switch (e.code) {
      case this.expiredActionCode:
        e.message = 'Your verification link is expired. Please verify your email again';
        break;
      case this.authInvalidActionCode:
        e.message = 'Your verification link is either expired, or has already been used. Please verify your email again';
        break;
      case this.userDisabled:
        e.message = 'Your account has been disabled. Please sign up again';
        break;
      case this.userNotFound:
        e.message = 'Your account is not found. Please sign up again';
        break;
      default:
        e.message = this.somethingWentWrong;
        break;
      }
      throw e;
    }
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    try {
      return await FIREBASE_AUTH.sendPasswordResetEmail(email);
    } catch (e) {
      // Handle error from Firebase Auth.
      console.error('Error in sendPasswordResetEmail() - ' + e.code + ': ' + e.message);
      switch (e.code) {
      case this.invalidEmail:
        e.message = 'Your email address is not valid. Please verify your email and try again';
        break;
      case this.userNotFound:
        e.message = 'Your account is not found. Please verify your email or sign up';
        break;
      case this.tooManyRequests:
        e.message = 'You tried too many times. Please check your email and click on provided link';
        break;
      default:
        e.message = this.somethingWentWrong;
        break;
      }
      throw e;
    }
  }

  async verifyPasswordResetCode(code: string): Promise<string> {
    try {
      return await FIREBASE_AUTH.verifyPasswordResetCode(code);
    } catch (e) {
      // Handle error from Firebase Auth.
      console.error('Error in verifyPasswordResetCode() - ' + e.code + ': ' + e.message);
      switch (e.code) {
      case this.expiredActionCode:
        e.message = 'Your password reset link is expired. Please reset your password again';
        break;
      case this.authInvalidActionCode:
        e.message = 'Your password reset link is invalid. Please reset your password again';
        break;
      case this.userDisabled:
        e.message = 'Your account has been disabled. Please sign up again';
        break;
      case this.userNotFound:
        e.message = 'Your account is not found. Please sign up again';
        break;
      default:
        e.message = this.somethingWentWrong;
        break;
      }
      throw e;
    }
  }

  async confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    try {
      return await FIREBASE_AUTH.confirmPasswordReset(code, newPassword);
    } catch (e) {
      // Handle error from Firebase Auth.
      console.error('Error in confirmPasswordReset() - ' + e.code + ': ' + e.message);
      switch (e.code) {
      case this.expiredActionCode:
        e.message = 'Your password reset link is expired. Please reset your password again';
        break;
      case this.authInvalidActionCode:
        e.message = 'Your password reset link is invalid. Please reset your password again';
        break;
      case this.userDisabled:
        e.message = 'Your account has been disabled. Please sign up again';
        break;
      case this.userNotFound:
        e.message = 'Your account is not found. Please sign up again';
        break;
      case this.weakPassword:
        e.message = 'Your password is not strong enough. Please try another password';
        break;
      default:
        e.message = this.somethingWentWrong;
        break;
      }
      throw e;
    }
  }

  async uploadFile(blob: Blob, path: string): Promise<string|null> {
    const storageRef = firebase.storage().ref();
    const uploadTask = storageRef.child(path).put(blob);
    return new Promise<string>((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot: any) => {
          // this is for watching it as it uploads, we won't bother doing anything here
        },
        (error: any) => {
          reject(error);
        },
        async () => {
          const url = await uploadTask.snapshot.ref.getDownloadURL();
          resolve(url);
        }
      );
    });
  }

  async getDownloadURL(path: string): Promise<string> {
    const storageRef = firebase.storage().ref();
    return storageRef.child(path).getDownloadURL();
  }

  async downloadFile(path: string): Promise<Blob> {
    const url = await this.getDownloadURL(path);
    return (await fetch(url)).blob();
  }

  async getLogo(): Promise<{url: string; width: number; height: number}|undefined> {
    if (this.user?.hasLogo) {
      try {
        const blob = await this.downloadFile(`logos/${this.user.firebaseUserId}`);
        const dataURL = URL.createObjectURL(blob);
        const image = new Image();
        return new Promise<{ url: string; width: number; height: number }>(resolve => {
          image.onload = (): void => {
            resolve({
              url: dataURL,
              height: image.height,
              width: image.width
            });
          };
          image.src = dataURL;
        });
      } catch (err) {
        return undefined;
      }
      return undefined;
    }
  }
}

export const Auth = new FirebaseAuthService();
