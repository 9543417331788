import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ProjectJSON } from '../../../schemas/projects/ProjectJSON';
import { DataService } from '../../../services/DataService';

interface UnbilledRetainageButtonProps {
  onHasUnbilledRetainage?: () => any;
}

interface UnbilledRetainageButtonState {
  loading: boolean;
  unbilledRetainage: boolean;
}

export default class UnbilledRetainageButton extends React.Component<UnbilledRetainageButtonProps, UnbilledRetainageButtonState> {
  dataService = new DataService();

  state: UnbilledRetainageButtonState = {
    loading: true,
    unbilledRetainage: false,
  }

  async componentDidMount(): Promise<void> {
    const projects = await this.dataService?.listProjects(false, false, true) as Array<ProjectJSON>;
    if (projects && projects.length > 0) {
      this.setState({ unbilledRetainage: true, loading: false });
      if (this.props.onHasUnbilledRetainage) this.props.onHasUnbilledRetainage();
    }
    else this.setState({ loading: false });
  }

  render(): React.ReactNode {
    if (this.state.loading) return '';
    if (this.state.unbilledRetainage) return (
      <Button as={Link} to={'/projects/unbilled-retainage'} className='closed-project-btn' color="green">
        <Icon name="dollar sign"/>
        Unbilled Retainage
      </Button>
    );
    return '';
  }
}
