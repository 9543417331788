import moment from 'moment';
import Page from '../components/Page';
import React from 'react';
import { AlertService } from '../services/AlertService';
import { Button, Header, Modal } from 'semantic-ui-react';
import { DataJSON, DataService } from '../services/DataService';
import { inject, observer } from 'mobx-react';
import { ProjectColumnName } from '../common/Constants';
import { ProjectJSON } from '../schemas/projects/ProjectJSON';
import { ReactTabulator } from 'react-tabulator';
import { RouteChildrenProps } from 'react-router-dom';

interface ArchivedProjectsPageProps extends RouteChildrenProps<{ id: string }> {
  alert?: AlertService;
}

interface ArchivedProjectsPageState {
  loading: boolean;
  projects: Array<ProjectJSON>;
  filtered: boolean;
  selectedProjectIds: Array<string>;
  openedDeleteModal: boolean;
  alert?: AlertService;
}

@inject('alert')
@observer
export default class OldBeforePaginationFeatureArchivedProjectsPage extends React.Component<ArchivedProjectsPageProps, ArchivedProjectsPageState> {
  ref?: React.ElementRef<any>;
  dataService = new DataService();

  state: ArchivedProjectsPageState = {
    loading: true,
    projects: [],
    filtered: false,
    selectedProjectIds: [],
    openedDeleteModal: false
  }

  columns = [
    {
      title: ProjectColumnName.ProjectName, field: 'name',
      headerFilter: 'input',
      headerFilterPlaceholder: 'Search project name',
    },
    {
      title: ProjectColumnName.Location, field: 'addressLine1',
      headerFilter: 'input',
      headerFilterPlaceholder: 'Search location',
    },
    {
      title: ProjectColumnName.GeneralContractorName, field: 'generalContractor.name',
      headerFilter: 'input',
      headerFilterPlaceholder: 'Search general contractor',
    },
  ]

  async componentDidMount(): Promise<void> {
    await this.getArchivedProjects();
  }

  async delete(): Promise<void> {
    const json = { deletedAt: moment(Date.now()).toDate() };
    try {
      await this.patch(json);
      this.props.alert?.createAlert({
        message: 'Project(s) successfully deleted.',
        title: 'Success',
        type: 'success'
      });
      await this.getArchivedProjects();
      this.setState({ openedDeleteModal: false });
    } catch (e) {
      this.setState({ openedDeleteModal: false, loading: false });
      this.showErrorAlert();
    }
  }

  async getArchivedProjects(): Promise<void> {
    let projects = await this.dataService?.listProjects(true, false) as Array<ProjectJSON>;
    if (!projects) projects = [];
    projects = projects.map(p => {
      p.addressLine1 = p.addressLine1?.concat(', ' + p.city || '', ' ' + p.zip || '');
      return p;
    });
    this.setState({ loading: false, projects, selectedProjectIds: [] });
  }

  async patch(json: DataJSON): Promise<void> {
    const params = new URLSearchParams();
    this.state.selectedProjectIds.map(p => params.append('id', p));
    await this.dataService.patch('projects', params, json);
    this.setState({ loading: false });
  }

  async restoreFromArchive(): Promise<void> {
    const json = { archivedAt: null };
    try {
      await this.patch(json);
      this.props.alert?.createAlert({
        message: 'Project(s) successfully removed from Archive.',
        title: 'Success',
        type: 'success'
      });
      await this.getArchivedProjects();
      this.setState({ openedDeleteModal: false });
    } catch (e) {
      this.setState({ openedDeleteModal: false, loading: false });
      this.showErrorAlert();
    }
  }

  openDeleteModal(isOpen: boolean): void {
    return this.setState({ openedDeleteModal: isOpen });
  }

  rowClick = (e: any, row: any): void => {
    const selectedProjectId = row.getData().id ? row.getData().id : null;

    row.toggleSelect();

    if (row._row.modules.select.selected) {
      this.setState((prevState) => ({
        selectedProjectIds: [ ...prevState.selectedProjectIds, selectedProjectId ]
      }));
    } else {
      const selectedProjectIds = this.state.selectedProjectIds;
      const index = selectedProjectIds.indexOf(selectedProjectId);
      if (index !== -1) {
        selectedProjectIds.splice(index, 1);
        this.setState({ selectedProjectIds: selectedProjectIds });
      } else {
        this.setState((prevState) => ({
          selectedProjectIds: [ ...prevState.selectedProjectIds, selectedProjectId ]
        }))
      }
    }
  };

  showErrorAlert(): void {
    this.props.alert?.createAlert({
      message: 'Please try again or let us know about it in Contact Us page.',
      title: 'Error',
      type: 'error'
    });
  }

  render(): React.ReactNode {
    return(
      <Page pageTitle='Archived' breadcrumb={[
        { key: 'home', href: '/', content: 'Your Projects', link: true },
        { key: 'archive', content: 'Archived', active: true }
      ]}>
        <div
          style={{
            position: 'sticky',
            width: '100%',
            top: 47.063,
            background: 'white',
            zIndex: 1,
          }}
        >
          <Button color='blue' disabled={this.state.selectedProjectIds.length < 1} onClick={(): Promise<void> => this.restoreFromArchive()}>Restore from Archive</Button>
          <Button color='red' disabled={this.state.selectedProjectIds.length < 1} onClick={(): void => this.openDeleteModal(true)}>Delete Permanently</Button>
        </div>
        <div style={{ marginTop: '20px', fontSize: '20px' }}>
          <b>Select</b> by clicking on desired project(s)  –   <b>To unselect</b> click on the highlighted project(s)
        </div>
        {
          this.state.projects.length > 0 ? (
            <div>
              <ReactTabulator
                ref={(ref: any) => (this.ref = ref)}
                // @ts-ignore
                columns={this.columns}
                data={this.state.projects}
                rowClick={this.rowClick}
                dataFiltered={(): void => {
                  let filtered = false;
                  document.querySelectorAll('div.tabulator .tabulator-header-filter input').forEach((input) => {
                    if (!filtered) { filtered = (input as HTMLInputElement).value !== ''; }
                  });
                  if (filtered !== this.state.filtered) {
                    this.setState({ filtered });
                  }
                }}
              />
              <Modal
                closeIcon
                open={this.state.openedDeleteModal}
                onClose={(): void => this.openDeleteModal(false)}
                onOpen={(): void => this.openDeleteModal(true)}
              >
                <Header icon='trash' content='Delete Project Permanently' />
                <Modal.Content>
                  <p>
                    If you delete this project, you will lose all the associated data and documents associated with this
                    record. You will no longer be able to download payments for application, continuation sheets, or
                    change order sheets. It will not be possible to recover any of this information. Are you sure you
                    want to permanently delete this project?
                  </p>
                </Modal.Content>
                <Modal.Actions>
                  <Button onClick={(): void => this.openDeleteModal(false)}>
                    Cancel
                  </Button>
                  <Button color='red' onClick={(): Promise<void> => this.delete()}>
                    Permanently Delete Project
                  </Button>
                </Modal.Actions>
              </Modal>
            </div>
          ) : (
            <Header as='h3'>There are no Archived Projects.</Header>
          )
        }
      </Page>
    );
  }
}
