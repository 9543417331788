import React from 'react';
import { observer, inject } from 'mobx-react';
import { FirebaseAuthService } from '../services/AuthService';
import { Message, Header } from 'semantic-ui-react';
import LearnMore from './LearnMore';
import PaymentService, { Subscription } from '../services/PaymentService';

interface FreeAccountMessageProps {
  auth?: FirebaseAuthService;
  payment?: PaymentService;
  subscriptions: Array<Subscription> | undefined;
  history?: any
}

@inject('auth')
@observer
export default class FreeAccountMessage extends React.Component<FreeAccountMessageProps> {
  render(): React.ReactNode {
    if (
      (
        // If auth and payment service are not initialized, show nothing
        !this.props.auth?.initialized
      ) || (
        // If auth and payment service are initialized but the user is subscribed, show nothing
        this.props.auth?.initialized
        && this.props.subscriptions?.length
      )
    ) {
      return '';
    }

    // return (
    //   <Message warning className='free-account-warning'>
    //     <Header as='h3' size='medium'>
    //       Your Free Limited Account allows you to create projects, navigate and examine PAYearned (Printing & Downloading restricted)
    //     </Header>
    //     <Header as='h3'>
    //       Get a Full Unlimited Subscription Risk Free. Cancel any time during the first 30 days and you will never be charged.
    //     </Header>
    //     <Header as='h3' className='normal'>
    //       Create, Print & Download Projects Risk Free
    //     </Header>
    //     <p>
    //       <LearnMore history={this.props.history}/>
    //     </p>
    //   </Message>
    // );
    return (
      <div className='free-account-warning'>
        <Header as='h3' size='medium'>
          You are currently using a Free <u>Limited</u> Account
        </Header>
      </div>
    )
  }
}
