import React, { ChangeEvent } from 'react';
import { Button, Form,  Message, Segment } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import { Redirect, Link } from 'react-router-dom';
import { EMAIL_IS_ALREADY_IN_USE_ERROR } from '../../common/Constants';
import TagManagerService from '../../services/TagManagerService';
import { AuthProps } from '../../pages/SignupPage';
import CheckEmailForPasswordPage from '../../pages/CheckEmailForPasswordPage';


export type role = 'SUB_CONTRACTOR' | 'GENERAL_CONTRACTOR' | 'unassigned' | 'ADMINISTRATOR';

interface SignupState {
  role: role;
  email: string;
  errorMessage: string | undefined;
  loading: boolean;
  touched: Array<string>;
  focused: string;
  showCheckEmailMessage: boolean;
}

const email = 'email';

@inject('auth')
@inject('cookie')
@observer
export default class EmailNoPasswordSignup extends React.Component<AuthProps> {

  state: SignupState = {
    role: 'SUB_CONTRACTOR',
    email: '',
    errorMessage: undefined,
    loading: false,
    touched: [],
    focused: '',
    showCheckEmailMessage: false
  }

  private async signUp(): Promise<void> {
    this.setState({ loading: true });
    if (this.props.auth) {
      try {
        this.props.cookie?.setPasswordlessEmail(this.state.email);
        await this.props.auth?.emailLoginLink(this.state.email);
        this.setState({ errorMessage: undefined, showCheckEmailMessage: true });
        TagManagerService.logSignupEvent(this.state.email);
      } catch (e) {
        this.setState({ errorMessage: e.message, loading: false });
      }
    }
  }

  displayErrorMessage(): React.ReactNode {

    if(typeof this.state.errorMessage !== 'undefined' && typeof this.state.errorMessage.startsWith(EMAIL_IS_ALREADY_IN_USE_ERROR)){
      return (
        <Message error>
          {this.state.errorMessage}
          <br/>
          <Link to={{ pathname: '/login', state: { email:this.state.email } }}>Log in with this email.</Link>
        </Message>
      ) ;
    }

    if (this.state.showCheckEmailMessage) {
      return <Redirect to={'/check-email'} />;
    }

    if (this.invalidEmail()) {
      return <Message error content='Invalid email address'/>;
    }

    if (this.state.errorMessage) {
      return (
        <Message negative content={this.state.errorMessage}/>
      );
    }
  }

  invalidEmail(): boolean {
    return this.state.focused !== email
      && this.state.touched.includes(email)
      && this.state.email.match(/^[a-z0-9][a-z0-9+.]*@[a-z0-9-]+(\.[a-z0-9-])+/) === null;
  }

  onChangeEmail(e: React.ChangeEvent<HTMLInputElement>): void {
    let touched = this.state.touched;
    touched.push(email);
    touched = touched.filter((v, i, a) => a.indexOf(v) === i);
    this.setState({ email: e.target.value.trim(), touched: touched });
  }

  onFocus(fieldName: string): void {
    this.setState({ focused: fieldName });
  }

  render(): React.ReactNode {

    if (this.props.auth?.user?.id) {
      setTimeout(() => {
        this.setState({ loading: false });
      });
      if (this.state.showCheckEmailMessage) {
        return <CheckEmailForPasswordPage />;
      }
      return <Redirect to="/"/>;
    }

    return (
      <span>
        <div style={{ width: '420px', height: '60px', display: 'block', marginBottom: '10px' }}>
          {this.displayErrorMessage()}
        </div>
        <Form size='large'>
          <Segment stacked>
            <Form.Input
              required
              type="email"
              error={this.invalidEmail()}
              placeholder="E-mail address"
              value={this.state.email}
              onFocus={(): void => this.onFocus(email)}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => this.onChangeEmail(e)}
            />
            <Button
              disabled={!this.state.email || this.invalidEmail() || this.state.loading}
              color='blue'
              fluid
              size='large'
              loading={this.state.loading}
              onClick={(): Promise<void> => this.signUp()}
            >
              {
                this.state.loading
                  ? <span>&nbsp;</span>
                  : 'Signup'
              }
            </Button>
          </Segment>
        </Form>
      </span>
    );
  }
}
