import { tabulatorParamMap } from './SchemaDefinition';


const mapAjaxResponse = (url: any, params: any, response: any) => {
  return {
    /* eslint-disable @typescript-eslint/camelcase */
    last_page: Math.ceil(parseInt(response.total)/parseInt(response.limit)),
    page: response.skip,
    size: response.limit,
    data: response.data,
  };
};

const mapTabulatorParams = (params: any) => {
  params.page = (params.page - 1) * params.size;
  if (params.filters.length === 0 || params.filters[0].value === '') {
    delete params.filters;
  } else {
    params.filters.forEach((filter: any) => {
      params [filter.field] = filter.value;
      delete params.filters;
    });
  }
  return Object.keys(params).reduce((result: any, key) => {
    const keyWithoutSpecialChars = key.replace('.','');
    const newKey = tabulatorParamMap[keyWithoutSpecialChars];
    result[newKey] = params[key];
    return result;
  }, {});
};

const ajaxURLGenerate = (url: any, config: any, params: any) => {
  const queryParams = mapTabulatorParams(params);
  return url + '?' + new URLSearchParams(queryParams).toString();
};


export {
  ajaxURLGenerate,
  mapAjaxResponse,
};

// Borrowed this gem from https://stackoverflow.com/a/61511955
function waitForElm(selector: string) {
  return new Promise(resolve => {
      if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver(mutations => {
          if (document.querySelector(selector)) {
              resolve(document.querySelector(selector));
              observer.disconnect();
          }
      });

      observer.observe(document.body, {
          childList: true,
          subtree: true
      });
  });
}

export const widthFix = async () => {
  await waitForElm("div.tabulator-row > div.tabulator-cell:last-child");
  setImmediate(() => {
    document.querySelectorAll("div.tabulator-row > div.tabulator-cell:last-child").forEach(cell => {
        // @ts-ignore
        while (cell.parentElement?.clientHeight > 70) {
        // @ts-ignore
        cell.style.width = String(cell.clientWidth-1)+"px";
      }
    });
  });
};