import React from 'react';
import { Dropdown, DropdownProps, Form, FormButton, FormInput, InputOnChangeData } from 'semantic-ui-react';
import { SubscriptionCommonProps } from './AddPaymentMethod';
import Joi from '@hapi/joi';


export interface BankAccount {
  id: string;
  account_holder_name: string;
  account_holder_type: string;
  bank_name: string;
  fingerprint: string;
  routing_number: string;
  customer: string;
  last4: string;
}

const RequiredErrMsg = 'Required field';

interface BankTransactionsState {
  name: string;
  accountHolderNameError: string;
  routingNum: number | null;
  routingNumError: string;
  validAccountForm: boolean;
  accountNum: number | null;
  accountNumError: string;
  accountTypeOption: string | null;
  accountTypeOptionError: boolean;
  isLoading: boolean;
}

interface AddBankAccountProps extends SubscriptionCommonProps {
  onAccountAddition: () => void;
}

export default class AddBankAccount extends React.Component<AddBankAccountProps> {

  state: BankTransactionsState = {
    name: '',
    accountHolderNameError: '',
    routingNum: null,
    routingNumError: '',
    validAccountForm: false,
    accountNum: null,
    accountNumError: '',
    accountTypeOption: '',
    accountTypeOptionError: false,
    isLoading: false
  }

  async handleFormSubmit(): Promise<void> {
    this.setState({ isLoading: true });
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const { token } = await this.props.stripe?.createToken('bank_account', {
      country: 'US',
      currency: 'usd',
      // eslint-disable-next-line @typescript-eslint/camelcase
      routing_number: this.state.routingNum,
      // eslint-disable-next-line @typescript-eslint/camelcase
      account_number: this.state.accountNum,
      // eslint-disable-next-line @typescript-eslint/camelcase
      account_holder_name: this.state.name,
      // eslint-disable-next-line @typescript-eslint/camelcase
      account_holder_type: this.state.accountTypeOption,
    });
    if (token && typeof this.props.auth?.user?.stripeCustomerId !== 'undefined') {
      const bankAccountId = await this.props.payment?.attachBankAccountToCustomer(token.id);
      if (bankAccountId) {
        this.setState({ isLoading: false });
      }
    }
  }

  isFormValid(): boolean {
    const {
      name,
      accountHolderNameError,
      routingNum,
      routingNumError,
      accountNumError,
      accountNum,
      accountTypeOption
    } = this.state;
    return (!(accountHolderNameError.length > 0) && (name.length > 0)) &&
      (!(routingNumError.length > 0) && (routingNum !== null)) &&
      (!(accountNumError.length > 0) && (accountNum !== null))
      && accountTypeOption !== null;
  }


  async validateAccountHolder(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const result = Joi.string().required().messages({ 'string.base': RequiredErrMsg })
      .validate(e.target.value, { abortEarly: true });
    return this.validateForm(result, 'accountHolderNameError');
  }

  async validateRoutingNumber(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const errorMessage = 'Please enter 9 digits for your routing number.\n';
    const result = Joi.string()
      .length(9)
      .message(errorMessage)
      .min(9)
      .message(errorMessage)
      .required()
      .messages({ 'string.base': RequiredErrMsg })
      .validate(e.target.value, { abortEarly: true });
    return this.validateForm(result, 'routingNumError');
  }

  async validateAccountNumber(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const errorMessage = 'Please enter 12 digits for your account number.\n';
    const result = Joi.string()
      .length(12)
      .message(errorMessage)
      .min(12)
      .message(errorMessage)
      .required()
      .messages({ 'string.base': RequiredErrMsg })
      .validate(e.target.value, { abortEarly: true });
    return this.validateForm(result, 'accountNumError');
  }


  async validateForm(validation: Joi.ValidationResult, key: string): Promise<void> {
    if (validation.error) {
      const tempResult = validation.error.details.map(errorDetail => {
        return errorDetail.message.replace(/[^a-zA-Z0-9 ]/g, '')
          .replace('value', 'Field');
      }).join('\n');
      await this.setState({ [key]: tempResult });
    } else {
      await this.setState({ [key]: '' });
    }
    const formStatus = this.isFormValid();
    await this.setState({ validAccountForm: formStatus });
  }

  checkFormStatus(validation: Joi.ValidationResult, key: string, validate: () => boolean): boolean {
    if (validation.error) {
      const tempResult = validation.error.details.map(errorDetail => {
        return errorDetail.message.replace(/[^a-zA-Z0-9 ]/g, '')
          .replace('value', 'Field');
      }).join('\n');
      this.setState({ [key]: tempResult });
    } else {
      this.setState({ [key]: '' });
    }
    return validate();
  }

  async handleDropDownSelect(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): Promise<void> {
    await this.setState({ accountTypeOption: data.value });
    const result = Joi.string()
      .required().messages({ 'string.base': RequiredErrMsg })
      .validate(data.value, { abortEarly: true });
    return this.validateForm(result, 'accountTypeOptionError');
  }

  async handleOnChange(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): Promise<void> {
    return this.setState({ [e.target.name]: data.value });
  }

  render(): React.ReactNode {
    const dropDownOptions = [
      { key: 'individ', text: 'Individual', value: 'individual' },
      { key: 'company', text: 'Company', value: 'company' },
    ];
    const {
      name,
      accountHolderNameError,
      routingNum,
      routingNumError,
      accountNum,
      accountNumError,
      isLoading,
      validAccountForm
    } = this.state;
    return (
      <div>
        <Form onSubmit={(): Promise<void> => this.handleFormSubmit()}>
          <FormInput
            label='Account holder name'
            width='16'
            type='text'
            name='name'
            value={name}
            placeholder='John Smith'
            error={accountHolderNameError ? accountHolderNameError : null}
            onChange={(e, d): Promise<void> => this.handleOnChange(e, d)}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>): Promise<void> => this.validateAccountHolder(e)}
          />
          <FormInput
            label='Routing number'
            width='16'
            type='number'
            value={routingNum}
            name='routingNum'
            placeholder='110000000'
            error={routingNumError ? routingNumError : null}
            onChange={(e, d): Promise<void> => this.handleOnChange(e, d)}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>): Promise<void> => this.validateRoutingNumber(e)}
          />
          <FormInput
            label='Account number'
            width='16'
            type='number'
            value={accountNum}
            name='accountNum'
            placeholder='000123456789'
            error={accountNumError ? accountNumError : null}
            onChange={(e: React.ChangeEvent<HTMLInputElement>, d: InputOnChangeData): Promise<void> => this.handleOnChange(e, d)}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>): Promise<void> => this.validateAccountNumber(e)}
          />
          <Dropdown
            fluid
            search
            selection
            options={dropDownOptions}
            placeholder='Account holder type'
            onChange={(e: React.SyntheticEvent<HTMLElement>, d: DropdownProps): Promise<void> => this.handleDropDownSelect(e, d)}
          />
          <br/>
          <FormButton
            primary
            loading={isLoading}
            disabled={!(validAccountForm && this.props.stripe && !this.state.isLoading)}>
            Create Bank Account
          </FormButton>
        </Form>
      </div>
    );
  }
}
