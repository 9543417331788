import React from 'react';

import { Table } from 'semantic-ui-react';

interface ProjectLineItemsSummaryProps {
  currentTotalScheduledValue: number;
  originalContractAmount: number;
  shortfallExcess: number;
  footerHeadingWidth: number;
  footerAmountWidth: number;
}

export default class ProjectLineItemsSummary extends React.Component<ProjectLineItemsSummaryProps> {

  constructor(props: ProjectLineItemsSummaryProps) {
    super(props);
  }

  render(): React.ReactNode {
    const {
      footerHeadingWidth,
      footerAmountWidth,
      originalContractAmount,
      shortfallExcess,
      currentTotalScheduledValue
    } = this.props;

    return (
      <div>
        <Table unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ width: footerHeadingWidth }}>
                Project Line Total
              </Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'right', width: footerAmountWidth }}>
                {currentTotalScheduledValue.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD'
                })}
              </Table.HeaderCell>
              <Table.HeaderCell> </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell style={{ width: footerHeadingWidth }}>
                Contract Original Scheduled Value
              </Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'right', width: footerAmountWidth }}>
                {originalContractAmount.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD'
                })}
              </Table.HeaderCell>
              <Table.HeaderCell> </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell style={{ width: footerHeadingWidth, color: 'red' }}>
                Current {shortfallExcess < 0 ? 'Shortfall' : 'Excess'}
              </Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'right', width: footerAmountWidth, color: 'red' }}>
                {Math.abs(shortfallExcess).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD'
                })}
              </Table.HeaderCell>
              <Table.HeaderCell> </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        </Table>
      </div>
    );
  } 

}
