import React from 'react';
import Page from '../../components/Page';
import JSONSchemaForm from '@rjsf/semantic-ui';
import {
  UserJSON,
  UserJSONSchema,
  UserUISchema,
  validateUser
} from '../../schemas/UserJSONSchema';
import { DataService } from '../../services/DataService';
import { RouteChildrenProps } from 'react-router-dom';
import Page404 from '../Page404';
import { Loader } from 'semantic-ui-react';
import { FormValidation, IChangeEvent } from '@rjsf/core';
import { FieldTemplate } from '../../schemas/templates/FieldTemplate';
import { FirebaseAuthService, User } from '../../services/AuthService';
import { inject, observer } from 'mobx-react';
import CalculationSchemaForm from '../../components/CalculationSchemaForm';
import { AlertService } from '../../services/AlertService';

interface EditProfileProps extends RouteChildrenProps {
  auth?: FirebaseAuthService;
  alert?: AlertService;
  location: any;
}

interface EditProfileState {
  loading: boolean;
  profile: UserJSON;
}

@observer
@inject('auth')
@inject('alert')
export default class EditProfile extends React.Component<EditProfileProps, EditProfileState> {
  state: EditProfileState = {
    loading: false,
    profile: this.props!.auth!.user as UserJSON,
  }
  dataService = new DataService();
  form?: JSONSchemaForm<UserJSON>;

  async componentDidMount(): Promise<void> {
    const loading = false;
    if (this.props.auth?.user?.hasLogo) {
      const logoData = await this.props.auth.getLogo();
      if (logoData) {
        this.props.auth.user.logo = logoData.url;
      }
    }
    this.setState({ loading });
  }

  async saveLogo(blobUrl: string): Promise<string|undefined|null> {
    const logo = await (await fetch(blobUrl)).blob();
    return this.props.auth?.uploadFile(logo, `logos/${this.props.auth?.user?.firebaseUserId}`);
  }

  async save(e: IChangeEvent<UserJSON>): Promise<void> {
    // Exit if we're already saving
    if (this.state.loading) { return; }

    // Create a new object w/ the form data
    const profile: UserJSON = JSON.parse(JSON.stringify(e.formData)) as UserJSON;

    // Show the loader
    this.setState({ loading: true, profile });

    // Save the logo if it's a blob URL
    if (profile.logo && profile.logo.match(/^blob:/)) {
      const logoURL = await this.saveLogo(profile.logo);
      if (logoURL) {
        profile.hasLogo = true;
        delete profile.logo;
      }
    }

    // Save an existing profile
    try {
      await this.dataService.save('users', profile.id as string, profile);
    } catch(err) {
      this.setState({ loading: false });
      // TODO: Remove hard-coded error here.  This is the only server-side error currently being thrown, so it's hardcoded for now
      this.props.alert!.createAlert({
        message: 'Another user already exists with this license Number.  License number must be unique.',
        title: 'Invalid License Number',
        type: 'error'
      });
      return;
    }

    // Reassign the user info
    this.props.auth!.user = profile as User;
    const  callBackUrl = this.props.location?.query?.callbackUrl;
    callBackUrl ? this.props.history.push(callBackUrl):  this.props.history.push('/profile');
  }

  render(): React.ReactNode {
    // Show the loader
    if (this.state.loading) { return <Loader active>Loading...</Loader>; }

    // Show the error page
    if (!this.props.auth?.user?.id) { return <Page404 {...this.props} />; }

    return (
      <Page pageTitle='Update Profile'>
        <CalculationSchemaForm
          FieldTemplate={FieldTemplate}
          schema={UserJSONSchema}
          uiSchema={UserUISchema}
          formData={this.state.profile}
          onSubmit={(e: IChangeEvent<UserJSON>): Promise<void> => this.save(e)}
          validate={(formData: UserJSON, errors: FormValidation): FormValidation => validateUser(formData, errors)}
        />
      </Page>
    );
  }
}
