import * as React from 'react';
import {
  StepperAction,
  StepperContent,
  StepperContext
} from 'react-material-stepper';
import { cancelButtonStyle, GeneralStepperProps, STEP1 } from './step1';
import { Dimmer, Loader, Radio, Table } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { ProjectLineJSON } from '../../../../schemas/ProjectLineSchema';
import { ProjectJSON } from '../../../../schemas/projects/ProjectJSON';

export const STEP2 = 'step-two';

interface Step2Props extends GeneralStepperProps{
  project: ProjectJSON;
}

const Step2: React.FunctionComponent<Step2Props> = (step2Props) => {
  const { reject, resolve, goAt } = React.useContext(StepperContext);
  const back = (): void => goAt(STEP1);
  const [ projectLines, setProjectLines ] = useState<ProjectLineJSON[] | undefined>([]);
  const [ selectedProjectLine, setSelectedProjectLine ] = useState<ProjectLineJSON>({});
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isError, setIsError ] = useState(false);

  useEffect(() => {
    const fetchProjectLines = async (): Promise<void> => {
      setIsError(false);
      setIsLoading(true);
      setProjectLines(step2Props.project.projectLines?.filter(projectLine => projectLine.lineType === 'original'));
      setIsLoading(false);
    };

    fetchProjectLines();
  }, [ step2Props.project.projectLines ]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (!selectedProjectLine) {
      reject('Unselected project line', 'Please select project line to create change order');
    } else {
      resolve(selectedProjectLine);
    }
  };

  const selectProjectLine = (projectLine: ProjectLineJSON): void => {
    setSelectedProjectLine(projectLine);
  };

  if (isLoading) {
    return <Dimmer active inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>;
  }

  function handleCancelClick(): void {
    step2Props.onResolveCancel();
  }

  return (
    <StepperContent
      onSubmit={onSubmit}
      actions={
        <React.Fragment>
          <StepperAction
            style={ cancelButtonStyle }
            onClick={ handleCancelClick }
            align='left'
            type='button'>
            Cancel
          </StepperAction>
          <StepperAction align="right" type="button" onClick={back}>
            Back
          </StepperAction>
          <StepperAction align='right' type="submit" disabled={!selectedProjectLine.id}>
            Continue
          </StepperAction>
        </React.Fragment>
      }
    >
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell/>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Scheduled Value</Table.HeaderCell>
            <Table.HeaderCell>Retainage</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            isError ?
              <div>Could not get data, please try again.</div>:
              projectLines?.map(projectLine => (

                <Table.Row key={projectLine.id}>
                  <Table.Cell textAlign='center'>
                    <Radio
                      checked={!!selectedProjectLine && selectedProjectLine === projectLine}
                      onClick={(): void => selectProjectLine(projectLine)}
                    />
                  </Table.Cell>
                  <Table.Cell>{projectLine.description}</Table.Cell>
                  <Table.Cell>{
                    projectLine.scheduledValue
                      ? projectLine.scheduledValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                      : ''
                  }</Table.Cell>
                  <Table.Cell>{
                    projectLine.retainage ? Number(projectLine.retainage).toLocaleString('en-US', { style: 'percent' }) : ''
                  }</Table.Cell>
                </Table.Row>
              ))
          }
        </Table.Body>
      </Table>
    </StepperContent>
  );
};

export default Step2;
